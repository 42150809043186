import React from 'react';

export const BongaNaNdovuFAQs = () => {
  return (
    <div className="mt-5">
      <ol>
        <li>
          <div>
            <div className="font-weight-bolder">What is Bonga na Ndovu? </div>
            <div>
              This is a service that allows Ndovu clients to convert Safaricom
              loyalty points (‘Bonga points’) into cash.{' '}
            </div>
          </div>
        </li>
        <br />

        <li>
          <div>
            <div className="font-weight-bolder">
              Why is it being offered now?
            </div>
            <div>
              Effective January 1, 2023, all Bonga points older than three years
              will expire and will be unavailable for redemption.
            </div>
          </div>
        </li>
        <br />

        <li>
          <div>
            <div className="font-weight-bolder">
              What is the conversion rate?
            </div>
            <div>1 Bonga point = KES 0.20</div>
            <div>This is a rate set by the telco, not Ndovu.</div>
          </div>
        </li>
        <br />

        <li>
          <div>
            <div className="font-weight-bolder">
              Can I withdraw my cash from Ndovu once the conversion is done?
            </div>
            <div>
              Yes. All withdrawals will be subject to a processing fee of KES
              50.
            </div>
          </div>
        </li>
        <br />

        <li>
          <div>
            <div className="font-weight-bolder">How will my cash be paid?</div>
            <div>
              All payments will be via mobile money to the number indicated in
              the client’s Ndovu account.
            </div>
          </div>
        </li>
        <br />

        <li>
          <div>
            <div className="font-weight-bolder">
              How long will it take for me to receive my cash if I withdraw?
            </div>
            <div>
              3 business days. Note that this applies only to clients with
              approved Ndovu accounts. The period may be longer than 3 business
              days if your account details are incomplete.
            </div>
          </div>
        </li>
        <br />

        <li>
          <div>
            <div className="font-weight-bolder">
              Can anyone use the Bonga na Ndovu service?
            </div>
            <div>
              Yes, as long as you have opened a Ndovu account and provided all
              the required information.
            </div>
          </div>
        </li>
        <br />

        <li>
          <div>
            <div className="font-weight-bolder">
              Can I use my points on Ndovu after 31st December 2022?
            </div>
            <div>
              Yes, you can use your points to invest in the funds offered on the
              platform.
            </div>
          </div>
        </li>
      </ol>
    </div>
  );
};

import React from 'react';
import Input from './Input/Input';
import { getInputClasses } from '../../helpers/getInputClasses';
import UnableViewPassword from './UnableViewPassword';
import { ValidationErrors } from '../Business/components/ValidationErrors';

export const PasswordInput = ({
  viewPassword,
  formik,
  setViewPassword,
  name,
  placeholder,
  children,
  passwordContainerSpacing,
}) => {
  return (
    <div
      className={`${
        passwordContainerSpacing ? passwordContainerSpacing : 'mt-7'
      } fv-plugins-icon-container`}
    >
      <Input
        placeholder={placeholder}
        type={viewPassword ? 'text' : 'password'}
        className={`form-control height-45 input-field ${getInputClasses(
          name,
          formik,
        )}`}
        name={name}
        formik={formik}
      />

      <UnableViewPassword
        handleClickEvent={() => setViewPassword(!viewPassword)}
        viewPassword={viewPassword}
        classes="password-eye-icon"
      />

      {children ? (
        children
      ) : formik.touched[name] && formik.errors[name] ? (
        <div className="mt-7">
          <ValidationErrors errorMessage={formik.errors[name]} />
        </div>
      ) : null}
    </div>
  );
};

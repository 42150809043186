import React from 'react';

export const DropdownContainer = ({ children, classes, fitContainer }) => {
  return (
    <div
      className={`${classes} ${
        fitContainer ? 'w-100' : 'width-345'
      } center-input mt-7`}
    >
      {children}
    </div>
  );
};

import React from 'react';
import EmptyFolder from '../../../icons/EmptyFolder.svg';

const NoInvestmentMade = () => {
  return (
    <div className="py-5 text-center">
      <img src={EmptyFolder} alt="No investment made" />
      <p className="mx-auto mt-8" style={{ width: '246px', fontSize: '14px' }}>
        No investment made so far. Go ahead and invest now.
      </p>
    </div>
  );
};

export default NoInvestmentMade;

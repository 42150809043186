import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_CONVERSIONS = `${REACT_APP_BASE_URL}/v1/investor/conversions`;
const FETCH_CONVERSION = `${REACT_APP_BASE_URL}/v1/investor/conversions/exchange-rate`;

const fetchConversions = async () => {
  const res = await axios.get(`${FETCH_CONVERSIONS}`);
  return res;
};

const fetchConversion = async (originCurrency, destinationCurrency) => {
  const res = await axios.get(`${FETCH_CONVERSION}`, {
    params: { originCurrency, destinationCurrency },
  });
  return res;
};

export { fetchConversions, fetchConversion };

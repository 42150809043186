import React from 'react';
import HorizontalLine from '../../../helpers/horizontalLine';

export const GoalSummaryInfo = ({ label, amount, currency }) => {
  return (
    <div className="card card-gutter-b internal-container border-light-blue-600 text-14 font-weight-500 p-5 mt-5">
      <span className="text-center mb-2">{`${amount} ${currency}`}</span>
      <HorizontalLine width={'308px'} color="#D9D9D9" height={0.1} />
      <span className="text-center text-blue mt-2">{label}</span>
    </div>
  );
};

import React from 'react';
import HorizontalLine from '../../../helpers/horizontalLine';

export const TransactionDetailsItem = ({ currency, value, label, bold }) => {
  return (
    <div>
      <div className="d-flex justify-content-between confirm-trans-details-text py-2">
        <div className={bold ? 'font-weight-600' : ''}>{label}</div>
        <div>
          {currency ? (
            <span className={'font-weight-500 text-blue'}>{currency}</span>
          ) : null}
          <span className={`text-blue font-weight-500`}> {value}</span>
        </div>
      </div>
      <HorizontalLine color="#EFEFEF" width={'100%'} height={0.1} />
    </div>
  );
};

import React from 'react';

const CongratulationContent = () => {
  return (
    <>
      <p className="font-weight-500 text-primary-orange text-13 text-center px-md-35 px-5 my-10">
        Your payment instructions have been received. Please see e-mail for your
        transaction details
      </p>
      <div className="p-md-10 p-5">
        <p className="ml-md-5 text-16 text-center font-weight-500">
          Please Note
        </p>
        <ul className="text-14">
          <li>
            All trade instructions are placed on a best-execution basis. All
            trade instructions & payments received by noon (EAT) are placed on
            the same day.
          </li>
          <li className="my-2">
            All trade instructions & payments received after noon (EAT) will be
            placed on the following day.
          </li>
          <li>
            All trade instructions & payments received on a weekend or public
            holiday in  Kenya or the USA will be actioned on the next working
            day.
          </li>
        </ul>
      </div>
    </>
  );
};

export default CongratulationContent;

import React from 'react';

export const TeamMemberPermissions = ({
  teamMemberPermissions,
  alignToLeft,
}) => {
  return (
    <div
      className={`team-member-permissions-container ${
        alignToLeft ? '' : 'center-input'
      }`}
    >
      <div className="d-flex flex-wrap pl-0 mt-2">
        {teamMemberPermissions?.map(permission => (
          <div
            className="d-flex flex-row permission-container pl-0 py-2"
            key={permission}
          >
            <label
              id="custom-checkbox"
              className="checkbox custom-checkbox checkbox-lg checkbox-single flex-shrink-0"
            >
              <input type="checkbox" checked onChange={() => {}} />
              <span></span>
            </label>
            <span className="checkbox-label">{permission}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

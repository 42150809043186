import React, { useState } from 'react';
import { connect } from 'react-redux';

import CommonButton from '../Common/Button';
import BackArrow from '../Common/BackArrow';
import { downgradeSubscription } from './_redux/subscriptionActions';
import * as profile from '../Profile/_redux/profileRedux';
import { getUserProfile } from '../Profile/_redux/profileCrud';

const ConfirmDowngrade = ({
  profileAction,
  setConfirm,
  setAlertOn,
  setAlert,
}) => {
  const [loading, setLoading] = useState(false);
  const handleSubscribe = async () => {
    try {
      setLoading(true);
      const response = await downgradeSubscription();
      setLoading(false);
      if (response?.status === 200) {
        const userProfile = await getUserProfile();
        profileAction(userProfile?.data?.data);
        setConfirm(false);
      }
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setTimeout(() => {
        setAlertOn(!setAlertOn);
      }, 3000);
    }
  };
  return (
    <>
      <div className="ml-5">
        <BackArrow text={'Back'} onClick={() => setConfirm(false)} />
      </div>
      <div className="p-5 mx-5 mt-5 mb-10 card card-custom ">
        <div className="mt-5 text-center">
          <p className="font-weight-bolder">Downgrading to Basic</p>
          <p className="font-weight-bold">
            Are you sure you want to downgrade to basic plan?
          </p>
          <p>If you continue you will lose all your Pro privileges</p>
        </div>

        <div className="my-10 text-center">
          <CommonButton
            btnText="Continue and Downgrade"
            className="ml-5 text-center resize common-btn cancel-btn-padding"
            onClick={handleSubscribe}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  profile: state?.profile,
});

export default connect(mapStateToProps, { ...profile?.actions })(
  ConfirmDowngrade,
);

import React from 'react';
import { StepNumberOne } from '../../../Common/StepNumberOne';
import { StepNumberTwo } from '../../../Common/StepNumberTwo';
import { StepNumberThree } from '../../../Common/StepNumberThree';

export const InstructionStep = ({ text, stepNumber }) => {
  const steps = {
    1: <StepNumberOne backgroundColor={'#0071CE'} color={'#ffffff'} />,
    2: <StepNumberTwo backgroundColor={'#0071CE'} color={'#ffffff'} />,
    3: <StepNumberThree backgroundColor={'#0071CE'} color={'#ffffff'} />,
  };
  return (
    <div className="d-flex align-items-center mt-3">
      {steps[stepNumber]}
      <div className="ml-2 text-12">{text}</div>
    </div>
  );
};

import { FundType, goalNdovuFee } from './constants';
import convertAmountToNumber from './convertAmountToNumber';

export const getNdovuFee = (goalId, planDetails, ndovuPercentage) => {
  let fee;
  const ndovuFeeCustomFee = planDetails?.ndovuFeeCustomFee;
  const fundType = planDetails?.fundType;
  const useNdovuCustomFee = planDetails?.useNdovuCustomFee;
  const allowBongaPointPayment = planDetails?.allowBongaPointPayment;

  if (goalId) {
    fee = goalNdovuFee;
  } else if (fundType === FundType.SAVE || allowBongaPointPayment) {
    fee = 0;
  } else if (
    useNdovuCustomFee &&
    ndovuFeeCustomFee !== null &&
    ndovuFeeCustomFee !== undefined
  ) {
    fee = ndovuFeeCustomFee;
  } else {
    fee = ndovuPercentage;
  }

  return fee;
};

export const calculateNdovuFee = (
  goalId,
  amount,
  planDetails,
  discountedAmount,
  ndovuPercentage,
) => {
  if (goalId) {
    return convertAmountToNumber(
      ((convertAmountToNumber(amount) * goalNdovuFee) / 100).toFixed(2),
    );
  }

  const fundType = planDetails?.fundType;
  const allowBongaPointPayment = planDetails?.allowBongaPointPayment;
  const useNdovuCustomFee = planDetails?.useNdovuCustomFee;
  const ndovuFeeCustomFee = planDetails?.ndovuFeeCustomFee;

  if (
    useNdovuCustomFee &&
    ndovuFeeCustomFee !== null &&
    ndovuFeeCustomFee !== undefined
  ) {
    if (ndovuFeeCustomFee <= 0) {
      return 0;
    }

    return (convertAmountToNumber(amount) * ndovuFeeCustomFee) / 100;
  } else if (fundType === FundType.SAVE || allowBongaPointPayment) {
    return 0;
  }

  if (discountedAmount <= 0) {
    return 0;
  }
  const ndovuFee = (convertAmountToNumber(amount) * ndovuPercentage) / 100;
  return convertAmountToNumber(ndovuFee.toFixed(2));
};

import React from 'react';

export const StepNumberOne = ({ backgroundColor, color }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill={backgroundColor} />
        <path
          d="M11.0476 8.04V6.878H13.6796V17H12.3916V8.04H11.0476Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

import { accountTypes } from './constants';
import { shortenString } from './handlingStrings';

export const displayInvestorFirstName = (
  investorType,
  investorName,
  businessChamaName,
  characterLimit,
) => {
  return investorType === accountTypes.INDIVIDUAL
    ? shortenString(investorName, characterLimit)
    : shortenString(businessChamaName, characterLimit);
};

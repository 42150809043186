import React from 'react';
import { useHistory } from 'react-router-dom';

import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { ConfirmButton } from '../../Common/ReusableButtons';

export const Confirmation = () => {
  const history = useHistory();
  return (
    <KycPageContentWrapper>
      <div className="text-center font-weight-500 text-15">
        Your request has been received and our team will contact you with the
        instructions to change the details. Please, allow 3 business days.
      </div>
      <div className="d-flex justify-content-center align-items-center mt-8">
        <ConfirmButton
          buttonText={'EXIT TO DASHBOARD'}
          handleOnClick={() => history.push('/')}
        />
      </div>
    </KycPageContentWrapper>
  );
};

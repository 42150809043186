import imageCompression from 'browser-image-compression';
import { ALLOWED_CHARS_REGEX } from './constants';

export const isFileNameValid = name => {
  if (ALLOWED_CHARS_REGEX.test(name.split('.')[0])) {
    return {
      valid: false,
      error:
        'The only special characters allowed in the file name are +, -, \\ _,',
    };
  }

  if (name.split('.')[0]?.length > 30) {
    return {
      valid: false,
      error: 'File name can not have more than 30 characters long.',
    };
  }

  return { valid: true, error: null };
};

const getImageFormat = arrayBuffer => {
  const arr = new Uint8Array(arrayBuffer).subarray(0, 4);
  let header = '';

  for (let i = 0; i < arr.length; i++) {
    header += arr[i].toString(16);
  }

  switch (true) {
    case /^89504e47/.test(header):
      return 'image/png';
    case /^ffd8ff/.test(header):
      return 'image/jpeg';
    case /^25504446/.test(header):
      return 'application/pdf';
    default:
      return false;
  }
};

export const validateFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = e => {
      const validation = {};
      const result = e.target.result;
      const valid = getImageFormat(result);
      if (!valid) {
        validation.valid = false;
        validation.error =
          'You can upload files with png, jpg, or pdf extensions';
      } else {
        validation.valid = true;
        validation.error = null;
      }
      resolve(validation);
    };

    reader.onerror = error => {
      reject(error);
    };
  });
};

export const compressImage = async file => {
  const options = {
    maxSizeMB: 5,
    useWebWorker: true,
    alwaysKeepResolution: true,
  };

  const compressedFile = await imageCompression(file, options);
  const newFile = new File([compressedFile], file.name, { type: file.type });

  return newFile;
};

export const generateImageDataUrl = file => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = e => {
      const fileDataUrl = e.target.result;
      resolve(fileDataUrl);
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const theFileIsLessThanOrEqualToMaximumSize = (
  fileSize,
  maximumSize,
) => {
  return fileSize <= maximumSize;
};

import React from 'react';

export const AcceptOrReject = ({ handleInvite, invite, text }) => {
  return (
    <div
      className="custom-btn mr-3 cursor-pointer"
      onClick={() =>
        handleInvite &&
        handleInvite(parseInt(invite.investor.id), invite.inviteCode)
      }
    >
      {text}
    </div>
  );
};

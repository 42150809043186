import React from 'react';

export const StepNumberThree = ({ backgroundColor, color }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill={backgroundColor} />
        <path
          d="M9.59586 9.426C9.66119 8.56733 9.99253 7.89533 10.5899 7.41C11.1872 6.92467 11.9619 6.682 12.9139 6.682C13.5485 6.682 14.0945 6.79867 14.5519 7.032C15.0185 7.256 15.3685 7.564 15.6019 7.956C15.8445 8.348 15.9659 8.79133 15.9659 9.286C15.9659 9.86467 15.7979 10.364 15.4619 10.784C15.1352 11.204 14.7059 11.4747 14.1739 11.596V11.666C14.7805 11.8153 15.2612 12.1093 15.6159 12.548C15.9705 12.9867 16.1479 13.5607 16.1479 14.27C16.1479 14.802 16.0265 15.2827 15.7839 15.712C15.5412 16.132 15.1772 16.4633 14.6919 16.706C14.2065 16.9487 13.6232 17.07 12.9419 17.07C11.9525 17.07 11.1405 16.8133 10.5059 16.3C9.87119 15.7773 9.51653 15.04 9.44186 14.088H10.6739C10.7392 14.648 10.9679 15.1053 11.3599 15.46C11.7519 15.8147 12.2745 15.992 12.9279 15.992C13.5812 15.992 14.0759 15.824 14.4119 15.488C14.7572 15.1427 14.9299 14.6993 14.9299 14.158C14.9299 13.458 14.6965 12.954 14.2299 12.646C13.7632 12.338 13.0585 12.184 12.1159 12.184H11.7939V11.12H12.1299C12.9885 11.1107 13.6372 10.9707 14.0759 10.7C14.5145 10.42 14.7339 9.99067 14.7339 9.412C14.7339 8.91733 14.5705 8.52067 14.2439 8.222C13.9265 7.92333 13.4692 7.774 12.8719 7.774C12.2932 7.774 11.8265 7.92333 11.4719 8.222C11.1172 8.52067 10.9072 8.922 10.8419 9.426H9.59586Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const SEND_REFERRAL_URL = `${REACT_APP_BASE_URL}/v1/investor/investors/referral-corporate`;
const SUBMIT_ACQUISITION_CHANNEL = `${REACT_APP_BASE_URL}/v1/investor/investors/acquisition-channel`;

const sendReferralCode = async (referralCode, voucher) => {
  const res = await axios.put(SEND_REFERRAL_URL, { referralCode, voucher });
  return res;
};

const submitAcquisitionChannel = async acquisitionChannel => {
  const res = await axios.put(SUBMIT_ACQUISITION_CHANNEL, {
    acquisitionChannel,
  });
  return res;
};

export { sendReferralCode, submitAcquisitionChannel };

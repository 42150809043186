import React from 'react';
import SVG from 'react-inlinesvg';

export const ShowMoreOrLess = ({ text, iconUrl, toggleShowMoreOrLess }) => {
  return (
    <div className="d-flex justify-content-end mt-5">
      <div
        className="d-flex align-items-center cursor-pointer w-auto ms-auto"
        onClick={toggleShowMoreOrLess}
      >
        <div className="text-14 font-weight-500">{text}</div>
        <div className="ml-2">
          <SVG src={iconUrl} />
        </div>
      </div>
    </div>
  );
};

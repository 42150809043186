import React from 'react';
import SVG from 'react-inlinesvg';
import '../components/index.scss';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

const FileUpload = ({
  title,
  titleStart,
  name,
  handleChange,
  displayText,
  required,
  disabled,
  inputContainerWidth,
}) => {
  const handleFileChange = e => {
    handleChange(e, name);
  };

  return (
    <div className="width-345" style={inputContainerWidth}>
      <div className="mb-2 text-13 text-dark-gray-200">
        {titleStart}
        <span className="font-weight-700">{title}</span>
        {required ? (
          <span
            className="text-red-500 d-inline"
            style={{
              marginLeft: '1px',
            }}
          >
            *
          </span>
        ) : null}
      </div>
      <div className="col-lg-5 pl-0 ">
        <div className="file-input d-flex align-items-center">
          <input
            type="file"
            accept="image/png,image/jpeg,.pdf"
            name={name}
            onChange={handleFileChange}
            disabled={disabled}
          />
          <div className="d-flex align-items-center" htmlFor={name}>
            <label
              className={`d-flex align-items-center font-weight-500 height-45 width-140  input-label${
                displayText === 'Upload File' ? '' : '-change'
              }`}
              htmlFor={name}
            >
              <div className="d-flex justify-content-center align-items-center">
                <SVG src={toAbsoluteUrl('/media/svg/ndovu/file.svg')} />
                <span className="ml-2 text-13 font-weight-500">
                  {displayText}{' '}
                </span>
              </div>
            </label>
          </div>
          <div
            style={{ marginTop: '-5px' }}
            className="ml-3 text-13 font-weight-500"
          >
            (Max 5MB)
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;

import React from 'react';

const PaymentChannel = ({ paymentChannel, otherText }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <h1 className="payment-channel">
          Pay with{' '}
          <span className="payment-channel-color">{paymentChannel}</span>
        </h1>
      </div>
      {otherText ? (
        <div className="d-flex justify-content-center payment-channel-sub-title">
          {otherText}
        </div>
      ) : null}

      <p className="mt-5 font-weight-bold d-flex justify-content-center instructions-title">
        To complete the transaction:
      </p>
    </>
  );
};

export default PaymentChannel;

import React from 'react';

const ExtremelyLowRisk = () => {
  const squares = [0, 1, 2, 3, 4, 5];
  return (
    <div className="d-flex justify-content-end">
      <span className="risk-profile-tiny-square border-radius-left-side blue "></span>
      <span className="risk-profile-tiny-square border-radius-right-side"></span>
      {squares.map(item => (
        <span
          key={item}
          className="risk-profile-little-square gray ml-1"
        ></span>
      ))}
    </div>
  );
};

export default ExtremelyLowRisk;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PocketAccount } from './PocketAccount';
import PocketOperations from './PocketOperations';

const ManageAccount = ({ match }) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`${match.path}/:pocketId`}
          component={PocketAccount}
        />

        <Route
          exact
          path={`${match.path}/:pocketId/withdraw`}
          component={PocketOperations}
        />

        <Route
          exact
          path={`${match.path}/:pocketId/topup`}
          component={PocketOperations}
        />

        <Route
          exact
          path={`${match.path}/:pocketId/conversion`}
          component={PocketOperations}
        />

        <Route
          exact
          path={`${match.path}/:pocketId/investment`}
          component={PocketOperations}
        />
      </Switch>
    </>
  );
};

export default ManageAccount;

import React from 'react';
import Back from '../../Common/BackArrow';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { ConfirmButton } from '../../Common/ReusableButtons';

const SettlementNotification = () => {
  const history = useHistory();

  return (
    <>
      <Back title={'Withdrawal Details'} text={'Back'} />
      <div className="mt-5">
        <div className="card card-custom">
          <div className="card-body d-flex flex-column justify-content-center px-1 px-md-20">
            <div className="border-1-light-blue-150 border-radius-16 p-10 d-flex flex-column align-items-center">
              <div>
                <SVG
                  className="mb-1"
                  src={toAbsoluteUrl('/media/svg/ndovu/orangeElephant.svg')}
                />
              </div>

              <div className="withdraw-title py-3">
                Where should we send your money?
              </div>
              <p className="withdraw-info">
                Please add withdrawal details to complete this operation
              </p>

              <ButtonsContainer>
                <ConfirmButton
                  buttonText={'Add Withdrawal Details'}
                  handleOnClick={() => history.push('/withdrawal-details')}
                />
              </ButtonsContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettlementNotification;

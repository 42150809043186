import React from 'react';
import './index.scss';

const Caution = ({ detail, classes }) => {
  return (
    <div
      className={`caution custom-width-input mt-5 col-lg-8 d-flex justify-content-center ${
        classes ? classes : ''
      }`}
    >
      <span className="font-weight-bolder">Note: </span>
      {detail}
    </div>
  );
};

export default Caution;

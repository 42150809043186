import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const INVITE_TEAM_MEMBER = `${REACT_APP_BASE_URL}/v1/investor/business-chama/invite`;
const FETCH_TEAM_MEMBERS = `${REACT_APP_BASE_URL}/v1/investor/business-chama/fetch-users`;
const FETCH_INVESTOR_ACCOUNTS = `${REACT_APP_BASE_URL}/v1/investor/business-chama/fetch-investor-accounts`;
const SWITCH_ACCOUNT = `${REACT_APP_BASE_URL}/v1/investor/business-chama/switch-view`;
const SUBMIT_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/business-chama/update-business-chama`;
const UPDATE_ROLE = `${REACT_APP_BASE_URL}/v1/investor/business-chama/update-role`;
const UPDATE_MEMBER_STATUS = `${REACT_APP_BASE_URL}/v1/investor/business-chama/update-active-status`;
const FETCH_ALL_INVITES = `${REACT_APP_BASE_URL}/v1/investor/business-chama/fetch-all-invites`;
const FETCH_USER_INVITES = `${REACT_APP_BASE_URL}/v1/investor/business-chama/fetch-invites`;
const FETCH_ALL_TEAM_MEMBERS = `${REACT_APP_BASE_URL}/v1/investor/business-chama/fetch-users`;

const ACTIVATE_MEMBERSHIP = `${REACT_APP_BASE_URL}/v1/investor/business-chama/accept`;

// kyc-documentation
const SUBMIT_KYC_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/business-chama/kyc-documentation`;
const SUBMIT_KYC_ADDITIONAL_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/business-chama/kyc-additional`;

const inviteTeamMember = async payload => {
  const res = await axios.post(INVITE_TEAM_MEMBER, payload);
  return res;
};

const fetchTeamMembers = async () => {
  const res = await axios.get(FETCH_TEAM_MEMBERS);
  return res;
};

const updateMemberStatus = async payload => {
  const res = await axios.post(UPDATE_MEMBER_STATUS, payload);
  return res;
};

const fetchInvestorAccounts = async () => {
  const res = await axios.get(FETCH_INVESTOR_ACCOUNTS);
  return res;
};

const updateMemberRole = async payload => {
  const res = await axios.post(UPDATE_ROLE, payload);
  return res;
};

const switchInvestorAccounts = async payload => {
  const res = await axios.post(SWITCH_ACCOUNT, payload);
  return res;
};

const submitBusinessChamaDetails = async payload => {
  const res = await axios.put(SUBMIT_DETAILS, payload);
  return res;
};

const submitbuschamaKYC = async payload => {
  const res = await axios.put(SUBMIT_KYC_DETAILS, payload);
  return res;
};

const buschamaAdditionalDetails = async payload => {
  const res = await axios.put(SUBMIT_KYC_ADDITIONAL_DETAILS, payload);
  return res;
};

const acceptMembership = async payload => {
  const res = await axios.post(ACTIVATE_MEMBERSHIP, payload);
  return res;
};

const fetchAllInvites = async () => {
  const res = await axios.get(FETCH_ALL_INVITES);
  return res;
};

const fetchAllTeamMembers = async () => {
  const res = await axios.get(FETCH_ALL_TEAM_MEMBERS);
  return res;
};

const fetchUserInvites = async () => {
  const res = await axios.get(FETCH_USER_INVITES);
  return res;
};

export {
  inviteTeamMember,
  fetchInvestorAccounts,
  switchInvestorAccounts,
  submitBusinessChamaDetails,
  submitbuschamaKYC,
  fetchTeamMembers,
  updateMemberRole,
  updateMemberStatus,
  acceptMembership,
  fetchAllInvites,
  fetchUserInvites,
  buschamaAdditionalDetails,
  fetchAllTeamMembers,
};

import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const REGISTER_WITH_WIZARD_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/register`; //TODO: separate this logic from the register URL
const LOGGED_IN_USER_INVESTOR_PROFILE_URL = `${REACT_APP_BASE_URL}/v1/investor/investors/complete-wizard`;

const registerWithWizardDetails = async ({
  firstName,
  lastName,
  password,
  phoneNumber,
  email,
  age,
  purpose,
  monthlyIncome,
  monthlySavings,
  cashSavings,
  timeFrame,
  investmentExperience,
  investmentAttitude,
  tenPercentInvestmentLoss,
  familiarInvestments,
}) => {
  const response = await axios.post(REGISTER_WITH_WIZARD_URL, {
    firstName,
    lastName,
    password,
    phoneNumber,
    email,
    age,
    purpose,
    monthlyIncome,
    monthlySavings,
    cashSavings,
    timeFrame,
    investmentExperience,
    investmentAttitude,
    tenPercentInvestmentLoss,
    familiarInvestments,
  });

  return response;
};

const updateLoggedInUserInvestorProfile = async ({
  age,
  purpose,
  monthlyIncome,
  monthlySavings,
  cashSavings,
  timeFrame,
  investmentExperience,
  investmentAttitude,
  tenPercentInvestmentLoss,
  familiarInvestments,
}) => {
  const response = await axios.put(LOGGED_IN_USER_INVESTOR_PROFILE_URL, {
    age,
    purpose,
    monthlyIncome,
    monthlySavings,
    cashSavings,
    timeFrame,
    investmentExperience,
    investmentAttitude,
    tenPercentInvestmentLoss,
    familiarInvestments,
  });

  return response;
};

export { registerWithWizardDetails, updateLoggedInUserInvestorProfile };

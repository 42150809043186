import React from 'react';
import { FundType } from '../../../../helpers/constants';

import { investmentsCopy } from '../../../../utils/investmentsCopy';
import { PlanDetailsCard } from '../PlanDetailsCard';

const HistoricalPerformance = ({ children, plan }) => {
  return (
    <PlanDetailsCard title={investmentsCopy.performance.title}>
      <div className="w-100 d-flex flex-wrap text-center text-left text-16 text-dark-gray-550 performance-container p-5 my-5">
        <div className="width text-left text-16 text-dark-gray-550 px-md-10 px-5 mt-md-6 mt-0">
          {plan.fundType === FundType.SAVE
            ? investmentsCopy.performance.descriptionForSavingFund
            : investmentsCopy.performance.description}
        </div>

        <div className="width">{children}</div>
      </div>
    </PlanDetailsCard>
  );
};

export default HistoricalPerformance;

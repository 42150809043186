import React from 'react';
import GoalIcon from '../../../../icons/NewInvestmentIcons/elephant.svg';
import SaveIcon from '../../../../icons/NewInvestmentIcons/elephant2.svg';
import InvestIcon from '../../../../icons/NewInvestmentIcons/flyingPlane.svg';

const saveText = (
  <div>
    <span>
      Earn <span className="font-weight-bolder">up to 7% fixed return</span> per
      year.
    </span>
  </div>
);

const investText = (
  <div>
    Choose an investment with average
    <span className="font-weight-bolder"> potential of 20% a year </span> with
    moderate risk.
  </div>
);

const goalText = (
  <div>
    Leave it to the experts &
    <span className="mt-3 font-weight-bolder"> watch your money grow.</span>
  </div>
);

export const investmentOptions = [
  {
    title: 'Fixed Returns',
    text: saveText,
    elephantIcon: SaveIcon,
    link: '/plans/fixed-return',
  },
  {
    title: 'General Investing',
    text: investText,
    elephantIcon: InvestIcon,
    link: '/plans',
  },
  {
    title: 'Goal-based Investing',
    text: goalText,
    elephantIcon: GoalIcon,
    link: '/goal/select-goal',
  },
];

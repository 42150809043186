import React from 'react';

const TopCard = ({
  children,
  imageBackground,
  imageLink,
  onClick,
  borderRdius,
}) => {
  return (
    <div onClick={onClick}>
      {imageLink ? (
        <div
          style={{
            backgroundImage: `url('${imageLink}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          className={`plan-card-first-container ${imageBackground} ${borderRdius}`}
        >
          {children}
        </div>
      ) : (
        <div
          className={`plan-card-first-container ${imageBackground} ${borderRdius}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default TopCard;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { paymentMethod, PaymentType } from '../../helpers/constants';
import Alert from '../Common/Alert';
import PaymentButtons from '../Common/PaymentButtons';
import BackArrow from '../Common/BackArrow';
import SubscriptionCurrency from './subscriptionCurrency';
import '../Onboarding/index.scss';
import { getCardPaymentModal as subscribeToBlack } from '../../helpers/cardPaymentModal';
import PayWithMpesa from '../Invest/components/PayWithMpesa';

const Subscribe = () => {
  let history = useHistory();
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState();
  const [payWithMpesa, setPayWithMpesa] = useState(false);
  const [mpesaDisabled, setMpesaDisabled] = useState(false);
  const [cardDisabled, setCardDisabled] = useState(false);
  const [paymentCurrency, setPaymentCurrency] = useState(null);
  const [userPickedCurrencyBool, setUserPickedCurrencyBool] = useState(false);

  const { prices, subscription } = history.location.state;
  if (!prices) history.push('/subscription');

  const handleSubscribe = async () => {
    setLoading(true);
    try {
      const price = prices.find(item => item.currency === paymentCurrency);
      const response = await subscribeToBlack(
        price.amount,
        price.currency,
        null,
        paymentMethod.CARD,
        PaymentType.SUBSCRIPTION_PAYMENT,
      );

      const { data: { data: { link } = {} } = {} } = response;
      localStorage.setItem(
        'action',
        JSON.stringify({
          name: 'subscribe',
          id: null,
        }),
      );
      window.location.replace(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setTimeout(() => {
        setAlertOn(!setAlertOn);
      }, 3000);
    }
  };

  const togglePaymentBtns = (toggleMpesaBtn = false, toggleCardBtn = false) => {
    setUserPickedCurrencyBool(true);
    setMpesaDisabled(toggleMpesaBtn);
    setCardDisabled(toggleCardBtn);
  };

  return (
    <>
      {payWithMpesa ? (
        <PayWithMpesa
          setPayWithMpesa={setPayWithMpesa}
          amount={prices.find(item => item.currency === 'KES').amount}
        />
      ) : (
        <>
          <div className="mx-2 mx-md-30 mx-lg-30">
            <BackArrow
              text={'Back'}
              onClick={() => history.push('/subscription')}
            />
          </div>
          <div id="card-margin" className="card card-custom mx-30">
            <div className="mx-5 mt-5 mb-10 card card-custom card-border ">
              <div className="card-header">
                <div className="card-title">
                  <h3 className="card-label" style={{ color: '#0071CE' }}>
                    {`Upgrade to ${subscription?.name}`}
                  </h3>
                </div>
              </div>
              {
                <div className="px-5 mt-5">
                  
                  Pay now
                  <>
                    {prices?.length &&
                      prices.map(price => (
                        <span key={price.id}>
                          <strong>
                            {` ${price.currency} ${price.amount}`}
                          </strong>{' '}
                          {prices?.indexOf(price) !== prices.length - 1
                            ? '/'
                            : ''}
                        </span>
                      ))}
                  </>{' '}
                </div>
              }
              <div className="text-center">
                {alertOn ? <Alert alert={alert} /> : null}
              </div>
              <SubscriptionCurrency
                togglePaymentBtns={togglePaymentBtns}
                paymentCurrency={paymentCurrency}
                setPaymentCurrency={setPaymentCurrency}
              />
              <PaymentButtons
                loading={loading}
                payWithCreditCard={handleSubscribe}
                className="subscription-payment-btn"
                setPayWithMpesa={setPayWithMpesa}
                subscribing={true}
                mpesaDisabled={userPickedCurrencyBool ? mpesaDisabled : true}
                cardDisabled={userPickedCurrencyBool ? cardDisabled : true}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Subscribe;

import React from 'react';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const SettlementDetailsAction = ({
  iconName,
  link,
  openConfirmModal,
  settlementDetailsId,
  setSettlementDetailsId,
  setAlert,
}) => {
  const history = useHistory();
  const handleDeleteIconClick = () => {
    setAlert({ alertMessage: null, alertMessageType: null });
    setSettlementDetailsId(settlementDetailsId);
    openConfirmModal(true);
  };

  return (
    <div
      className="ml-2 cursor-pointer d-flex align-items-center"
      onClick={() => (link ? history.push(link) : handleDeleteIconClick())}
    >
      <SVG src={toAbsoluteUrl(`/media/svg/ndovu/${iconName}.svg`)} />
    </div>
  );
};

import React from 'react';
import ExtremelyLowRisk from '../../Plan/components/PlanCard/ExtremelyLow';
import HighRisk from '../../Plan/components/PlanCard/HighRisk';
import LowRisk from '../../Plan/components/PlanCard/LowRisk';
import MediumRisk from '../../Plan/components/PlanCard/MediumRisk';
import Card from './Card';
import LetfContainer from './LetfContainer';
import RigthContainer from './RigthContainer';

const FundCard = ({
  planDetails,
  investBtnIcon,
  generateButtonText,
  handleInvestButtonClick,
}) => {
  const {
    name,
    sector,
    planRiskLevel,
    coolName,
    imageUrl,
    performance1: annualPerformance,
    isCurrentOffer,
    tenor,
    fundType,
    fundCurrency,
    profitTypeDescription,
    investable,
  } = planDetails;
  return (
    <Card>
      <LetfContainer fundBackground={imageUrl} sector={sector} />
      <RigthContainer
        coolName={coolName}
        name={name}
        annualPerformance={annualPerformance}
        classNames="height-plan"
        isCurrentOffer={isCurrentOffer}
        tenor={tenor}
        fundType={fundType}
        profitTypeDescription={profitTypeDescription}
        investBtnIcon={investBtnIcon}
        generateButtonText={generateButtonText}
        handleInvestButtonClick={handleInvestButtonClick}
        investable={investable}
      >
        <div className="d-flex justify-content-between w-100 p-5">
          <div>
            <img
              className="card-flag"
              src={fundCurrency?.currencyFlag}
              alt="country flag"
            />
          </div>
          {planRiskLevel?.id ? (
            <div style={{ marginLeft: '5px' }}>
              {planRiskLevel?.id === 1 ? (
                <LowRisk />
              ) : planRiskLevel?.id === 2 ? (
                <MediumRisk />
              ) : planRiskLevel?.id === 3 ? (
                <HighRisk />
              ) : (
                planRiskLevel?.id === 4 && <ExtremelyLowRisk />
              )}
              <div
                style={{ color: '#000000' }}
                className={'mb-5 text-right text-13'}
              >
                Risk level:{' '}
                <span className="blue-color-text">{planRiskLevel?.name}</span>
              </div>
            </div>
          ) : null}
        </div>
      </RigthContainer>
    </Card>
  );
};

export default FundCard;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Currency,
  InvestmentAction,
  subscriptionCardColor,
  subscriptionType,
} from '../../helpers/constants';
import { moneyFormat } from '../../helpers/moneyFormat';
import { actions as investmentReducerActions } from '../Invest/_redux/investmentReducers';
import { BasicSubscriptionList } from './BasicSubscriptionDescription';
import { StandardSubscriptionList } from './StandardSubscriptionDescription';
import './Subscription.scss';
import { UltimateSubscriptionList } from './UltimateSubList';
import { actions as subscriptionReducerActions } from './_redux/subscriptionReducers';

import { CurrentPlan } from './CurrentPlan';
import { UpgradeButton } from './UpgradeButton';
import './index.scss';

const Subscription = ({ subscription, setConfirm, currentPlan }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setInvestmentAction();
  });

  const setInvestmentAction = () => {
    dispatch(
      investmentReducerActions.updateInvestmentAction(
        InvestmentAction.SUBSCRIPTION,
      ),
    );
  };

  const handleSubscribe = async () => {
    setConfirm(false);
    if (subscription?.type === subscriptionType.BASIC) {
      setConfirm(true);
      return;
    }

    dispatch(
      subscriptionReducerActions.updateSubscriptionPaymentDetails({
        subscriptionId: subscription?.id,
      }),
    );

    history.push({
      pathname: '/subscribe',
      state: { prices: subscription.prices, subscription: subscription },
    });
  };

  const price = subscription?.prices.find(
    price => price.currency === Currency.KES,
  );

  const subscriptionFeatures = {
    BASIC: <BasicSubscriptionList />,
    STANDARD: <StandardSubscriptionList />,
    ULTIMATE: <UltimateSubscriptionList />,
  };

  return (
    <>
      <div className="col-lg-4 col-sm-6 col-xs-12">
        <div
          className="py-8 mx-1 mb-10 card card-custom card-border"
          style={{
            backgroundColor:
              subscriptionCardColor[subscription?.type]?.background,
            color: subscriptionCardColor[subscription?.type]?.color,
            borderRadius: '13px',
          }}
        >
          <div>
            <p className="subs-title">{`${subscription?.name}`}</p>
            {subscription?.type === subscriptionType?.BASIC && (
              <p className="subs-free mb-7">0 {Currency.KES}</p>
            )}
            {subscription?.type === subscriptionType?.BASIC ||
            !price?.currency ? null : (
              <div className="subs-price-wrapper">
                <p className="subs-fee mb-0">
                  <span
                    className={`${subscription?.type ===
                      subscriptionType.ULTIMATE && 'subs-ultimate'}`}
                  >
                    {price?.currency} {moneyFormat(price?.amount)}
                  </span>
                </p>
                <p
                  className={`subs-one-off-payment ${subscription?.type ===
                    subscriptionType?.ULTIMATE && 'subs-ultimate'}`}
                >
                  Per Annum
                </p>
              </div>
            )}
            <div>
              <p className="fee-charge-info mb-0">
                Access to competitive market rates:
              </p>
            </div>
            <div className="subs-specific-info-wrapper mx-5 mt-2">
              <p className="mb-0 subs-specific-info-text">
                Global Funds:{' '}
                <strong>{`${subscription?.percentageFee} %`}</strong>
              </p>
              <p className="mb-0 subs-specific-info-text">
                Savings &#38; Fixed Returns: <strong>0% - 1.0%</strong>
              </p>
            </div>
          </div>

          <div className="my-8 text-center">
            {currentPlan ? (
              <CurrentPlan />
            ) : subscription?.type !== subscriptionType.BASIC ? (
              <UpgradeButton handleSubscribe={handleSubscribe} />
            ) : null}
          </div>
          {subscription?.type === subscriptionType.BASIC && (
            <p className="mb-0 font-weight-500">Features</p>
          )}
          {subscription?.type === subscriptionType.STANDARD && (
            <>
              <p className="mb-0 font-weight-500">Includes Basic Features</p>
              <i className="fas fa-plus fa-sm my-2 text-white"></i>
            </>
          )}
          {subscription?.type === subscriptionType.ULTIMATE && (
            <>
              <p className="mb-0 font-weight-500">Includes Standard Features</p>
              <i className="fas fa-plus fa-sm my-2 text-dark-600 "></i>
            </>
          )}
          <hr
            style={{ width: '90%', margin: '0 auto' }}
            color={subscriptionCardColor[subscription?.type]?.featureColor}
          />
          <ul
            style={{ listStyle: 'none' }}
            className="mt-5 subs-list-info text-left"
          >
            {subscriptionFeatures[subscription?.type]}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Subscription;

import React from 'react';
import { ConfirmButton } from '../ReusableButtons';

const RigthContainer = ({
  name,
  children,
  classes,
  classNames,
  coolName,
  annualPerformance,
  isCurrentOffer,
  tenor,
  profitTypeDescription,
  generateButtonText,
  investBtnIcon,
  handleInvestButtonClick,
  investable,
}) => {
  return (
    <div className={`plan-details-second position-relative ${classNames}`}>
      <div className="d-flex justify-content-between align-items-end mb-2 p-5">
        <div className="plan-cool-name">
          <div className="font-weight-600 text-20">{coolName || name}</div>
        </div>

        <div className="text-right">
          {annualPerformance ? (
            <div>
              <span className="p-1 text-dark font-weight-bold pl-md-5 font-size-14">
                {`${annualPerformance}%`}
              </span>
            </div>
          ) : null}

          {profitTypeDescription ? (
            <div className="pl-md-5 text-15">{profitTypeDescription}</div>
          ) : null}
        </div>
      </div>
      <div className="blue-separator" />
      <div className={`d-flex justify-content-between mt-5 ${classes}`}>
        {children}
      </div>

      {isCurrentOffer && tenor ? (
        <div className=" right-container-mt">
          <div className="d-flex  align-items-center">
            <div>
              Investment Tenor:
              <span
                style={{
                  fontSize: '16px',
                  color: '#2686d5',
                  marginLeft: '10px',
                  fontWeight: 700,
                }}
              >
                {tenor}
              </span>
            </div>
          </div>
        </div>
      ) : null}
      {generateButtonText ? (
        <div className="w-100 px-5 mx-5">
          <ConfirmButton
            buttonText={generateButtonText()}
            buttonIcon={investBtnIcon}
            buttonWidth={'width-94'}
            classes={
              'position-absolute fixed-bottom z-index-1 mb-md-10 mb-5 mx-auto'
            }
            handleOnClick={handleInvestButtonClick}
            disable={!investable}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RigthContainer;

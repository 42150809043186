import React from 'react';
import ArrowDownIcon from '../../icons/arrow-down.svg';

const SelectPhoneNumber = ({ selectedCountry, setOpen, disableInput }) => {
  return (
    <div
      onClick={() => !disableInput && setOpen(true)}
      className={`d-flex ${
        disableInput
          ? 'justify-content-center align-items-center'
          : 'justify-content-between align-items-center cursor-pointer'
      } border-radius-8 px-2 text-14 height-45 text-dark-600 border-gray-450`}
    >
      {selectedCountry?.countryCallingCode || selectedCountry?.flag ? (
        <span>
          {selectedCountry?.flag ? (
            <span
              className={`flag-icon ${selectedCountry?.flag} ml-2 mr-1`}
            ></span>
          ) : null}
          {`+${selectedCountry.countryCallingCode}`}
        </span>
      ) : (
        <span className="text-light-gray-600 text-13 ml-2">Country</span>
      )}

      {!disableInput ? (
        <div>
          <img src={ArrowDownIcon} alt="Select Icon" />
        </div>
      ) : null}
    </div>
  );
};

export default SelectPhoneNumber;

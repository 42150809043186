import * as Yup from 'yup';
import * as profile from '../../Profile/_redux/profileRedux';

import React, { useState } from 'react';

import Alert from '../../Common/Alert';
import { connect } from 'react-redux';
import { getUserProfile } from '../../Profile/_redux/profileCrud';
import { sendReferralCode } from '../../Onboarding/_redux/ReferalAction';
import { useFormik } from 'formik';
import { FullWidthWrapper } from '../../Common/FullWidthWrapper';
import CustomInput from '../../Common/CustomInput';
import { getInputClasses } from '../../../helpers/getInputClasses';
import { ConfirmButton } from '../../Common/ReusableButtons';

const DiscountInactive = ({ profileAction }) => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const initialValues = {
    voucher: '',
  };

  const referalCodeSchema = Yup.object().shape({
    voucher: Yup.string()
      .min(8, 'Minimum 8 characters')
      .max(8, 'Maximum 8 characters'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: referalCodeSchema,
    onSubmit: async values => {
      try {
        const response = await sendReferralCode(null, values.voucher);

        if (response.status === 200) {
          formik.resetForm();
          const userProfile = await getUserProfile();
          profileAction(userProfile.data.data);
        }
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
        setTimeout(() => {
          setAlertOn(false);
        }, 3000);
      }
    },
  });
  return (
    <>
      <div className="mt-10">
        <FullWidthWrapper title={'Corporate Code'}>
          <div className="mt-10 text-center mx-5">
            <p className="px-md-35 px-5">
              We work with employers to provide their employees with a discount
              when it comes to building wealth. If you have a corporate code,
              please insert it here to unlock your discount. If you would like
              us to partner with your employer, email us at support@ndovu.co
            </p>
          </div>

          <div className="form-group fv-plugins-icon-container m-10">
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework px-md-20 px-lg-40"
            >
              <CustomInput
                type="text"
                name="voucher"
                formik={formik}
                getInputClasses={getInputClasses(formik, 'voucher')}
                required={true}
                placeholder={'Voucher'}
                classes="mx-auto"
              />

              {<Alert alert={alert} />}
              {formik.touched.voucher && formik.errors.voucher ? (
                <div
                  className="text-center font-weight-bold"
                  style={{ color: '#F05B7F' }}
                >
                  {formik.errors.voucher}
                </div>
              ) : null}

              <div className="text-center my-5">
                <ConfirmButton
                  buttonType={'submit'}
                  buttonText={'Submit'}
                  buttonWidth={'width-345'}
                />
              </div>
            </form>
          </div>
        </FullWidthWrapper>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { ...profile.actions })(
  DiscountInactive,
);

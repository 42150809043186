import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { InvestmentAction, paymentMethod } from '../../../../helpers/constants';
import convertAmountToNumber from '../../../../helpers/convertAmountToNumber';

import Alert from '../../../Common/Alert';
import { CancelButton } from '../../../Common/buttons/Buttons';
import PaymentChannel from '../../../Common/PaymentChannel';
import { goalTopUp, investmentInGoal } from '../../../Goal/_redux/goalActions';
import { directInvestment, fundTopUp } from '../../../Plan/_redux/plansActions';
import { payWithBongaPoints } from '../../_redux/investmentAction';
import warningIcon from '../../../../icons/warning.svg';
import Input from '../../../Common/Input/ControlledInput';
import CheckBox from '../../../Common/CheckBox';

const handleGoalInvestment = async (
  id,
  textReference,
  channel,
  transactionId,
) => {
  const response = await investmentInGoal(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId),
  );
  return response;
};

const handleDirectInvestment = async (
  id,
  textReference,
  channel,
  transactionId,
) => {
  const response = await directInvestment(
    parseInt(id),
    textReference,
    channel,
    transactionId,
  );
  return response;
};

const handleFundTopUp = async (
  planSubscribtionId,
  textReference,
  channel,
  transactionId,
) => {
  const response = await fundTopUp(
    parseInt(planSubscribtionId),
    textReference,
    channel,
    transactionId,
  );
  return response;
};

const handleGoalTopUp = async (id, textReference, channel) => {
  const response = await goalTopUp(parseInt(id), textReference, channel);
  return response;
};

const {
  DIRECT_INVESTMENT,
  DIRECT_INVESTMENT_TOPUP,
  GOAL_INVESTMENT,
  GOAL_TOPUP,
} = InvestmentAction;

const actions = [
  { name: DIRECT_INVESTMENT, invest: handleDirectInvestment },
  { name: DIRECT_INVESTMENT_TOPUP, invest: handleFundTopUp },
  { name: GOAL_INVESTMENT, invest: handleGoalInvestment },
  { name: GOAL_TOPUP, invest: handleGoalTopUp },
];

const PayWithBongaPoints = ({
  amount,
  currency,
  minimumInvestableAmount,
  setpayWithBongaPoints,
}) => {
  const history = useHistory();
  const [promoCode, setPromoCode] = useState();
  const [alertOn, setAlertOn] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const [checked, setChecked] = useState(false);

  const investmentAction = useSelector(
    state => state?.investment?.investmentAction,
  );
  const reduxPlanInvestmentDtls = useSelector(
    state => state?.plan?.planInvestmentDetails,
  );
  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  useEffect(() => {
    const promoCode = localStorage.getItem('promoCode');
    setPromoCode(promoCode);
  }, []);

  const displayWarning = () => {
    return (
      <div className="mt-5">
        <div className="information-box d-flex justify-content-center">
          <img src={warningIcon} alt="Information Icon" />
          <div className="ml-3">
            We will finalise the operation only when the money is received. If
            the money is not transferred this operation will be automatically
            canceled.
          </div>
        </div>
      </div>
    );
  };

  const goalOrPlanId = () => {
    if (
      investmentAction === DIRECT_INVESTMENT ||
      investmentAction === DIRECT_INVESTMENT_TOPUP
    ) {
      return Number(reduxPlanInvestmentDtls?.planId);
    } else if (
      investmentAction === GOAL_INVESTMENT ||
      investmentAction === GOAL_TOPUP
    ) {
      return Number(reduxGoalInvestmentDtls?.goalId);
    }
  };

  const trancationIdSchema = Yup.object().shape({
    transactionId: Yup.string()
      .min(10, 'Transaction reference must be 10 character long')
      .max(10, 'Transaction reference must be 10 character long'),
  });

  const initialValues = {
    transactionId,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: trancationIdSchema,
  });

  const handleSubmit = async () => {
    const { transactionId } = formik?.values;
    setLoading(true);
    try {
      const response = await payWithBongaPoints(
        convertAmountToNumber(amount),
        currency,
        promoCode,
      );

      const { tx_ref } = response?.data;
      const id = goalOrPlanId();

      actions.forEach(async item => {
        if (item.name === investmentAction) {
          try {
            const result = await item.invest(
              id,
              tx_ref,
              paymentMethod.BONGA_POINT,
              transactionId,
            );

            if (result.status === 201) {
              setLoading(false);
              localStorage.removeItem('discountInfo');
              localStorage.removeItem('promoCode');
              history.push('/congratulations');
              return;
            }
          } catch (error) {
            setLoading(false);
            setAlertOn(true);
            setAlert({
              alertMessage: error.response.data.message,
              alertMessageType: 'error',
            });
          }
        }
      });
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage:
          error.response.data.message || error.response.data.errorMessage,
        alertMessageType: 'error',
      });
    }
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const handleChange = e => {
    setAlertOn(false);
    setAlert({
      alertMessage: null,
      alertMessageType: null,
    });
    const { value } = e?.target;
    if (value?.length > 10) {
      return false;
    } else {
      setTransactionId(e?.target?.value);
    }
  };

  const handleCheckBankTransferBoxChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="card card-custom card-gutter-b">
      <div className="pt-5 card-body">
        {alertOn && (
          <div className="mb-5">
            <Alert alert={alert} />
          </div>
        )}
        <div className="py-5 mx-auto responsive-width">
          <PaymentChannel
            paymentChannel={'Bonga Points'}
            otherText="(Lipa na Bonga Points)"
          />
          <div className="payment-instructions-container">
            <ol>
              <li>
                Dial <span className="font-weight-bolder">*126#</span> and
                select Lipa na Bonga Points
              </li>
              <li>
                Select <span className="font-weight-bolder">Paybill</span>
              </li>
              <li>
                Enter Business Number:{' '}
                <span className="font-weight-bolder">4069483</span>
              </li>
              Enter Account Number: Your Name as per{' '}
              <span className="font-weight-bolder">ID</span>
              <li>
                Enter the amount you want to pay (
                <span className="font-weight-bolder">
                  minimum of {currency} {minimumInvestableAmount}
                </span>
                )
              </li>
              <li>
                Click <span className="font-weight-bolder">Accept</span> to
                confirm payment to{' '}
                <span className="font-weight-bolder">
                  WAANZILISHI CAPITAL LIMITED
                </span>
              </li>
              <li>
                <span className="font-weight-bolder">MPesa</span> will send you
                a text message to confirm the transaction
              </li>
              <li>
                <span className="font-weight-bolder">Insert</span> the
                transaction{' '}
                <span className="font-weight-bolder">reference number</span> in
                the space below
              </li>
            </ol>
          </div>
          {displayWarning()}
          <div className="text-center mt-5 font-weight-bold">
            Please insert your transaction reference number below.
          </div>
        </div>

        <form className="" id="kt_form" onSubmit={formik.handleSubmit}>
          <div className="input-container">
            <Input
              placeholder="Insert Transaction Reference"
              type="text"
              className={`form-control input-field ${getInputClasses(
                'transactionId',
              )}`}
              name="transactionId"
              formik={formik}
              defaultValue={transactionId}
              handleChange={handleChange}
            />
          </div>
          {formik.touched.transactionId && formik.errors.transactionId ? (
            <div className="input-container fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.transactionId}</div>
            </div>
          ) : null}

          <div className="mt-5">
            <CheckBox
              checked={checked}
              label={
                <span className="font-weight-bold">
                  I have paid using{' '}
                  <span className="bonga-point">Bonga Points</span>
                </span>
              }
              name={''}
              handleCheckBoxChange={handleCheckBankTransferBoxChange}
            />
          </div>
          <div className="my-10 text-center mb-lg-20">
            <CancelButton
              BtnText="Cancel"
              classes="full-width-small py-4"
              onClick={() => {
                setAlertOn(false);
                setpayWithBongaPoints(false);
              }}
            />

            {checked &&
            formik?.values?.transactionId &&
            formik?.values?.transactionId?.length === 10 &&
            !alertOn ? (
              <span
                className="px-5 py-4 text-center cursor-pointer margin-left display-small-block btn-size common-btn"
                onClick={handleSubmit}
              >
                Confirm Payment
                {loading && <span className="mx-3 spinner spinner-white" />}
              </span>
            ) : (
              <span className="px-5 py-4 text-center cursor-pointer margin-left display-small-block btn-size disabled-btn">
                Confirm Payment
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default PayWithBongaPoints;

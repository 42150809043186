import axios from 'axios';
import dotenv from 'dotenv';
import { settlementDetailsStatuses } from '../../../helpers/constants';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const POCKET_BASE_URL = `${REACT_APP_BASE_URL}/v1/investor/pocket`;
const GET_SETTLEMENT_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/settlement-details`;

const fetchUserPockets = async () => {
  const res = await axios.get(POCKET_BASE_URL);
  return res;
};

const getPocketDetails = async pocketId => {
  const res = await axios.get(`${POCKET_BASE_URL}/${Number(pocketId)}`);
  return res;
};

const transferFromPocketToPocket = async (payload, pocketId) => {
  const res = await axios.put(
    `${POCKET_BASE_URL}/${Number(pocketId)}/transfer`,
    payload,
  );

  return res;
};

const withdrawFromPocket = async (payload, pocketId) => {
  const res = await axios.put(
    `${POCKET_BASE_URL}/${Number(pocketId)}/withdraw`,
    payload,
  );

  return res;
};

const investInFundFromPocket = async payload => {
  const res = await axios.post(`${POCKET_BASE_URL}/fund-investment`, payload);

  return res;
};

const investInGoalFromPocket = async payload => {
  const res = await axios.post(`${POCKET_BASE_URL}/goal-investment`, payload);

  return res;
};

const fetchPocketTransactions = async (pocketId, page, limit) => {
  const res = await axios.get(
    `${POCKET_BASE_URL}/${Number(
      pocketId,
    )}/transactions?page=${page}&limit=${limit}`,
  );

  return res;
};

const getSettlementDetails = async () => {
  const res = await axios.get(`${GET_SETTLEMENT_DETAILS}`);
  return res;
};

const getApprovedSettlementDetails = async () => {
  const res = await axios.get(
    `${GET_SETTLEMENT_DETAILS}?status=${settlementDetailsStatuses.SUBMITTED}`,
  );
  return res;
};

const topupPocket = async (payload, pocketId) => {
  const res = await axios.put(
    `${POCKET_BASE_URL}/${Number(pocketId)}/top-up`,
    payload,
  );

  return res;
};

export {
  fetchUserPockets,
  getPocketDetails,
  transferFromPocketToPocket,
  fetchPocketTransactions,
  withdrawFromPocket,
  investInFundFromPocket,
  investInGoalFromPocket,
  getSettlementDetails,
  getApprovedSettlementDetails,
  topupPocket,
};

import React from 'react';
import { PlanFeature } from './components/PlanFeature';
import { PlanSubFeature } from './components/PlanSubFeature';

export const UltimateSubscriptionList = () => {
  return (
    <>
      <PlanFeature
        title={'Dedicated Relationship Manager & Unlimited advice'}
        boxBackground={'#FF7F30'}
        boxColor={'rgb(222, 236, 247)'}
      >
        <ul
          style={{ listStyle: 'none' }}
          className="subs-list-info subs-subs-list-info text-left"
        >
          <PlanSubFeature
            bulletPointColor={'#FF7F30'}
            title={'Maximize your wealth with expert advice'}
          />
          <PlanSubFeature
            bulletPointColor={'#FF7F30'}
            title={'Reach financial freedom faster'}
          />
          <PlanSubFeature
            bulletPointColor={'#FF7F30'}
            title={'Invest confidently and save time'}
          />
        </ul>
      </PlanFeature>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import getOnPromoPlans from '../../helpers/promotionPlans';
import NewInvestmentOption from './components/NewInvestmentOptions';
import { investmentOptions } from './components/NewInvestmentOptions/investmentOptions';
import PromoPlan from './components/PromoPlan';

import './NewInvestment.scss';

const NewInvestment = () => {
  const [promoPlans, setPromoPlans] = useState([]);
  const [bongaPlan, setBongaPlan] = useState();

  useEffect(() => {
    async function fetchPlans() {
      const promoPlans = await getOnPromoPlans();
      setPromoPlans(promoPlans);
      setBongaPlan(bongaPlan);
    }

    fetchPlans();
    localStorage.removeItem('pathName');
  }, []);

  const isPromoPlansAvalaible = () => {
    return (promoPlans && promoPlans?.length) || bongaPlan;
  };
  return (
    <>
      <h3 className="text-center mb-5">Smart Investing</h3>
      <p
        className="new-investment-text"
        style={{ textAlign: 'center', marginBottom: '30px' }}
      >
        Our investments are designed to have the lowest risk, while enjoying a
        high return over a given period.
      </p>
      <div
        className="justify-content-between new-investment-main-container"
        style={{ display: 'grid' }}
      >
        {promoPlans && promoPlans.length
          ? promoPlans.map(plan => <PromoPlan plan={plan} />)
          : null}

        {investmentOptions.map(investmentOption => (
          <NewInvestmentOption
            title={investmentOption?.title}
            text={investmentOption?.text}
            ElephantIcon={investmentOption.elephantIcon}
            isPromoPlansAvalaible={isPromoPlansAvalaible()}
            link={investmentOption.link}
            key={investmentOption?.title}
          />
        ))}
      </div>
    </>
  );
};

export default NewInvestment;

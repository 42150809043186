import React from 'react';

export default function SwitchCheckBox({
  label,
  checked,
  handleTwoFactorChange,
  name,
}) {
  return (
    <div className="form-group row">
      <div className="col-3">
        <span
          className={
            checked ? `switch switch-primary mt-1` : 'switch switch-icon mt-1'
          }
        >
          <label>
            <input
              type="checkbox"
              name={name}
              checked={checked}
              onChange={handleTwoFactorChange}
            />
            <span></span>
          </label>
        </span>
      </div>
    </div>
  );
}

import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { accountTypes } from '../../../helpers/constants';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';

export const InvestorInformationSummaryTitle = ({
  firstName,
  lastName,
  investorType,
  businessChamaName,
}) => {
  const smallDevice = useMediaQuery('(max-width : 768px)');

  const isBusinessAccount = investorType === accountTypes.BUSINESS;
  const isChamaAccount = investorType === accountTypes.CHAMA;

  return (
    <>
      {smallDevice ? (
        <div className="mt-3">
          <div className="text-14 text-dark-600 bg-light-blue-60 border-1-blue border-radius-12 px-5 py-2">
            <span className="font-weight-500">
              {isBusinessAccount || isChamaAccount
                ? businessChamaName
                : `${firstName} ${lastName}`}
              {' - '}
            </span>
            <span>
              {isBusinessAccount || isChamaAccount
                ? capitalizeTheFirstLetterOfEachWord(investorType)
                : 'Personal account'}
            </span>
          </div>
        </div>
      ) : (
        <div className="font-weight-500 mt-3">
          <div className="text-blue text-20 font-weight-600">
            {capitalizeTheFirstLetterOfEachWord(investorType)} Information
          </div>
          <div className="text-14 font-weight-500">
            {`The following is a summary of your submitted ${capitalizeTheFirstLetterOfEachWord(
              investorType,
            )} information`}
          </div>
        </div>
      )}
    </>
  );
};

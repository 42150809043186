import React, { useState } from 'react';
import * as Yup from 'yup';
import * as auth from '../_redux/authRedux';
import PasswordChecklist from 'react-password-checklist';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';

import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import Input from '../../Common/Input/Input';
import { SubmitButton } from '../../Common/buttons/Buttons';
import { requestPasswordConfirmation } from '../_redux/authCrud';
import UnableViewPassword from '../../Common/UnableViewPassword';
import {
  passwordRegex,
  passwordValidationMessage,
} from '../../../helpers/constants';
import { PasswordInput } from '../../Common/PasswordInput';
import { ConfirmButton } from '../../Common/ReusableButtons';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = props => {
  let history = useHistory();
  const searchParams = new URLSearchParams(props.location.search);
  const passwordResetCode = searchParams.get('code');
  const id = Number(searchParams.get('id'));
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const RedirectToLogin = () => {
    history.push('/auth/login');
  };

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, passwordValidationMessage)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
    confirmPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      )
      .when('password', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match",
        ),
      }),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        const response = await requestPasswordConfirmation(
          id,
          values.password,
          passwordResetCode,
        );
        setLoading(false);
        setSubmitting(false);
        if (response.status === 200) {
          setStatus(response.data.message);
          setSuccess(true);
          setTimeout(RedirectToLogin, 3000);
        }
      } catch (error) {
        setSubmitting(false);
        setStatus(error.response.data.message);
        setSuccess(false);
        setLoading(false);
      }
    },
  });

  const { password } = formik.values;

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <ExternalPageTitle
            title="Reset Password"
            subTitle="Enter your new password"
          />
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            {formik.status && (
              <div className="mb-10 text-center">
                {success ? (
                  <h4
                    className="font-weight-bold font-size-h4"
                    style={{ color: '#67CCCA' }}
                  >
                    {formik.status}
                  </h4>
                ) : (
                  <p style={{ color: '#F05B7F' }}>{formik.status}</p>
                )}
              </div>
            )}

            {/* begin: password */}
            <PasswordInput
              viewPassword={viewPassword}
              formik={formik}
              setViewPassword={setViewPassword}
              name={'password'}
              placeholder="Password"
            >
              <div className="mt-5">
                {formik.touched.password && formik.errors.password && (
                  <PasswordChecklist
                    rules={[
                      'minLength',
                      'maxLength',
                      'specialChar',
                      'number',
                      'capital',
                      'lowercase',
                    ]}
                    messages={{
                      minLength: 'At least 8 characters.',
                      maxLength: 'At most 16 characters.',
                      lowercase: 'At least one lower case letter.',
                      capital: 'At least one upper case letter.',
                      number: 'At least one number.',
                      specialChar: 'At least one special character.',
                    }}
                    minLength={8}
                    maxLength={16}
                    value={password}
                    validTextColor="green"
                    invalidTextColor="#F64E60"
                    hideIcon
                  />
                )}
              </div>
            </PasswordInput>
            {/* end: password */}

            {/* begin: confirm password */}
            <PasswordInput
              viewPassword={viewConfirmPassword}
              formik={formik}
              setViewPassword={setViewConfirmPassword}
              name="confirmPassword"
              placeholder="Confirm Password"
              passwordContainerSpacing={'mt-9 mb-7'}
            />
            {/* end: confirm password */}

            <ConfirmButton
              buttonText="Reset Password"
              loading={loading || formik.isSubmitting}
              classes={'mt-5'}
              buttonWidth={'w-100'}
              buttonType={'submit'}
            />
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(ResetPassword));

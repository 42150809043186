import { eventTracking } from '../../app/eventTracking/httpRequest';
import { customerIOEventType, menuNames } from '../../app/helpers/constants';

export const trackMenuItemClickEvent = async menuName => {
  if (menuName === menuNames.INVEST) {
    try {
      await eventTracking(customerIOEventType.INVEST_ON_HOME_PAGE);
    } catch (error) {}
  }
};

import React from 'react';
import './index.scss';

export const CurrencyFlag = ({ currencyFlag, imageSize }) => {
  return (
    <div className={`${imageSize}`}>
      <img
        className="image border-radius-3"
        src={currencyFlag}
        alt="country flag"
      />
    </div>
  );
};

export const investmentsCopy = {
  performance: {
    title: 'Historical Performance',
    description: `Past performance is not a guide to future returns. The value of your
    investment can go down as well as up and you may not get back your
    original investment. Fluctuations in currency rates may affect the
    performance of your investment both positively and negatively.`,
    descriptionForSavingFund:
      'This is a fixed return product designed to offer a fixed interest each year.',
  },

  fund: {
    action: `About this fund`,
  },
  fundDescription: {
    action: `Do you live for the next adrenaline rush? If your comfort zone is
    living on the edge, then you'll feel right at home investing on this
    fund. With this more aggressive blend of stocks and bonds, it's high
    risk, high reward.`,
  },

  companies: {
    tilte: `Companies & Brands`,
    description: `These are the most representative companies held in the fund. More are contained within the fund  for portfolio diversification and risk reduction.`,
  },
};

export const investmentPeriodCopy = [
  {
    title: 'Short Term (<3 years)',
    description:
      'To achieve this financial goal or get close to it, you should  remain invested for a minimum of 2 years.',
  },

  {
    title: 'Medium Term (3 - 5 years)',
    description:
      'To achieve this financial goal or get close to it, you should remain invested for a minimum of 3 years.',
  },

  {
    title: 'Long Term (5+ years)',
    description:
      'To achieve this financial goal or get close to it, you should remain invested for a minimum of 5 years.',
  },
];

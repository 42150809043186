import React from 'react';

const WithdrawalSliderInput = ({ input, silderLabels, unit }) => {
  return (
    <div className="goal-amount-slider-container pt-3">
      <div>
        <div className="d-flex flex-column justify-content-between mx-auto goal-amount-slider-container">
          {unit}
          {input}
        </div>
      </div>
      {silderLabels}
    </div>
  );
};

export default WithdrawalSliderInput;

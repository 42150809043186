import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_PLANS_DAILY_TRADING = `${REACT_APP_BASE_URL}/v1/investor/daily-trading`;
const FETCH_LEARNING_VIDEOS = `${REACT_APP_BASE_URL}/v1/investor/learning/youtube-videos`;
const FETCH_POCKETS_AND_PORTFOLIO_VALUES = `${REACT_APP_BASE_URL}/v1/investor/pocket-and-portfolio`;

const fetchPlansDailyTrading = async () => {
  const res = await axios.get(`${FETCH_PLANS_DAILY_TRADING}`);
  return res;
};

const fetchLearningVideos = async limitNumber => {
  const res = await axios.get(`${FETCH_LEARNING_VIDEOS}/?limit=${limitNumber}`);
  return res;
};

const fetchPocketsAndPortfolioValues = async () => {
  const res = await axios.get(`${FETCH_POCKETS_AND_PORTFOLIO_VALUES}`);
  return res;
};

export {
  fetchPlansDailyTrading,
  fetchLearningVideos,
  fetchPocketsAndPortfolioValues,
};

import React from 'react';

import './index.scss';

export const KycPageContentWrapper = ({ children }) => {
  return (
    <>
      <div className="border-light-blue-600 border-radius-8 kyc-content-wrapper mx-auto pt-6 pb-sm-10 pb-5 px-5 px-sm-10 ">
        {children}
      </div>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import PageHeader from '../../../helpers/pageHeader';
import ErrorCard from '../../Common/ErrorCard';
import {
  fetchAllDirectInvestmentsProfit,
  fetchDirectInvestmentAssets,
  fetchDirectInvestmentCurrencies,
  fetchDirectInvestments,
} from '../_redux/directInvestmentsActions';
import NoInvestmentMade from '../components/NoInvestmentMade';
import PortfolioAssetCard from '../components/PortfolioAssetCard';
import PortfolioDetails from '../components/PortfolioDetailCards';

const Investments = () => {
  const [loading, setLoading] = useState(true);
  const [loadingInvestments, setLoadingInvestments] = useState(true);
  const [loadingCurrencies, setLoadingCurrencies] = useState(true);
  const [loadingPortfolioValue, setLoadingPortfolioValue] = useState(true);
  const [investments, setInvestments] = useState([]);
  const [assets, setAssets] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [
    directInvestmentsPortfolioValue,
    setDirectInvestmentsPortfolioValue,
  ] = useState([]);
  const [errorMessage, setErrorMessage] = useState();

  const url = localStorage.getItem('pathName');

  const fetchInvestments = async () => {
    // Fetch direct investment assets
    try {
      setLoadingInvestments(true);
      const response = await fetchDirectInvestments();
      if (response?.data?.data) {
        setInvestments(response.data.data);
        setLoadingInvestments(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoadingInvestments(false);
    }
  };

  const fetchAssets = async () => {
    // Fetch direct investment asset classes
    try {
      setLoading(true);
      const response = await fetchDirectInvestmentAssets();
      if (response?.data?.data) {
        setAssets(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const fetchDirectInvestmentProfit = async () => {
    // Fetch direct investments profit
    try {
      setLoadingPortfolioValue(true);
      const response = await fetchAllDirectInvestmentsProfit();
      if (response?.data?.data) {
        setDirectInvestmentsPortfolioValue(response.data.data);
        setLoadingPortfolioValue(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoadingPortfolioValue(false);
    }
  };

  const fetchCurrencies = async () => {
    // Fetch direct investment currencies
    try {
      setLoadingCurrencies(true);
      const response = await fetchDirectInvestmentCurrencies();
      if (response?.data?.data) {
        setCurrencies(response.data.data);
        setLoadingCurrencies(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoadingCurrencies(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem('pathName');
    fetchInvestments();
    fetchAssets();
    fetchCurrencies();
    fetchDirectInvestmentProfit();
  }, []);

  const checkRecentTransactionStatus = investments.some(
    item => item.investmentDetails.fundPortfolioValue > 0,
  );

  if (
    loading ||
    loadingInvestments ||
    loadingCurrencies ||
    loadingPortfolioValue
  ) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorCard errorMessage={errorMessage} />
      ) : (
        <>
          <PageHeader
            title="Portfolio Summary"
            buttonLabel="Invest in a new fund"
            buttonLink="/plans"
            hide={false}
            pathName={url}
          />

          {investments?.length > 0 &&
          !loading &&
          !loadingInvestments &&
          !loadingCurrencies &&
          !loadingPortfolioValue ? (
            <>
              {checkRecentTransactionStatus ? (
                <PortfolioDetails
                  assets={assets}
                  currencies={currencies}
                  directInvestmentsPortfolioValue={
                    directInvestmentsPortfolioValue
                  }
                />
              ) : (
                <></>
              )}
              <div className="mt-5"></div>
              <PageHeader title="Assets" hide={true} />
              <div
                className={`${
                  investments?.length > 2
                    ? 'justify-content-between'
                    : 'justify-content-start'
                } portfolio-card-section portfolio-card-section-mobile`}
              >
                {investments?.map(investment => (
                  <PortfolioAssetCard
                    key={investment.id}
                    {...investment}
                    different={true}
                  />
                ))}
              </div>
            </>
          ) : investments?.length === 0 &&
            !loading &&
            !loadingInvestments &&
            !loadingCurrencies &&
            !loadingPortfolioValue ? (
            <NoInvestmentMade />
          ) : null}
        </>
      )}
    </>
  );
};

export default Investments;

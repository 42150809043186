import React, { useEffect, useState } from 'react';
import {
  acceptMembership,
  fetchUserInvites,
} from '../../Business/_redux/busChamaCrud';
import { invitesStatus } from '../../../helpers/constants';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import Alert from '../../Common/Alert';
import Back from '../../Common/BackArrow';
import { Invite } from '../components/Invite';
import { NoInvitesOrTeamMembers } from '../components/NoInvitesOrTeamMember';

const Invites = () => {
  const [invites, setInvites] = useState([]);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const fetchInvites = async () => {
    try {
      const res = await fetchUserInvites();
      if (res?.status === 200) {
        const filteredData = res?.data?.data?.filter(
          item => item.status === invitesStatus.PENDING,
        );

        setInvites(filteredData);
        setLoading(false);
      }
    } catch (error) {
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  const handleInvite = async (id, code) => {
    const payload = {
      investorId: id,
      inviteCode: code,
    };

    try {
      const response = await acceptMembership(payload);
      const removedAcceptedInvitation = invites?.filter(
        invite => invite?.id !== response?.data?.data?.id,
      );

      setInvites(removedAcceptedInvitation);
      setMessage('Invite accepted successfully');
      setShow(true);
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
    }
  };

  return (
    <div className="mt-5">
      <Back title={'Team Invitations'} />
      <div>{alert?.alertMessage ? <Alert alert={alert} /> : null}</div>

      {invites?.length > 0 && !loading ? (
        <div className="card card-custom gutter-b px-md-10 px-lg-10">
          <div className="card-body px-0 business-overview">
            {invites?.map(invite => (
              <Invite
                key={invite?.id}
                invite={invite}
                handleInvite={handleInvite}
              />
            ))}
          </div>
        </div>
      ) : (
        !loading && <NoInvitesOrTeamMembers message={'No pending Invites'} />
      )}

      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={message}
        handleButtonClick={() => setShow(false)}
        btnText={'OK'}
        moreClasses={'bg-white'}
      />
    </div>
  );
};

export default Invites;

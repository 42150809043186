import React from 'react';
import { useHistory } from 'react-router-dom';
import { Titles } from '../../Common/Titles';

const Promo = ({ plans }) => {
  const history = useHistory();

  return (
    <div className={plans?.length > 0 ? 'mt-10' : 'd-none'}>
      <Titles title={'Hot Funds'} />
      <div className="fund-plans">
        {plans?.length > 0 &&
          plans.map(plan => {
            return (
              <div
                onClick={() => history.push(`/plans/${plan?.id}`)}
                key={plan?.id}
              >
                <img
                  src={plan.offerAssetName || plan.imageUrl}
                  alt="PromoImage"
                  className="promo-card"
                  key={plan.id}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { Promo };

import React from 'react';

export const StepNumberTwo = ({ backgroundColor, color }) => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill={backgroundColor} />
        <path
          d="M9.66127 15.978C10.8466 15.026 11.7753 14.2467 12.4473 13.64C13.1193 13.024 13.6839 12.3847 14.1413 11.722C14.6079 11.05 14.8413 10.392 14.8413 9.748C14.8413 9.14133 14.6919 8.66533 14.3933 8.32C14.1039 7.96533 13.6326 7.788 12.9793 7.788C12.3446 7.788 11.8499 7.98867 11.4953 8.39C11.1499 8.782 10.9633 9.30933 10.9353 9.972H9.70327C9.7406 8.92667 10.0579 8.11933 10.6553 7.55C11.2526 6.98067 12.0226 6.696 12.9653 6.696C13.9266 6.696 14.6873 6.962 15.2473 7.494C15.8166 8.026 16.1013 8.75867 16.1013 9.692C16.1013 10.4667 15.8679 11.2227 15.4013 11.96C14.9439 12.688 14.4213 13.332 13.8333 13.892C13.2453 14.4427 12.4939 15.0867 11.5793 15.824H16.3953V16.888H9.66127V15.978Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

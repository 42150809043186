import React from 'react';

export const TableHeader = ({ header, maxWidth }) => {
  return (
    <th
      className="tr-color"
      style={{ minWidth: maxWidth ? maxWidth : '110px' }}
      scope="col"
    >
      <span className="text-13 font-weight-500">{header}</span>
    </th>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

export const DoYouHaveAccount = ({ question, action, link, margin }) => {
  return (
    <div className={`${margin} text-center`}>
      <span>{question}</span>
      <Link
        to={link}
        className="font-weight-bolder ml-2 text-blue"
        id="kt_login_signup"
      >
        {action}
      </Link>
    </div>
  );
};

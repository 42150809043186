import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import RiskProfileImage from './RiskProfileImage';
import elephantLogo from '../../../images/elephantLogo.svg';

import '../index.scss';
import ReusableDropdown from '../../Common/ReusableDropdown';
import { ValidationErrors } from '../../Business/components/ValidationErrors';
import OptionsModal from '../../Common/OptionsModal';
import { acquisitionChannel } from '../../../helpers/constants';
import { submitAcquisitionChannel } from '../_redux/ReferalAction';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { ConfirmButton } from '../../Common/ReusableButtons';

const WelcomePage = () => {
  const [errorMessage, setErrorMessage] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  const history = useHistory();

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const handleSelectOption = item => {
    setSelectedOption(item);
    closeDropdown();
  };

  const handleSubmit = async () => {
    try {
      await submitAcquisitionChannel(selectedOption.value);
      history.push('/onboarding/instructions');
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="container instructions-container d-flex flex-column justify-content-center align-items-center">
        <RiskProfileImage image={elephantLogo} />
        <div className="text-center">
          <p className="instruction-title">Welcome to ndovu</p>
          <p>
            Before you get started, please let us know how you heard about us:
          </p>
        </div>

        <DropdownContainer classes={'mx-auto'}>
          <ReusableDropdown
            handleOpenSelector={handleDropdown}
            dropdownPlaceholder={'How did you hear about us?'}
            selectedOption={selectedOption?.label}
            classes={'pr-5 pl-2'}
            name={'idType'}
          />

          {errorMessage ? (
            <ValidationErrors errorMessage={errorMessage} />
          ) : null}
        </DropdownContainer>

        {selectedOption?.value ? (
          <ConfirmButton
            buttonText="Continue"
            classes={'mt-8'}
            handleOnClick={handleSubmit}
          />
        ) : null}
      </div>

      <OptionsModal
        open={openDropdown}
        handleClose={() => closeDropdown()}
        items={acquisitionChannel}
        handleSelect={handleSelectOption}
        selectedItem={selectedOption}
        placeholder={'How did you hear about us?'}
        name={'idType'}
      />
    </>
  );
};

export default WelcomePage;

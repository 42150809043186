import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { CancelButton, ConfirmButton } from './ReusableButtons';
import { ButtonsContainer } from './ButtonsContainer';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '560px',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down(868)]: {
      width: '90%',
    },
  },
}));

export const ActivateCameraModal = ({
  open,
  setOpen,
  content,
  moreClasses,
  handleButtonClick,
  addCloseButton,
  title,
  handleCancelButton,
  iconName,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const body = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${moreClasses} border-light-blue-600 border-radius-8 p-10`}
    >
      <div className="d-flex align-items-start">
        <SVG
          className="mr-5"
          src={toAbsoluteUrl(`/media/svg/ndovu/${iconName}.svg`)}
        />
        <div>
          <div className="w-100 text-18 text-blue font-weight-600">
            {title ? title : 'Notification'}
          </div>
          <div className="text-10">{content}</div>
        </div>
      </div>

      <ButtonsContainer justifyContent="justify-content-between mt-10">
        <CancelButton
          buttonText="Previous"
          handleOnClick={handleCancelButton}
          twinButton
        />
        <ConfirmButton
          buttonText={'Activate Camera'}
          handleOnClick={handleButtonClick}
          twinButton
        />
      </ButtonsContainer>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => addCloseButton && setOpen()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import * as profile from '../../Profile/_redux/profileRedux';

import Alert from '../../Common/Alert';
import DiscountInactive from './DiscountInactive';
import removeVoucherCorporate from '../_redux/voucherCorporateAction';
import { getUserProfile } from '../../Profile/_redux/profileCrud';

const DiscountActive = ({ profileAction }) => {
  const [openDiscountInactive, setOpenDiscountInactive] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const { investor } = useSelector(state => state.profile);

  useEffect(() => {
    if (!investor.voucherCorporate || investor.voucherCorporate === null) {
      setOpenDiscountInactive(true);
    }
  }, [investor.voucherCorporate]);

  const handleClick = async () => {
    try {
      const response = await removeVoucherCorporate();
      if (response.status === 200) {
        const userProfile = await getUserProfile();
        profileAction(userProfile.data.data);
        setAlertOn(true);
        setAlert({
          alertMessage: response.data.message,
          alertMessageType: 'success',
        });
        setTimeout(() => {
          setOpenDiscountInactive(true);
        }, 2000);
      }
    } catch (error) {
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
      setTimeout(() => {
        setAlertOn(!setAlertOn);
      }, 3000);
    }
  };

  return (
    <>
      {!openDiscountInactive && (
        <div
          id="card-margin"
          className="card card-custom card-border mx-40 my-15"
        >
          <div className="card-header">
            <div className="card-title">
              <h3
                className="card-label text-center"
                style={{ color: '#0071CE', textAlign: 'center' }}
              >
                Corporate Code
              </h3>
            </div>
          </div>
          {alertOn ? <Alert alert={alert} /> : null}
          <div className="mt-10 px-5 font-weight-bolder">
            Your account is active with the promotion for:
          </div>
          <div className="mt-10 ml-10 font-weight-bolder">
            {investor.voucherCorporate
              ? investor.voucherCorporate.corporateName
              : '---'}
          </div>
          <div className="mx-10 mt-10">
            {investor.voucherCorporate && (
              <img
                src={investor.voucherCorporate.logoLink}
                alt="Corporate logo"
                style={{ width: '20%', height: '20%', borderRadius: '5px' }}
              />
            )}
          </div>

          <div className="text-center my-10">
            <button
              type="button"
              className="btn font-weight-bolder px-12 py-4"
              onClick={handleClick}
              style={{ color: '#fff', background: '#0071CE' }}
            >
              I left the company
            </button>
          </div>
        </div>
      )}
      {openDiscountInactive && <DiscountInactive />}
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { ...profile.actions })(DiscountActive);

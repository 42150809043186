import axios from 'axios';
import dotenv from 'dotenv';
import { platformType } from '../helpers/constants';

dotenv.config();

const { REACT_APP_BASE_URL } = process.env;

const EVENT_TRACKING = `${REACT_APP_BASE_URL}/v1/investor/event/customerio/track`;

const eventTracking = async eventName => {
  const response = await axios.post(`${EVENT_TRACKING}`, {
    eventName,
    platform: platformType.WEB_APP,
  });
  return response;
};

export { eventTracking };

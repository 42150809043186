import './../index.scss';

import { Link } from 'react-router-dom';
import React from 'react';
import { ConfirmButton } from '../../Common/ReusableButtons';

const WizardHomePage = () => {
  return (
    <div className="px-5 mt-md-20 mt-lg-40 px-md-20 px-lg-20">
      <h2 className="font-weight-bold text-dark">
        Get your personalized portfolio
      </h2>
      <p>
        Tell us about yourself and your goals. We will then build a plan that is
        perfect for you. We’ll ask about:
      </p>
      <div className="my-10">
        <p>
          <strong className="font-weight-bold text-dark">
            - Your investment goals and timelines
          </strong>
          <br />
          What are your goals and when do you hope to achieve them?
        </p>
        <p>
          <strong className="font-weight-bold text-dark">
            - Your income and savings
          </strong>
          <br />
          How much you earn and how much you save?
        </p>
      </div>
      <p>
        This only takes a couple of minutes and will help us design your
        portfolio
      </p>
      <div className="text-center mt-5">
        <Link to={'/wizard/age'}>
          <ConfirmButton buttonText={'Get Started'} />
        </Link>
      </div>
    </div>
  );
};

export default WizardHomePage;

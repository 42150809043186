import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions as goalActions } from '../_redux/goalReducers';
import Back from '../../Common/BackArrow';
import { GoalCard } from '../components/GoalCard';
import { getGoals } from '../_redux/goalActions';
import { FullWidthWrapper } from '../../Common/FullWidthWrapper';

const SelectGoalList = () => {
  const [goals, setGoals] = useState();
  const dispatch = useDispatch();

  const fetchGoalList = async () => {
    try {
      const res = await getGoals();
      const data = res.data.items;
      dispatch(goalActions.updateSuggestedGoalNames(data));
      setGoals(data);
    } catch (error) {}
  };

  useEffect(() => {
    // fetch goals
    fetchGoalList();
  }, []);

  let history = useHistory();

  const backLink = localStorage.getItem('backLink');

  return (
    <>
      <Back
        text={'Back'}
        title={'Select a Goal'}
        onClick={() => {
          localStorage.removeItem('backLink');
          history.push(backLink);
        }}
      />

      <FullWidthWrapper>
        <GoalCard goals={goals} />
      </FullWidthWrapper>
    </>
  );
};

export default injectIntl(SelectGoalList);

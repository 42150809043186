import React from 'react';
import { useDispatch } from 'react-redux';

import { actions as kycActions } from '../../KYC/_redux/kycRedux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Back from '../../Common/BackArrow';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { Note } from '../../Common/Note';

const InstructionStageTwo = () => {
  const { investor } = useSelector(state => state.profile);
  const kycLevel = investor?.kycLevel;
  const pendingKyc = investor?.pendingKyc;

  let history = useHistory();
  const dispatch = useDispatch();

  const previousPage = localStorage.getItem('previousPage');

  return (
    <>
      <Back title={'Investor Information'} previousPageLink={previousPage} />
      <KycPageContentWrapper>
        <div
          className="text-center text-18 font-weight-500 mt-5 px-10 mx-auto"
          style={{ maxWidth: '412px' }}
        >
          Additional information required
        </div>

        <div
          style={{ maxWidth: '646px' }}
          className="text-center text-16 mx-auto mt-1 mt-5"
        >
          To be compliant with the regulator, we need to verify your identity
          before placing your trade instructions. In line with the data
          protection act your data is safe guarded and will not be shared or
          sold to any third party.
        </div>

        <div className="d-flex justify-content-center mt-10">
          <ConfirmButton
            buttonText="START VERIFICATION"
            handleOnClick={() => {
              dispatch(kycActions?.cleanKycState());
              history.push(
                kycLevel?.level === 2
                  ? '/kyc-information/investor-details'
                  : kycLevel?.level === 3 && pendingKyc?.length > 1
                  ? '/kyc-information/investor-details'
                  : '/manage-account/investor-info/upload-documents',
                { previousStep: 3 },
              );
            }}
          />
        </div>

        <Note
          classes={
            'mx-auto mt-10 mb-8 bg-orange-200 border-orange-600 p-3 border-radius-7 text-13'
          }
          title={'Note:'}
          content={
            'All trade instructions placed with pending verification will not be actioned until you complete user verification.'
          }
          titleColor="text-red-200"
          contentColor="text-red-200"
          alignText="text-left"
        />
      </KycPageContentWrapper>
    </>
  );
};

export default InstructionStageTwo;

import React from 'react';
import { useSelector } from 'react-redux';

const CurrencyOptions = () => {
  const {
    currencies: { currencies },
  } = useSelector(state => state);
  return (
    <>
      {currencies?.map(currency => (
        <option key={currency.currency} value={currency.currency}>
          {currency.currency}
        </option>
      ))}
    </>
  );
};

export default CurrencyOptions;

import React from 'react';
import SVG from 'react-inlinesvg';

import { FullWidthWrapper } from '../../Common/FullWidthWrapper';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const NoInvitesOrTeamMembers = ({ message }) => {
  return (
    <FullWidthWrapper>
      <div className="d-flex flex-column justify-content-center align-items-center p-5">
        <SVG src={toAbsoluteUrl('/media/svg/ndovu/empty.svg')} />
        <span className="text-center text-16 text-blue font-weight-500">
          {message}
        </span>
      </div>
    </FullWidthWrapper>
  );
};

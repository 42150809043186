import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseModal from '../../Common/CloseModal';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';

function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '40%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const KycReasonModal = ({
  openReasonModal,
  setOpenReasonModal,
  setReason,
  sendKYCupdateRequest,
  loading,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const handleChange = e => {
    setReason(e.target.value);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="d-flex justify-content-between align-items-center">
        <div id="simple-modal-title" className="text-20 font-weight-500">
          Reason
        </div>
        <CloseModal setOpen={setOpenReasonModal} />
      </div>
      <div className="divider"></div>
      <p className="my-5" id="simple-modal-description">
        <textarea
          className="reason-text-area"
          name="reason-text-area"
          placeholder="Enter the reason for requesting investor information change"
          onChange={handleChange}
          rows="4"
          cols="50"
        />
      </p>

      <div className="d-flex justify-content-center buttons-gap">
        <CancelButton
          buttonText="CANCEL"
          handleOnClick={() => setOpenReasonModal(false)}
        />

        <ConfirmButton
          buttonText={'CONTINUE'}
          handleOnClick={() => sendKYCupdateRequest()}
          loading={loading}
        />
      </div>
    </div>
  );
  return (
    <Modal
      open={openReasonModal}
      onClose={() => setOpenReasonModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default KycReasonModal;

// src/styles/modalStyles.js
import { makeStyles } from '@material-ui/core/styles';

const useModalStyles = makeStyles(theme => ({
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    width: props => props.width || '800px',
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    [theme.breakpoints.down(868)]: {
      padding: theme.spacing(2),
      width: '90%',
    },
  },
}));

export default useModalStyles;

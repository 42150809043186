import React from 'react';
import { capitalizeTheFirstLetterOfEachWord } from '../../helpers/investmentHelpers';

export const DetailsItem = ({
  value,
  label,
  labelAdditionalClasses,
  valueAdditionalClasses,
  changeValueString = true,
}) => {
  return (
    <div className="d-flex justify-content-between py-1 text-12">
      <span className={`${labelAdditionalClasses}`}>{label}</span>
      <span className={`font-weight-600 ml-5 ${valueAdditionalClasses}`}>
        {value && changeValueString
          ? capitalizeTheFirstLetterOfEachWord(value)
          : value
          ? value
          : '---'}
      </span>
    </div>
  );
};

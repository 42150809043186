import { fetchConversion } from '../modules/Account/_redux/conversions/conversionActions';

const fetchConversionRate = async (from, to) => {
  try {
    const response = await fetchConversion(from, to);
    return response?.data;
  } catch (error) {
    return { error: error?.response?.data?.message };
  }
};

export const getConversion = async (from, to, allConversions) => {
  const conversion = allConversions?.find(
    conversion => conversion.conversion === `${from}_TO_${to}`,
  );
  if (conversion?.offeredConversionRate) return conversion;

  const currencyConversion = await fetchConversionRate(from, to);

  return currencyConversion;
};

export const convertAmount = (amount, converstionRate) => {
  return converstionRate && amount ? Math.round(amount * converstionRate) : 0;
};

import React from 'react';
import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';

const LetfContainer = ({ sector, imageBackground, fundBackground }) => {
  return (
    <>
      {fundBackground ? (
        <div
          style={{
            backgroundImage: `url('${fundBackground ? fundBackground : ''}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          className={`d-flex justify-content-between plan-details-first mr-3 ${imageBackground}`}
        >
          <span />
          {sector && (
            <span className="brand-label">{firstLetterUpperCase(sector)}</span>
          )}
        </div>
      ) : (
        <div
          className={`d-flex justify-content-between plan-details-first mr-3 ${imageBackground}`}
        >
          <span />
          {sector && (
            <span className="brand-label">{firstLetterUpperCase(sector)}</span>
          )}
        </div>
      )}
    </>
  );
};

export default LetfContainer;

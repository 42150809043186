import React from 'react';

export const PreferenceItem = ({ leftContent, rightContent }) => {
  return (
    <div className="bg-light-blue-40 border-light-blue-545 border-radius-4 my-2 p-3">
      <div className="d-flex flex-row justify-content-between align-items-center">
        {leftContent}

        {rightContent}
      </div>
    </div>
  );
};

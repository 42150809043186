import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export const SummaryPageRow = ({ label, value }) => {
  const smallDevice = useMediaQuery('(max-width : 768px)');

  return (
    <>
      {smallDevice ? (
        <div className="mt-2 text-13">
          <span>{label}:</span>
          <span className="text-blue font-weight-500 ml-2">{value}</span>
        </div>
      ) : (
        <div className="d-flex justify-content-between mt-2">
          <div className="text-14">{label}:</div>
          <div className="text-right text-blue text-14 font-weight-500">
            {value}
          </div>
        </div>
      )}
    </>
  );
};

import React from 'react';
import { messages } from '../../utils/messages';
import PopoverComponent from './PopoverComponent';

const PopoverIcon = ({ message }) => {
  const content = (
    <div>{message ? message : messages.pendingTransactions.totalInvested}</div>
  );
  return (
    <PopoverComponent popoverContent={content}>
      <span
        style={{ marginTop: '-4px', marginLeft: '5px', paddingTop: '5px' }}
        className="cursor-pointer"
      >
        <i
          style={{ color: '#0071CE' }}
          className="fa fa-exclamation-circle"
          aria-hidden="true"
        ></i>
      </span>
    </PopoverComponent>
  );
};

export default PopoverIcon;

import React from 'react';
import { invitesStatus } from '../../../helpers/constants';
import { AcceptOrReject } from './AcceptOrRejct';

export const Invite = ({ invite, handleInvite }) => {
  return (
    <div className="card card-custom wizard-custom-selector gutter-b bg-light-blue-200 border-light-blue border-radius-3">
      <div
        className="card-body d-flex flex-column flex-sm-row justify-content-sm-start justify-content-sm-between align-items-sm-center align-items-start"
        style={{ padding: '10px 17px' }}
      >
        <div className="d-flex flex-column auth-left text-dark-blue-700 font-weight-500">
          <span className="font-weight-600 text-17">
            {invite?.investor?.businessChamaName}
          </span>

          <span className="text-12 mt-2">
            By: {invite?.investor?.accountOwner?.firstName}{' '}
            {invite?.investor?.accountOwner?.lastName}
          </span>
        </div>

        {invite.status === invitesStatus.PENDING ? (
          <div className="d-flex mt-5 mt-sm-0">
            <AcceptOrReject
              invite={invite}
              text={'Accept'}
              handleInvite={handleInvite}
            />
          </div>
        ) : invite.status === invitesStatus.ACCEPTED ? (
          <AcceptOrReject invite={invite} text={'Accepted'} />
        ) : null}
      </div>
    </div>
  );
};

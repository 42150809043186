/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { checkToken, isTokenValidated } from './helpers/auth';
import { AuthPage, Logout } from './modules/Auth';
import TokenAuth from './modules/Auth/pages/TokenAuth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import Onboarding from './modules/Onboarding';
import WizardPage from './modules/Wizard/pages/Wizard';

export function Routes() {
  const referralCompleted = localStorage.getItem('referralCompleted');

  const { profile, tokenValidated } = useSelector(
    ({ auth, profile }) => ({
      profile,
      tokenValidated: auth.tokenValid,
    }),
    shallowEqual,
  );

  const { investor, user } = profile;

  const twofactorauthenabled = user?.twofactorauthenabled;
  const otpCode = user?.otpCode;

  const auth = checkToken();
  const isTokenValid = isTokenValidated();

  return (
    <Switch>
      <Route path="/wizard" component={WizardPage} />
      <Route path="/onboarding" component={Onboarding} />

      {!auth ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!auth ? (
        <Redirect to="/auth/login" />
      ) : !twofactorauthenabled ? (
        referralCompleted === 'false' && investor?.originPartner === null ? (
          <>
            <Redirect to="/onboarding" />
          </>
        ) : (
          <Layout>
            <BasePage />
          </Layout>
        )
      ) : (isTokenValid && !otpCode) || tokenValidated ? (
        <Layout>
          <BasePage />
        </Layout>
      ) : (
        <TokenAuth />
      )}
    </Switch>
  );
}

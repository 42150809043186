import React from 'react';

import { openUploadedFileInNewWindow } from '../../../helpers/openUploadedFileIntoNewTab';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { ImageMaximumSize } from './ImageMaximumSize';
import { DisplayUploadedFile } from './DisplayUploadedFile';

export const UploadDocument = ({
  inputRef,
  handleImageChange,
  loading,
  handleImageClick,
  title,
  image,
  uploadedFile,
  name,
  openExistingFile,
  existingFile,
  header,
}) => {
  return (
    <div
      className={`d-flex flex-wrap flex-column justify-content-center image-upload-card-wrapper border-light-blue-600 border-radius-8 px-5 py-2 position-relative`}
    >
      {header ? header : null}
      <div className="d-flex align-items-start">
        <div>{image}</div>

        <div className={`ml-2 text-16 text-blue font-weight-600`}>{title}</div>
      </div>
      <div>
        <input
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          name={name}
          onChange={e => {
            handleImageChange(e);
          }}
        />
        <div className="d-flex justify-content-center mt-4">
          <ConfirmButton
            buttonText={
              uploadedFile || existingFile ? 'CHANGE FILE' : 'UPLOAD FILE'
            }
            disable={loading}
            handleOnClick={handleImageClick}
            loading={loading}
            buttonWidth={'w-100'}
          />
        </div>

        {!uploadedFile && !existingFile ? <ImageMaximumSize /> : null}

        {uploadedFile || existingFile ? (
          <DisplayUploadedFile
            uploadedFile={uploadedFile}
            existingFile={existingFile}
            openUploadedFileInNewWindow={openUploadedFileInNewWindow}
            openExistingFile={openExistingFile}
          />
        ) : null}
      </div>
    </div>
  );
};

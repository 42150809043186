import React from 'react';
import { moneyFormat } from '../../../helpers/moneyFormat';

export const PendingTransactionAmount = ({ label, amount, currency }) => {
  return (
    <div className="bg-orange-150 font-weight-500 border-primary-orange p-2 border-radius-4 text-11 mt-5">
      {label}: {`${currency} ${moneyFormat(amount?.toFixed(2))}`}
    </div>
  );
};

import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_INVESTOR_ACTIVITIES = `${REACT_APP_BASE_URL}/v1/investor/investors/activities`;

const fetchInvestorActivities = async (page, limit) => {
  const res = await axios.get(
    `${FETCH_INVESTOR_ACTIVITIES}?page=${page}&limit=${limit}`,
  );
  return res;
};

export default fetchInvestorActivities;

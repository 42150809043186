import React from 'react';

const WarningMessage = ({ message, classes, background, borderColor }) => {
  return (
    <div
      className={`${classes} ${background} ${borderColor} warning-message-container`}
    >
      <div>{message}</div>
    </div>
  );
};

export default WarningMessage;

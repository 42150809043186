import React from 'react';
import { useHistory } from 'react-router-dom';

import { InvestorAccountType } from '../Components/InvestorAccountType';
import { AccountTypesList, accountTypes } from '../../../helpers/constants';
import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';

const AccountType = () => {
  const history = useHistory();
  // TODO::Handle errors

  const selectPrimaryAccount = async value => {
    localStorage.setItem('accountType', value);

    if (value === accountTypes.INDIVIDUAL) {
      history.push('/auth/success');
    } else {
      history.push('/auth/accountDetails');
    }
  };

  return (
    <>
      <div className="login-form" style={{ display: 'block' }}>
        <ExternalPageTitle title="Select account Type" />

        <div>
          {AccountTypesList.map(account => (
            <InvestorAccountType
              account={account}
              key={account?.value}
              selectPrimaryAccount={selectPrimaryAccount}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AccountType;

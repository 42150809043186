import React from 'react';

export const ButtonsContainer = ({
  children,
  justifyContent = 'justify-content-center mx-auto',
  addTopMargin = true,
  flexWrap = 'flex-wrap',
}) => {
  return (
    <div
      className={`${justifyContent} ${flexWrap} ${
        addTopMargin ? 'mt-10' : ''
      } w-100 d-flex buttons-gap `}
    >
      {children}
    </div>
  );
};

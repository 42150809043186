import React from 'react';

export const PermissionModalContent = ({ operationType }) => {
  const messages = {
    viwerPermission: `You can't complete this action as your user permissions don't allow it.`,
  };
  return (
    <>
      {operationType
        ? messages[operationType]
        : `You can't complete this action as your user permissions don't allow it.`}
    </>
  );
};

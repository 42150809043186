import {
  TeamAdminPermissions,
  TeamMemberDefaultPermissions,
  TeamManagerPermissions,
  TeamViewerPermissions,
} from '../../../helpers/constants';

export const getRolePermissions = role => {
  switch (role) {
    case 'MANAGER':
      return TeamManagerPermissions;
    case 'VIEWER':
      return TeamViewerPermissions;
    case 'ADMIN':
      return TeamAdminPermissions;
    default:
      return TeamMemberDefaultPermissions;
  }
};

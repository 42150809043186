import React from 'react';
import SVG from 'react-inlinesvg';
import './Style.scss';
import { useHistory } from 'react-router';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import './Style.scss';

const AddButton = ({ linkTo }) => {
  const history = useHistory();
  return (
    <button
      className="add-button  bg-blue text-white border-radius-4"
      onClick={() => history.push(linkTo)}
    >
      <SVG src={toAbsoluteUrl('/media/svg/ndovu/plus.svg')} />
      <span className="text-uppercase font-weight-500">Add</span>
    </button>
  );
};

export default AddButton;

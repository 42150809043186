import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { CustomButton } from '../ReusableButtons';
import { ButtonsContainer } from '../ButtonsContainer';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '455px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down(868)]: {
      width: '90%',
    },
  },
}));

const ErrorModal = ({
  open,
  setOpen,
  content,
  modalHeight,
  backgroundColor,
  btnLink,
  clearState,
  showCloseIcon,
  buttonText,
  modalTitle,
  generateModalButtonRedirectLink,
}) => {
  const [modalStyle] = useState(getModalStyle());
  const classes = useStyles();
  const history = useHistory();

  const body = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${modalHeight} ${backgroundColor} border-radius-8 border-1-orange-100 pt-5 pb-8 px-10`}
    >
      {showCloseIcon ? (
        <div
          onClick={() => setOpen(false)}
          className="bg-red-600 text-white border-radius-3 text-15 font-weight-500 ml-auto cursor-pointer"
          style={{
            paddingBottom: '1px',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          x
        </div>
      ) : null}
      <div className="d-flex justify-content-start align-items-center flex-column">
        <SVG
          className="mb-10"
          src={toAbsoluteUrl('/media/svg/ndovu/error.svg')}
          style={{ width: '72px', height: '72px' }}
        />
        <div className="w-100 modal-summary-header text-center text-red-600 font-weight-600">
          {modalTitle ? modalTitle : 'Error'}
        </div>
      </div>

      <div className="modal-summary-body text-center">{content}</div>

      {
        <ButtonsContainer>
          <CustomButton
            buttonText={buttonText ? buttonText : 'Try again'}
            handleOnClick={() => {
              clearState && clearState();
              setOpen(false);
              (btnLink || generateModalButtonRedirectLink) &&
                history.push(
                  btnLink ? btnLink : generateModalButtonRedirectLink(),
                );
            }}
            classes={'bg-red-600 text-white'}
          />
        </ButtonsContainer>
      }
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default ErrorModal;

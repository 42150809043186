import React, { useEffect, useState } from 'react';
import briefCase from '../../../images/briefCase.svg';
import chama from '../../../images/chama.svg';
import { useHistory } from 'react-router-dom';
import { accountTypes } from '../../../helpers/constants';
import Back from '../../Common/BackArrow';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';

const Welcome = () => {
  const history = useHistory();
  const [accountType, setAccountType] = useState();
  useEffect(() => {
    const accountSelected = localStorage.getItem('accountType');
    setAccountType(accountSelected);
  }, []);

  const handleNext = async () => {
    history.push('/investor-information/create-account');
  };
  return (
    <>
      <Back
        onClick={() =>
          history.push('/investor-information/select-account-type')
        }
        title={'Investor Information'}
      />
      <KycPageContentWrapper>
        <div className="text-center mt-10">
          <img
            src={
              accountType?.toLocaleLowerCase() ===
              accountTypes.CHAMA.toLocaleLowerCase()
                ? chama
                : briefCase
            }
            alt={'Brief case'}
          />
        </div>
        <div className="my-5 px-md-40 px-lg-40 px-0 text-center">
          <span className="mb-10 text-dark-gray-200 text-16 font-weight-600 text-center">
            Tell us about your{' '}
            {accountType?.toLocaleLowerCase() ===
            accountTypes.CHAMA.toLocaleLowerCase()
              ? 'Chama'
              : 'Business'}{' '}
          </span>
          <p className="mt-5 text-dark-gray-200">
            In order to complete your verification please prepare to Upload
            listed documents according to your type of business and location. In
            line with data protection act your data is safe guarded and shall
            not be shared or sold to any third party.
          </p>

          <div className="mt-10">
            <ConfirmButton
              buttonText={'Get Started'}
              handleOnClick={handleNext}
              buttonWidth={'w-100'}
            />
          </div>
        </div>
      </KycPageContentWrapper>{' '}
    </>
  );
};

export default Welcome;

import React from 'react';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import './singleCard.scss';
import ProgressBar from '../../../../helpers/ProgressBar';
import goalIcon from '../../../../icons/Goal.svg';

export const SingleCard = ({ goals }) => {
  const history = useHistory();

  const formatDate = date => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const calculatePercentageCovered = (currentAmount, goalAmount) => {
    const data = [];
    if (currentAmount?.totalValue >= 0 || currentAmount?.portfolioValue >= 0) {
      const value =
        currentAmount.totalValue >= 0
          ? currentAmount.totalValue
          : currentAmount.portfolioValue;
      const percentage = Math.floor((value / goalAmount) * 100);
      data.push({ value: percentage.toFixed(2) });
    }
    return data;
  };

  return (
    <>
      {goals?.map(goal => (
        <div
          className="single-goal-card border-light-blue-600 border-radius-6 p-5"
          key={goal.id}
          onClick={() => history.push(`/goal/viewGoal/${goal.id}`)}
        >
          <div className="goal-card-row1 align-items-start  mb-1">
            <img alt={goal.name} src={goalIcon} />{' '}
            <div
              className=" d-flex flex-column w-100"
              style={{ marginLeft: '15px' }}
            >
              <div className="d-flex flex-row">
                <div className="goal-top d-flex flex-row w-100 justify-content-between align-items-start">
                  <div className="d-flex align-items-center text-13 font-weight-500">
                    <span className="mr-1">Return</span>
                    <span
                      className={
                        Number(goal.goalPortfolioDetails.margin).toFixed(2) < 0
                          ? 'text-second-red'
                          : 'text-second-green'
                      }
                    >
                      {Number(goal.goalPortfolioDetails.margin).toFixed(2)}%
                    </span>
                  </div>
                  <div className="goal-date bg-light-blue-530 border-radius-4 text-10 text-blue">
                    <SVG
                      src={toAbsoluteUrl('/media/svg/ndovu/calendar.svg')}
                    ></SVG>
                    <div className="ml-1 text-10">
                      {formatDate(goal.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="goal-name">{goal.name}</div>
            </div>
          </div>
          <div className="goal-card-row2 mt-5">
            <ProgressBar
              valueArr={calculatePercentageCovered(
                goal?.goalPortfolioDetails,
                goal?.goalAmount,
              )}
              hidden={true}
            />
          </div>
          <div className="goal-card-row3 mt-1">
            <div className="goal-current-amount">
              <div className="text-10 font-weight-500">Current Amount</div>
              <div className="text-13 font-weight-600 text-blue">
                {goal.currency}
                <span style={{ marginLeft: '3px' }}>
                  {Number(
                    isNaN(goal.goalPortfolioDetails.totalValue)
                      ? goal.goalPortfolioDetails.investedAmount
                      : goal.goalPortfolioDetails.totalValue,
                  ).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>

            <div className="goal-target">
              <div className="goal-target-title text-10 font-weight-500">
                Target
              </div>
              <div className="text-primary-orange text-13 font-weight-600">
                {goal.currency}
                <span style={{ marginLeft: '3px' }}>
                  {Number(goal.goalAmount).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ProgressBar from './progress-bar/ProgressBar';
import { familiarInvestmentsChoices } from '../helpers/choices';
import { progressBarWidth } from '../../../utils/progressBarWidth';

import './../index.scss';
import '../pages/selector.scss';
import Exit from './Exit';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';

const FamiliarInvestmentsWizardPage = () => {
  let history = useHistory();
  const [familiarInvestments, setFamiliarInvestments] = useState([]);
  const [wizardData, setWizardData] = useState(null);
  const isRetakingWizard = localStorage.getItem('retakingTheWizard');

  useEffect(() => {
    const localStorageWizard = JSON.parse(localStorage.getItem('wizard'));
    setWizardData(localStorageWizard);
    localStorageWizard?.familiarInvestments &&
      setFamiliarInvestments(localStorageWizard?.familiarInvestments);
  }, []);

  const clickChoice = (e, id) => {
    let currentFamiliarInvestments = [...familiarInvestments];
    if (currentFamiliarInvestments.includes(id)) {
      currentFamiliarInvestments = currentFamiliarInvestments.filter(
        eachItem => eachItem !== id,
      );
      setFamiliarInvestments(currentFamiliarInvestments);
    } else {
      currentFamiliarInvestments.push(id);
      setFamiliarInvestments(currentFamiliarInvestments);
    }
  };

  const nextButtonSubmit = () => {
    localStorage.setItem(
      'wizard',
      JSON.stringify({ ...wizardData, familiarInvestments }),
    );
    history.push('/wizard/investmentAttitude');
  };

  const prevButtonSubmit = () => {
    history.push('/wizard/investmentExperience');
  };

  const cancelWizard = () => {
    localStorage.removeItem('wizard');
    localStorage.removeItem('retakingTheWizard');
    history.push('/dashboard');
  };

  return (
    <div>
      {/* <!--begin::Container--> */}
      <div className="container">
        <ProgressBar width={progressBarWidth()} />
        <div className="card-body p-0">
          {/* <!--begin::Wizard--> */}
          <div
            className="wizard wizard-1 animate__animated animate__fadeInUp"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="false"
          >
            {/* <!--begin::Wizard Body--> */}
            <div className="row justify-content-center my-10 px-1 my-lg-15 px-lg-10">
              <div className="col-xl-12">
                {/* <!--begin::Wizard Form--> */}
                <form className="form" id="kt_form">
                  <div
                    className="pb-3"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h2 className="mb-5 font-weight-bold text-dark">
                      Which type of investments are you familiar with?
                    </h2>

                    <div>
                      <p
                        className="font-weight-bold"
                        style={{ color: '#0071CE' }}
                      >
                        Select all that apply
                      </p>
                      {isRetakingWizard ? (
                        <Exit cancelWizard={cancelWizard} />
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    {familiarInvestmentsChoices.map(eachChoice => {
                      if (familiarInvestments?.includes(eachChoice.id)) {
                        return (
                          <div className="col-xl-12" key={eachChoice.id}>
                            <div
                              onClick={e => clickChoice(e, eachChoice.id)}
                              className="card card-custom  animated-card gutter-b"
                              style={{
                                background: '#0071CE',
                                color: 'white',
                              }}
                            >
                              {/* <!--begin::Body--> */}
                              <div
                                className="card-body d-flex flex-row justify-content-between"
                                style={{ padding: '1.5rem 2.25rem' }}
                              >
                                <div
                                  style={{ fontWeight: 300 }}
                                  className="font-size-h4"
                                >
                                  {eachChoice.choice}
                                </div>
                                <div
                                  style={{ fontWeight: 300 }}
                                  className="font-size-h4"
                                >
                                  <i
                                    style={{
                                      color: '#ffffff',
                                    }}
                                    className="fa fa-check"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              {/* <!--end::Body--> */}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-xl-12" key={eachChoice.id}>
                            <div
                              onClick={e => clickChoice(e, eachChoice.id)}
                              className="card animated-card card-custom  gutter-b"
                              style={{
                                background: 'rgba(0, 113, 206, 0.05)',
                                border: '0.5px solid rgba(0, 113, 206, 0.5)',
                                borderRadius: '5px',
                              }}
                            >
                              {/* <!--begin::Body--> */}
                              <div
                                className="card-body d-flex flex-row"
                                style={{ padding: '1.5rem 2.25rem' }}
                              >
                                {/* <!--begin::Stats--> */}
                                <div
                                  style={{ fontWeight: 300 }}
                                  className="font-size-h4"
                                >
                                  {eachChoice.choice}
                                </div>
                                {/* <!--end::Stats--> */}
                              </div>
                              {/* <!--end::Body--> */}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div>
                    <div className="d-flex d-lg-flex d-md-flex justify-content-between btn-wrapper  pt-10">
                      <CancelButton
                        buttonText="Prev"
                        buttonWidth={'width-140'}
                        handleOnClick={() =>
                          history.push('/wizard/investmentExperience')
                        }
                      />

                      {(wizardData?.familiarInvestments?.length > 0 ||
                        familiarInvestments?.length > 0) && (
                        <ConfirmButton
                          buttonText={'Next'}
                          buttonWidth={'width-140'}
                          handleOnClick={nextButtonSubmit}
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamiliarInvestmentsWizardPage;

import Axios from 'axios';
import { Country } from 'country-state-city';

export const getCountryCode = async countryCodeOrName => {
  const countryCode = Country.getCountryByCode(countryCodeOrName);

  if (countryCode) {
    return { countryCode: countryCodeOrName };
  }

  if (countryCodeOrName) {
    try {
      const response = await Axios.get(
        `https://restcountries.com/v3.1/name/${countryCodeOrName}`,
      );

      const countryDetails = response?.data?.find(
        country =>
          country?.cca2?.toLowerCase() === countryCodeOrName?.toLowerCase() ||
          country?.name?.common?.toLowerCase() ===
            countryCodeOrName?.toLowerCase(),
      );

      return { countryCode: countryDetails?.cca2 };
    } catch (error) {
      return { countryCode: null };
    }
  } else {
    return { countryCode: null };
  }
};

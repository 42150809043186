export const getInputClasses = (fieldname, formik) => {
  if (formik?.touched?.[fieldname] && formik?.errors?.[fieldname]) {
    return 'is-invalid';
  }

  if (formik?.touched?.[fieldname] && !formik?.errors?.[fieldname]) {
    return 'is-valid';
  }

  return '';
};

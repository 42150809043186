import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpIcon from '../../icons/arrow-up.svg';

function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '390px',
    maxHeight: '90vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const OptionsModal = ({
  open,
  handleSelect,
  handleClose,
  items,
  selectedItem,
  name,
  addSearch,
  placeholder,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [filteredOptions, setFilteredOptions] = useState();

  useEffect(() => {
    selectedItem &&
      items &&
      items.forEach((item, i) => {
        if (item.value === selectedItem.value) {
          items.splice(i, 1);
          items.unshift(item);
        }
      });
    setFilteredOptions(items);
  }, [items, selectedItem]);

  const handleChange = event => {
    const { value } = event.target;
    const searchResults = items.filter(
      item =>
        item.label.toLowerCase().includes(value.toLowerCase()) ||
        item.value.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredOptions(searchResults);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        {addSearch ? (
          <input
            onChange={handleChange}
            type="text"
            name="searchItem"
            className="search-input mb-5"
            placeholder="Search"
            autoComplete="off"
          />
        ) : null}
        <div className="d-flex justify-content-between align-items-center">
          <div className="ml-2">{placeholder}</div>
          <div className="cursor-pointer" onClick={() => handleClose()}>
            <img src={ArrowUpIcon} alt="Select Icon" />
          </div>
        </div>

        <div className="border-b-light-blue-400 my-3"></div>
        {filteredOptions?.map(item => (
          <div key={item.value}>
            <div
              className="d-flex justify-content-between align-items-center mt-1 cursor-pointer"
              onClick={() => handleSelect(item, name)}
            >
              <div
                style={{
                  color: selectedItem?.value === item?.value ? '#0071CE' : '',
                }}
                className="ml-2"
              >
                {item.label}
              </div>

              <div>
                {selectedItem?.value === item.value ? (
                  <i
                    className="fa fa-check"
                    style={{ color: '#0071CE', fontSize: '12px' }}
                    aria-hidden="true"
                  ></i>
                ) : null}
              </div>
            </div>
            <div className="border-b-light-blue-400 my-3"></div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default OptionsModal;

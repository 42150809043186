import React from 'react';
import {
  serviceProviders,
  settlementDetailsStatuses,
} from '../../../helpers/constants';
import { SettlementDetailsAction } from './SettlementDetailsAction';
import { SettlementDetailsStatusDescription } from './SettlementDetailsStatusDescription';
import { displayBankAccountDetails } from '../helpers/displayBankAcountNumber';

export const SettlementDetails = ({
  settlementDetail,
  editSettlementDetailsPageLink,
  openConfirmModal,
  settlementDetailsId,
  setSettlementDetailsId,
  setAlert,
}) => {
  const settlementDetailsStatusDescription = {
    [settlementDetailsStatuses.ON_HOLD]: (
      <SettlementDetailsStatusDescription
        settlementDetailsStatus={'On Hold'}
        settlementDetailsStatusDescription={
          <span>
            Sorry, there is an error with your submitted details, please confirm
            all the information provided is correct with our support team on
            e-mail
          </span>
        }
      />
    ),

    [settlementDetailsStatuses.REJECTED]: (
      <SettlementDetailsStatusDescription
        settlementDetailsStatus={'Rejected'}
        settlementDetailsStatusDescription={
          'Sorry the detail we received was not approved. Please provide another detail for review. You will not be able to use this detail.'
        }
      />
    ),
  };

  return (
    <div
      className={`mobile-money d-flex justify-content-between ${
        settlementDetail?.approvalStatus !== settlementDetailsStatuses.SUBMITTED
          ? 'align-items-start'
          : 'align-items-center'
      } border-light-blue-600 border-radius-8 mb-3 py-2 px-4`}
      key={settlementDetail?.id}
    >
      <div>
        <div className="mb-1 text-12 text-back-600">
          {settlementDetail?.serviceProvider === serviceProviders.MPESA
            ? 'Safaricom M-pesa:'
            : settlementDetail?.bankName}
        </div>
        <div className="text-black-700 text-12 font-weight-600 mt-3">
          {settlementDetail?.serviceProvider === serviceProviders.MPESA
            ? settlementDetail?.phoneNumber
            : displayBankAccountDetails(settlementDetail?.accountNumber)}
        </div>

        {settlementDetail?.approvalStatus !==
          settlementDetailsStatuses.SUBMITTED &&
          settlementDetailsStatusDescription[settlementDetail?.approvalStatus]}
      </div>

      <div className="d-flex align-items-center">
        {settlementDetail?.approvalStatus ===
        settlementDetailsStatuses.SUBMITTED ? (
          <SettlementDetailsAction
            link={editSettlementDetailsPageLink}
            iconName={'edit'}
            setSettlementDetailsId={setSettlementDetailsId}
            settlementDetailsId={settlementDetailsId}
            setAlert={setAlert}
          />
        ) : null}

        <SettlementDetailsAction
          iconName={'delete'}
          openConfirmModal={openConfirmModal}
          setSettlementDetailsId={setSettlementDetailsId}
          settlementDetailsId={settlementDetailsId}
          setAlert={setAlert}
        />
      </div>
    </div>
  );
};

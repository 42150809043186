import React from 'react';

export const PlanSubFeature = ({ title, bulletPointColor }) => {
  return (
    <li className="mt-1">
      <i
        className="fas fa-circle fa-sm mr-1"
        style={{
          color: bulletPointColor ? bulletPointColor : '#FFF',
          fontSize: '5px',
        }}
      ></i>
      <span className="text-12">{title}</span>
    </li>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import '../components/PlanCard/index.scss';
import { PlanTitle } from '../../Common/PlanTitle';
import Card from '../components/PlanCard/Card';
import TopCard from '../components/PlanCard/TopCard';
import ProfileRisk from '../components/PlanCard/ProfileRisk';
import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';

const SavingPlanCard = ({ data, classes }) => {
  const history = useHistory();
  return (
    <Card width={'plan-card-width'}>
      <PlanTitle className={'plan-title px-3'} data={data} />

      {data && data.imageUrl ? (
        <TopCard
          onClick={() => history.push(`/plans/${data?.id}`)}
          imageLink={data.imageUrl}
        >
          <span className="cash">{firstLetterUpperCase(data?.sector)}</span>
        </TopCard>
      ) : (
        <TopCard onClick={() => history.push(`/plans/${data?.id}`)}>
          <span className="cash">{firstLetterUpperCase(data?.sector)}</span>
        </TopCard>
      )}

      <div className="plan-card-second-container p-2">
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="d-flex align-items-center justify-content-center font-weight-bold lock-in-period-tile px-3"
            onClick={() => history.push(`/plans/${data?.id}`)}
          >
            Fixed Return
          </div>

          <div
            onClick={() => history.push(`/plans/${data?.id}`)}
            className="fixed-return ml-2"
          >
            <div
              style={{ fontSize: '12px' }}
            >{`${data.lockInPeriod} Months Minimum*`}</div>
          </div>
        </div>

        <div
          onClick={() => history.push(`/plans/${data?.id}`)}
          className="fund-container"
        ></div>

        <ProfileRisk
          planTypeName={data?.planType?.name}
          riskProfile={data?.planRiskLevel}
          data={data}
          onClick={() => history.push(`/plans/${data?.id}`)}
        />
      </div>
    </Card>
  );
};

export default SavingPlanCard;

import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const UPDATE_INVESTOR_KYC_DETAILS = `${REACT_APP_BASE_URL}/v2/investor/investors/update-investor-details`;
const UPDATE_NEW_KYC = `${REACT_APP_BASE_URL}/v2/investor/investors/update-investor-details`;

const sendFormDataToBackend = async payload => {
  const res = await axios.put(UPDATE_INVESTOR_KYC_DETAILS, payload);
  return res;
};

const UpdateKycAdditionalInfo = async payload => {
  const res = await axios.put(UPDATE_INVESTOR_KYC_DETAILS, payload);
  return res;
};

const UpdateKYCInformation = async payload => {
  const res = await axios.put(UPDATE_NEW_KYC, payload);
  return res;
};

export { sendFormDataToBackend, UpdateKycAdditionalInfo, UpdateKYCInformation };

import React from 'react';
import { PlanTitle } from '../../../Common/PlanTitle';
import ExtremelyLowRisk from './ExtremelyLow';
import HighRisk from './HighRisk';

import LowRisk from './LowRisk';
import MediumRisk from './MediumRisk';

const numberOfSquares = [0, 1, 2, 3, 4, 5, 6];

const ProfileRisk = ({ riskProfile, bold, onClick, goalName, data }) => {
  return (
    <div
      className={
        goalName
          ? 'mt-2'
          : 'd-flex justify-content-between align-items-center px-2'
      }
    >
      {goalName ? (
        data ? (
          <PlanTitle data={data} />
        ) : (
          goalName
        )
      ) : (
        <div
          style={{
            color: 'rgb(63, 66, 84)',
            fontSize: '14px',
          }}
          className={`mt-2 ${bold}`}
        >
          <div className="d-flex flex-column p-1 pl-md-2">
            <div className="d-flex flex-row">
              <img
                style={{
                  width: '29.422px',
                  height: '19.084px ',
                }}
                src={data.fundCurrency?.currencyFlag}
                alt="country flag"
              />
              <div
                style={{
                  fontSize: '11.133px',
                }}
                className="pl-2 font-weight-bold text-bold-blue"
              >
                {data?.fundCurrency?.currency}
              </div>
            </div>
            <div
              className="text-dark font-weight-bold td-font-size-12"
              style={{ width: '120px' }}
            >
              Currency
            </div>
          </div>
        </div>
      )}

      {!goalName && (
        <div
          onClick={onClick}
          className="d-flex flex-column align-content-end mt-2"
        >
          {riskProfile?.id === 1 ? (
            <LowRisk squares={numberOfSquares} />
          ) : riskProfile?.id === 2 ? (
            <MediumRisk squares={numberOfSquares} />
          ) : riskProfile?.id === 3 ? (
            <HighRisk squares={numberOfSquares} />
          ) : (
            riskProfile?.id === 4 && (
              <ExtremelyLowRisk squares={numberOfSquares} />
            )
          )}
          <div className="text-dark font-weight-bold td-font-size-12 mt-2">
            Risk level:{' '}
            <span style={{ color: '#0071CE', fontWeight: '500' }}>
              {' '}
              {riskProfile?.name}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileRisk;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import * as profile from '../_redux/profileRedux';
import { changePreferedCurrency, getUserProfile } from '../_redux/profileCrud';

import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import './index.scss';
import '../../Onboarding/index.scss';
import CurrencyOptions from '../../Common/CurrencyOptions';

const ProfileOverview = ({ profileAction }) => {
  const history = useHistory();
  const { investor, user } = useSelector(state => state.profile);

  const [editing, setEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    currencies: { currencies },
  } = useSelector(state => state);

  useEffect(() => {
    const getData = async () => {
      const { data: { data: userProfile } = {} } = await getUserProfile();
      profileAction(userProfile);
    };
    getData();
  }, []);

  const handleCurrencyChange = async event => {
    setLoading(true);
    const selectedCurrency = currencies?.find(
      currency => currency.currency === event.target.value,
    );

    try {
      const {
        data: { data },
      } = await changePreferedCurrency(selectedCurrency?.id);
      profileAction(data);
      setLoading(false);
      setEditing(false);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setLoading(false);
    }
  };

  const triggerCurrencyChange = () => {
    setEditing(true);
  };

  return (
    <>
      {/* <!--begin::Content--> */}
      <div
        id="card-margin"
        style={{ background: '#ffffff' }}
        className="flex-row-fluid mx-40"
      >
        {/* <!--begin::Card--> */}
        <div className="card card-custom card-stretch">
          {/* <!--begin::Header--> */}
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label" style={{ color: '#0071CE' }}>
                Your Personal Information
              </h3>
              <span className="font-size-sm mt-1">
                Update your personal information
              </span>
            </div>
            <div className="card-toolbar">
              <button
                onClick={() =>
                  history.push('/investor-information/create-account')
                }
                type="submit"
                className="btn  mr-2"
                style={{ color: '#fff', background: '#0071CE' }}
              >
                Edit Profile
              </button>
            </div>
          </div>
          {/* <!--end::Header--> */}
          {/* <!--begin::Form--> */}
          <div className="p-10">
            <div className="d-flex justify-content-between">
              <p>First Name</p>
              <p>{`${user.firstName}`}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Middle Name</p>
              <p>{user.middleName ? `${user.middleName}` : '---'}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Last Name</p>
              <p>{`${user.lastName}`}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Email</p>
              <p>{`${user.email}`}</p>
            </div>
          </div>

          {/* <!--end::Form--> */}
        </div>
      </div>
      {/* <!--end::Content--> */}

      <div
        style={{ background: '#ffffff' }}
        id="card-margin"
        className="flex-row-fluid mx-40 mt-5"
      >
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label" style={{ color: '#0071CE' }}>
                Preferences
              </h3>
            </div>
          </div>
          <div className="p-10">
            {errorMessage ? (
              <div
                style={{
                  color: '#F05B7F',
                  textAlign: 'center',
                  marginBottom: '5px',
                }}
              >
                {errorMessage}
              </div>
            ) : null}
            <div className="d-flex justify-content-between flex-wrap  mt-2">
              <div>Investor Type</div>
              <div className="font-weight-bold">
                {investor?.riskProfile?.alias}
              </div>
              <div
                style={{ color: '#0071CE', cursor: 'pointer' }}
                onClick={() => history.push('/investor-type')}
              >
                {investor?.riskProfile?.alias
                  ? 'Change Investor Type'
                  : 'Take Test'}
              </div>
            </div>

            <div className="d-flex justify-content-between flex-wrap align-items-center mt-5">
              <div className="mr-5">User Currency</div>
              <div className="font-weight-bold">
                {loading ? (
                  <span className="ml-3 spinner spinner-orange" />
                ) : (
                  investor?.currency?.currency
                )}
              </div>
              {editing ? (
                <select
                  placeholder="Currency"
                  className="goal-amount-currency"
                  id="exampleSelect1"
                  onChange={handleCurrencyChange}
                  defaultValue={investor?.currency?.currency}
                >
                  <option value="">Currency</option>
                  <CurrencyOptions />
                </select>
              ) : (
                <div
                  style={{ color: '#0071CE', cursor: 'pointer' }}
                  onClick={triggerCurrencyChange}
                >
                  Change Investor Currency
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  profile: state.profile,
  userData: state.auth.authToken,
});

export default connect(mapStateToProps, {
  ...profile.actions,
})(ProfileOverview);

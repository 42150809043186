import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchPlansDailyTrading } from './dashboardHttpRequests';

export const actionTypes = {
  PlansDailyTrading: '[PlansDailyTrading] Action',
  FetchPlansDailyTrading: '[FetchPlansDailyTrading] Action',
  DisplayKycRejectionReasonModal: '[DisplayKycRejectionReasonModal] Action',
};

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PlansDailyTrading: {
      const { payload } = action;
      return {
        ...state,
        plansDailyTrading: payload,
      };
    }

    default:
      return state;
  }
};

export const displayKycRejectionReasonReducer = (state = false, action) => {
  switch (action.type) {
    case actionTypes.DisplayKycRejectionReasonModal: {
      const { payload } = action;
      return payload;
    }

    default:
      return state;
  }
};

export const actions = {
  plansDailyTradingAction: plansDailyTrading => ({
    type: actionTypes.PlansDailyTrading,
    payload: plansDailyTrading,
  }),

  fetchPlansDailyTradingAction: () => ({
    type: actionTypes.FetchPlansDailyTrading,
  }),

  displayKycRejectionReasonModal: displayTheModal => ({
    type: actionTypes.DisplayKycRejectionReasonModal,
    payload: displayTheModal,
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.FetchPlansDailyTrading,
    function* fetchPlansDailyTradingAction() {
      const {
        data: { data },
      } = yield call(fetchPlansDailyTrading);
      yield put(actions.plansDailyTradingAction(data));
    },
  );
}

import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../../Common/Button';

const NextButtonFooter = ({ url }) => {
  const history = useHistory();
  return (
    <div className="text-center mb-10 mx-5">
      <Button
        btnText="Next"
        className="text-center full-width-small btn-size common-btn"
        onClick={() => history.push(url)}
      />
    </div>
  );
};
export default NextButtonFooter;

import React from 'react';

import appleStore from '../../../images/appleStore.svg';
import googlePlayStore from '../../../images/googlePlayStore.svg';
import { removeDownloadAppBanner } from '../../Profile/_redux/profileCrud';

export const DownloadAppBanner = () => {
  const removeDownloadAppBannerFromDashboard = async () => {
    try {
      await removeDownloadAppBanner();
      window.location.reload();
    } catch (error) {}
  };

  const closeInstallAppCallToAction = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.4 12L19.7 5.7C20.1 5.3 20.1 4.7 19.7 4.3C19.3 3.9 18.7 3.9 18.3 4.3L12 10.6L5.7 4.3C5.3 3.9 4.7 3.9 4.3 4.3C3.9 4.7 3.9 5.3 4.3 5.7L10.6 12L4.3 18.3C4.1 18.5 4 18.7 4 19C4 19.6 4.4 20 5 20C5.3 20 5.5 19.9 5.7 19.7L12 13.4L18.3 19.7C18.5 19.9 18.7 20 19 20C19.3 20 19.5 19.9 19.7 19.7C20.1 19.3 20.1 18.7 19.7 18.3L13.4 12Z"
        fill="#0D78D0"
      />
    </svg>
  );

  return (
    <div className="banner-container bg-light-blue-60 border-light-blue-600 mb-5 py-2 px-3 border-radius-5">
      <div className="banner-content-container">
        <div className="text-md-13 mb-md-0 mb-2 text-13 font-weight-500 text-dark-350">
          Install the app to better manage your portfolio
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex">
            <a
              href="https://apps.apple.com/ug/app/ndovu/id1579305298"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appleStore} alt="Apple store" />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=co.ndovu.investor"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="ml-2"
                src={googlePlayStore}
                alt="Google Play store"
              />
            </a>
          </div>

          <div
            onClick={() => removeDownloadAppBannerFromDashboard()}
            className="hide-close-bar-one ml-3 font-weight-600 text-15 close-bar cursor-pointer"
          >
            {closeInstallAppCallToAction}
          </div>
        </div>
      </div>
      <div
        onClick={() => removeDownloadAppBannerFromDashboard()}
        className="hide-close-bar-two ml-2 mr-5-- font-weight-600 close-bar cursor-pointer"
      >
        {closeInstallAppCallToAction}
      </div>
    </div>
  );
};

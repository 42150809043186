/* eslint-disable jsx-a11y/anchor-is-valid */

import { FullWidthWrapper } from '../../Common/FullWidthWrapper';
import '../../Onboarding/index.scss';

import React from 'react';

const Referral = ({ profile }) => {
  const userReferralCode = profile?.investor?.userReferralCode;
  return (
    <>
      <div className="mt-10">
        <FullWidthWrapper title={'Referral Code'}>
          <div className="text-center tex-16">
            <div className="mt-10 mx-5 px-md-35 px-5">
              Invite your friends. Earn a referral bonus. For every friend you
              refer who invests on ndovu, you will get a referral bonus of $5!
              limited to 5 referrals. Hurry this is a limited offer!
            </div>

            <div className="mt-10 mx-5">
              <span>Referral ID:</span>{' '}
              <span className="text-blue font-weight-500">
                {userReferralCode ? userReferralCode : '00000'}
              </span>
            </div>
            <div className="mx-5 my-10">
              <p> Get your friends in on the fun. Happy Referring!</p>
            </div>
          </div>
        </FullWidthWrapper>
      </div>
    </>
  );
};

export default Referral;

import { accountTypes, managerOperations, teamMemberRole } from './constants';

export const checkInvestorPermission = (
  investorRole,
  investorType,
  operation,
) => {
  if (investorType === accountTypes.INDIVIDUAL) return true;

  if (
    investorType === accountTypes.BUSINESS ||
    investorType === accountTypes.CHAMA
  ) {
    if (investorRole === teamMemberRole.ADMIN) return true;

    if (
      investorRole === teamMemberRole.MANAGER &&
      managerOperations?.includes(operation)
    )
      return true;
  }

  return false;
};

import React from 'react';

import { openUploadedFileInNewWindow } from '../../../helpers/openUploadedFileIntoNewTab';
import DocumentsIcon from '../../../icons/document.svg';

export const DisplayUploadedFile = ({
  uploadedFile,
  existingFile,
  openExistingFile,
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-1 mx-auto">
      <img src={DocumentsIcon} alt="Documents Icon" />
      <div className="additional-documents text-13 font-weight-500 ml-3">
        File Uploaded
        <span
          className="text-blue pl-3 cursor-pointer"
          onClick={() =>
            uploadedFile
              ? openUploadedFileInNewWindow(uploadedFile)
              : existingFile && openExistingFile(existingFile)
          }
        >
          (View)
        </span>
      </div>
    </div>
  );
};

export const actionTypes = {
  UpdateWithdrawalDetails: '[UpdateWithdrawalDetails] Action',
  ResetWithdrawalDetails: '[ResetWithdrawalDetails] Action',
};

export const initialState = {
  unitPercentage: null,
  numberOfUnits: null,
  transactionFee: null,
  approximatedValue: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UpdateWithdrawalDetails: {
      return {
        ...state,
        ...action.payload.withdrawalDetails,
      };
    }

    case actionTypes.ResetWithdrawalDetails: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};

export const actions = {
  updateWithdrawalDetails: withdrawalDetails => ({
    type: actionTypes.UpdateWithdrawalDetails,
    payload: { withdrawalDetails },
  }),

  resetWithdrawalDetails: () => ({
    type: actionTypes.ResetWithdrawalDetails,
  }),
};

export function* saga() {}

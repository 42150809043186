import React from 'react';
import { PlanFeature } from './components/PlanFeature';
import { PlanSubFeature } from './components/PlanSubFeature';

export const StandardSubscriptionList = () => {
  return (
    <>
      <PlanFeature title={'One expert financial planning session worth $1,000'}>
        <ul
          style={{ listStyle: 'none' }}
          className="subs-list-info subs-subs-list-info text-left"
        >
          <PlanSubFeature title={'Craft a tailored financial plan'} />
          <PlanSubFeature
            title={'Achieve your financial goals using expert advice'}
          />
        </ul>
      </PlanFeature>

      <PlanFeature
        title={'Track your budget with Ndovu’s downloadable budget planner'}
      />

      <PlanFeature
        title={
          'Track your investment with Ndovu’s downloadable investment performance tracker'
        }
      />
    </>
  );
};

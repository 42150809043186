import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { actions as goalReducerActions } from '../../Goal/_redux/goalReducers';
import { actions as pocketActions } from '../../Pocket/_redux/reducers';
import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import CurrencyOptions from '../../Common/CurrencyOptions';
import { changePreferedCurrency } from '../../Profile/_redux/profileCrud';
import Back from '../../Common/BackArrow';
import { FullWidthWrapper } from '../../Common/FullWidthWrapper';
import { PreferenceItem } from '../components/PreferenceItem';

const Preferences = () => {
  const history = useHistory();
  const {
    profile,
    currencies: { currencies },
  } = useSelector(state => state);

  const { investor } = profile;

  const [editing, setEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(
    investor?.currency?.currency,
  );

  const dispatch = useDispatch();

  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );

  const handleCurrencyChange = async event => {
    setLoading(true);
    const selectedCurrency = currencies?.find(
      currency => currency.currency === event.target.value,
    );

    try {
      const {
        data: { data },
      } = await changePreferedCurrency(selectedCurrency?.id);
      setCurrentCurrency(data?.currency?.currency);

      reduxGoalInvestmentDtls?.staticGoalId &&
        dispatch(
          goalReducerActions.updateGoalInvestmentDetails({
            ...reduxGoalInvestmentDtls,
            currency: data?.currency?.currency,
          }),
        );

      dispatch(
        investorProfileActions.profileAction({
          ...profile,
          investor: { ...investor, currency: data?.currency },
          loading: false,
        }),
      );

      selectedCurrency?.pockets[0]
        ? dispatch(
            pocketActions.updatePocket({
              ...selectedCurrency?.pockets[0],
              currency: selectedCurrency?.currency,
            }),
          )
        : dispatch(pocketActions.updatePocket(null));

      setLoading(false);
      setEditing(false);
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className="mt-5">
      <Back title={'Preferences'} />
      <FullWidthWrapper>
        {/* Investor Type */}
        <PreferenceItem
          leftContent={
            <div className="d-flex flex-column text-16 text-md-16 font-weight-500">
              <span>Investor Type</span>
              <span className="text-blue font-weight-600">
                {investor?.riskProfile?.alias}
              </span>
            </div>
          }
          rightContent={
            <div
              className="custom-btn width-140"
              type="submit"
              onClick={() => history.push('/wizard/age')}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>{'Take Quiz'}</div>
                <div className="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.21967 2.96967C8.51256 2.67678 8.98744 2.67678 9.28033 2.96967L13.5303 7.21967C13.8232 7.51256 13.8232 7.98744 13.5303 8.28033L9.28033 12.5303C8.98744 12.8232 8.51256 12.8232 8.21967 12.5303C7.92678 12.2374 7.92678 11.7626 8.21967 11.4697L11.1893 8.5H3.75C3.33579 8.5 3 8.16421 3 7.75C3 7.33579 3.33579 7 3.75 7H11.1893L8.21967 4.03033C7.92678 3.73744 7.92678 3.26256 8.21967 2.96967Z"
                      fill="#FFFFFE"
                    />
                  </svg>
                </div>
              </div>
            </div>
          }
        />

        {/* User Currency */}
        <PreferenceItem
          leftContent={
            <div className="d-flex flex-column text-16 text-md-16 font-weight-500">
              <span>User Currency</span>
              {loading ? (
                <div>
                  <span className="ml-3 spinner" />
                </div>
              ) : (
                <span className="text-10">{currentCurrency}</span>
              )}
            </div>
          }
          rightContent={
            <select
              placeholder="Currency"
              className="user-preferred-currency width-140"
              id="exampleSelect1"
              onChange={handleCurrencyChange}
              defaultValue={investor?.currency?.currency}
            >
              <option value="">Currency</option>
              <CurrencyOptions />
            </select>
          }
        />
      </FullWidthWrapper>
    </div>
  );
};

export default Preferences;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Country } from 'country-state-city';

import {
  registerBusinessChama,
  updateBusinessChama,
} from '../../Auth/_redux/authCrud';

import Caution from '../components/Caution';

import {
  accountTypes,
  allowWhiteSpace,
  allowWhiteSpaceError,
  countryCode,
  countryCode as CountryCode,
  kycFlowActions,
} from '../../../helpers/constants';
import Dropdown from '../../Common/Dropdown';
import { FieldErrorMessage } from '../../Individual/components/FieldErrorMessage';
import { getCountryCode } from '../../../helpers/getCountryCode';
import { checkForErrors } from '../../../helpers/checkForErrors';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import getCountries from '../../../helpers/countries';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';
import CustomInput from '../../Common/CustomInput';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import useUserProfile from '../../../helpers/CustomHook/useUserProfile';
import { BusinessType, RequiredDocument } from '../components/RequiredDocument';

const CreateBusinessOrChama = props => {
  const { id: stepperId, handleStepChange } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openCountryDropdown, setOpenCountryDropdown] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [errorExist, setErrorExist] = useState(false);

  const { investor } = useSelector(state => state.profile);
  const kycInformation = useSelector(state => state.kyc);

  const { updateInvestorProfile, error } = useUserProfile();

  let creatingAccount = localStorage.getItem('creatingAccount');
  creatingAccount = creatingAccount && Boolean(creatingAccount);

  let accountType = localStorage.getItem('accountType');
  accountType = accountType ? accountType : investor?.investorType;

  const isBusinessAccount =
    accountType?.toLocaleLowerCase() ===
    accountTypes.BUSINESS.toLocaleLowerCase();

  const isChamaAccount =
    accountType?.toLocaleLowerCase() === accountTypes.CHAMA.toLocaleLowerCase();

  const updateCountryDetails = async () => {
    const { countryCode } = await getCountryCode(investor?.country);

    const countryCodeToUse = isChamaAccount ? CountryCode.KE : countryCode;

    setSelectedCountry({
      countryFlag: countryCodeToUse
        ? `flag-icon-${countryCodeToUse.toLocaleLowerCase()}`
        : null,
      currency: countryCodeToUse
        ? Country.getCountryByCode(countryCodeToUse)?.name
        : null,
      countryCode: countryCodeToUse ? countryCodeToUse : null,
    });
  };

  useEffect(() => {
    updateCountryDetails();
    handleStepChange({ next: stepperId });
  }, []);

  const handleCountryDropdown = () => {
    setOpenCountryDropdown(!openCountryDropdown);
  };

  const initialValues = {
    businessChamaName: creatingAccount ? '' : investor?.businessChamaName || '',
    country: creatingAccount ? '' : investor?.country || 'Kenya',
  };

  const DetailsSchema = Yup.object().shape({
    businessChamaName: Yup.string()
      .min(4, 'Minimum 4 Characters')
      .max(50, 'Maximum 50 Characters')
      .matches(allowWhiteSpace, allowWhiteSpaceError)
      .required('Account Name is required'),
  });

  const handleSelect = country => {
    setSelectedCountry({
      countryFlag: country?.flag,
      currency: country?.name,
      countryCode: country?.countryCode,
    });
    setOpenCountryDropdown(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: DetailsSchema,
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);

      const fields = {
        country: selectedCountry?.countryCode,
      };

      const noErrors = checkForErrors(
        { ...values, ...fields },
        isChamaAccount ? ['country'] : [],
      );

      if (!noErrors) {
        setErrorExist(true);
        setLoading(false);
        return;
      }

      const businessChama = {
        businessChamaName: values.businessChamaName,
        country: isBusinessAccount
          ? selectedCountry?.countryCode
          : countryCode.KE,
        investorType: accountType,
      };

      const updateBusinessChamaNamePayload = {
        businessChamaName: values.businessChamaName,
        country: isBusinessAccount
          ? selectedCountry?.countryCode
          : countryCode.KE,
      };

      if (creatingAccount) {
        try {
          const res = await registerBusinessChama(businessChama);

          if (res.status === 201) {
            localStorage.setItem('ndovuToken', res?.data?.data?.accessToken);

            updateInvestorProfile();
            setLoading(false);
            localStorage.removeItem('creatingAccount');
            history.push('/investor-information/documentation');
          } else {
            // Handle any other response status codes or errors
            setLoading(false);
            setStatus(`Failed to add ${accountType}, please try again`);
          }
        } catch (error) {
          // Handle exceptions or errors here, e.g., set an error message with setStatus
          setStatus(error.response.data.message);
          setLoading(false);
        }
      } else {
        try {
          if (
            updateBusinessChamaNamePayload?.businessChamaName ===
              investor?.businessChamaName &&
            selectedCountry?.countryCode === investor?.country
          ) {
            localStorage.removeItem('creatingAccount');
            history.push('/investor-information/documentation');
            return;
          }

          await updateBusinessChama(
            investor?.id,
            updateBusinessChamaNamePayload,
          );

          updateInvestorProfile();

          localStorage.removeItem('creatingAccount');
          history.push('/investor-information/documentation');
        } catch (error) {
          setStatus(error.response.data.message);
          setLoading(false);
        }
      }
    },
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const previousPage = localStorage.getItem('previousPage');
  const updatingKyc =
    kycInformation?.kycAction === kycFlowActions.UPDATING_KYC_INFORMATION;

  return (
    <>
      <form className="mt-5" id="kt_form" onSubmit={formik.handleSubmit}>
        <KycPageContentWrapper>
          {formik.status ? (
            <div>
              <div
                className="text-center font-weight-bold"
                style={{ color: '#F05B7F' }}
              >
                {formik.status}
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="d-flex justify-content-between flex-wrap">
            <DropdownContainer>
              <DropdownTitle
                title={isBusinessAccount ? 'Business Country' : 'Chama Country'}
              />

              <Dropdown
                handleOpenSelector={handleCountryDropdown}
                dropdownPlaceholder={
                  isBusinessAccount
                    ? 'Select Business Country'
                    : 'Select Chama Country'
                }
                selectedOption={selectedCountry}
                classes={
                  'custom-input-width dropdown-padding border-radius-8 pr-5 pl-2'
                }
                name={'selectedCountry'}
                disableDropdown={isChamaAccount}
              />

              {errorExist ? (
                <FieldErrorMessage
                  errorExist={errorExist}
                  errorMessage={`${'Business Country is Required'}`}
                  fieldValue={selectedCountry?.countryCode}
                />
              ) : null}
            </DropdownContainer>

            <CustomInput
              type="text"
              title={`${capitalizeTheFirstLetterOfEachWord(accountType)} Name`}
              name="businessChamaName"
              formik={formik}
              getInputClasses={getInputClasses}
            />
          </div>

          <div className="flex-wrap d-flex flex-center justify-content-center mobile-row mt-7">
            <div className="instruction-information border-light-blue-600 border-radius-8 mt-3">
              <div className="text-center text-14 font-weight-600 my-5">
                Prepare to upload listed documents
              </div>
              {isChamaAccount ? (
                <ol type="1" className="mx-auto">
                  <RequiredDocument
                    documentName={'National ID or Passport (Treasurer’s)'}
                  />
                  <RequiredDocument
                    documentName={'Certificate of Registration'}
                  />
                  <RequiredDocument documentName={'Tax PIN Certificate'} />
                  <RequiredDocument documentName={'Letter of Authorization'} />
                </ol>
              ) : (
                <>
                  {selectedCountry?.countryCode === countryCode.KE ? (
                    <div className="country-requirements px-5">
                      <div>
                        <BusinessType
                          type={'a) Sole Proprietorship Business'}
                        />

                        <ol type="1" className="get-right-margin pt-3">
                          <RequiredDocument
                            documentName={
                              'National ID or Passport (Director’s)'
                            }
                          />
                          <RequiredDocument
                            documentName={'Certificate of Registration'}
                          />
                          <RequiredDocument
                            documentName={'Tax PIN Certificate'}
                          />
                        </ol>
                      </div>

                      <div>
                        <BusinessType type={'b) Partnership Business'} />

                        <ol type="1" className="pt-3">
                          <RequiredDocument
                            documentName={
                              'National ID or Passport (Director’s)'
                            }
                          />
                          <RequiredDocument
                            documentName={'Certificate of Incorporation'}
                          />
                          <RequiredDocument
                            documentName={'Board Resolution Letter'}
                          />
                          <RequiredDocument
                            documentName={'CR 12 Certificate'}
                          />
                          <RequiredDocument
                            documentName={'Tax PIN Certificate'}
                          />
                        </ol>
                      </div>
                    </div>
                  ) : (
                    <ol type="1" className="mx-auto">
                      <RequiredDocument
                        documentName={'National ID or Passport (Director’s)'}
                      />
                      <RequiredDocument documentName="Certificate of Registration" />
                      <RequiredDocument
                        documentName={'Tax Certificate or Equivalent'}
                      />
                    </ol>
                  )}
                </>
              )}
            </div>
          </div>

          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Previous"
              handleOnClick={() => {
                history.push(
                  previousPage
                    ? previousPage
                    : updatingKyc
                    ? '/investor-information/summary'
                    : '/investor-information',
                );
              }}
              twinButton
            />

            <ConfirmButton
              buttonType={'submit'}
              buttonText={creatingAccount ? 'Create Account' : 'Next'}
              loading={loading}
              twinButton
            />
          </ButtonsContainer>

          <Caution
            detail={'The documents will depend on your country of operation.'}
            classes="mx-auto"
          />
        </KycPageContentWrapper>
      </form>

      <SelectOptionsModal
        open={openCountryDropdown}
        handleClose={() => setOpenCountryDropdown(false)}
        allCountries={getCountries()}
        handleSelect={handleSelect}
        placeholder={'Business Country'}
        selectedCountry={selectedCountry}
      />
    </>
  );
};

export default CreateBusinessOrChama;

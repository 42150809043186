import React from 'react';

export const SettlementDetailsStatusDescription = ({
  settlementDetailsStatus,
  settlementDetailsStatusDescription,
}) => {
  return (
    <div className="mt-2 pr-md-40 mr-md-14">
      <p className="mb-0 status-title">
        <strong>Status</strong>: {settlementDetailsStatus}
      </p>
      <p className="mb-0 status-text">{settlementDetailsStatusDescription}</p>
    </div>
  );
};

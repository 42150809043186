import { Route, Switch } from 'react-router-dom';

import React from 'react';
import ChangePassword from '../../../Profile/pages/changePassword';
import Activities from '../Activities/index';
import Deactivate from '../Deactivate/Deactivate';
import Device from '../Device/index';

const SecurityPage = ({ match, profile }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/activities`} component={Activities} />
        <Route exact path={`${match.path}/devices`} component={Device} />
        <Route
          exact
          path={`${match.path}/change-password`}
          component={ChangePassword}
        />
        <Route
          exact
          path={`${match.path}/deactivate-account`}
          component={Deactivate}
        />
      </Switch>
    </>
  );
};

export default SecurityPage;

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { checkIsActive } from '../../../../_helpers';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { bottomMenuItems } from './MenuList';
import MenuItem from './MenuItem';
import { renderMenuItemsBool } from './renderMenuItemsBool';
import { trackMenuItemClickEvent } from '../../../../_helpers/trackMenuItemClickevent';

export function BottomMenuList() {
  const [hoverBackground, setHoverBackground] = useState('');
  const [menu, setMenu] = useState(bottomMenuItems);
  const location = useLocation();
  const { originPartner } = useSelector(state => state?.profile);
  const menuIdTags = originPartner?.tagsToDisplay?.menuIdTags;

  const changeIconOnHover = (name, action) => {
    let hover = false;
    const newArray = [];
    menu.forEach(element => {
      if (element.id === name) {
        if (action === 'mouseOver') {
          hover = true;
          element.hover = hover;
        }
        if (action === 'mouseOut') {
          hover = false;
          element.hover = hover;
        }
      }
      newArray.push(element);
    });
    setMenu(newArray);
  };

  useEffect(() => {
    const newArray = [];
    menu.forEach(element => {
      if (location.pathname.startsWith(element.path)) {
        element.active = true;
      } else {
        element.active = false;
      }
      newArray.push(element);
    });
    setMenu(newArray);
  }, [location.pathname]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'active-bottom-menu-item'}`
      : '';
  };

  const handleHover = id => {
    const linkText = document.getElementById(id);
    linkText.classList.add('hover-bottom-item-color');
    setHoverBackground('hover-bottom-item-background');
    changeIconOnHover(id, 'mouseOver');
  };

  const handleMouseOut = id => {
    const linkText = document.getElementById(id);
    linkText.classList.remove('hover-bottom-item-color');
    setHoverBackground('');
    changeIconOnHover(id, 'mouseOut');
  };

  const handleMenuItemClick = async menuName => {
    await trackMenuItemClickEvent(menuName);
  };

  return (
    <>
      <ul className="bottom-menu">
        {menu.map(item => {
          const displayMenuItem = renderMenuItemsBool(
            item,
            originPartner,
            menuIdTags,
            true,
          );

          return displayMenuItem ? (
            <MenuItem
              key={item.name}
              getMenuItemActive={getMenuItemActive}
              path={item.path}
              handleHover={handleHover}
              handleMouseOut={handleMouseOut}
              closeLeftMenuAsideInMobile={() => handleMenuItemClick(item.name)}
              hoverBackground={hoverBackground}
              icon={
                item.active || item.hover ? item.icon.white : item.icon.active
              }
              name={item.id}
              menuName={item.title}
              linkTextStyles={'bottom-menu-text-color'}
            />
          ) : null;
        })}
      </ul>
    </>
  );
}

import React from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

const HistogramChart = ({ graphData, plan }) => {
  const labelFormatter = value => {
    if (value) {
      return value + '%';
    }
  };

  return (
    <>
      {plan?.performanceDate ? (
        <div className="p-2 d-flex justify-content-end">
          <span>
            <small>{plan?.performanceDate}</small>
          </span>
        </div>
      ) : null}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={graphData}>
          <XAxis
            dataKey="name"
            style={{ fontSize: '12px' }}
            axisLine={{ stroke: 'transparent' }}
          />
          <YAxis
            dataKey="value"
            tickFormatter={tick => {
              return `${tick}%`;
            }}
            style={{ fontSize: '12px' }}
            axisLine={false}
          />
          <CartesianGrid
            horizontal="true"
            vertical=""
            stroke="#cae2f6"
            style={{ borderBottom: '1px solid' }}
          />
          <Bar
            dataKey="value"
            fill="#2686d5"
            barSize={80}
            label={{
              position: 'top',
              formatter: labelFormatter,
              fontSize: '12px',
              fontWeight: 'bold',
              fill: '#000',
            }}
            unit="%"
            fontFamily="Poppins"
            fontSize="12px"
          ></Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default HistogramChart;

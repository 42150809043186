import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_INVESTOR_DEVICES = `${REACT_APP_BASE_URL}/v1/investor/investors/devices`;
const REMOVE_DEVICE = `${REACT_APP_BASE_URL}/v1/investor/investors/devices/`;

const fetchInvestorDevices = async () => {
  const res = await axios.get(`${FETCH_INVESTOR_DEVICES}`);
  return res;
};

const removeDevices = async deviceId => {
  const res = await axios.delete(`${REMOVE_DEVICE}${deviceId}`);
  return res;
};

export { fetchInvestorDevices, removeDevices };

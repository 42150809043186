import './Device.scss';

import React from 'react';
import moment from 'moment';

const Device = ({ device, handleDelete }) => {
  return (
    <>
      <div className="d-flex flex-wrap align-items-center justify-content-between w-100 mb-2">
        <div className="text-dark-75 font-weight-bold font-size-lg">{`${device.deviceBrand} ${device.osName}`}</div>
        <div onClick={() => handleDelete(device.id)}>
          <i
            className="far fa-trash-alt  cursor-pointer icon-md"
            style={{ color: '#FF7F30' }}
          />
        </div>
      </div>

      <br />
      <div className="d-flex flex-wrap align-items-center justify-content-between w-100 mb-2">
        <div className="text-muted">Date</div>
        <span className="font-size-sm">
          {moment(device?.createdAt).format('llll')}
        </span>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between w-100 mb-2">
        <div className="text-muted">IP</div>
        <span className="font-size-sm">{device?.ip}</span>
      </div>
    </>
  );
};

export default Device;

import React, { useEffect, useState } from 'react';

import {
  getGoalPieChartContent,
  getGoalsWithDetails,
} from '../_redux/goalActions';
import { actions as goalActions } from '../_redux/goalReducers';

import { SingleCard } from '../components/GoalCard/SingleCard';
import CustomDonutChart from '../components/CustomDonutChart';
import PageHeader from '../../../helpers/pageHeader';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import NoInvestmentMade from '../../Portfolio/components/NoInvestmentMade';
import ErrorCard from '../../Common/ErrorCard';
import { PredefinedColors } from '../../../helpers/constants';
import { useDispatch } from 'react-redux';

const Goals = () => {
  const [goals, setGoals] = useState();
  const [pieChartInfo, setPieChartInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const dispatch = useDispatch();

  const fetchGoals = async () => {
    try {
      setLoading(true);
      const res = await getGoalsWithDetails();
      if (res.status === 200) {
        dispatch(goalActions.updateCreatedGoalsList(res.data.data));
        setGoals(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const piechartgoals = async () => {
    try {
      setLoading(true);
      const res = await getGoalPieChartContent();
      if (res.status === 200) {
        setPieChartInfo(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGoals();
    piechartgoals();
  }, []);

  const url = localStorage.getItem('pathName');

  const getColors = arr => {
    const colors = {};

    for (let index = 0; index < arr?.length; index++) {
      colors[arr[index].staticName.name] = PredefinedColors[index];
    }

    return colors;
  };

  const extractGoalData = arr => {
    if (!arr || arr.length === 0) {
      return false;
    }

    const percentagePortfolioAllocation = [];
    const staticNameNames = [];
    const investedAmounts = [];
    const amountCurrency = [];

    for (const obj of arr) {
      percentagePortfolioAllocation.push(obj.percentagePortfolioAllocation);
      staticNameNames.push(obj.staticName.name);
      amountCurrency.push(obj.currency);
      investedAmounts.push(obj.goalPortfolioDetails.investedAmount);
    }

    return {
      percentagePortfolioAllocation,
      staticNameNames,
      investedAmounts,
      amountCurrency,
    };
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorCard errorMessage={errorMessage} />
      ) : (
        <>
          <PageHeader
            title="Goals"
            buttonLabel="Add a new goal"
            buttonLink="goal/select-goal"
            hide={false}
            pathName={url}
            investingInGoal={true}
          />
          {goals?.length === 0 && !loading ? (
            <NoInvestmentMade />
          ) : (
            <>
              {pieChartInfo?.length > 0 && (
                <div className="pie-chart-section border-light-blue-600 border-radius-6 py-5">
                  <CustomDonutChart
                    percentages={
                      extractGoalData(pieChartInfo)
                        ?.percentagePortfolioAllocation
                    }
                    colorsArray={getColors(pieChartInfo)}
                    labels={extractGoalData(pieChartInfo)?.staticNameNames}
                    amount={extractGoalData(pieChartInfo)?.investedAmounts}
                    currency={extractGoalData(pieChartInfo)?.amountCurrency}
                  />
                </div>
              )}
              <div
                className={`${
                  goals?.length > 2
                    ? 'justify-content-between'
                    : 'justify-content-start'
                } goal-cards-container mt-8`}
              >
                <SingleCard goals={goals} />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Goals;

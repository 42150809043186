import React, { useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import Alert from '../../../Common/Alert';
import './index.scss';
import { ConfirmTransactionDetailsPageWrapper } from '../../../Settlements/components/ConfirmTransactionsDetailsPageWrapper';
import { CancelButton, ConfirmButton } from '../../../Common/ReusableButtons';
import { ButtonsContainer } from '../../../Common/ButtonsContainer';

const PhoneNumber = ({
  setPayWithMpesa,
  phoneNumber,
  setPhoneNumber,
  pushNotification,
}) => {
  const [showCountDown, setShowCountDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const handlePhoneNumber = e => {
    const { value } = e.target;
    setPhoneNumber(value);
  };

  return (
    <ConfirmTransactionDetailsPageWrapper title={'MPESA Payment'}>
      {alertOn && (
        <div className="mb-5">
          <Alert alert={alert} />
        </div>
      )}
      <p className="text-center text-14 font-weight-600">
        Enter your phone number
      </p>
      <div className="mt-5 d-flex justify-content-start">
        <input
          readOnly
          className="input-field border-gray-450 border-radius-8"
          type="text"
          value={'+254'}
          style={{
            height: '45px',
            width: '20%',
          }}
          autoComplete="off"
        />

        <input
          name="phoneNumber"
          onChange={handlePhoneNumber}
          className="input-field border-radius-8 border-gray-450"
          type="number"
          value={phoneNumber || ''}
          style={{
            height: '45px',
            marginLeft: '5px',
            width: '80%',
          }}
          placeholder="Phone Number"
          autoComplete="off"
          onInput={e => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 10);
          }}
        />
      </div>
      {showCountDown && (
        <div className="m-5 text-12 text-center">
          Please wait for{'  '}
          <Countdown
            date={Date.now() + 180000}
            renderer={({ minutes, seconds, completed }) =>
              !completed && (
                <span className="font-weight-bolder">
                  {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
              )
            }
          />
          {'  '}
          while your payment is being processed.
        </div>
      )}
      <ButtonsContainer justifyContent="justify-content-between">
        <CancelButton
          disabled={loading}
          buttonText="Cancel"
          handleOnClick={() => setPayWithMpesa(false)}
          twinButton
        />

        <ConfirmButton
          buttonText={'Pay now'}
          handleOnClick={() =>
            pushNotification({
              setShowCountDown,
              setLoading,
              setAlert,
              setAlertOn,
            })
          }
          loading={loading}
          twinButton
        />
      </ButtonsContainer>
    </ConfirmTransactionDetailsPageWrapper>
  );
};

export default PhoneNumber;

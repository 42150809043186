import React from 'react';
import { DropdownContainer } from '../ReusableDropdown/DropDownContainer';
import { DropdownTitle } from '../Dropdown/DropdownTitle';
import SelectPhoneNumber from '../SelectPhoneNumber';
import { ValidationErrors } from '../../Business/components/ValidationErrors';
import { getInputClasses } from '../../../helpers/getInputClasses';

export const PhoneNumberComponent = ({
  setOpen,
  selectedCountry,
  formik,
  validPhoneNumber,
  inputName,
}) => {
  return (
    <DropdownContainer>
      <DropdownTitle title={'Phone Number'} />

      <div className="d-flex w-100">
        <div className="width-40">
          <SelectPhoneNumber
            setOpen={setOpen}
            selectedCountry={selectedCountry}
          />

          {formik.touched[inputName] && formik.errors[inputName] ? (
            <ValidationErrors errorMessage={'Required'} />
          ) : null}
        </div>

        <div className="ml-2 width-60">
          <input
            name={inputName}
            className={`w-100 border-gray-450 px-4 border-radius-8 ${getInputClasses(
              `${inputName}`,
              formik,
            )}`}
            type="tel"
            {...formik.getFieldProps(inputName)}
            style={{
              height: '45px',
            }}
            autoComplete="off"
          />

          {formik.touched[inputName] && formik.errors[inputName] ? (
            <ValidationErrors errorMessage={formik.errors[inputName]} />
          ) : null}

          {!validPhoneNumber ? (
            <ValidationErrors
              errorMessage={'Enter a valid phone number e.g. 7********'}
            />
          ) : null}
        </div>
      </div>
    </DropdownContainer>
  );
};

import React from 'react';
import { InstructionStep } from './InstructionStep';
import { PayWithBankTransferCardsWrapper } from './PayWithBankTransferCardsWrapper';

export const Instructions = () => {
  return (
    <PayWithBankTransferCardsWrapper>
      <div className="bank-transfer-instructions-wrapper border-radius-8">
        <div className="text-14 font-weight-600 mb-2">Instructions:</div>
        <InstructionStep
          text={
            <span>
              From your bank account initiate a transfer of the total deposit
              amount to the{' '}
              <span className="font-weight-700">
                provided beneficiary account.
              </span>
            </span>
          }
          stepNumber={1}
        />

        <InstructionStep
          text={
            <span>
              The indicated total deposit amount does not include bank charges.
              Make sure to{' '}
              <span className="font-weight-700">cover all bank charges</span>{' '}
              from your bank and the receiving bank.
            </span>
          }
          stepNumber={2}
        />

        <InstructionStep
          text={
            <span>
              Ensure to indicate the{' '}
              <span className="font-weight-700">Transfer Reference Code</span>{' '}
              as per below table.
            </span>
          }
          stepNumber={3}
        />
      </div>
    </PayWithBankTransferCardsWrapper>
  );
};

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import CustomWebcam from './WebCam';
import { useDispatch, useSelector } from 'react-redux';

import { actions as kycActions } from '../../KYC/_redux/kycRedux';
import { actions as investorProfileActions } from '../../Profile/_redux/profileRedux';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers/AssetsHelpers';
import '../components/index.scss';
import { UploadDocument } from '../components/UploadDocument';
import { DocumentInstructions } from '../components/DocumentInstructions';
import { sendFormDataToBackend } from '../_redux/individualKYCAction';
import Alert from '../../Common/Alert';
import { WebcamFileUpload } from './WebcamFileUpload';

import {
  compressImage,
  generateImageDataUrl,
  isFileNameValid,
  theFileIsLessThanOrEqualToMaximumSize,
  validateFile,
} from '../../../helpers/validateFiles';
import { downloadUsersFile } from '../../Profile/_redux/profileCrud';
import { idTypeOptions } from '../../../helpers/constants';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { ButtonsContainer } from '../../Common/ButtonsContainer';

const UploadIdAndFacePhoto = props => {
  const [idFileUploaded, setIdFileUploaded] = useState(false);
  const FILE_SIZE = 5 * 1024 * 1024;
  const { id: stepperId, handleStepChange, profile } = props;
  const { investor } = profile;

  const { facePhoto, personalIdentity } = investor;

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();

  // FIRST UPLOAD
  const [errorMessage, setErrorMessage] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingPhotoFile, setUploadingPhotoFile] = useState(false);

  const inputFrontRef = useRef(null);
  const [selectedFrontFile, setSelectedFrontFile] = useState();
  const [isSelectedFront, setIsSelectedFront] = useState(false);
  const [selectedImageDataUrl, setSelectedImageDataUrl] = useState();
  const dispatch = useDispatch();
  const kycData = useSelector(state => state.kyc);
  const investorIdType = kycData?.idType;
  const investorCountry = kycData?.country;

  const handleFrontChange = async event => {
    setErrorMessage(null);
    setAlert(null);
    const file = event.target.files[0];

    if (file) {
      setUploadingFile(true);
      // Validate File name
      const result = isFileNameValid(file.name);
      if (result?.error) {
        setErrorMessage(result?.error);
        setUploadingFile(false);
        return;
      }

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setErrorMessage(validationError?.error);
        setUploadingFile(false);
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setErrorMessage('ID should be Max 5MB');
          setUploadingFile(false);
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setErrorMessage('ID should be Max 5MB');
        setUploadingFile(false);
        return;
      }

      setSelectedFrontFile(newFile);
      setIsSelectedFront(true);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setSelectedImageDataUrl(fileDataUrl);
          setUploadingFile(false);
        })
        .catch(error => {
          console.error('Error reading file as data URL:', error);
          setUploadingFile(false);
        });
    }
  };

  const handleFrontClick = () => {
    inputFrontRef.current.click();
  };

  const handleOpeningFile = async link => {
    downloadUsersFile(link)
      .then(response => {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      })
      .catch(() => {
        setAlert('Failed to download this document');
      });
  };

  // SECOND UPLOAD
  const inputFacePhotoRef = useRef(null);
  const [selectedFaceFile, setSelectedFaceFile] = useState();
  const [isSelectedFace, setIsSelectedFace] = useState(false);
  const [selectedFaceDataUrl, setSelectedFaceDataUrl] = useState();

  const handleFaceClick = () => {
    inputFacePhotoRef.current.click();
  };

  const handleFaceChange = async event => {
    setSelectedPhotoDataUrl();
    setErrorMessage(null);
    const file = event.target.files[0];
    if (file) {
      setUploadingFile(true);
      // Validate File name
      const result = isFileNameValid(file?.name);
      if (result?.error) {
        setErrorMessage(result?.error);
        setUploadingFile(false);
        return;
      }

      // Validate file extension
      const validationError = await validateFile(file)
        .then(validation => {
          return validation;
        })
        .catch(error => {
          return { valid: false, error: error?.message };
        });

      if (!validationError.valid) {
        setErrorMessage(validationError?.error);
        setUploadingFile(false);
        return;
      }

      let newFile;

      if (file.type === 'application/pdf') {
        if (FILE_SIZE < file?.size) {
          setErrorMessage('Photo should be Max 5MB');
          setUploadingFile(false);
          return;
        }
        newFile = file;
      } else {
        // Reduce the file size to 5MB
        newFile = await compressImage(file);
      }

      if (!theFileIsLessThanOrEqualToMaximumSize(newFile?.size, FILE_SIZE)) {
        setErrorMessage('Photo should be Max 5MB');
        setUploadingFile(false);
        return;
      }

      setSelectedFaceFile(newFile);
      setIsSelectedFace(true);

      // This is used to view file before sending it to the BE
      generateImageDataUrl(newFile)
        .then(fileDataUrl => {
          setSelectedFaceDataUrl(fileDataUrl);
          setUploadingFile(false);
        })
        .catch(error => {
          setUploadingFile(false);
        });
    }
  };

  // WEBCAM PHOTO
  const webcamRef = useRef(null);
  const [openCameraModal, setOpenCameraModal] = useState(false);

  const [imgSrc, setImgScr] = useState('');
  const [urlLink, setUrlLink] = useState('');
  const [open, setOpen] = useState(false);
  const [useWebcam, setUseWebcam] = useState(false);
  const [existingFile, setExistingFile] = useState(facePhoto);
  const [selectedPhotoFile, setSelectedPhotoFile] = useState();
  const [isSelectedPhoto, setIsSelectedPhoto] = useState(false);
  const [selectedPhotoDataUrl, setSelectedPhotoDataUrl] = useState();

  const switchOnWebcam = () => {
    setOpen(true);
    setUseWebcam(true);
  };

  const dataURItoBlob = dataURI => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const retakeSelfie = () => {
    inputFacePhotoRef.current.value = null;
    if (existingFile) {
      setOpenCameraModal(false);
    } else {
      setOpenCameraModal(true);
    }
    setSelectedPhotoFile(null);
    setSelectedPhotoDataUrl(null);
    setImgScr('');
    setUrlLink();
    setExistingFile();
    setSelectedFaceDataUrl();
    setSelectedPhotoDataUrl();
  };

  const captureSelfie = useCallback(async () => {
    if (!webcamRef?.current?.getScreenshot()) {
      retakeSelfie();
    }
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (imageSrc) {
      setUploadingPhotoFile(true);
      setImgScr(imageSrc);
      const result = dataURItoBlob(imageSrc);
      const file = new File([result], 'filename.png', { type: 'image/png' });
      setSelectedPhotoDataUrl(imageSrc);
      setSelectedPhotoFile(file);
      setUploadingPhotoFile(false);
      setSelectedFaceDataUrl();
    }
  }, [webcamRef]);

  useEffect(() => {
    const goToFaceValidation =
      personalIdentity?.front && stepperId < state?.previousStep;
    goToFaceValidation && setIdFileUploaded(true);
    goToFaceValidation
      ? handleStepChange({
          // previous: stepperId + 1,
          next: stepperId + 1,
        })
      : handleStepChange({ next: stepperId });
  }, []);

  const handleNext = async () => {
    const formData = new FormData();
    selectedFrontFile && formData.append('front', selectedFrontFile);
    (selectedFaceFile || selectedPhotoFile) &&
      formData.append('facePhoto', selectedPhotoFile || selectedFaceFile);
    investorIdType && formData.append('idType', investorIdType);
    investorCountry && formData.append('country', investorCountry);

    try {
      // Send formData to the backend
      setLoading(true);
      const response = await sendFormDataToBackend(formData);
      // Redirect to the next page
      const investorInformation = response?.data?.data?.investors[0];
      const { kycExtractionResult } = investorInformation;

      // Store the updated data back in the global state
      investorInformation &&
        dispatch(
          kycActions.updateKycInfo({
            ...kycData,
            gender: kycExtractionResult?.gender,
            identificationNumber: kycExtractionResult?.idNumber,
            dateOfBirth: kycExtractionResult?.dateOfBirth,
            personalIdentity: investorInformation?.personalIdentity,
            facePhoto: investorInformation?.facePhoto,
          }),
        );

      dispatch(
        investorProfileActions.profileAction({
          ...profile,
          investor: {
            ...investorInformation,
            pendingKyc: investor?.pendingKyc,
            personalIdentity: investorInformation?.personalIdentity,
            facePhoto: investorInformation?.facePhoto,
          },
          loading: false,
        }),
      );

      setLoading(false);
      setIdFileUploaded(false);

      setSelectedFrontFile();
      setSelectedImageDataUrl();

      setSelectedFaceDataUrl();
      setSelectedFaceFile();

      setSelectedPhotoFile();
      setSelectedPhotoDataUrl();

      history.push('/kyc-information/investor-details');
    } catch (error) {
      setLoading(false);
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setSelectedFrontFile();
      setSelectedImageDataUrl();

      setSelectedFaceDataUrl();
      setSelectedFaceFile();

      setSelectedPhotoFile();
      setSelectedPhotoDataUrl();
    }
  };

  useEffect(() => {
    const getImage = async (fileLink, setLink) => {
      if (fileLink) {
        try {
          const response = await downloadUsersFile(fileLink);
          const contentType = response.headers['content-type'];

          if (contentType.includes('image')) {
            // Handle image files
            const file = new Blob([response.data], { type: contentType });
            const fileURL = URL.createObjectURL(file);
            setLink(fileURL);
          } else if (contentType.includes('application/pdf')) {
            // Handle PDF files
            const pdfFile = new Blob([response.data], { type: contentType });
            const pdfFileURL = URL.createObjectURL(pdfFile);
            setLink(pdfFileURL);
          } else {
            console.error('Unsupported file type');
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    getImage(facePhoto, setUrlLink);
  }, [facePhoto]);

  const disableButton =
    (!selectedFrontFile && !personalIdentity?.front) ||
    (idFileUploaded && !selectedFaceFile && !selectedPhotoFile && !facePhoto);

  const disabledButtonColor =
    (!idFileUploaded && (selectedFrontFile || personalIdentity?.front)) ||
    (idFileUploaded && (selectedFaceFile || facePhoto)) ||
    (idFileUploaded && (selectedPhotoFile || facePhoto));

  return (
    <>
      <KycPageContentWrapper>
        {' '}
        <div className="image-upload-cards-wrapper d-flex justify-content-center flex-wrap">
          {/* Front ID Card Upload */}
          {idFileUploaded && (selectedFrontFile || personalIdentity?.front) ? (
            <CustomWebcam
              isImageSelected={isSelectedPhoto}
              loading={uploadingPhotoFile}
              image={
                <SVG
                  src={toAbsoluteUrl(
                    '/media/svg/ndovu/faceVerificationIcon.svg',
                  )}
                />
              }
              header={
                <div className="ml-2">
                  <div className="text-blue text-16 font-weight-500 mt-1">
                    Face Verification
                  </div>
                  <div className="text-10">
                    Please take a selfie as per instructions
                  </div>
                </div>
              }
              uploadedFile={selectedPhotoDataUrl || selectedFaceDataUrl}
              openExistingFile={handleOpeningFile}
              existingFile={existingFile}
              capture={captureSelfie}
              retake={retakeSelfie}
              imgSrc={imgSrc}
              webcamRef={webcamRef}
              urlLink={urlLink}
              switchOnWebcam={switchOnWebcam}
              useWebcam={useWebcam}
              open={open}
              setOpen={setOpen}
              openCameraModal={openCameraModal}
              setOpenCameraModal={setOpenCameraModal}
              additionalComponent={
                <WebcamFileUpload
                  inputRef={inputFacePhotoRef}
                  handleImageChange={handleFaceChange}
                  isImageSlected={isSelectedFace}
                  loading={uploadingFile}
                  handleImageClick={handleFaceClick}
                  uploadedFile={selectedPhotoDataUrl || selectedFaceDataUrl}
                  name="facePhoto"
                  openExistingFile={handleOpeningFile}
                  existingFile={facePhoto}
                />
              }
            />
          ) : (
            <UploadDocument
              inputRef={inputFrontRef}
              handleImageChange={handleFrontChange}
              isImageSlected={isSelectedFront}
              handleImageClick={handleFrontClick}
              image={<SVG src={toAbsoluteUrl('/media/svg/ndovu/IDIcon.svg')} />}
              title={
                <div className="text-16 mt-1">
                  Upload your {`${idTypeOptions[investorIdType]} Photo`}
                </div>
              }
              uploadedFile={selectedImageDataUrl}
              name="front"
              loading={uploadingFile}
              openExistingFile={handleOpeningFile}
              existingFile={personalIdentity?.front}
            />
          )}

          <DocumentInstructions
            instructions={
              idFileUploaded &&
              (selectedFrontFile || personalIdentity?.front) ? (
                <ol className="text-12 mt-5 ">
                  <li>
                    Please take your selfie in a well lit space, and capture
                    full image of your face
                  </li>
                  <li className="my-5">
                    Ensure whole face is clear and visible
                  </li>
                  <li>Photo should be a max of 5mb</li>
                </ol>
              ) : (
                <ol className="text-12 mt-5">
                  <li>
                    Please take photo in a well lit space,and capture full image
                    of ID / Passport
                  </li>
                  <li className="my-5">
                    Ensure whole photo is clear and visible
                  </li>
                  <li>Photo should be a max of 5mb</li>
                </ol>
              )
            }
            cardWrapper={
              idFileUploaded && (selectedFrontFile || personalIdentity?.front)
                ? 'face-photo-upload-card-wrapper'
                : 'image-upload-card-wrapper'
            }
          />
        </div>
        <ButtonsContainer justifyContent="justify-content-between">
          <CancelButton
            buttonText="Previous"
            handleOnClick={
              idFileUploaded
                ? () => {
                    handleStepChange({
                      previous: stepperId + 1,
                      next: stepperId,
                    });
                    setIdFileUploaded(false);
                  }
                : () => history.push('/kyc-information/information')
            }
            twinButton
          />

          <ConfirmButton
            buttonText={'Next'}
            disable={disableButton}
            handleOnClick={
              (selectedFrontFile || personalIdentity?.front) &&
              (selectedFaceFile || selectedPhotoFile || facePhoto) &&
              idFileUploaded
                ? () => handleNext()
                : () => {
                    handleStepChange({ next: stepperId + 1 });
                    setIdFileUploaded(true);
                  }
            }
            loading={loading}
            twinButton
          />
        </ButtonsContainer>
        {errorMessage ? (
          <div className="text-red-500 text-13 text-center font-weight-600 mt-3">
            {errorMessage}
          </div>
        ) : alert?.alertMessage ? (
          <Alert alert={alert} />
        ) : null}
      </KycPageContentWrapper>
    </>
  );
};

export default UploadIdAndFacePhoto;

import React from 'react';
import { accountTypes, operationType } from '../../../helpers/constants';
import { currencyHelper } from '../../../helpers/currencyHelper';
import {
  capitalizeTheFirstLetterOfEachWord,
  firstLetterUpperCase,
} from '../../../helpers/investmentHelpers';
import PopoverComponent from '../../Common/PopoverComponent';
import TransactionPopup from '../../Common/Popup/transactionPopup';
import { TransactionStatusBadge } from './TransactionStatusBadge';

export const ArchivedTransactionsTableData = ({
  transaction,
  investorType,
}) => {
  return (
    <tr key={transaction.id}>
      <td className="text-13" style={{ minWidth: '120px' }}>
        {transaction?.createdAt ? transaction?.createdAt.split('T')[0] : '---'}
      </td>

      <td className="td-font-size-14" style={{ minWidth: '130px' }}>
        {transaction?.planSubscription?.plan?.coolName
          ? transaction?.planSubscription?.plan?.coolName
          : '---'}
      </td>

      <td className="td-font-size-14" style={{ minWidth: '150px' }}>
        {transaction?.planSubscription?.plan?.fundManager?.fundname
          ? transaction?.planSubscription?.plan?.fundManager?.fundname
          : '---'}
      </td>

      <td className="td-font-size-14" style={{ minWidth: '175px' }}>
        {transaction?.transactionType ? transaction?.transactionType : '---'}
      </td>

      <td className="td-font-size-14" style={{ minWidth: '200px' }}>
        {transaction?.goal?.name ? transaction?.goal?.name : '---'}
      </td>

      <td className="td-font-size-14" style={{ minWidth: '175px' }}>
        {transaction?.convertedFinalAmount && transaction?.finalCurrency
          ? currencyHelper(
              transaction?.convertedFinalAmount,
              transaction?.finalCurrency,
            )
          : '---'}
      </td>

      <td className="td-font-size-14">
        {transaction?.operationType
          ? firstLetterUpperCase(transaction?.operationType)
          : '---'}
      </td>

      <td className="td-font-size-14" style={{ minWidth: '250px' }}>
        {transaction?.archivedReason
          ? capitalizeTheFirstLetterOfEachWord(
              transaction?.archivedReason?.replaceAll('_', ' '),
            )
          : '---'}
      </td>

      <td className="td-font-size-14">{transaction.refunded ? 'Yes' : 'No'}</td>

      {investorType !== accountTypes.INDIVIDUAL ? (
        <>
          <td className="td-font-size-14" style={{ minWidth: '150px' }}>
            {transaction.initiatedBy ? (
              <>
                {firstLetterUpperCase(transaction.initiatedBy?.firstName)}{' '}
                {firstLetterUpperCase(transaction.initiatedBy?.lastName)}
              </>
            ) : (
              '---'
            )}
          </td>

          <td className="td-font-size-14">
            {transaction.initiatedBy ? transaction.initiatedBy?.email : '---'}
          </td>
        </>
      ) : null}

      <td className="text-13" style={{ minWidth: '150px' }}>
        {transaction?.transactionStatus ? (
          <TransactionStatusBadge
            transactionStatus={transaction?.transactionStatus}
          />
        ) : (
          '---'
        )}
      </td>

      {transaction?.operationType === operationType.DEPOSIT && (
        <td className="text-13">
          <PopoverComponent popoverContent={TransactionPopup(transaction)}>
            <span style={{ cursor: 'pointer' }}>
              <i
                style={{
                  color: '#0071CE',
                }}
                className="ki ki-bold-more-hor"
                aria-hidden="true"
              ></i>
            </span>
          </PopoverComponent>
        </td>
      )}
    </tr>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { CancelButton } from '../../../Common/buttons/Buttons';

import HeaderCard from './HeaderCard';

const Cancelled = ({ handleTryAgain }) => {
  const history = useHistory();
  return (
    <div className="card card-custom card-gutter-b">
      <HeaderCard headerText="Cancelled!" />
      <div className="card-body">
        <div className="text-center">
          Your payment has been canceled. Please{' '}
          <span
            className="font-weight-bold cursor-pointer"
            style={{ color: '#0071CE' }}
            onClick={handleTryAgain}
          >
            try again
          </span>
        </div>
      </div>
      <div className="text-center my-5">
        <CancelButton
          BtnText="Exit"
          onClick={() => {
            history.push('/dashboard');
          }}
          classes="full-width-small py-4"
        />
      </div>
    </div>
  );
};

export default Cancelled;

import React, { useState } from 'react';
import Webcam from 'react-webcam';
import ReusableModal from '../../Common/ReusableModal/index';
import { ConfirmButton, CustomButton } from '../../Common/ReusableButtons';
import { ActivateCameraModal } from '../../Common/ActivateCameraModal';

const CustomWebcam = ({
  switchOnWebcam,
  useWebcam,
  urlLink,
  loading,
  image,
  existingFile,
  header,
  capture,
  retake,
  imgSrc,
  webcamRef,
  open,
  setOpen,
  additionalComponent,
  openCameraModal,
  setOpenCameraModal,
}) => {
  const [putOnCamera, setPutOnCamera] = useState(false);
  const [showCaptureButton, setShowCaptureButton] = useState(false);

  const onUserMedia = e => {
    if (e?.active) {
      setShowCaptureButton(true);
    } else {
      setShowCaptureButton(false);
    }
  };

  const handleClose = () => {
    setOpenCameraModal(!openCameraModal);
    // retake();
  };

  const content = <div>Please activate camera to proceed.</div>;

  const text = (
    <div style={{ width: '100%' }}>
      <div className="text-right">
        <button
          className="bg-blue text-white text-center  border-0 border-radius-circle"
          style={{ width: '30px', height: '30px' }}
          onClick={handleClose}
        >
          X
        </button>
      </div>
      <div className="d-flex flex-column align-items-center">
        {putOnCamera && useWebcam && !existingFile && !imgSrc ? (
          <Webcam
            height="300"
            width="350"
            ref={webcamRef}
            screenshotFormat="image/png"
            screenshotQuality={0.8}
            imageSmoothing={true}
            onUserMedia={onUserMedia}
            minScreenshotHeight="150"
            minScreenshotWidth="200"
            style={{ objectFit: 'cover', borderRadius: '5px' }}
          />
        ) : existingFile ? (
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={imgSrc || urlLink}
              alt="webcam"
              height="300"
              width="350"
              style={{ objectFit: 'cover', borderRadius: '5px' }}
            />
          </div>
        ) : (
          !existingFile &&
          !imgSrc && (
            <div className="mt-10">
              <CustomButton
                buttonText={'USE WEBCAM'}
                disable={loading}
                handleOnClick={switchOnWebcam}
                loading={loading}
                buttonWidth={'w-100'}
                classes={'bg-white text-blue border-1-blue'}
                addBorder={true}
              />
            </div>
          )
        )}
        {imgSrc && (
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={imgSrc}
              alt="webcam"
              height="300"
              width="350"
              style={{ objectFit: 'cover', borderRadius: '5px' }}
            />
          </div>
        )}
        {urlLink && (
          <div>
            <ConfirmButton
              buttonText={'RETAKE'}
              disable={loading}
              handleOnClick={retake}
              loading={loading}
              buttonWidth={'w-100'}
            />
          </div>
        )}
        {showCaptureButton && (
          <div className="take-selfie-button mt-5">
            <ConfirmButton
              buttonText={imgSrc ? 'RETAKE' : 'TAKE SELFIE'}
              disable={loading}
              handleOnClick={imgSrc ? retake : capture}
              classes={`${
                imgSrc ? 'bg-blue text-white' : 'bg-white text-blue'
              }my-2`}
              loading={loading}
              buttonWidth={'w-100'}
            />
            {imgSrc && (
              <ConfirmButton
                buttonText={'ACCEPT'}
                handleOnClick={() => setOpenCameraModal(false)}
                buttonWidth={'w-100'}
                classes={'mt-5'}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={`d-flex flex-column face-photo-upload-card-wrapper border-light-blue-600 border-radius-8 px-5 py-2`}
    >
      <div className="d-flex align-items-start">
        {image ? <div>{image}</div> : null}
        {header ? header : null}
      </div>

      <div className="d-flex flex-column align-items-center">
        {putOnCamera && useWebcam && !existingFile && !imgSrc ? (
          <Webcam
            height="150"
            width="200"
            ref={webcamRef}
            screenshotFormat="image/png"
            screenshotQuality={0.8}
            imageSmoothing={true}
            onUserMedia={onUserMedia}
            minScreenshotHeight="150"
            minScreenshotWidth="200"
            style={{ objectFit: 'cover', borderRadius: '5px' }}
          />
        ) : (
          !existingFile &&
          !imgSrc && (
            <CustomButton
              buttonText={'USE WEBCAM'}
              handleOnClick={switchOnWebcam}
              buttonWidth={'w-100'}
              classes={'bg-white text-blue mb-2 border-1-blue mt-5'}
              addBorder={true}
            />
          )
        )}

        {(urlLink || existingFile) && (
          <ConfirmButton
            buttonText={'RETAKE'}
            handleOnClick={retake}
            buttonWidth={'w-100 '}
            classes={'my-2'}
          />
        )}
        {showCaptureButton && (
          <>
            <ConfirmButton
              buttonText={imgSrc ? 'RETAKE' : 'TAKE SELFIE'}
              handleOnClick={imgSrc ? retake : capture}
              buttonWidth={`w-100`}
              classes={`${
                imgSrc
                  ? 'bg-blue text-white'
                  : 'bg-white text-blue border-1-blue'
              } my-2`}
              disable={loading}
              loading={loading}
            />
          </>
        )}
        {additionalComponent ? additionalComponent : null}
      </div>

      <ActivateCameraModal
        open={open}
        setOpen={() => setOpen(!open)}
        iconName="camera"
        moreClasses={'bg-white'}
        title={'Notification'}
        content={content}
        handleButtonClick={() => {
          setOpen(false);
          setPutOnCamera(true);
          setOpenCameraModal(true);
        }}
        handleCancelButton={() => setOpen(false)}
      />

      <ReusableModal
        open={openCameraModal}
        setOpen={setOpenCameraModal}
        text={text}
      />
    </div>
  );
};

export default CustomWebcam;

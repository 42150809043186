import React from 'react';

export const ReasonForInformation = ({ title, content }) => {
  return (
    <div className="next-of-kin-reason-container center-input mt-5 text-14">
      <div className="font-weight-700 text-18">{title} </div>
      <div className="mt-3">{content}</div>
    </div>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
// import backArrow from '../../../icons/kycBackArrow.svg';
import backArrow from '../../../icons/backArrow.svg';

import './index.scss';

const KycBackArrow = ({ text }) => {
  const history = useHistory();

  return (
    <div
      style={{ width: 'fit-content' }}
      className="cursor-pointer mb-5"
      onClick={() => history.goBack()}
    >
      <img
        src={backArrow}
        alt="Back arrow"
        style={{
          paddingBottom: '5px',
        }}
      />
      <span className="ml-3 verify-back-section">{text}</span>
    </div>
  );
};

export default KycBackArrow;

import { Route, Switch } from 'react-router-dom';
import React from 'react';

import WatchList from './WatchList';
import PlanDetails from '../../Plan/pages/PlanDetails';

const WathcListHome = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={WatchList} />
        <Route exact path={`${match.path}/:id`} component={PlanDetails} />
      </Switch>
    </>
  );
};

export default WathcListHome;

export const actionTypes = {
  ViewedPlan: '[ViewedPlan] Action',
  FetchViewedPlan: '[FetchViewedPlan] Action',
  UpdatePlanInvestmentDetails: '[UpdatePlanInvestmentDetails] Action',
  ResetPlanInvestmentDetails: '[ResetPlanInvestmentDetails] Action',
};

const initialPlanState = {
  viewedPlan: '',
  planInvestmentDetails: {
    planId: '',
    amount: '',
    currency: '',
  },
};

export const reducer = (state = initialPlanState, action) => {
  switch (action.type) {
    case actionTypes.ViewedPlan: {
      const viewedPlan = action.payload;
      return { ...state, viewedPlan };
    }
    case actionTypes.UpdatePlanInvestmentDetails: {
      const planInvestmentDetails = action.payload;
      return { ...state, planInvestmentDetails };
    }
    case actionTypes.ResetPlanInvestmentDetails: {
      return { ...state, ...initialPlanState };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  viewedPlan: viewedPlan => ({
    type: actionTypes.ViewedPlan,
    payload: viewedPlan,
  }),
  updatePlanInvestmentDetails: planInvestmentDetails => ({
    type: actionTypes.UpdatePlanInvestmentDetails,
    payload: planInvestmentDetails,
  }),
  ResetPlanInvestmentDetails: () => ({
    type: actionTypes.ResetPlanInvestmentDetails,
  }),
};

// TODO, add error handling on fetching the plan details. The error should be dispalayed on the
// planDetails page and the ViewInvestment page of portfolio
export function* saga() {}

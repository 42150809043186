import React from 'react';
import SVG from 'react-inlinesvg';

import PopoverComponent from '../../../Common/PopoverComponent';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers/AssetsHelpers';

export const BreakdownTitle = ({ title, popoverMessage }) => {
  return (
    <div className="mt-8 text-blue text-13">
      {title}
      <PopoverComponent popoverContent={popoverMessage}>
        <span className="cursor-pointer ml-2">
          <SVG
            src={toAbsoluteUrl('/media/svg/ndovu/question-mark-popover.svg')}
          />
        </span>
      </PopoverComponent>
    </div>
  );
};

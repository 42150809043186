import React from 'react';
import { TransactionStatus } from '../../../helpers/constants';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';

export const TransactionStatusBadge = ({ transactionStatus }) => {
  return (
    <span
      className={`border-radius-3 text-13 px-3 py-2 badge_${transactionStatus?.toLowerCase()} text-white`}
      style={{ maxWidth: '180px' }}
    >
      {transactionStatus === TransactionStatus.RECONCILED
        ? 'Deposit Received'
        : capitalizeTheFirstLetterOfEachWord(
            transactionStatus?.replaceAll('_', ' '),
          )}
    </span>
  );
};

import React from 'react';

import './index.scss';

const SavingAccountMesssage = ({ classes, message, lockInPeriod }) => {
  return (
    <div className={`saving-plan-message text-center ${classes}`}>
      {lockInPeriod ? (
        <div>{`Lock In Period ${lockInPeriod} months.`}</div>
      ) : null}
      {message}
    </div>
  );
};

export default SavingAccountMesssage;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { moneyFormat } from '../../../../helpers/moneyFormat';
import { CurrencyFlag } from '../../../Common/CurrencyFlag';

const TargetAmountAndCurrency = ({ classes, currency, compoundInterest }) => {
  const history = useHistory();

  const {
    investor: { currency: userPreferedCurrency },
  } = useSelector(state => state.profile);

  return (
    <div
      className={`${
        classes ? classes : 'goal-amount-container'
      } border-radius-5 border-1-blue-sky-400 d-flex align-items-center justify-content-between`}
    >
      <div className="w-100 d-flex justify-content-between">
        <div className="line-height-19 target-amount-responsive">
          <div className="text-14">
            <span>Target</span>
            <span className="mx-1">:</span>
            <span className="text-blue font-weight-600">
              {currency ? currency : ''}{' '}
              {compoundInterest ? moneyFormat(compoundInterest) : 0}
            </span>
          </div>
          <div className="text-10 text-dark-gray-300  target-amount-small-text">
            Amount you are estimated to achieve
          </div>
        </div>

        <div>
          <div className="d-flex align-items-center">
            <CurrencyFlag
              currencyFlag={userPreferedCurrency?.currencyFlag}
              imageSize={'currency-flag-size'}
            />
            <div className="ml-2 text-13 text-dark-550">
              {currency ? currency : ''}
            </div>
          </div>

          <div className="text-blue line-height-19">
            <small
              onClick={() => history.push('/manage-account/preferences')}
              className="cursor-pointer "
            >
              <u>change base currency</u>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetAmountAndCurrency;

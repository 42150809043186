import React from 'react';

export const ValidationErrors = ({ errorMessage, alignCenter }) => {
  return (
    <div
      className={`${
        alignCenter ? alignCenter : 'text-left'
      } mt-2 text-13 text-red-500 font-weight-500`}
    >
      {errorMessage}
    </div>
  );
};

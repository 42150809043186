import React, { useState, useEffect, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

const CustomDonutChart = ({
  percentages,
  labels,
  colorsArray,
  amount,
  currency,
}) => {
  const colors = useMemo(() => labels?.map(label => colorsArray[label]), [
    colorsArray,
    labels,
  ]);

  const [config, setConfig] = useState({
    series: percentages || [],
    options: {
      chart: {
        width: 350,
        type: 'donut',
      },
      labels,
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors,
      },
      legend: {
        show: false,
      },
      stroke: {
        colors: ['#FFFFFF'], // Set the color of the border line
        width: 0.5, // Set the width of the border line
      },
      colors,
      plotOptions: {
        pie: {
          donut: {
            size: '55%',
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 280,
            },
            legend: {
              show: false,
            },
          },
        },
        {
          breakpoint: 767,
          options: {
            chart: {
              width: 280,
            },
            legend: {
              show: false,
            },
          },
        },
        {
          breakpoint: 320,
          options: {
            chart: {
              width: 250,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (percentages && percentages.length > 0) {
      setConfig(prevConfig => ({
        ...prevConfig,
        series: percentages,
        options: {
          ...prevConfig.options,
          labels,
          colors,
          fill: {
            colors,
          },
        },
      }));
    }
  }, [colors, labels, percentages]);

  const listItems = labels.map((label, index) => (
    <li key={index}>
      <div className="d-flex flex-colum align-items-start mb-5">
        <div
          className="dot"
          style={{ backgroundColor: colorsArray[label] }}
        ></div>

        <div>
          <div>
            <div
              style={{ marginTop: '-9px' }}
              className="d-flex align-items-center text-14 font-weight-500"
            >
              <div>{label}</div>

              <div style={{ color: '#0071CE', marginLeft: '5px' }}>
                ({percentages[index]}%)
              </div>
            </div>
          </div>

          <div className="goal-currency text-29 font-weight-600">
            <span style={{ marginLeft: '3px' }}>{currency[index]}</span>{' '}
            {Number(amount[index]).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>

      {/* <div className="pie-chart-content-list text-14 font-weight-500">
        <div>
          <div
            className="dot"
            style={{ backgroundColor: colorsArray[label] }}
          ></div>
          {label}
          <span style={{ color: '#0071CE', marginLeft: '5px' }}>
            ({percentages[index]}%)
          </span>
        </div>
        <div className="goal-currency text-29 font-weight-600">
          {Number(amount[index]).toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
          <span style={{ marginLeft: '3px' }}>{currency[index]}</span>
        </div>
      </div> */}
    </li>
  ));

  return (
    <div className="pie-chart-content">
      <div>
        <ReactApexChart
          options={config.options}
          series={config.series}
          type="donut"
          width={config.options.chart.width}
        />
      </div>
      <ul className="chart-list">{listItems}</ul>
    </div>
  );
};

export default CustomDonutChart;

import React from 'react';

export const GoalCalculatorInputContainer = ({ label, children }) => {
  return (
    <div className="px-sm-3 text-center border-light-blue-545 border-radius-8 goal-amount-slider-container input-card-container py-3 mt-5">
      <div className="text-center goal-amount-slider-container py-6 px-5">
        <div className="text-center font-weight-600 text-16 mb-2 mx-3">
          {label}
        </div>
        {children}
      </div>
    </div>
  );
};

import dotenv from 'dotenv';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { teamMemberRole } from '../../../../../app/helpers/constants';
import { updateUserJoinedTheCommunity } from '../../../../../app/modules/Auth/_redux/authCrud';
import { CommonSuccessModal } from '../../../../../app/modules/Common/CommonSuccessModal';
import { PermissionModalContent } from '../../../../../app/modules/Common/PermissionModalContent';

dotenv.config();

export default function Menu({
  closeAsideNav,
  menu,
  handleHover,
  handleMouseOut,
}) {
  const { user } = useSelector(state => state.profile);
  let history = useHistory();

  const [show, setShow] = React.useState(false);

  const handlePermissionCheck = (name, path) => {
    if (
      name === 'manageAccount' &&
      user?.businessChamaRole?.name === teamMemberRole.VIEWER
    ) {
      setShow(true);
      return;
    } else {
      if (name === 'faq') {
        window.open(path, '_blank', 'noopener,noreferrer');
      } else if (name === 'community') {
        const { isRegisteredToCommunity, email, firstName } = user;

        const fullPath = isRegisteredToCommunity
          ? `authLoginEmailPara?company=${process.env.REACT_APP_SOFIA_COMPANY_ID}&userMail=${email}&userName=${firstName}`
          : `authCreateEmailPara?company=${process.env.REACT_APP_SOFIA_COMPANY_ID}&userMail=${email}&userName=${firstName}`;

        window.open(path + fullPath, '_blank', 'noopener,noreferrer');

        // send the update to the BE to update the user registered if user is not registered already
        !isRegisteredToCommunity && updateUserJoinedTheCommunity();
      } else {
        localStorage.setItem('kycEntryPointUrl', history.location.pathname);
        history.push(path);
      }
    }

    // Close navigation menu after menu is clicked
    closeAsideNav();
  };

  const linkRef = useRef(null);

  const linkMouseOver = () => {
    if (linkRef.current) {
      linkRef.current.setAttribute('href', menu.path);
    }
  };

  const linkMouseOut = () => {
    if (linkRef.current) {
      linkRef.current.removeAttribute('href');
    }
  };

  return (
    <>
      <a
        ref={linkRef}
        onClick={e => {
          e.preventDefault();
          handlePermissionCheck(menu.name, menu.path);
        }}
        className="navi-item link-nav cursor-pointer"
        onMouseOver={linkMouseOver}
        onMouseOut={linkMouseOut}
      >
        <div
          id={menu.name}
          className="right-nav pl-5"
          onMouseOver={() => handleHover(menu.name)}
          onMouseOut={() => handleMouseOut(menu.name)}
        >
          <img
            src={menu.active || menu.hover ? menu.icon.white : menu.icon.orange}
            alt="menu icon"
            width={25}
            height={25}
            style={{ objectFit: 'contain' }}
          />
          <div>
            <div
              id={`${menu.name}-title`}
              className="text-dark-gray-200 text-13  pl-5"
            >
              {menu.title}
              <span className="text-primary-orange ml-4 text-center">
                {menu.newItem && menu.newItem}
              </span>
            </div>
          </div>
        </div>
      </a>

      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={<PermissionModalContent setShow={() => setShow(false)} />}
        handleButtonClick={() => setShow(false)}
        moreClasses={'bg-white'}
        title={'Notification'}
        addCloseButton={true}
      />
    </>
  );
}

import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import * as goal from '../../Goal/_redux/goalReducers';
import {
  actions as pocketActions,
  initialState as pocketFlowInitialState,
} from '../../Pocket/_redux/reducers';
import { fetchAllPlans } from '../_redux/plansActions';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';
import PlanCard from '../components/PlanCard';
import PlanTypeComponent from '../components/PlanTypeComponent';

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
}));

const AllPlans = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [planCategories, setPlanCategories] = useState([]);

  const fetchPlans = async () => {
    try {
      setLoading(true);
      const res = await fetchAllPlans();
      setPlanCategories(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(pocketActions.updatePocketFlow(pocketFlowInitialState));
    fetchPlans();
  }, []);

  const getThePlans = () => {
    return (
      <>
        {planCategories.map(category => {
          return category.plans.length ? (
            <div key={category.id} className="mt-5">
              <PlanTypeComponent
                fontSize={'25px'}
                planTypeName={category.name}
              />
              <div className="p-3 plans-container px-md-5 px-lg-5">
                {category.plans.map(plan => (
                  <div key={plan?.id}>
                    <PlanCard
                      classes="cursor-pointer"
                      data={plan}
                      key={plan.id}
                      planTypeName={firstLetterUpperCase(plan.planType?.name)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          );
        })}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <>
          {/* <Back text={'Back'} onClick={() => history.push('/dashboard')} /> */}
          <div className="card gutter-b internal-container">
            <div className={classes.root}>{getThePlans()}</div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ goal, profile }) => ({
  userRiskProfileId: profile?.riskProfile?.id,
  goal,
  bondPlans: goal.selectedBondPlans,
  cashPlans: goal.selectedCashPlans,
  equityPlans: goal.selectedEquityPlans,
});

export default connect(mapStateToProps, goal.actions)(AllPlans);

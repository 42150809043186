import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  updateMemberRole,
  updateMemberStatus,
} from '../../Business/_redux/busChamaCrud';
import Alert from '../../Common/Alert';
import Back from '../../Common/BackArrow';
import ReusableDropdown from '../../Common/ReusableDropdown';
import { FieldErrorMessage } from '../../Individual/components/FieldErrorMessage';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import OptionsModal from '../../Common/OptionsModal';
import { roles, teamMemberRole } from '../../../helpers/constants';
import { checkForErrors } from '../../../helpers/checkForErrors';
import { getRolePermissions } from '../helpers/getRolePermissions';
import { TeamMemberPermissions } from '../components/TeamMemberPermissions';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';

const AssignRole = ({ getTeamMemberId }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [message, setMessage] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);

  const teamMember = useSelector(state => state.teamMember);
  const profile = useSelector(state => state.profile);
  const user = profile?.user;

  const inviteDetails = useSelector(state => state.inviteDetails);

  const [selectedRole, setSelectedRole] = useState({
    label: capitalizeTheFirstLetterOfEachWord(
      teamMember?.role ? teamMember?.role?.name : inviteDetails?.role?.name,
    ),
    value: teamMember?.role
      ? teamMember?.role?.name
      : inviteDetails?.role?.name,
  });

  const [errorExist, setErrorExist] = useState();

  const history = useHistory();

  useEffect(() => {
    getTeamMemberId(id);
  }, [getTeamMemberId, id]);

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const handleSelectOption = item => {
    setSelectedRole(item);
    closeDropdown();
  };

  const updateAccountRole = async () => {
    try {
      setLoading(true);

      const noErrors = checkForErrors({
        role: selectedRole?.value,
      });

      if (!noErrors) {
        setErrorExist(noErrors);
        setLoading(false);
        return;
      }

      const payload = {
        id: parseInt(id),
        role: selectedRole?.value,
      };

      const res = await updateMemberRole(payload);

      if (res.status === 201) {
        setLoading(false);
        setMessage(res.data.message);
        setOpenTeamModal(true);
      }
    } catch (error) {
      setLoading(false);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const handleAccountDeactivation = async () => {
    const info = {
      id: parseInt(id),
      status: 'DEACTIVATED',
    };

    try {
      setLoadingActive(true);

      const res = await updateMemberStatus(info);

      if (res.status === 201) {
        setLoadingActive(false);
        setMessage(res.data.message);
      }
    } catch (error) {
      setLoadingActive(false);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };

  const isAdmin = teamMember?.role?.name === teamMemberRole.ADMIN;

  return (
    <div className="mt-5">
      <Back title={'Team Member Role'} />

      <KycPageContentWrapper>
        {alert?.alertMessage && <Alert alert={alert} />}
        <div className="w-full center-input mt-7">
          <DropdownTitle title={'User Role'} />

          <ReusableDropdown
            handleOpenSelector={handleDropdown}
            dropdownPlaceholder={'Select Role'}
            selectedOption={selectedRole?.label}
            classes={
              'bg-second-white py-3 height-45 border-radius-3 border-light-blue-400 pr-5 pl-2'
            }
            name={'role'}
            required={true}
            disabled={isAdmin}
          />

          <FieldErrorMessage
            errorExist={errorExist}
            errorMessage={'Role is Required'}
            fieldValue={selectedRole?.value}
          />
        </div>

        {selectedRole?.value ? (
          <TeamMemberPermissions
            teamMemberPermissions={getRolePermissions(selectedRole?.value)}
            alignToLeft={true}
          />
        ) : null}

        {teamMember?.id ? (
          <ButtonsContainer>
            <ConfirmButton
              buttonText={'Save'}
              buttonType={'submit'}
              h
              handleOnClick={updateAccountRole}
              disable={isAdmin}
              loading={loading}
            />
          </ButtonsContainer>
        ) : null}
      </KycPageContentWrapper>

      <OptionsModal
        open={openDropdown}
        handleClose={() => closeDropdown()}
        items={roles}
        handleSelect={handleSelectOption}
        selectedItem={selectedRole}
        placeholder={'Role'}
        name={'role'}
      />

      <CommonSuccessModal
        open={openTeamModal}
        setOpen={() => setOpenTeamModal(false)}
        content={message}
        handleButtonClick={() => history.push('/manage-account/team-members')}
        btnText={'Ok'}
        moreClasses={'bg-white'}
      />
    </div>
  );
};

export default AssignRole;

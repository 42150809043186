import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { inviteTeamMember } from '../../Business/_redux/busChamaCrud';
import CustomInput from '../../Common/CustomInput';
import {
  allowWhiteSpace,
  allowWhiteSpaceError,
  roles,
} from '../../../helpers/constants';
import * as Yup from 'yup';
import Alert from '../../Common/Alert';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import ReusableDropdown from '../../Common/ReusableDropdown';
import { FieldErrorMessage } from '../../Individual/components/FieldErrorMessage';
import OptionsModal from '../../Common/OptionsModal';
import { checkForErrors } from '../../../helpers/checkForErrors';
import Back from '../../Common/BackArrow';
import { getRolePermissions } from '../helpers/getRolePermissions';
import { TeamMemberPermissions } from '../components/TeamMemberPermissions';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';

const AddTeamMember = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [message, setMessage] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [errorExist, setErrorExist] = useState();

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  };

  const DetailSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Minimum 2 Characters')
      .max(50, 'Maximum 50 Characters')
      .matches(allowWhiteSpace, allowWhiteSpaceError)
      .required('First Name is required'),
    lastName: Yup.string()
      .min(2, 'Minimum 2 Characters')
      .max(50, 'Maximum 50 Characters')
      .matches(allowWhiteSpace, allowWhiteSpaceError)
      .required('Last Name is required'),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 Characters')
      .max(50, 'Maximum 50 Characters')
      .required('Email is invalid or Missing'),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const handleSelectOption = item => {
    setSelectedRole(item);
    closeDropdown();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: DetailSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);

        const noErrors = checkForErrors({
          ...values,
          role: selectedRole?.value,
        });

        if (!noErrors) {
          setErrorExist(noErrors);
          setLoading(false);
          return;
        }

        const memberInfo = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: selectedRole?.value,
        };

        const res = await inviteTeamMember(memberInfo);

        if (res.status === 201) {
          setLoading(false);
          setMessage(
            <div>
              You have successfully sent an invite to{' '}
              <span className="font-weight-500">{res?.data?.data?.email}</span>
            </div>,
          );
          setOpenTeamModal(true);
        } else {
          setLoading(false);
          setStatus(res?.data?.message);
        }
      } catch (error) {
        setLoading(false);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
      }
    },
  });

  return (
    <>
      <Back
        title={'Invite Team Member'}
        previousPageLink={'/manage-account/team-members'}
      />
      <form className="mt-5" id="kt_form" onSubmit={formik.handleSubmit}>
        <KycPageContentWrapper>
          {alert?.alertMessage && <Alert alert={alert} />}

          <div className="d-flex justify-content-between flex-wrap">
            <CustomInput
              type="text"
              title="First Name"
              name="firstName"
              placeholder="First Name"
              formik={formik}
              getInputClasses={getInputClasses}
            />

            <CustomInput
              type="text"
              title="Last Name"
              name="lastName"
              placeholder="Last Name"
              formik={formik}
              getInputClasses={getInputClasses}
            />
          </div>

          <div className="d-flex justify-content-between flex-wrap">
            <CustomInput
              type="text"
              title="Email"
              name="email"
              placeholder="Email"
              formik={formik}
              getInputClasses={getInputClasses}
            />

            <DropdownContainer>
              <DropdownTitle title={'User Role'} />

              <ReusableDropdown
                handleOpenSelector={handleDropdown}
                dropdownPlaceholder={'Select Role'}
                selectedOption={selectedRole?.label}
                classes={
                  'bg-second-white py-3 height-45 border-radius-3 border-light-blue-400 pr-5 pl-2'
                }
                name={'role'}
                required={true}
              />

              <FieldErrorMessage
                errorExist={errorExist}
                errorMessage={'Role is Required'}
                fieldValue={selectedRole?.value}
              />
            </DropdownContainer>
          </div>

          {selectedRole?.value ? (
            <TeamMemberPermissions
              teamMemberPermissions={getRolePermissions(selectedRole?.value)}
            />
          ) : null}

          <ButtonsContainer>
            <CancelButton
              buttonText="Cancel"
              handleOnClick={() => history.push('/manage-account/team-members')}
            />

            <ConfirmButton
              buttonText={'Send Invite'}
              buttonType={'submit'}
              loading={loading}
            />
          </ButtonsContainer>
        </KycPageContentWrapper>
      </form>

      <OptionsModal
        open={openDropdown}
        handleClose={() => closeDropdown()}
        items={roles}
        handleSelect={handleSelectOption}
        selectedItem={selectedRole}
        placeholder={'Role'}
        name={'role'}
      />

      <CommonSuccessModal
        open={openTeamModal}
        setOpen={() => setOpenTeamModal(false)}
        content={message}
        handleButtonClick={() => history.push('/manage-account/team-members')}
        btnText={'CONTINUE'}
        moreClasses={'bg-white'}
      />
    </>
  );
};

export default AddTeamMember;

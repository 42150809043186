import React from 'react';

import Alert from '../../../Common/Alert';
import Input from '../../../Common/Input/ControlledInput';
import { ConfirmTransactionDetailsPageWrapper } from '../../../Settlements/components/ConfirmTransactionsDetailsPageWrapper';
import { CancelButton, ConfirmButton } from '../../../Common/ReusableButtons';
import { ButtonsContainer } from '../../../Common/ButtonsContainer';

const ApplyVoucher = ({
  alertOn,
  formik,
  getInputClasses,
  investmentDetails,
  handleChange,
  setUseVoucher,
  applyPromoCode,
  loading,
  alert,
  setAlertOn,
}) => {
  return (
    <ConfirmTransactionDetailsPageWrapper title={'Redeem bonus'}>
      <div className="mb-5">{alertOn && <Alert alert={alert} />}</div>

      <p className="mb-5 text-center">Use your discount code to get a bonus.</p>

      <form className="mt-10" id="kt_form" onSubmit={formik.handleSubmit}>
        <Input
          placeholder="Insert discount code"
          type="text"
          className={`form-control input-field ${getInputClasses('promoCode')}`}
          name="promoCode"
          formik={formik}
          defaultValue={investmentDetails?.promoCode}
          handleChange={handleChange}
        />
        {formik.touched.promoCode && formik.errors.promoCode ? (
          <div className="input-container fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.promoCode}</div>
          </div>
        ) : null}

        <ButtonsContainer justifyContent="justify-content-between">
          <CancelButton
            buttonText="Cancel"
            handleOnClick={() => {
              delete investmentDetails?.promoCode;
              localStorage.removeItem('promoCode');
              setAlertOn(false);
              setUseVoucher(false);
            }}
            twinButton
          />

          <ConfirmButton
            handleOnClick={applyPromoCode}
            buttonText={'Redeem Bonus'}
            loading={loading}
            twinButton
          />
        </ButtonsContainer>
      </form>
    </ConfirmTransactionDetailsPageWrapper>
  );
};

export default ApplyVoucher;

export const renderMenuItemsBool = (
  item,
  originPartner,
  menuIdTags,
  bottomMenu = false,
) => {
  if (originPartner) {
    const existingTags = menuIdTags?.filter(tag => {
      const tagItem = bottomMenu ? `bottom-${tag}` : tag;
      return tagItem.toLowerCase() === item?.id?.toLowerCase();
    });
    return existingTags && existingTags.length > 0;
  } else {
    return true;
  }
};

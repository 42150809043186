import React from 'react';

import './index.scss';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';

const CommonButton = ({
  icon,
  alt,
  className,
  btnText,
  onClick,
  type,
  loading,
  disabled,
  loaderClasses,
}) => {
  return (
    <button
      type={type ? type : 'button'}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <img className="mr-3" src={icon} alt={alt} />}
      {typeof btnText === 'string'
        ? capitalizeTheFirstLetterOfEachWord(btnText)
        : btnText}
      {loading && (
        <span className={`${loaderClasses} ml-3 spinner spinner-white`} />
      )}
    </button>
  );
};

export default CommonButton;

import React from 'react';

export const DocumentInstructions = ({ instructions, cardWrapper }) => {
  return (
    <div
      className={`${cardWrapper} d-flex flex-column border-light-blue-600 border-radius-8 py-2 px-5`}
    >
      <div>
        <div className="font-weight-600 text-13 mt-5">Instructions:</div>
        {instructions}
      </div>
    </div>
  );
};

import React from 'react';
import moment from 'moment';

export default function Activity({ activity }) {
  return (
    <>
      <h4 className="font-weight-bold" style={{ color: '#0071CE' }}>
        {activity.type}
      </h4>
      <br />
      <div className="d-flex flex-wrap align-items-center justify-content-between w-100 mb-2">
        <div className="text-muted">Time</div>
        <span className="font-size-sm">
          {moment(activity?.createdAt).format('llll')}
        </span>
      </div>

      <div className="d-flex flex-wrap align-items-center justify-content-between w-100 mb-2">
        <div className="text-muted">Source</div>
        <span className="font-size-sm">{activity?.platform}</span>
      </div>

      <div className="d-flex flex-wrap align-items-center justify-content-between w-100 mb-2">
        <div className="text-muted">Location</div>
        <span className="font-size-sm">
          {activity?.city || activity?.country
            ? `${activity?.city} - ${activity?.country}`
            : '---'}
        </span>
      </div>

      <div className="d-flex flex-wrap align-items-center justify-content-between w-100 mb-2">
        <div className="text-muted">IP</div>
        <span className="font-size-sm">{activity?.ip || '---'}</span>
      </div>
    </>
  );
}

export const actionTypes = {
  UpdateGoalInvestmentDetails: '[UpdateGoalInvestmentDetails] Action',
  ResetGoalInvestmentDetails: '[ResetGoalInvestmentDetails] Action',
  UpdateSuggestedGoalNames: '[UpdateSuggestedGoalNames] Action',
  UpdateCreatedGoalList: '[UpdateCreatedGoalList] Action',
};

const initialGoalInvestmentDetails = {
  goalInvestmentDetails: {
    goalId: '',
    amount: '',
    currency: '',
    investmentType: '',
    pocketId: null,
    goalName: '',
    staticGoalId: null,
    years: 0,
    months: 0,
    monthlyAmount: '',
    initialAmount: '',
    targetAmount: '',
    riskLevel: null,
  },
};

const initialSuggestedGoalNames = [];

const initialGoals = [];

export const reducer = (state = initialGoalInvestmentDetails, action) => {
  switch (action.type) {
    case actionTypes.UpdateGoalInvestmentDetails: {
      const goalInvestmentDetails = action.payload;
      return { ...state, goalInvestmentDetails };
    }
    case actionTypes.ResetGoalInvestmentDetails: {
      return { ...state, ...initialGoalInvestmentDetails };
    }
    default: {
      return state;
    }
  }
};

export const getSuggestedGoalNamesReducer = (
  state = initialSuggestedGoalNames,
  action,
) => {
  switch (action.type) {
    case actionTypes.UpdateSuggestedGoalNames: {
      const suggestedGoalNames = action?.payload;
      return { ...state, suggestedGoalNames };
    }

    default: {
      return state;
    }
  }
};

export const updateCreatedGoalsListReducer = (state = initialGoals, action) => {
  switch (action.type) {
    case actionTypes.UpdateCreatedGoalList: {
      const goals = action?.payload;
      return goals;
    }

    default: {
      return state;
    }
  }
};

export const actions = {
  updateGoalInvestmentDetails: goalInvestmentDetails => ({
    type: actionTypes.UpdateGoalInvestmentDetails,
    payload: goalInvestmentDetails,
  }),

  ResetGoalInvestmentDetails: () => ({
    type: actionTypes.ResetGoalInvestmentDetails,
  }),

  updateSuggestedGoalNames: suggestedGoalNames => ({
    type: actionTypes.UpdateSuggestedGoalNames,
    payload: suggestedGoalNames,
  }),

  updateCreatedGoalsList: goals => ({
    type: actionTypes.UpdateCreatedGoalList,
    payload: goals,
  }),
};

export function* saga() {}

import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';

import * as auth from '../_redux/authRedux';
import { requestPassword } from '../_redux/authCrud';

import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import CustomInput from '../../Common/CustomInput';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';

const initialValues = {
  email: '',
};

const ForgotPassword = props => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 Characters')
      .max(50, 'Maximum 50 Characters')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        const response = await requestPassword(values.email.toLowerCase());
        setLoading(false);
        setSubmitting(false);
        if (response.status === 200) {
          setStatus(response.data.message);
          setSuccess(true);
        }
      } catch (error) {
        setSubmitting(false);
        setStatus(error.response.data.message);
        setSuccess(false);
        setLoading(false);
      }
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: 'block' }}>
          <ExternalPageTitle
            title="Forgotten Password?"
            subTitle="Enter the email address associated with your ndovu account to have a password reset request emailed to you"
          />

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework mt-10"
          >
            {formik.status && (
              <div className="mt-15 mb-10 text-center">
                <h4
                  className="font-weight-bold font-size-h4"
                  style={{ color: success ? '#67CCCA' : '#F05B7F' }}
                >
                  {formik.status}
                </h4>
              </div>
            )}

            <CustomInput
              type="email"
              placeholder="Email"
              name="email"
              formik={formik}
              inputWidth={'auth-page-input-container'}
            />

            <ButtonsContainer justifyContent="justify-content-between">
              <div className="auth-buttons-size">
                <Link to="/auth/login" className="w-100">
                  <CancelButton buttonText="Cancel" buttonWidth={'w-100'} />
                </Link>
              </div>

              <ConfirmButton
                buttonType={'submit'}
                buttonText="Submit"
                loading={loading}
                disable={!formik?.values?.email}
                buttonWidth={'auth-buttons-size'}
              />
            </ButtonsContainer>
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(ForgotPassword));

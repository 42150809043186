import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions as goalReducerActions } from '../Goal/_redux/goalReducers';

import CongratulationContent from '../Common/Button/CongratulationContent';
import AfterSubscription from '../Invest/components/FinalPage/AfterSubscription';
import KycReminder from '../KYC/pages/KycReminder';
import { getUserProfile } from '../Profile/_redux/profileCrud';
import * as profile from '../Profile/_redux/profileRedux';
import MainWrapper from '../Pocket/components/MainWrapper';
import { ConfirmButton } from '../Common/ReusableButtons';

const Congratulations = ({ profileAction }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState(false);

  const investorProfile = useSelector(state => state.profile?.investor);

  useEffect(() => {
    const subscriptionId = localStorage.getItem('subscriptionId');

    const subscribeToPro = async () => {
      if (subscriptionId) {
        setSubscription(true);
        const userProfile = await getUserProfile();
        profileAction(userProfile?.data?.data?.investor);
        localStorage.removeItem('subscribe');
        localStorage.removeItem('subscriptionId');
      }
    };

    subscribeToPro();
  }, [investorProfile?.subscription?.id, profileAction]);
  return !investorProfile?.kycSubmittedAt ? (
    <KycReminder />
  ) : (
    <>
      {subscription ? (
        <AfterSubscription />
      ) : (
        <div className="py-md-20">
          <MainWrapper>
            <h3 className="font-size-20 font-weight-600 text-center">
              Congratulations.
            </h3>
            <CongratulationContent />
            <div className="text-center">
              <ConfirmButton
                buttonText="Done"
                handleOnClick={() => {
                  dispatch(goalReducerActions.ResetGoalInvestmentDetails());
                  localStorage.removeItem('investedIn');
                  history.push('/home');
                }}
                buttonWidth={'button-md-w-360 button-w-360'}
              />
            </div>
          </MainWrapper>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  profile: state?.profile,
});

export default connect(mapStateToProps, { ...profile?.actions })(
  Congratulations,
);

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Country } from 'country-state-city';

import * as kyc from '../../KYC/_redux/kycRedux';

import { injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { idTypeOptions, idTypes } from '../../../helpers/constants';
import getCountries from '../../../helpers/countries';
import { ValidationErrors } from '../../Business/components/ValidationErrors';
import Dropdown from '../../Common/Dropdown';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import OptionsModal from '../../Common/OptionsModal';
import ReusableDropdown from '../../Common/ReusableDropdown';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';

const Information = props => {
  const { profile, handleStepChange, id: stepperId } = props;

  const kycData = useSelector(state => state.kyc);
  const idType = kycData?.idType;
  const country = kycData?.country;

  const [allCountries, setAllCountries] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openCountryDropdown, setOpenCountryDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    idType || profile.investor?.personalIdentity?.type
      ? {
          label:
            idTypeOptions[
              idType
                ? idType?.toUpperCase()
                : profile.investor?.personalIdentity?.type?.toUpperCase()
            ],
          value: idType
            ? idType
            : profile.investor?.personalIdentity?.type?.toUpperCase(),
        }
      : {
          label: null,
          value: null,
        },
  );

  const [selectedCountry, setSelectedCountry] = useState(
    country || profile.investor?.country
      ? {
          countryFlag: `flag-icon-${
            country
              ? country?.toLowerCase()
              : profile.investor?.country?.toLowerCase()
          }`,
          currency: Country.getCountryByCode(
            country ? country : profile.investor?.country,
          )?.name,
          countryCode: country ? country : profile.investor?.country,
        }
      : {
          countryFlag: null,
          currency: null,
          countryCode: null,
        },
  );
  const [fieldsError, setFieldsError] = useState();
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    const countries = getCountries();
    handleStepChange({ next: stepperId });
    setAllCountries(countries);
  }, []);

  const handleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleCountryDropdown = () => {
    setOpenCountryDropdown(!openCountryDropdown);
  };

  const closeCountryDropdown = () => {
    setOpenCountryDropdown(false);
  };

  const closeDropdown = () => {
    setOpenDropdown(false);
  };

  const handleSelect = country => {
    setFieldsError({ ...fieldsError, country: null });
    setSelectedCountry({
      countryFlag: country?.flag,
      currency: country?.name,
      countryCode: country?.countryCode,
    });
    closeCountryDropdown();
  };

  const handleNext = async () => {
    setFieldsError(null);
    let errors = {};
    if (!selectedOption?.value) {
      errors.idType = 'ID Type is Required';
    }

    if (!selectedCountry?.countryCode) {
      errors.country = 'Country is Required';
    }

    if (!selectedCountry?.countryCode || !selectedOption?.value) {
      setFieldsError(errors);
      return;
    }

    dispatch(
      kyc?.actions?.updateKycInfo({
        ...kycData,
        idType: selectedOption?.value,
        country: selectedCountry?.countryCode,
      }),
    );
    history.push('/kyc-information/investor-documents', { previousStep: 1 });
  };

  const handleSelectOption = item => {
    setFieldsError({ ...fieldsError, idType: null });
    setSelectedOption(item);
    closeDropdown();
  };

  return (
    <>
      <KycPageContentWrapper>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <DropdownContainer fitContainer={true}>
            <DropdownTitle title={'ID Type'} />
            <ReusableDropdown
              handleOpenSelector={handleDropdown}
              dropdownPlaceholder={'Select Document Type'}
              selectedOption={selectedOption?.label}
              classes={' py-3 border-radius-3 pr-5 pl-2'}
              name={'idType'}
            />

            {fieldsError?.idType ? (
              <ValidationErrors errorMessage={fieldsError?.idType} />
            ) : null}
          </DropdownContainer>

          <DropdownContainer fitContainer={true}>
            <DropdownTitle title="Country" />

            <Dropdown
              handleOpenSelector={handleCountryDropdown}
              dropdownPlaceholder={'Select Country'}
              selectedOption={selectedCountry}
              classes={'py-3 pr-5 pl-2'}
              name={'country'}
            />

            {fieldsError?.country ? (
              <ValidationErrors errorMessage={fieldsError?.country} />
            ) : null}
          </DropdownContainer>

          <div className="w-100">
            <ButtonsContainer justifyContent="justify-content-between">
              <CancelButton
                buttonText="Previous"
                handleOnClick={() => history.push('/kyc-information')}
                twinButton
              />

              <ConfirmButton
                buttonText={'Next'}
                handleOnClick={handleNext}
                twinButton
              />
            </ButtonsContainer>
          </div>
        </div>
      </KycPageContentWrapper>

      <OptionsModal
        open={openDropdown}
        handleClose={() => closeDropdown()}
        items={idTypes}
        handleSelect={handleSelectOption}
        selectedItem={selectedOption}
        placeholder={'Select Document Type'}
        name={'idType'}
      />

      <SelectOptionsModal
        open={openCountryDropdown}
        handleClose={() => setOpenCountryDropdown(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        placeholder={'Select Country'}
        selectedCountry={selectedCountry}
      />
    </>
  );
};

const mapStateToProps = ({ kyc, profile }) => ({
  kyc,
  profile,
});

export default injectIntl(
  connect(mapStateToProps, { ...kyc.actions })(Information),
);

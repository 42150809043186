import React from 'react';
import DashboardCompanyLogos from '../modules/Dashboard/pages/DashboardCompanyLogos';

const PageBottomContent = () => {
  return (
    <div className="d-flex flex-column mt-10">
      <div className="licensed-content text-md-13 text-13 text-dark-450">
        <p>
          Ndovu is a regulated robo advisory platform operated by Ndovu Wealth
          Limited (NWL). NWL is a fund manager licensed by the Capital Markets
          Authority (Kenya).
        </p>
        <p>
          The information provided on this platform, as well as the products and
          services offered , are intended solely for the persons in regions and
          jurisdictions where such distribution and utilisation are in
          accordance with with local laws and regulations. Ndovu does not
          promote its services in regions where it lacks the necessary licences:
          it’s exclusively available to persons residing in countries where it
          hold a valid licence or has regulated partners. Ndovu does not extend
          its services to citizens in the United States, Japan and other other
          restricted terrirories
        </p>
      </div>
      <DashboardCompanyLogos dashboardCompanyClass="desktop-dashboard-company" />
    </div>
  );
};

export default PageBottomContent;

import React from 'react';
import { openUploadedFileInNewWindow } from '../../../helpers/openUploadedFileIntoNewTab';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { ImageMaximumSize } from '../components/ImageMaximumSize';
import { DisplayUploadedFile } from '../components/DisplayUploadedFile';

export const WebcamFileUpload = ({
  inputRef,
  handleImageChange,
  loading,
  handleImageClick,
  uploadedFile,
  name,
  openExistingFile,
  existingFile,
}) => {
  return (
    <>
      <div style={{ width: '100%' }}>
        <input
          style={{ display: 'none' }}
          ref={inputRef}
          type="file"
          name={name}
          onChange={e => {
            handleImageChange(e);
          }}
        />
        <div className="my-1 font-weight-600 text-14 text-center">OR</div>
        <div className="d-flex justify-content-center mt-4">
          <ConfirmButton
            buttonText={
              uploadedFile || existingFile ? 'CHANGE FILE' : 'UPLOAD FILE'
            }
            disable={loading}
            handleOnClick={handleImageClick}
            loading={loading}
            buttonWidth={'w-100'}
          />
        </div>

        {!uploadedFile && !existingFile ? <ImageMaximumSize /> : null}

        {uploadedFile || existingFile ? (
          <DisplayUploadedFile
            uploadedFile={uploadedFile}
            existingFile={existingFile}
            openUploadedFileInNewWindow={openUploadedFileInNewWindow}
            openExistingFile={openExistingFile}
          />
        ) : null}
      </div>
    </>
  );
};

import React from 'react';
import ArrowDownIcon from '../../../icons/arrow-down.svg';

const Dropdown = ({
  handleOpenSelector,
  selectedOption,
  dropdownPlaceholder,
  classes,
  name,
  disableDropdown,
  withBorders = true,
}) => {
  return (
    <div
      className={`${!disableDropdown ? 'cursor-pointer' : ''} ${classes} ${
        withBorders ? 'border-gray-450 border-radius-8 height-45' : ''
      } input-padding`}
    >
      <div
        className="d-flex w-100 justify-content-between align-items-center"
        onClick={
          handleOpenSelector && !disableDropdown
            ? () => handleOpenSelector(name)
            : null
        }
      >
        <div className="d-flex align-items-center">
          {selectedOption?.currencyFlag ||
          selectedOption?.countryFlag ||
          selectedOption?.currency ? (
            <>
              <div className="width-30">
                {selectedOption?.countryFlag ? (
                  <span
                    className={`flag-icon ${selectedOption?.countryFlag} ml-3`}
                  ></span>
                ) : (
                  <img
                    className="w-100 h-100 border-radius-2"
                    src={selectedOption?.currencyFlag}
                    alt={`Country flag`}
                  />
                )}
              </div>
              {selectedOption?.currency ? (
                <div className="ml-3 text-13 text-dark-550">
                  {' '}
                  {selectedOption?.currency}{' '}
                </div>
              ) : null}
            </>
          ) : (
            <div className="ml-1 text-13 text-light-gray-600">
              {' '}
              {dropdownPlaceholder}{' '}
            </div>
          )}
        </div>
        {!disableDropdown ? (
          <div>
            <img src={ArrowDownIcon} alt="Select Icon" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Dropdown;

import Pagination from '@material-ui/lab/Pagination';
import _ from 'lodash';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import {
  accountTypes,
  archivedTransactionsColumns,
  transactionsColumns,
  TransactionStatus,
} from '../../../helpers/constants';
import { convertToCamelCase } from '../../../helpers/convertToCamelCase';
import Alert from '../../Common/Alert';
import CommonButton from '../../Common/Button';
import NumberOfRows from '../../Common/SelectNumberOfRows/index';
import SavingAccountMesssage from '../../Plan/components/SavingAccoutMessage';
import NoIinvestmentMade from '../../Portfolio/components/NoInvestmentMade';
import { downloadPdf, fetchTransactions } from '../_redux/transactionCrud';
import { ArchivedTransactionsTableData } from '../components/ArchivedTransactions';
import { DateInput } from '../components/DateInput';
import { TransactionsTableData } from '../components/TransactionsTableData';
import { TransactionsTableHeaders } from '../components/TransactionsTableHeaders';
import './index.scss';
import { FullWidthWrapper } from '../../Common/FullWidthWrapper';

const Transaction = () => {
  const [loading, setLoading] = useState(true);
  const [dateValues, setDateValues] = useState();
  const [pdfLoading, setPdfLoading] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const initialTransactionsState = {
    pending: {
      transactions: [],
      totalRecord: 0,
      totalPages: 0,
      page: 1,
      limit: 10,
    },
    completed: {
      transactions: [],
      totalRecord: 0,
      totalPages: 0,
      page: 1,
      limit: 10,
    },
    archived: {
      transactions: [],
      totalRecord: 0,
      totalPages: 0,
      page: 1,
      limit: 10,
    },
    transferIncomplete: {
      transactions: [],
      totalRecord: 0,
      totalPages: 0,
      page: 1,
      limit: 10,
    },
    reconciled: {
      transactions: [],
      totalRecord: 0,
      totalPages: 0,
      page: 1,
      limit: 10,
    },
  };

  const { investor } = useSelector(state => state.profile);

  const [transactions, setTransactions] = useState(initialTransactionsState);

  const mapResponseData = data => {
    return {
      totalRecord: data.totalItems,
      totalPages: data.totalPages,
      transactions: data.data,
      limit: parseInt(data.itemsPerPage),
      page: parseInt(data.currentPage),
    };
  };

  useEffect(() => {
    const getTransactions = async currentStatus => {
      const { page, limit } = transactions[convertToCamelCase(currentStatus)];
      let res = await fetchTransactions(currentStatus, page, limit);

      const mappedData = mapResponseData(res.data);

      setTransactions(prevTransactions => {
        const updatedTransactions = {
          ...prevTransactions,
          [convertToCamelCase(currentStatus)]: mappedData,
        };
        return updatedTransactions;
      });
    };

    const transactionStatusArray = Object.keys(TransactionStatus);
    // Use Promise.all to wait for all getTransactions promises to resolve
    Promise.all(
      transactionStatusArray.map(status => getTransactions(status)),
    ).then(() => {
      // Set loading to false only after all transactions are fetched
      setLoading(false);
    });
  }, []);

  const handlePageChange = async (page, transactionStatus) => {
    const statusToCamelCase = convertToCamelCase(transactionStatus);
    const { limit } = transactions[statusToCamelCase];
    let res = await fetchTransactions(transactionStatus, page, limit);
    const mappedData = mapResponseData(res.data);
    setTransactions({
      ...transactions,
      [statusToCamelCase]: mappedData,
    });
  };

  const changeTransferIncompletePage = async (_, value) => {
    handlePageChange(value, TransactionStatus.TRANSFER_INCOMPLETE);
  };

  const changePendingTransactionspage = async (_, value) => {
    handlePageChange(value, TransactionStatus.PENDING);
  };

  const changeCompletedTransactionspage = async (_, value) => {
    handlePageChange(value, TransactionStatus.COMPLETED);
  };

  const handleArchivedChangePage = async (_, value) => {
    handlePageChange(value, TransactionStatus.ARCHIVED);
  };

  const handleReconciledChangePage = async (_, value) => {
    handlePageChange(value, TransactionStatus.RECONCILED);
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue, name } = event.target;
    const statusToCamelCase = convertToCamelCase(name);
    let res = await fetchTransactions(name, 1, selectedValue);
    setTransactions({
      ...transactions,
      [statusToCamelCase]: {
        limit: selectedValue,
        totalRecord: res.data.totalItems,
        totalPages: res.data.totalPages,
        transactions: res.data.data,
        page: 1,
      },
    });
  };

  const handleDateChange = event => {
    const { name, value } = event.target;
    setDateValues({ ...dateValues, [name]: value });
  };

  const handlePdfDownload = async () => {
    setPdfLoading(true);
    setAlert({
      alertMessage: null,
      alertMessageType: null,
    });
    if (!dateValues?.startDate || !dateValues?.endDate) {
      setAlert({
        alertMessage: 'Please provide start and end date',
        alertMessageType: 'error',
      });
      setPdfLoading(false);
    } else {
      try {
        await downloadPdf(dateValues);
        setPdfLoading(false);
      } catch (error) {
        // Handle the error response as JSON
        let errorMessage;
        if (error?.response?.request?.responseType === 'arraybuffer') {
          errorMessage = JSON.parse(
            new TextDecoder().decode(error?.response?.data),
          )?.message;
        } else {
          errorMessage = error?.response?.data?.message;
        }
        setAlert({
          alertMessage: errorMessage
            ? errorMessage
            : 'Something went wrong, please try again',
          alertMessageType: 'error',
        });
        setPdfLoading(false);
      }
    }
  };

  const todayDate = moment(new Date()).format('YYYY-MM-DD');

  const noTransactionsMade = () => {
    const transactionStatuses = Object.keys(TransactionStatus);
    let transactionsExist = [];

    for (let i = 0; i < transactionStatuses.length; i++) {
      const status = transactionStatuses[i];
      if (transactions[convertToCamelCase(status)].transactions.length < 1) {
        !transactionsExist.includes(status) && transactionsExist.push(status);
      }
    }
    return _.isEqual(transactionStatuses, transactionsExist);
  };

  const getTransactionsColumns = columns => {
    const allTransactionsColumns =
      investor?.investorType === accountTypes.INDIVIDUAL
        ? columns?.filter(
            column =>
              column?.toLowerCase() !== 'actioned by' &&
              column?.toLowerCase() !== 'email',
          )
        : columns;

    return allTransactionsColumns;
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <>
          {transactions.completed.transactions.length ? (
            <FullWidthWrapper>
              <div className="my-5">
                {alert?.alertMessage ? <Alert alert={alert} /> : null}
                <div
                  style={{ gap: '20px' }}
                  className="d-flex justify-content-center flex-wrap"
                >
                  <DateInput
                    name="startDate"
                    label="Start Date"
                    handleDateChange={handleDateChange}
                    max={
                      dateValues?.endDate
                        ? moment(dateValues?.endDate).format('YYYY-MM-DD')
                        : todayDate
                    }
                  />

                  <DateInput
                    name="endDate"
                    label="End Date"
                    handleDateChange={handleDateChange}
                    classes="ml-2"
                    min={
                      dateValues?.startDate
                        ? moment(dateValues?.startDate).format('YYYY-MM-DD')
                        : null
                    }
                    max={todayDate}
                  />

                  <CommonButton
                    btnText={'Download Statement'}
                    className={`text-center mt-2 resize common-btn cancel-btn-padding`}
                    onClick={handlePdfDownload}
                    loading={pdfLoading}
                    loaderClasses="mt-3 mr-1"
                  />
                </div>
                <SavingAccountMesssage
                  message={
                    <span>
                      <span className="font-weight-bolder">Note: </span>
                      Please use your{' '}
                      {investor?.investorType === accountTypes.BUSINESS ? (
                        <span className="font-weight-bolder">
                          business registration
                        </span>
                      ) : investor?.investorType === accountTypes.CHAMA ? (
                        <span className="font-weight-bolder">
                          chama registration
                        </span>
                      ) : (
                        <>
                          <span className="font-weight-bolder">ID</span> or{' '}
                          <span className="font-weight-bolder">Passport</span>
                        </>
                      )}{' '}
                      number as a password to open your statement
                    </span>
                  }
                  classes="password-message mx-7 mb-7 mt-10 mx-auto"
                />
              </div>
            </FullWidthWrapper>
          ) : null}
          {transactions.pending.transactions.length ? (
            <>
              <FullWidthWrapper>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <TransactionsTableHeaders
                        columns={getTransactionsColumns(transactionsColumns)}
                        investorType={investor?.investorType}
                      />
                    </thead>
                    <tbody>
                      {transactions.pending.transactions?.map(transaction => (
                        <TransactionsTableData
                          key={transaction.id}
                          transaction={transaction}
                          investorType={investor?.investorType}
                        />
                      ))}
                    </tbody>
                  </table>
                  <div
                    style={{ borderBottom: '0.6px solid #ebedf3' }}
                    className="divider"
                  />
                  <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                    {transactions.pending.totalPages > 0 ? (
                      <Pagination
                        page={transactions.pending.page}
                        count={transactions.pending.totalPages}
                        onChange={changePendingTransactionspage}
                      />
                    ) : (
                      ''
                    )}
                    {transactions.pending.totalRecord > 10 ? (
                      <NumberOfRows
                        handleChangeLimit={handleChangeLimit}
                        totalRecord={transactions.pending.totalRecord}
                        name={TransactionStatus.PENDING}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </FullWidthWrapper>
            </>
          ) : null}
          {transactions.transferIncomplete.transactions.length ? (
            <FullWidthWrapper>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <TransactionsTableHeaders
                      columns={getTransactionsColumns(transactionsColumns)}
                      investorType={investor?.investorType}
                    />
                  </thead>
                  <tbody>
                    {transactions.transferIncomplete.transactions.map(
                      transaction => (
                        <TransactionsTableData
                          key={transaction?.id}
                          transaction={transaction}
                          investorType={investor?.investorType}
                        />
                      ),
                    )}
                  </tbody>
                </table>
                <div
                  style={{ borderBottom: '0.6px solid #ebedf3' }}
                  className="divider"
                />
                <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                  {transactions.transferIncomplete.totalPages > 0 ? (
                    <Pagination
                      page={transactions.transferIncomplete.page}
                      count={transactions.transferIncomplete.totalPages}
                      onChange={changeTransferIncompletePage}
                    />
                  ) : (
                    ''
                  )}
                  {transactions.transferIncomplete.totalRecord > 10 ? (
                    <NumberOfRows
                      handleChangeLimit={handleChangeLimit}
                      totalRecord={transactions.transferIncomplete.totalRecord}
                      name={TransactionStatus.TRANSFER_INCOMPLETE}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </FullWidthWrapper>
          ) : null}

          {transactions.reconciled.transactions.length ? (
            <FullWidthWrapper>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <TransactionsTableHeaders
                      columns={getTransactionsColumns(transactionsColumns)}
                      investorType={investor?.investorType}
                    />
                  </thead>
                  <tbody>
                    {transactions.reconciled.transactions.map(transaction => (
                      <TransactionsTableData
                        key={transaction?.id}
                        transaction={transaction}
                        investorType={investor?.investorType}
                      />
                    ))}
                  </tbody>
                </table>
                <div
                  style={{ borderBottom: '0.6px solid #ebedf3' }}
                  className="divider"
                />
                <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                  {transactions.reconciled.totalPages > 0 ? (
                    <Pagination
                      page={transactions.reconciled.page}
                      count={transactions.reconciled.totalPages}
                      onChange={handleReconciledChangePage}
                    />
                  ) : (
                    ''
                  )}
                  {transactions.reconciled.totalRecord > 10 ? (
                    <NumberOfRows
                      handleChangeLimit={handleChangeLimit}
                      totalRecord={transactions.reconciled.totalRecord}
                      name={TransactionStatus.RECONCILED}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </FullWidthWrapper>
          ) : null}
          {transactions.completed.transactions.length ? (
            <FullWidthWrapper>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <TransactionsTableHeaders
                      columns={getTransactionsColumns(transactionsColumns)}
                      investorType={investor?.investorType}
                    />
                  </thead>
                  <tbody>
                    {transactions.completed.transactions.map(transaction => (
                      <TransactionsTableData
                        key={transaction.id}
                        transaction={transaction}
                        investorType={investor?.investorType}
                      />
                    ))}
                  </tbody>
                </table>
                <div
                  style={{ borderBottom: '0.6px solid #ebedf3' }}
                  className="divider"
                />
                <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                  {transactions.completed.totalPages > 0 ? (
                    <Pagination
                      page={transactions.completed.page}
                      count={transactions.completed.totalPages}
                      onChange={changeCompletedTransactionspage}
                    />
                  ) : (
                    ''
                  )}
                  {transactions.completed.totalRecord > 10 ? (
                    <NumberOfRows
                      handleChangeLimit={handleChangeLimit}
                      totalRecord={transactions.completed.totalRecord}
                      name={TransactionStatus.COMPLETED}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </FullWidthWrapper>
          ) : null}
          {transactions.archived.transactions.length ? (
            <FullWidthWrapper>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <TransactionsTableHeaders
                      columns={getTransactionsColumns(
                        archivedTransactionsColumns,
                      )}
                      investorType={investor?.investorType}
                    />
                  </thead>
                  <tbody>
                    {transactions.archived.transactions.map(transaction => (
                      <ArchivedTransactionsTableData
                        transaction={transaction}
                        key={transaction?.id}
                        investorType={investor?.investorType}
                      />
                    ))}
                  </tbody>
                </table>
                <div
                  style={{ borderBottom: '0.6px solid #ebedf3' }}
                  className="divider"
                />
                <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                  {transactions.archived.totalPages > 0 ? (
                    <Pagination
                      page={transactions.archived.page}
                      count={transactions.archived.totalPages}
                      onChange={handleArchivedChangePage}
                    />
                  ) : (
                    ''
                  )}
                  {transactions.archived.totalRecord > 10 ? (
                    <NumberOfRows
                      handleChangeLimit={handleChangeLimit}
                      totalRecord={transactions.archived.totalRecord}
                      name={TransactionStatus.ARCHIVED}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </FullWidthWrapper>
          ) : null}
        </>
      )}

      {noTransactionsMade() && !loading ? (
        <div className="card card-custom card-gutter-b">
          <div className="card-header">
            <div className="card-title">
              <h2 className="font-weight-bold " style={{ fontSize: '18px' }}>
                Transactions
              </h2>
            </div>
          </div>
          <NoIinvestmentMade />
        </div>
      ) : null}
    </>
  );
};

export default Transaction;

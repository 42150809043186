import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { countries } from 'countries-list';

import {
  SourceOfFunds,
  SourceOfFundsObject,
  accountTypes,
  letterNumberRegex,
  specialCharError,
} from '../../../helpers/constants';

import { actions as kycActions } from '../../KYC/_redux/kycRedux';

import '../components/index.scss';
import Alert from '../../Common/Alert';
import SelectOptionsModal from '../../Common/SelectOptionsModal';
import getCountries from '../../../helpers/countries';
import parsePhoneNumber from 'libphonenumber-js';
import ReusableDropdown from '../../Common/ReusableDropdown';
import { FieldErrorMessage } from '../../Individual/components/FieldErrorMessage';
import OptionsModal from '../../Common/OptionsModal';
import { checkForErrors } from '../../../helpers/checkForErrors';
import { Note } from '../../Common/Note';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import {
  getFullPhoneNumber,
  validatePhoneNumber,
} from '../../../helpers/validatePhoneNumber';
import CustomInput from '../../Common/CustomInput';

import '../components/index.scss';
import { DropdownTitle } from '../../Common/Dropdown/DropdownTitle';
import { DropdownContainer } from '../../Common/ReusableDropdown/DropDownContainer';
import { PhoneNumberComponent } from '../../Common/PhoneNumberComponent';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';

const AdditionalDetails = props => {
  const { id: stepperId, handleStepChange } = props;
  const intl = useIntl();
  const profile = useSelector(state => state.profile?.investor);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [allCountries, setAllCountries] = useState();
  const [accountType, setAccountType] = useState(profile?.investorType);
  const [openSourceOfFundsDropdown, setOpenSourceOfFundsDropdown] = useState(
    false,
  );

  const kycInformation = useSelector(state => state.kyc);

  const [selectedCountry, setSelectedCountry] = useState({
    countryCallingCode:
      profile?.phoneNumber || kycInformation?.phoneNumber
        ? parsePhoneNumber(
            kycInformation?.phoneNumber
              ? kycInformation?.phoneNumber
              : profile?.phoneNumber,
          )?.countryCallingCode
        : null,

    name:
      profile?.phoneNumber || kycInformation?.phoneNumber
        ? countries[
            parsePhoneNumber(
              kycInformation?.phoneNumber
                ? kycInformation?.phoneNumber
                : profile?.phoneNumber,
            )
          ]?.name
        : null,

    flag:
      profile?.phoneNumber || kycInformation?.phoneNumber
        ? `flag-icon-${parsePhoneNumber(
            kycInformation?.phoneNumber
              ? kycInformation?.phoneNumber
              : profile?.phoneNumber,
          )?.country.toLowerCase()}`
        : null,
  });

  const [selectedNextOfKinCountry, setSelectedNextOfKinCountry] = useState({
    countryCallingCode:
      profile?.nextOfKinContact || kycInformation?.nextOfKinContact
        ? parsePhoneNumber(
            kycInformation?.nextOfKinContact
              ? kycInformation?.nextOfKinContact
              : profile?.nextOfKinContact,
          )?.countryCallingCode
        : null,

    name:
      profile?.nextOfKinContact || kycInformation?.nextOfKinContact
        ? countries[
            parsePhoneNumber(
              kycInformation?.nextOfKinContact
                ? kycInformation?.nextOfKinContact
                : profile?.nextOfKinContact,
            )
          ]?.name
        : null,

    flag:
      kycInformation?.nextOfKinContact || profile?.nextOfKinContact
        ? `flag-icon-${parsePhoneNumber(
            kycInformation?.nextOfKinContact
              ? kycInformation?.nextOfKinContact
              : profile?.nextOfKinContact,
          )?.country.toLowerCase()}`
        : null,
  });

  const [selectedSourceOfFunds, setSelectedSourceOfFunds] = useState({
    value: kycInformation?.sourceOfFunds
      ? kycInformation?.sourceOfFunds
      : profile?.sourceOfFunds
      ? profile?.sourceOfFunds
      : null,

    label:
      profile?.sourceOfFunds || kycInformation?.sourceOfFunds
        ? SourceOfFundsObject[
            kycInformation?.sourceOfFunds
              ? kycInformation?.sourceOfFunds
              : profile?.sourceOfFunds
          ]
        : null,
  });
  const [open, setOpen] = useState(false);
  const [errorExist, setErrorExist] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);

  const [
    openModalForNextOfKinContact,
    setOpenModalForNextOfKinContact,
  ] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }
    return '';
  };

  const initialValues = {
    city: kycInformation?.city || profile?.city || '',

    businessChamaBusinessAddress:
      kycInformation?.businessChamaBusinessAddress ||
      profile?.businessChamaBusinessAddress ||
      '',

    postalAddress:
      kycInformation?.postalAddress || profile?.postalAddress || '',

    postalCode: kycInformation?.postalAddress || profile?.postalCode || '',

    businessChamaEmail:
      kycInformation?.businessChamaEmail || profile?.businessChamaEmail || '',

    country: kycInformation?.country || profile?.country || '',

    phoneNumber:
      kycInformation?.phoneNumber || profile?.phoneNumber
        ? parsePhoneNumber(
            kycInformation?.phoneNumber
              ? kycInformation?.phoneNumber
              : profile?.phoneNumber,
          )?.nationalNumber
        : '',
  };

  const AdditionalValidationSchema = Yup.object().shape({
    city: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('City is required'),

    businessChamaBusinessAddress: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('Address is required'),

    postalAddress: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('Postal Address is required'),

    postalCode: Yup.string()
      .matches(letterNumberRegex, specialCharError)
      .required('Postal Code is required'),

    businessChamaEmail: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 Characters')
      .max(50, 'Maximum 50 Characters')
      .required('Email is required'),

    country: Yup.mixed().required('Country is required'),

    phoneNumber: Yup.number().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      }),
    ),
  });

  const handleSelectSourceOfFunds = item => {
    setSelectedSourceOfFunds(item);
    setOpenSourceOfFundsDropdown(false);
  };

  useEffect(() => {
    handleStepChange({ next: stepperId });
    const countriesOptions = getCountries();
    setAllCountries(countriesOptions);
  }, []);

  const handleSelect = country => {
    setSelectedCountry(country);
    setOpen(false);
  };

  const selectNextOfKinCountry = country => {
    setSelectedNextOfKinCountry(country);
    setOpenModalForNextOfKinContact(false);
  };

  const handleSourceOfFundsDropdown = () => {
    setOpenSourceOfFundsDropdown(!openSourceOfFundsDropdown);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: AdditionalValidationSchema,
    onSubmit: async values => {
      const {
        city,
        businessChamaBusinessAddress,
        postalAddress,
        postalCode,
        businessChamaEmail,
        phoneNumber,
      } = values;

      const fullInvestorPhoneNumber = getFullPhoneNumber(
        selectedCountry?.countryCallingCode,
        phoneNumber,
      );

      const isInvestorPhoneNumberValid = validatePhoneNumber(
        fullInvestorPhoneNumber,
        phoneNumber,
      );

      setValidPhoneNumber(isInvestorPhoneNumberValid);

      if (!isInvestorPhoneNumberValid) return;

      const fields = {
        sourceOfFunds: selectedSourceOfFunds?.value,
      };

      const noErrors = checkForErrors({ ...values, ...fields });
      if (!noErrors) {
        setErrorExist(true);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const payload = {
          sourceOfFunds: selectedSourceOfFunds?.value,
          city,
          businessChamaBusinessAddress,
          postalAddress,
          postalCode,
          businessChamaEmail,
          phoneNumber: fullInvestorPhoneNumber,
        };

        dispatch(
          kycActions?.updateKycInfo({
            ...kycInformation,
            ...payload,
          }),
        );

        setLoading(false);
        localStorage.setItem('previousPage', history.location.pathname);
        history.push('/investor-information/overview', {
          from: 'Additional Details',
        });
      } catch (error) {
        // Handle exceptions or errors here
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'error',
        });
      }
    },
  });

  return (
    <>
      <form className="mt-5" id="kt_form" onSubmit={formik.handleSubmit}>
        <KycPageContentWrapper>
          {alertOn && <Alert alert={alert} />}

          <div className="d-flex justify-content-between flex-wrap">
            <DropdownContainer>
              <DropdownTitle title={'Source of Funds'} />

              <ReusableDropdown
                handleOpenSelector={handleSourceOfFundsDropdown}
                dropdownPlaceholder={'Select Source of Funds'}
                selectedOption={selectedSourceOfFunds?.label}
                classes={'pr-5 pl-2'}
                name={'selectedSourceOfFunds'}
              />

              {errorExist ? (
                <FieldErrorMessage
                  errorExist={errorExist}
                  errorMessage={`Source of Funds is required`}
                  fieldValue={selectedSourceOfFunds?.value}
                />
              ) : null}
            </DropdownContainer>

            <CustomInput
              type="text"
              title={
                accountType?.toLocaleLowerCase() ===
                accountTypes.BUSINESS.toLocaleLowerCase()
                  ? 'Business Email'
                  : 'Chama Email'
              }
              name="businessChamaEmail"
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
            />
          </div>

          <div className="d-flex justify-content-between flex-wrap">
            <CustomInput
              type="text"
              title={
                accountType?.toLocaleLowerCase() ===
                accountTypes.BUSINESS.toLocaleLowerCase()
                  ? 'Business Address'
                  : 'Chama Address'
              }
              name="businessChamaBusinessAddress"
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
            />

            <CustomInput
              type="text"
              title="Postal Address"
              name="postalAddress"
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
            />
          </div>

          <div className="d-flex justify-content-between flex-wrap">
            <CustomInput
              type="text"
              title="Postal Code"
              name="postalCode"
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
            />

            <CustomInput
              type="text"
              title="City"
              name="city"
              formik={formik}
              getInputClasses={getInputClasses}
              required={true}
            />
          </div>

          <div className="d-flex justify-content-between flex-wrap">
            <PhoneNumberComponent
              formik={formik}
              selectedCountry={selectedCountry}
              validPhoneNumber={validPhoneNumber}
              setOpen={setOpen}
              inputName={'phoneNumber'}
            />
          </div>

          <ButtonsContainer justifyContent="justify-content-between">
            <CancelButton
              buttonText="Previous"
              handleOnClick={() =>
                history.push('/investor-information/documentation')
              }
              twinButton
            />

            <ConfirmButton
              buttonType={'submit'}
              buttonText="Next"
              loading={loading}
              twinButton
            />
          </ButtonsContainer>
        </KycPageContentWrapper>
      </form>

      <OptionsModal
        open={openSourceOfFundsDropdown}
        handleClose={() => setOpenSourceOfFundsDropdown(false)}
        items={SourceOfFunds}
        handleSelect={handleSelectSourceOfFunds}
        selectedItem={selectedSourceOfFunds}
        placeholder={'Source of Funds'}
        name={'sourceOfFunds'}
      />

      <SelectOptionsModal
        open={openModalForNextOfKinContact}
        handleClose={() => setOpenModalForNextOfKinContact(false)}
        allCountries={allCountries}
        handleSelect={selectNextOfKinCountry}
        selectedCountry={selectedNextOfKinCountry}
      />

      <SelectOptionsModal
        open={open}
        handleClose={() => setOpen(false)}
        allCountries={allCountries}
        handleSelect={handleSelect}
        selectedCountry={selectedCountry}
      />
    </>
  );
};

export default AdditionalDetails;

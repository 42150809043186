import React from 'react';

export const TransactionsTableHeaders = ({ columns }) => {
  return (
    <tr>
      {columns.map(column => {
        return (
          <th
            style={{
              maxWidth: '220px',
            }}
            scope="col"
            key={column}
          >
            <div className="text-blue text-13 font-weight-500">{column}</div>
          </th>
        );
      })}
    </tr>
  );
};

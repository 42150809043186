import React from 'react';
import { currencyHelper } from '../../../../helpers/currencyHelper';
import PopoverComponent from '../../../Common/PopoverComponent';

const DisplayMoney = ({
  label,
  currency,
  value,
  discountAmount,
  keyCustomStyle,
  valueCustomStyle,
  mathSign,
  minimumAmountError,
}) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-3">
        <span className="text-13" style={{ ...keyCustomStyle }}>
          {label}
        </span>

        <span
          className="font-weight-600 text-13"
          style={{ ...valueCustomStyle }}
        >
          <span>
            {discountAmount
              ? `- ${discountAmount}`
              : currency && value
              ? currencyHelper(value, currency, mathSign)
              : value}
          </span>
          {minimumAmountError ? (
            <PopoverComponent popoverContent={minimumAmountError}>
              <span className="cursor-pointer">
                <i
                  style={{
                    fontSize: '16px',
                    color: '#F64E60',
                    marginLeft: '5px',
                  }}
                  className="fa fa-question-circle"
                  aria-hidden="true"
                ></i>
              </span>
            </PopoverComponent>
          ) : null}
        </span>
      </div>
    </>
  );
};

export default DisplayMoney;

import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import VerticalLine from '../../../icons/VerticalLine.svg';

import { actions as pocketActions } from '../_redux/reducers';
import { actions as investmentReducerActions } from '../../Invest/_redux/investmentReducers';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { InvestmentAction, PocketActions } from '../../../helpers/constants';
import { moneyFormat } from '../../../helpers/moneyFormat';
import Alert from '../../Common/Alert';
import Back from '../../Common/BackArrow';
import { CommonSuccessModal } from '../../Common/CommonSuccessModal';
import {
  fetchPocketTransactions,
  getPocketDetails,
} from '../_redux/axiosRequest';
import { SingleTransaction } from '../components/SingleTransaction';
import { pocketContents } from '../copies';
import { checkInvestorPermission } from '../../../helpers/checInvestorPermission';
import { PermissionModalContent } from '../../Common/PermissionModalContent';
import { PendingTransactionAmount } from '../components/PendindTransactionAmount';

export const PocketAccount = () => {
  const alertInitialState = {
    alertMessage: null,
    alertMessageType: null,
  };
  const { pocketId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [alert, setAlert] = useState(alertInitialState);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const pocketFlow = useSelector(state => state.pocketFlow);
  const pocketTransactions = useSelector(state => state.pocketTransactions);
  const pocketDetails = useSelector(state => state.pocketDetails);
  const { investor, user } = useSelector(state => state.profile);

  const handleErrorMessage = errorMessage => {
    setLoading(false);
    setAlert({ alertMessage: errorMessage, alertMessageType: 'error' });
  };

  const fetchPocketDetails = async () => {
    try {
      const response = await getPocketDetails(pocketId);
      dispatch(
        pocketActions.updatePocket({
          ...response?.data,
          currency: response?.data?.currency?.currency,
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleErrorMessage(error?.response?.data?.message);
    }
  };

  const getPocketTransactions = async () => {
    try {
      const response = await fetchPocketTransactions(
        pocketId,
        pocketTransactions?.page,
        pocketTransactions?.limit,
      );
      const { meta, items } = response.data;
      dispatch(
        pocketActions.getPocketTransactions({
          transactions: items,
          totalRecord: meta?.totalItems,
          totalPages: meta?.totalPages,
          page: meta?.currentPage,
          limit: meta.itemsPerPage,
        }),
      );
      setLoading(false);
    } catch (error) {
      handleErrorMessage(error?.response?.data?.message);
    }
  };

  const handlePageChange = async (_, page) => {
    const response = await fetchPocketTransactions(
      pocketId,
      page,
      pocketTransactions?.limit,
    );
    const { meta, items } = response.data;
    dispatch(
      pocketActions.getPocketTransactions({
        transactions: items,
        totalRecord: meta?.totalItems,
        totalPages: meta?.totalPages,
        page: meta?.currentPage,
        limit: meta.itemsPerPage,
      }),
    );
  };

  useEffect(() => {
    fetchPocketDetails();
    getPocketTransactions();
  }, []);

  const handleOperationClick = action => {
    const investorHasPermissionToInvest = checkInvestorPermission(
      user?.businessChamaRole?.name,
      investor?.investorType,
      action?.path,
    );

    if (!investorHasPermissionToInvest) {
      setShow(true);
      return;
    }

    dispatch(
      pocketActions.updatePocketFlow({
        ...pocketFlow,
        type: action,
        pocketId,
      }),
    );

    action?.path === pocketContents()?.topup.action &&
      dispatch(
        investmentReducerActions.updateInvestmentAction(
          InvestmentAction.POCKET_TOPUP,
        ),
      );
    const { pathname } = history?.location;

    localStorage.setItem('investingPageEntryPoint', pathname);

    history.push(
      action?.path === pocketContents()?.investment.action
        ? '/plans'
        : `/pocket/${pocketId}/${action?.path}`,
    );
  };

  return (
    <div>
      {loading ? (
        <div className="text-center my-10">
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <>
          <Back
            text={'Back'}
            onClick={() => history.push('/home')}
            title={`${pocketDetails?.currency} ${'Pocket'}`}
            capitalizeEveryFirstLetterOfTheWord={false}
          />
          <div className="bg-white border-light-blue-545 py-10 border-radius-8 d-flex justify-content-around flex-wrap align-items-center mb-md-5 mb-2 pocket-balance-container">
            <div
              className="d-flex  flex-column align-items-center"
              style={{ flex: 1 }}
            >
              <span className="pocket-value text-blue">
                {pocketDetails?.balance &&
                  moneyFormat(pocketDetails?.balance?.toFixed(2))}
              </span>
              <div className="text-14 font-weight-500 mt-1">
                <span>Current Balance</span>
                <span>{`(${pocketDetails?.currency})`}</span>
              </div>
              {pocketDetails?.pendingWithdrawBalance ? (
                <PendingTransactionAmount
                  label={'Pending withdrawal'}
                  amount={pocketDetails?.pendingWithdrawBalance}
                  currency={pocketDetails?.currency}
                />
              ) : null}

              {pocketDetails?.pendingTransferAmount ? (
                <PendingTransactionAmount
                  label={'Pending top up'}
                  amount={pocketDetails?.pendingTransferAmount}
                  currency={pocketDetails?.currency}
                />
              ) : null}
            </div>
            <div className="display-sm-none">
              <img
                src={VerticalLine}
                alt="vertical line"
                style={{ zIndex: '-9999' }}
              />
            </div>
            <div
              className="d-flex justify-content-center pocket-actions-container px-5"
              style={{ flex: 1 }}
            >
              {PocketActions?.map(action => {
                return (
                  <div
                    className="cursor-pointer"
                    key={action.path}
                    onClick={() => handleOperationClick(action)}
                    style={{ marginRight: '30px' }}
                  >
                    <div className="mx-auto pocket-icons-container">
                      <img
                        key={action.name}
                        src={require(`../../../icons/PocketActions/${action.path}.svg`)}
                        alt={action}
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>

                    <div className="text-center text-13 text-sm-15 font-weight-500 mt-3">
                      {action.label}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {alert.alertMessage ? <Alert alert={alert} /> : null}

          {pocketTransactions?.transactions?.length ? (
            <div className="bg-white border-light-blue-545 border-radius-8 py-5">
              <div className="text-18 font-weight-600 px-5 pb-5">
                Transactions
              </div>
              <div className="divider"></div>
              <div className="mt-5 px-5">
                {pocketTransactions?.transactions?.length
                  ? pocketTransactions?.transactions?.map(
                      (transaction, index) => (
                        <div key={transaction?.id}>
                          <SingleTransaction
                            type={transaction?.source}
                            data={transaction}
                          />
                        </div>
                      ),
                    )
                  : null}
              </div>

              <div className="d-flex justify-content-between flex-wrap mt-10 pl-md-35 pr-md-40 px-10">
                {
                  <Pagination
                    page={pocketTransactions?.page}
                    count={pocketTransactions?.totalPages}
                    onChange={handlePageChange}
                  />
                }
              </div>
            </div>
          ) : null}
        </>
      )}

      <CommonSuccessModal
        open={show}
        setOpen={() => setShow(false)}
        content={<PermissionModalContent />}
        moreClasses={'bg-white'}
        handleButtonClick={() => setShow(false)}
        title={'Notification'}
        addCloseButton={true}
      />
    </div>
  );
};

import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_INVESTMENTS = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions`;
const FETCH_SINGLE_INVESTMENT = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions/`;
const FETCH_LINE_GRAPH = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions/historical-profit?`;
const FETCH_DIRECT_INVESTMENTS_PROFIT = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions/direct-investment-portfolio-profit`;

const fetchDirectInvestments = async () => {
  const res = await axios.get(FETCH_INVESTMENTS);
  return res;
};

const fetchSingleInvestment = async investmentId => {
  const res = await axios.get(`${FETCH_SINGLE_INVESTMENT}${investmentId}`);
  return res;
};

const fetchLineGraphContent = async (period, page, limit) => {
  const res = await axios.get(
    `${FETCH_LINE_GRAPH}timeUnit=${period}&page=${page}&limit=${limit}`,
  );
  return res;
};

// Assets, AssetClasses,ReturnTypes, Currencies
const fetchDirectInvestmentAssets = async () => {
  const res = await axios.get(`${FETCH_INVESTMENTS}/plan-name`);
  return res;
};

const fetchDirectInvestmentAssetClasses = async () => {
  const res = await axios.get(`${FETCH_INVESTMENTS}/plan-type`);
  return res;
};

const fetchDirectInvestmentReturnTypes = async () => {
  const res = await axios.get(`${FETCH_INVESTMENTS}/profit-type`);
  return res;
};

const fetchDirectInvestmentCurrencies = async () => {
  const res = await axios.get(`${FETCH_INVESTMENTS}/currency-type`);
  return res;
};

const fetchAllDirectInvestmentsProfit = async () => {
  const res = await axios.get(`${FETCH_DIRECT_INVESTMENTS_PROFIT}`);
  return res;
};

export {
  fetchDirectInvestments,
  fetchSingleInvestment,
  fetchLineGraphContent,
  fetchDirectInvestmentAssets,
  fetchDirectInvestmentAssetClasses,
  fetchDirectInvestmentReturnTypes,
  fetchDirectInvestmentCurrencies,
  fetchAllDirectInvestmentsProfit,
};

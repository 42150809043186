import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import * as profile from '../../Profile/_redux/profileRedux';
import RiskProfile from '../../Onboarding/pages/RiskProfile';
import Button from '../../Common/Button';
import InvestAndSaveIcon from '../../../icons/investBtnIcon.svg';
import useUserProfile from '../../../helpers/CustomHook/useUserProfile';
import Loader from '../../Common/Loader';

function InvestorType({ profileAction }) {
  const history = useHistory();
  const [onInvestorType, setOnInvestorType] = useState(false);
  const { investor, loading } = useSelector(state => state.profile);

  const { updateInvestorProfile, error } = useUserProfile();

  useEffect(() => {
    if (history.location.pathname === '/investor-type') {
      setOnInvestorType(true);
    }

    updateInvestorProfile();
  }, [updateInvestorProfile]);

  const toWizardBtn = (
    <div className="text-center my-10 mx-10">
      <Link
        to={{
          pathname: '/wizard/age',
          state: { prevPath: history.location.pathname },
        }}
      >
        <Button
          btnText={
            investor?.riskProfile?.alias
              ? 'Re-take test to recalculate your risk profile'
              : 'Take test to calculate your risk profile'
          }
          className="common-btn pl-13 pr-15 py-3 mb-3"
          onClick={() => localStorage.setItem('retakingTheWizard', true)}
        />
      </Link>

      <Button
        btnText={[
          <img
            className="mr-3"
            src={InvestAndSaveIcon}
            alt={'Invest and save icon'}
            key="image"
          />,
          'Invest Now',
        ]}
        className="common-btn pl-13 pr-15 py-3 ml-5"
        onClick={() => {
          localStorage.setItem('backLink', history.location.pathname);
          history.push('/goal/select-goal');
        }}
      />
    </div>
  );
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <RiskProfile
          toWizardBtn={toWizardBtn}
          onInvestorType={onInvestorType}
        />
      )}
    </>
  );
}

const mapStateToProps = state => ({
  profile: state.auth.authToken,
  userData: state.profile,
});

export default connect(mapStateToProps, { ...profile.actions })(InvestorType);

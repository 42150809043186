export const actionTypes = {
  Profile: '[Profile] Action',
  FetchProfile: '[FetchProfile] Action',
  RiskProfileLevels: '[RiskProfileLevels] Action',
};

const defaultProfileState = {
  investor: {},
  user: {},
};

export const reducer = (state = defaultProfileState, action) => {
  switch (action.type) {
    case actionTypes.Profile: {
      const { profile } = action.payload;
      return {
        ...state,
        ...profile,
      };
    }

    default:
      return state;
  }
};

export const updateRiskProfileLevelsReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.RiskProfileLevels: {
      const { riskProfileLevels } = action.payload;
      return riskProfileLevels;
    }

    default:
      return state;
  }
};

export const actions = {
  profileAction: profile => ({
    type: actionTypes.Profile,
    payload: { profile },
  }),

  fetchProfileAction: () => ({ type: actionTypes.FetchProfile }),

  riskProfileLevels: riskProfileLevels => ({
    type: actionTypes.RiskProfileLevels,
    payload: { riskProfileLevels },
  }),
};

export function* saga() {}

import React from 'react';

export const DropdownTitle = ({ title }) => {
  return (
    <div className="text-left text-12 mb-1 text-dark-gray-200">
      <span>{title}</span>
      <span className="text-red-500 ml-1">*</span>
    </div>
  );
};

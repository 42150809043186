import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import logout from '../modules/Auth/pages/Logout';

const SessionTimeout = ({ isAuthenticated }) => {
  const idleTimer = useRef(null);

  const onIdle = () => {
    if (isAuthenticated) logout();
  };

  return <IdleTimer ref={idleTimer} onIdle={onIdle} timeout={1000 * 60 * 10} />;
};

export default SessionTimeout;

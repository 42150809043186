import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { errorMessages } from '../../../helpers/constants';
import * as profile from '../../Profile/_redux/profileRedux';
import '../styles/registration.scss';
import { login } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';
import { getInputClasses } from '../../../helpers/getInputClasses';
import CustomInput from '../../Common/CustomInput';
import { PasswordInput } from '../../Common/PasswordInput';
import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import { loginSchema } from '../Schemas';
import { loginInitialValues } from '../InitialValues';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { DoYouHaveAccount } from '../Components/DoYouHaveAccount';

const Login = props => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginSchema(intl),
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      localStorage.removeItem('tokenValidated');
      enableLoading();
      try {
        const { data } = await login(
          values.email.toLowerCase(),
          values.password,
        );
        disableLoading();
        localStorage.setItem(
          'referralCompleted',
          data.user?.investors[0]?.isReferralCompleted,
        );
        props.login(data);
        props.profileAction(data.user);
      } catch (error) {
        disableLoading();
        setSubmitting(false);
        setStatus(
          error.response.data
            ? error.response.data.message
            : 'Unable to login.Try again.',
        );
      }
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}

      <ExternalPageTitle
        title="Sign In"
        subTitle="Enter your details to access your account"
      />

      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 ">
            <div className="text-center font-weight-bold text-red-500">
              {formik.status}
              {formik.status === errorMessages.VERIFY_EMAIL ? (
                <p>
                  <Link to={'/auth/resendVerification'}>
                    {' '}
                    Resend Confirmation email.
                  </Link>
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        <CustomInput
          type="email"
          placeholder="Email"
          name="email"
          formik={formik}
          getInputClasses={getInputClasses}
          inputWidth={'auth-page-input'}
        />

        <PasswordInput
          viewPassword={viewPassword}
          formik={formik}
          setViewPassword={setViewPassword}
          name={'password'}
          placeholder="Password"
          passwordContainerSpacing={'mt-8 mb-7'}
        />

        <Link
          to="/auth/resetpassword"
          className="font-weight-600 text-md-right text-left text-hover-primary btn-mobile mt-3 mr-2"
          id="kt_login_forgot"
        >
          <p className="text-blue text-13">Forgot Password</p>
        </Link>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mobile-row">
          <ConfirmButton
            buttonType={'submit'}
            buttonText="Sign in"
            loading={loading}
            disable={formik.isSubmitting}
            buttonWidth={'w-100'}
            changeText={false}
          />
        </div>

        <DoYouHaveAccount
          question="Don't have an account?"
          action={'Sign up'}
          link={'/auth/registration'}
          margin="mt-n3"
        />
      </form>
      {/*end::Form*/}
    </div>
  );
};

export default injectIntl(
  connect(null, { ...auth.actions, ...profile.actions })(Login),
);

import React from 'react';
import { ValidationErrors } from '../../Business/components/ValidationErrors';

export const DateValidation = ({ formik }) => {
  const errorMessage =
    (formik.touched.year || formik.touched.month || formik.touched.day) &&
    (formik.errors.year || formik.errors.month || formik.errors.day);

  return <ValidationErrors errorMessage={errorMessage} />;
};

import React from 'react';

export const UpgradeButton = ({ handleSubscribe }) => {
  return (
    <button
      type="submit"
      className="py-2 btn font-weight-bold px-6"
      onClick={handleSubscribe}
      style={{
        color: '#fff',
        background: 'rgb(255, 127, 48)',
        fontFamily: 'Poppins',
      }}
    >
      <span>Upgrade</span>
    </button>
  );
};

import React from 'react';

import HeaderCard from './HeaderCard';
import NextButtonFooter from './NextButtonFooter';

const Fail = ({ error }) => {
  return (
    <div className="card card-custom card-gutter-b">
      <HeaderCard headerText="Failed!" />
      <div className="card-body">
        <div className="text-center">
          <p className="text-center">Something went wrong!</p>
          <p className="text-center" style={{ color: '#F05B7F' }}>
            {error}
          </p>
        </div>
      </div>
      <NextButtonFooter url="/portfolio" />
    </div>
  );
};

export default Fail;

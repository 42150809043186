import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { connect, useSelector } from 'react-redux';
import { InvestmentAction } from '../../../../helpers/constants';

import * as profile from '../../../Profile/_redux/profileRedux';

import Cancelled from './Cancelled';
import Fail from './Fail';
import Success from './Success';
import AfterSubscription from './AfterSubscription';
import { goalTopUp, investmentInGoal } from '../../../Goal/_redux/goalActions';
import { subscribeToBlack } from '../../../Subscriptions/_redux/subscriptionActions';
import Loader from '../../../Common/Loader';
import KycReminder from '../../../KYC/pages/KycReminder';
import { getUserProfile } from '../../../Profile/_redux/profileCrud';
import { directInvestment, fundTopUp } from '../../../Plan/_redux/plansActions';

const handleGoalInvestment = async (
  id,
  textReference,
  channel,
  transactionId,
) => {
  const response = await investmentInGoal(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId),
  );
  return response;
};

const handleDirectInvestment = async (
  id,
  textReference,
  channel,
  transactionId,
) => {
  const response = await directInvestment(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId),
  );
  return response;
};

const handleFundTopUp = async (
  planSubscribtionId,
  textReference,
  channel,
  transactionId,
) => {
  const response = await fundTopUp(
    parseInt(planSubscribtionId),
    textReference,
    channel,
    parseInt(transactionId),
  );
  return response;
};

const handleGoalTopUp = async (id, textReference, channel, transactionId) => {
  const response = await goalTopUp(
    parseInt(id),
    textReference,
    channel,
    parseInt(transactionId),
  );
  return response;
};

const handleSubscribe = async (
  subscriptionId,
  txReference,
  channel,
  transactionId,
) => {
  const response = await subscribeToBlack(
    Number(subscriptionId),
    txReference,
    channel,
    Number(transactionId),
  );
  return response;
};

const {
  DIRECT_INVESTMENT,
  DIRECT_INVESTMENT_TOPUP,
  GOAL_INVESTMENT,
  GOAL_TOPUP,
  SUBSCRIPTION,
} = InvestmentAction;

const actions = [
  { name: DIRECT_INVESTMENT, invest: handleDirectInvestment },
  { name: DIRECT_INVESTMENT_TOPUP, invest: handleFundTopUp },
  { name: GOAL_INVESTMENT, invest: handleGoalInvestment },
  { name: GOAL_TOPUP, invest: handleGoalTopUp },
  { name: SUBSCRIPTION, invest: handleSubscribe },
];

const FinalPage = ({ profileAction }) => {
  const { investor } = useSelector(state => state.profile);
  const { search } = useLocation();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [subscribeToPro, setSubscribeToPro] = useState();
  const [success, setSuccess] = useState(false);
  const [redirectToFunds, setRedirectToFunds] = useState(false);
  const [redirectToGoals, setRedirectToGoals] = useState(false);

  const investmentAction = useSelector(
    state => state?.investment?.investmentAction,
  );
  const reduxGoalInvestmentDtls = useSelector(
    state => state?.goal?.goalInvestmentDetails,
  );
  const reduxPlanInvestmentDtls = useSelector(
    state => state?.plan?.planInvestmentDetails,
  );
  const reduxSubscriptionPaymentDtls = useSelector(
    state => state?.subscription?.subscriptionPaymentDetails,
  );

  const { status, tx_ref, transaction_id, cardtype } = queryString.parse(
    search,
  );

  useEffect(() => {
    let isMounted = true;

    (investmentAction === DIRECT_INVESTMENT ||
      investmentAction === DIRECT_INVESTMENT_TOPUP) &&
      isMounted &&
      setRedirectToFunds(true);
    (investmentAction === GOAL_INVESTMENT || investmentAction === GOAL_TOPUP) &&
      isMounted &&
      setRedirectToGoals(true);

    if (investmentAction === SUBSCRIPTION) setSubscribeToPro(true);

    if (!investmentAction) {
      history.push('/dashboard');
      return;
    }
    if ((status === 'cancelled' || status === 'failed') && isMounted) {
      setCancelled(true);
      if (investmentAction === SUBSCRIPTION) {
        setSubscribeToPro(investmentAction);
      }
      isMounted && setLoading(false);
      return;
    }

    const investmentId = action => {
      switch (action) {
        case SUBSCRIPTION:
          return reduxSubscriptionPaymentDtls?.subscriptionId;
        case DIRECT_INVESTMENT:
          return reduxPlanInvestmentDtls?.planId;
        case DIRECT_INVESTMENT_TOPUP:
          return reduxPlanInvestmentDtls?.planId;
        case GOAL_INVESTMENT:
          return reduxGoalInvestmentDtls?.goalId;
        case GOAL_TOPUP:
          return reduxGoalInvestmentDtls?.goalId;
        default:
          return '';
      }
    };

    const invest = async () => {
      isMounted && setLoading(true);
      actions.forEach(async item => {
        if (item.name === investmentAction) {
          const id = investmentId(investmentAction);

          try {
            const result = await item.invest(
              id,
              tx_ref,
              cardtype,
              transaction_id,
            );

            if (result.status === 201 && isMounted) {
              if (investmentAction === SUBSCRIPTION) {
                const userProfile = await getUserProfile();
                profileAction(userProfile?.data?.data);
              }
              setLoading(false);
              setSuccess(true);
            }
          } catch (err) {
            isMounted && setErrorMessage(err.response.data.message);
            isMounted && setLoading(false);
          }
        }
      });
    };

    if (status === 'successful' && investmentAction) {
      invest();
    }

    return () => {
      isMounted = false;
    };
  }, [status, tx_ref, transaction_id]);

  const handleTryAgain = () => {
    history.push(
      subscribeToPro
        ? '/subscription'
        : investmentAction === GOAL_INVESTMENT ||
          investmentAction === GOAL_TOPUP
        ? `/new-investment/investorInformation/${reduxGoalInvestmentDtls?.goalId}`
        : '/new-investment/plan-investment-payment',
    );
  };

  let history = useHistory();

  return (
    <>
      {loading ? (
        <Loader />
      ) : cancelled ? (
        <Cancelled handleTryAgain={handleTryAgain} />
      ) : errorMessage ? (
        <Fail error={errorMessage} />
      ) : success && !investor?.kycSubmittedAt ? (
        <KycReminder />
      ) : subscribeToPro ? (
        <AfterSubscription />
      ) : (
        success && (
          <Success
            redirectToFunds={redirectToFunds}
            redirectToGoals={redirectToGoals}
          />
        )
      )}
    </>
  );
};

const mapStateToProps = state => ({
  profile: state?.profile,
});

export default connect(mapStateToProps, { ...profile?.actions })(FinalPage);

import React from 'react';
import ArrowDownIcon from '../../../icons/arrow-down.svg';

const ReusableDropdown = ({
  handleOpenSelector,
  selectedOption,
  dropdownPlaceholder,
  classes,
  disabled,
}) => {
  return (
    <div
      className={`${
        disabled ? '' : 'cursor-pointer'
      } ${classes} dropdown-padding py-3 max height-45 border-radius-8 border-gray-450`}
    >
      <div
        className="d-flex w-100 justify-content-between align-items-center"
        onClick={
          handleOpenSelector && !disabled ? () => handleOpenSelector() : null
        }
      >
        {selectedOption ? (
          <div className="px-3 dropdown-selected-item text13 text-dark-550">
            {' '}
            {selectedOption}{' '}
          </div>
        ) : (
          <div className="px-3 text-13 text-dark-550">
            {' '}
            {dropdownPlaceholder}{' '}
          </div>
        )}
        <div>
          <img src={ArrowDownIcon} alt="Select Icon" />
        </div>
      </div>
    </div>
  );
};

export default ReusableDropdown;

import React from 'react';
import HorizontalLine from '../../../helpers/horizontalLine';

export const GoalSummaryItem = ({ label, value, addHorizontalLine }) => {
  return (
    <>
      <div className="d-flex justify-content-between mt-8 tex-14 font-weight-500">
        <span>{label}</span>
        <span className="text-blue">{value}</span>
      </div>

      {addHorizontalLine ? (
        <HorizontalLine color="#D9D9D9" height={0.1} width={'100%'} />
      ) : null}
    </>
  );
};

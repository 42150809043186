import React, { useState } from 'react';
import BackArrow from '../../../Common/BackArrow';
import { useHistory } from 'react-router-dom';
import './style.scss';
import deleteInvestor from '../../_redux/deactivate/deactivateAction';
import Alert from '../../../Common/Alert';
import { FullWidthWrapper } from '../../../Common/FullWidthWrapper';
import { CancelButton, ConfirmButton } from '../../../Common/ReusableButtons';
import { ButtonsContainer } from '../../../Common/ButtonsContainer';
import { ConfirmDeleteModal } from '../../../Common/ConfirmDeleteModal';

const Deactivate = () => {
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  let history = useHistory();

  const handleChange = () => {
    setIsDeactivated(!isDeactivated);
  };

  const handleBtn = () => {
    setOpen(!open);
  };

  const triggerAction = async () => {
    try {
      const res = await deleteInvestor();
      if (res.status === 200) {
        localStorage.removeItem('ndovuToken');
        history.push('/auth/login');
      }
    } catch (error) {
      setAlertOn(true);
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'alert-light-danger',
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <BackArrow
        text="Back"
        onClick={() => {
          history.goBack();
        }}
      />
      <FullWidthWrapper>
        {alertOn && <Alert alert={alert} />}
        <div className="deactivate border-light-blue-545 border-radius-8">
          <div className="content-card align-items-center">
            <div className="content-left">
              <input
                id="deactivate"
                className="checkbox-custom"
                type="checkbox"
                value={isDeactivated}
                onChange={handleChange}
                name="deactivate"
              />
            </div>
            <div className="content-right ml-5">
              <p className="deactivate-warning">
                ATTENTION - This operation cannot be reversed. You will be
                contacted by an operator within 48 hours, and all your info will
                be not recoverable.
              </p>
            </div>
          </div>

          <div className="mt-15 pb-5">
            <div className="px-md-40 px-lg-40">
              <ButtonsContainer>
                <CancelButton
                  buttonText={'Cancel'}
                  handleOnClick={() => {
                    history.push('/manage-account/security');
                  }}
                />
                <ConfirmButton
                  buttonText={'Deactivate'}
                  disable={!isDeactivated}
                  handleOnClick={handleBtn}
                />
              </ButtonsContainer>
            </div>
          </div>
        </div>
      </FullWidthWrapper>

      <ConfirmDeleteModal
        open={open}
        setOpen={setOpen}
        moreClasses={'bg-white'}
        content={
          'Are you sure you want to delete this settlement detail? This action can not be undone.'
        }
        title={'Deactivate'}
        handleButtonClick={triggerAction}
        buttonText={'Deactivate'}
      />
    </div>
  );
};

export default Deactivate;

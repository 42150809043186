import React from 'react';

import Minus from '../../../icons/Signs/Minus.svg';
import Equal from '../../../icons/Signs/Equal.svg';
import Multiplication from '../../../icons/Signs/Multiplication.svg';

export const Sign = ({ sign }) => {
  const signs = {
    minus: Minus,
    equal: Equal,
    multiplication: Multiplication,
  };
  return <img src={signs[sign]} alt={sign} />;
};

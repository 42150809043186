import React from 'react';
import { useHistory } from 'react-router-dom';

import { moneyFormat } from '../../../../helpers/moneyFormat';

export const PortfolioValueOrPocket = ({
  icon,
  label,
  amount,
  link,
  classes,
  isPocket,
}) => {
  const history = useHistory();
  return (
    <div
      className={`${classes} ${
        amount || amount === 0 ? 'cursor-pointer' : ''
      } d-flex mt-5 portfolio-value-and-pocket-container`}
      onClick={() => (amount || amount === 0) && history.push(link)}
    >
      <div className="d-flex flex-column">
        <div>
          <img src={icon} alt="Portfolio value" />
        </div>
        <div className={`font-weight-500 text-13 `}>{label}</div>
      </div>

      <div
        className={`${
          isPocket ? 'pocket-balance' : 'portfolio-value-balance'
        } w-100 d-flex justify-content-between align-items-center border-radius-20 border-light-blue-540 px-4`}
      >
        <div className="font-weight-600 text-13">
          {amount || amount === 0 ? moneyFormat(amount) : 0}
        </div>
        {amount || amount === 0 ? (
          <img
            src={`/media/svg/icons/Navigation/Arrow-right.svg`}
            alt="right arrow"
          />
        ) : null}
      </div>
    </div>
  );
};

import React from 'react';

import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';
import { ConfirmButton } from '../../Common/ReusableButtons';

export const TeamMemberOrInvite = ({ data, handleOnclick }) => {
  return (
    <div
      className={`card card-custom w-100 gutter-b bg-light-blue-200 border-light-blue-550 border-radius-3 ${
        handleOnclick ? 'cursor-pointer' : ''
      }`}
    >
      <div
        className="card-body invite-card-mobile"
        style={{ padding: '10px 17px' }}
        onClick={() => (handleOnclick ? handleOnclick(data) : null)}
      >
        <div className="d-flex flex-column auth-left text-bold-blue">
          <span className="text-dark-blue-700 text-17 font-weight-500">
            {data?.user?.firstName || data?.firstName ? (
              <span>
                {data?.user?.firstName
                  ? data?.user?.firstName
                  : data?.firstName}
              </span>
            ) : null}{' '}
            {data?.user?.lastName || data?.lastName ? (
              <span>
                {data?.user?.lastName ? data?.user?.lastName : data?.lastName}
              </span>
            ) : null}
          </span>
          <span className="text-dark-blue-700 text-12 font-weight-500">
            {data?.user?.email || data?.email ? (
              <span>{data?.user?.email ? data?.user?.email : data?.email}</span>
            ) : null}
          </span>
        </div>

        <ConfirmButton
          buttonText={capitalizeTheFirstLetterOfEachWord(data?.status)}
          buttonWidth={'width-140'}
        />
      </div>
    </div>
  );
};

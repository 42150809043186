import { useFormik } from 'formik';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { onboardingCopy } from '../../../utils/onboardingCopy';
import { sendReferralCode } from '../_redux/ReferalAction';
import * as referral from '../_redux/ReferalReducer';

import elephantLogo from '../../../images/elephantLogo.svg';
import Alert from '../../Common/Alert';
import RiskProfileImage from './RiskProfileImage';

import '../index.scss';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import useUserProfile from '../../../helpers/CustomHook/useUserProfile';

const OfferCodes = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [skipLoading, setSkipLoading] = useState(false);

  const profile = useSelector(state => state.profile);
  const user = profile?.user;

  const { updateInvestorProfile, error } = useUserProfile();

  const initialValues = {
    referralCode: '',
    voucher: '',
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const RedirectToInstructions = () => {
    history.push(
      user?.acquisitionChannel
        ? '/onboarding/instructions'
        : '/onboarding/welcome',
    );
  };

  const referalCodeSchema = Yup.object().shape({
    referralCode: Yup.string()
      .min(8, 'Minimum 8 digits')
      .max(8, 'Maximum 8 digits'),
  });

  const handleSkip = async () => {
    const referralCode = null;

    try {
      setSkipLoading(true);
      const response = await sendReferralCode(referralCode);

      if (response.status === 200) {
        setSkipLoading(false);
        localStorage.setItem('referralCompleted', true);

        updateInvestorProfile();

        RedirectToInstructions();
        return;
      } else {
        setSkipLoading(false);
        return;
      }
    } catch (error) {
      setSkipLoading(false);
      setAlertOn(true);

      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
      return;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: referalCodeSchema,
    onSubmit: async values => {
      setLoading(true);
      const referralCodeUpperCase = values.referralCode.toUpperCase();

      try {
        const response = await sendReferralCode(referralCodeUpperCase);

        if (response.status === 200) {
          setLoading(false);
          formik.resetForm();
          localStorage.setItem('referralCompleted', true);

          setAlert({
            alertMessage: response.data.message,
            alertMessageType: 'success',
          });

          updateInvestorProfile();

          setTimeout(RedirectToInstructions, 2000);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: error.response.data.message,
          alertMessageType: 'alert-light-danger',
        });
      }
    },
  });

  return (
    <>
      <div className="container text-center referal-container">
        {alertOn ? <div className="mb-5"></div> : null}

        <div>
          <div className="text-center">
            <RiskProfileImage image={elephantLogo} />

            <span className="bonus-text">{onboardingCopy?.referal?.title}</span>
            <br />

            <span className="font-weight-bolder font-size-16">
              {onboardingCopy?.referal?.subTitle}
            </span>
          </div>

          <div className="mt-3 font-size-16">
            {onboardingCopy?.referal?.description}
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
          >
            <div className="form-group fv-plugins-icon-container">
              <div className="mx-20 mt-10">
                <input
                  placeholder="Referral Code"
                  type="text"
                  className={`form-control  bg-second-white input-field ${getInputClasses(
                    'referralCode',
                  )}`}
                  name="referralCode"
                  {...formik?.getFieldProps(`${'referralCode'}`)}
                  autoComplete="off"
                  onKeyUp={() => {
                    setAlert({
                      alertMessage: null,
                      alertMessageType: null,
                    });
                  }}
                />
              </div>
            </div>

            {alert?.alertMessage ? (
              <Alert alert={alert} />
            ) : error?.alertMessage ? (
              <Alert alert={error} />
            ) : null}

            {formik.touched.referralCode && formik.errors.referralCode ? (
              <div className="fv-plugins-message-container">
                <div className="text-center fv-help-block">
                  {formik.errors.referralCode}
                </div>
              </div>
            ) : null}
            <div className="my-10 d-flex justify-content-between">
              <CancelButton
                buttonText="Skip"
                loading={skipLoading}
                handleOnClick={handleSkip}
                buttonWidth={'width-140'}
              />

              <ConfirmButton
                buttonType={'submit'}
                buttonText="Use Code"
                loading={loading}
                disable={formik.isSubmitting || !formik.values.referralCode}
                buttonWidth={'width-140'}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  referral: state.referral,
});

export default connect(mapStateToProps, referral.actions)(OfferCodes);

import React from 'react';
import { useHistory } from 'react-router-dom';
import TopCard from '../../Plan/components/PlanCard/TopCard.js';
import Card from '../../Plan/components/PlanCard/Card';

function SingleWatchList(props) {
  const history = useHistory();

  const {
    plan: { coolName, id: planId, imageUrl },
  } = props;

  return (
    <Card classes="watchlist-height card-width">
      <TopCard
        imageLink={imageUrl}
        borderRdius="top-card-border-radius"
        onClick={() => {
          history.push(`/watchlist/${planId}`);
        }}
      />

      <div className="plan-card-second-container">
        <div className="d-flex justify-content-between p-3" style={{background: '#deecf7'}}>
          <span
            className="font-weight-bold"
            style={{ fontSize: '16px', color: '#0071ce' }}
            onClick={() => {
              history.push(`/watchlist/${planId}`);
            }}
          >
            {coolName}
          </span>
          <div className="box"></div>
        </div>
      </div>
    </Card>
  );
}

export default SingleWatchList;

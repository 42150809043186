import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const GET_PAYMENT_MODAL = `${REACT_APP_BASE_URL}/v1/investor/payments/get-payment-modal`;

export const getCardPaymentModal = async (
  amount,
  currency,
  promoCode,
  channel,
  paymentType,
) => {
  const response = await axios.post(GET_PAYMENT_MODAL, {
    amount,
    currency,
    promoCode,
    cardType: channel,
    ...(paymentType && { paymentType }),
  });
  return response;
};

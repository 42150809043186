export const displayBankAccountDetails = bankAccountNumber => {
  const accountIntoString = bankAccountNumber.toString();
  const accountNumberCharacters = accountIntoString.length;
  const visibleCharacters = 4;

  if (accountNumberCharacters < 5) return accountIntoString;

  const hiddenCharacters = accountNumberCharacters - visibleCharacters;
  const maskedNumber =
    '*'.repeat(hiddenCharacters) + accountIntoString.slice(-4);

  return maskedNumber;
};

// WithdrawalAccounts.jsx
import React, { useState, useEffect } from 'react';
import Back from '../../Common/BackArrow';
import { useHistory } from 'react-router-dom';

import { actions as pocketActions } from '../../Pocket/_redux/reducers';
import AccountCards from '../components/AccountCards';
import { Note } from '../../Common/Note';
import { getApprovedSettlementDetails } from '../../Pocket/_redux/axiosRequest';
import Loader from '../../Common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';

const WithdrawalAccounts = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [settlementDetails, setSettlementDetails] = useState([]);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const pocketFlow = useSelector(state => state.pocketFlow);

  const history = useHistory();
  const dispatch = useDispatch();

  const fetchSettlementDetails = async () => {
    try {
      setLoading(true);
      const res = await getApprovedSettlementDetails();
      if (res.status === 200) {
        setSettlementDetails(res.data.data);
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettlementDetails();
  }, []);

  const handleClick = () => {
    localStorage.setItem('previousPage', history.location.pathname);
    history.push('/withdrawal-details');
  };

  const previousPage = `/pocket/${pocketFlow?.pocketId}/withdraw`;

  return (
    <>
      <Back
        title={'Withdrawal Details'}
        text={'Back'}
        onClick={() => history.push(previousPage)}
      />
      <KycPageContentWrapper>
        <div className="d-flex flex-column justify-content-center">
          {!loading ? (
            settlementDetails.length > 0 ? (
              <div className="d-flex flex-column align-items-center">
                <div className="text-18 text-center font-weight-500 withdraw-settlement-title">
                  Where should we send your money?
                </div>

                <div className="w-100 mt-5">
                  {settlementDetails?.map(item => (
                    <AccountCards
                      key={item?.id}
                      uniqueId={item?.id}
                      accountTitle={
                        item?.bankName ? item?.bankName : item?.serviceProvider
                      }
                      accountNumber={
                        item?.type === 'MOBILE_MONEY'
                          ? item?.phoneNumber
                          : item?.accountNumber
                      }
                      type={item?.type}
                      currency={item?.currency?.currency}
                      title={item?.accountName}
                      selected={selectedAccount?.id}
                      checked={item?.id === selectedAccount?.id}
                      onPress={() => setSelectedAccount(item)}
                      // disable={
                      //   item?.serviceProvider === serviceProviders.MPESA &&
                      //   convertAmountToNumber(pocketFlow?.inputOne?.amount) >
                      //     2000
                      // }
                      // withdrawalLimit={2000}
                    />
                  ))}
                </div>

                <div
                  style={{ textDecoration: 'underline' }}
                  className="ml-auto text-blue text-14 font-weight-600 mt-2 cursor-pointer"
                  onClick={handleClick}
                >
                  +Add new withdrawal details
                </div>

                <Note
                  classes="mt-5"
                  title="Note:"
                  content="The transfer of your money to your account may take up to 2 business days. Standard bank and mobile money charges may apply"
                  titleColor="text-blue"
                  contentColor={'text-dark-550'}
                  alignText="text-left"
                  backgroundColor={'bg-white'}
                  border={'border-light-blue-545'}
                />

                <ButtonsContainer justifyContent="justify-content-between">
                  <CancelButton
                    buttonText="Cancel"
                    handleOnClick={() => history.goBack()}
                    twinButton
                  />

                  <ConfirmButton
                    buttonText={'Withdraw'}
                    handleOnClick={() => {
                      dispatch(
                        pocketActions?.updatePocketFlow({
                          ...pocketFlow,
                          settlementDetails: {
                            settlementAccountCurrency:
                              selectedAccount?.currency?.currency,
                            settlementAccountNumber: selectedAccount?.accountNumber
                              ? selectedAccount?.accountNumber
                              : selectedAccount?.phoneNumber,
                            settlementBankName: selectedAccount?.bankName
                              ? selectedAccount?.bankName
                              : 'MPESA',
                          },
                        }),
                      );
                      history.push(
                        `/settlement/details/${selectedAccount?.id}`,
                      );
                    }}
                    disable={!selectedAccount}
                    twinButton
                  />
                </ButtonsContainer>
              </div>
            ) : (
              <div className="border-1-light-blue-150 border-radius-16 p-10 d-flex flex-column align-items-center">
                <div className="text-blue text-20 font-weight-500 d-flex flex-row justify-content-center">
                  You have no approved Settlement detail.
                </div>
              </div>
            )
          ) : (
            <Loader />
          )}
        </div>
      </KycPageContentWrapper>
    </>
  );
};

export default WithdrawalAccounts;

import { planType } from './constants';
import { investmentPeriodCopy } from '../utils/investmentsCopy';
import { messages } from '../utils/messages';

function getNumOfMonths(targetMonth, targetYear) {
  const presentMonth = new Date().getMonth() + 1;
  const presentYear = new Date().getFullYear();
  if (targetYear - presentYear === 0) {
    return targetMonth - presentMonth === 0 ? 1 : targetMonth - presentMonth;
  } else if (targetYear - presentYear > 0) {
    if (targetMonth > presentMonth) {
      let numOfMonths = (targetYear - presentYear) * 12;
      let diffInMonth = targetMonth - presentMonth;
      return diffInMonth + numOfMonths;
    } else {
      let numOfMonths = (targetYear - presentYear) * 12;
      let diffInMonth = presentMonth - targetMonth;
      return numOfMonths - diffInMonth;
    }
  }
}
const months = [
  { value: '01', name: 'January' },
  { value: '02', name: 'February' },
  { value: '03', name: 'March' },
  { value: '04', name: 'April' },
  { value: '05', name: 'May' },
  { value: '06', name: 'June' },
  { value: '07', name: 'July' },
  { value: '08', name: 'August' },
  { value: '09', name: 'September' },
  { value: '10', name: 'October' },
  { value: '11', name: 'November' },
  { value: '12', name: 'December' },
];

const days = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];

const SHORT_MONTHS = {
  '04': true,
  '06': true,
  '09': true,
  '11': true,
};

const getDaysInMonth = (month, year) => {
  let days = [];
  if (month === '02') {
    // Check if it's a leap year
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
      // Leap year has 29 days in February
      for (let day = 1; day <= 29; day++) {
        days.push(day.toString().padStart(2, '0'));
      }
    } else {
      // Non-leap year has 28 days in February
      for (let day = 1; day <= 28; day++) {
        days.push(day.toString().padStart(2, '0'));
      }
    }
  } else {
    // All other months have 30 or 31 days
    const lastDay = month in SHORT_MONTHS ? 30 : 31;
    for (let day = 1; day <= lastDay; day++) {
      days.push(day.toString().padStart(2, '0'));
    }
  }
  return days;
};

const getYears = () => {
  const years = [];
  for (let year = 1000; year < new Date().getFullYear() - 17; year++) {
    years.unshift(year.toString());
  }

  return years;
};
const getPresentDate = () => {
  let presentDay;
  let presentMonth;
  const day = new Date().getDate();
  const month = new Date().getMonth() + 1;
  const presentYear = new Date().getFullYear();

  if (day < 10) {
    presentDay = `0${day}`;
  } else {
    presentDay = day;
  }
  if (month < 10) {
    presentMonth = `0${month}`;
  } else {
    presentMonth = month;
  }
  return `${presentYear}-${presentMonth}-${presentDay}`;
};

const getTargetDate = (timelineYears, timelineMonths) => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const sumOfMonths = currentMonth + Number(timelineMonths);
  const targetMonth = sumOfMonths <= 12 ? sumOfMonths : sumOfMonths % 12;
  const sumOfYears =
    Number((sumOfMonths / 12).toString().split('.')[0]) + Number(timelineYears);

  const targetYear = currentYear + sumOfYears;

  return `${months[targetMonth - 1]?.name}-${targetYear}`;
};

function convertDate(timestamp) {
  let date = new Date(timestamp);
  let month;
  let day;
  if (date.getMonth() + 1 < 10) {
    month = `0${date.getMonth() + 1}`;
  } else {
    month = date.getMonth() + 1;
  }
  if (date.getDate() < 10) {
    day = `0${date.getDate()}`;
  } else {
    day = date.getDate();
  }
  return `${date.getFullYear()}-${month}-${day}`;
}

const checkRiskProfileColor = {
  'Low risk': 'success',
  'Medium risk': 'warning',
  'High risk': 'danger',
};

const firstLetterUpperCase = string => {
  if (string) {
    return string?.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  } else {
    return null;
  }
};

const capitalizeTheFirstLetterOfEachWord = words => {
  const separateWord = words?.toLowerCase()?.split(' ');
  if (separateWord) {
    separateWord.map(
      (eachWord, i) =>
        (separateWord[i] =
          eachWord?.length > 1
            ? separateWord[i].charAt(0).toUpperCase() +
              separateWord[i].substring(1)
            : separateWord[i].charAt(0) + separateWord[i].substring(1)),
    );
    return separateWord.join(' ');
  }
  return '';
};

const investmentPeriod = years => {
  let investmentPeriodContent;

  if (years < 3 && years >= 0) {
    investmentPeriodContent = investmentPeriodCopy[0];
  } else if (years > 2 && years < 6) {
    investmentPeriodContent = investmentPeriodCopy[1];
  } else {
    investmentPeriodContent = investmentPeriodCopy[2];
  }

  return investmentPeriodContent;
};

const getTargetYearsOptions = () => {
  let currentYear = new Date().getFullYear();
  const options = [currentYear];
  for (let i = 0; i <= 60; i++) {
    currentYear = currentYear + 1;
    options.push(currentYear);
  }

  return options;
};

const popoverMessage = planTypeValue => {
  return planTypeValue === planType.CASH
    ? messages.cashDescription
    : planTypeValue === planType.BOND
    ? messages.bondDescription
    : planTypeValue === planType.EQUITY
    ? messages.equityDescription
    : '';
};

export {
  getNumOfMonths,
  getPresentDate,
  getTargetDate,
  days,
  getDaysInMonth,
  months,
  getYears,
  convertDate,
  checkRiskProfileColor,
  firstLetterUpperCase,
  capitalizeTheFirstLetterOfEachWord,
  investmentPeriod,
  getTargetYearsOptions,
  popoverMessage,
};

import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '30%',
    height: '90vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const SelectOptionsModal = ({
  open,
  handleSelect,
  handleClose,
  allCountries,
  selectedCountry,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [filteredCountries, setFilteredCountries] = useState();

  useEffect(() => {
    selectedCountry &&
      allCountries &&
      allCountries.forEach((item, i) => {
        if (item.name === selectedCountry?.name) {
          allCountries.splice(i, 1);
          allCountries.unshift(item);
        }
      });
    setFilteredCountries(allCountries);
  }, [allCountries, selectedCountry]);

  const handleChange = event => {
    const { value } = event.target;
    const searchResults = allCountries.filter(country =>
      country.name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredCountries(searchResults);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <input
          onChange={handleChange}
          type="text"
          name="searchCountry"
          className="border-gray-450 px-3 height-45 border-radius-8 w-100 mb-5"
          placeholder="Search"
          autoComplete="off"
        />
        {filteredCountries?.map(country => (
          <div
            className="d-flex justify-content-between mt-1 cursor-pointer"
            onClick={() => handleSelect(country)}
            key={country.name}
          >
            <div>
              {country?.flag ? (
                <span className={`flag-icon ${country?.flag}`}></span>
              ) : null}
              <span
                style={{
                  color:
                    selectedCountry?.name === country.name ? '#0071CE' : '',
                }}
                className="ml-2"
              >
                {country?.name}
              </span>
            </div>
            {selectedCountry?.name === country.name ? (
              <i
                className="fa fa-check"
                style={{ color: '#0071CE', fontSize: '12px' }}
                aria-hidden="true"
              ></i>
            ) : null}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default SelectOptionsModal;

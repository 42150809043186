import React from 'react';

import Document from './Document';
import rightArrow from '../../../../icons/rightArrow.svg';
import linksIcon from '../../../../icons/linksIcon.svg';
import { PlanDetailsCard } from '../PlanDetailsCard';

const Documents = ({ factSheet, prospectus, isFractionalStock }) => {
  return (
    <PlanDetailsCard title={'Links to Documents'}>
      <div className="d-flex flex-column documents-width p-5 center-container">
        {factSheet ? (
          <Document
            factSheet={factSheet}
            iconA={linksIcon}
            iconB={rightArrow}
            name={isFractionalStock ? 'Tear Sheet' : 'Fact Sheet PDF'}
          />
        ) : null}
        {prospectus && !isFractionalStock ? (
          <Document
            prospectus={prospectus}
            iconA={linksIcon}
            iconB={rightArrow}
            name="Prospectus"
          />
        ) : null}
      </div>
    </PlanDetailsCard>
  );
};

export default Documents;

import investmentIconActive from '../../../../../app/icons/LeftMenuIcons/investmentIconActive.svg';
import investmentIcon from '../../../../../app/icons/LeftMenuIcons/investmentIcon.svg';
import watchlistIconActive from '../../../../../app/icons/LeftMenuIcons/watchlistIconActive.svg';
import watchlistIcon from '../../../../../app/icons/LeftMenuIcons/watchlistIcon.svg';
import portfolioIconActive from '../../../../../app/icons/LeftMenuIcons/portfolioIconActive.svg';
import portfolioIcon from '../../../../../app/icons/LeftMenuIcons/portfolioIcon.svg';
import goalIconActive from '../../../../../app/icons/LeftMenuIcons/GoalIconActive.svg';
import goalIcon from '../../../../../app/icons/LeftMenuIcons/GoalIcon.svg';
import homeIconActive from '../../../../../app/icons/LeftMenuIcons/HomeIconActive.svg';
import homeIcon from '../../../../../app/icons/LeftMenuIcons/HomeIcon.svg';

const home = {
  title: 'Home',
  name: 'home',
  id: 'home',
  path: '/home',
  icon: { white: homeIcon, active: homeIconActive },
  active: false,
  hover: false,
};

const invest = {
  title: 'Invest',
  name: 'new-investment',
  id: 'new-investment',
  path: '/plans',
  icon: { white: investmentIcon, active: investmentIconActive },
  active: false,
  hover: false,
};

const goal = {
  title: 'Goals',
  name: 'goals',
  id: 'goals',
  path: '/goals',
  icon: { white: goalIcon, active: goalIconActive },
  active: false,
  hover: false,
};

const watchlist = {
  title: 'Watchlist',
  name: 'watchlist',
  id: 'watchlist',
  path: '/watchlist',
  icon: { white: watchlistIcon, active: watchlistIconActive },
  active: false,
  hover: false,
};

const portfolio = {
  title: 'Portfolio',
  name: 'portfolio',
  id: 'portfolio',
  path: '/portfolio',
  icon: { white: portfolioIcon, active: portfolioIconActive },
  active: false,
  hover: false,
};

export const menuList = [home, invest, goal, watchlist, portfolio];

export const bottomMenuItems = [
  {
    ...home,
    id: 'bottom-dashboard',
  },
  {
    ...invest,
    id: 'bottom-new-investment',
  },
  {
    ...goal,
    id: 'bottom-goals',
  },
  {
    ...watchlist,
    id: 'bottom-watchlist',
  },
  {
    ...portfolio,
    id: 'bottom-portfolio',
  },
];

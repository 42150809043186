import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import VerifyBusinessDocuments from './VerifyBusinessDocuments';
import CreateBusinessOrChama from './CreateBusinessOrChama';
import AdditionalDetails from './AdditionalDetails';
import BusinessOverview from './BusinessOverview';
import SelectAccount from './SelectAccount';
import KycProgressBar from '../../BusinessChama/helpers/KycProgressBar';
import Welcome from './Welcome';
import PageBottomContent from '../../../helpers/pageBottomContent';

const Index = ({ match }) => {
  const [stepperData, setStepperData] = useState([
    { id: 1, text: 'Documentation', done: false },
    { id: 2, text: 'Additional', done: false },
    { id: 3, text: 'Confirmation', done: false },
  ]);

  const profile = useSelector(state => state.profile);

  const handleStepChange = id => {
    const updateStep = data => {
      return data.map(step => {
        let doneValue;
        if (id?.next) {
          doneValue = true;
        }

        return step.id <= id?.next
          ? { ...step, done: doneValue }
          : step.id <= id?.previous
          ? { ...step, done: false }
          : step;
      });
    };
    setStepperData(updateStep(stepperData));
  };

  const pageUrlsWithNoPageIndicators = [
    '/investor-information',
    '/investor-information/summary',
    '/investor-information/select-account-type',
    '/investor-information/create-account',
  ];

  const renderKycRouter = () => {
    return (
      <div className="mt-5">
        {pageUrlsWithNoPageIndicators.includes(
          window.location.pathname,
        ) ? null : (
          <KycProgressBar stepperData={stepperData} />
        )}
        <Switch>
          <Route
            exact
            path={`${match.path}`}
            render={props => <Welcome profile={profile} {...props} />}
          />
          <Route
            exact
            path={`${match.path}/create-account`}
            render={props => (
              <CreateBusinessOrChama
                profile={profile}
                handleStepChange={handleStepChange}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/documentation`}
            render={props => (
              <VerifyBusinessDocuments
                handleStepChange={handleStepChange}
                id={1}
                profile={profile}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/additional`}
            render={props => (
              <AdditionalDetails
                handleStepChange={handleStepChange}
                id={2}
                profile={profile}
                {...props}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/overview`}
            render={props => (
              <BusinessOverview
                handleStepChange={handleStepChange}
                id={3}
                profile={profile}
                {...props}
                businessOverViewPage={true}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/summary`}
            render={props => (
              <BusinessOverview
                handleStepChange={handleStepChange}
                profile={profile}
                investorInformationSummaryPage={true}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`${match.path}/select-account-type`}
            render={props => <SelectAccount profile={profile} {...props} />}
          />
        </Switch>
      </div>
    );
  };

  return <>{renderKycRouter()}</>;
};

export default Index;

import * as device from '../../_redux/devices/deviceReducers';

import React, { useEffect, useState } from 'react';
import {
  fetchInvestorDevices,
  removeDevices,
} from '../../_redux/devices/devicesAction';

import Alert from '../../../Common/Alert';
import Device from './Device';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import Back from '../../../Common/BackArrow';
import { FullWidthWrapper } from '../../../Common/FullWidthWrapper';
import { SettingsPageHeaderSection } from '../SettingsPageHeaderSection';

const Devices = ({ deviceAction, devices }) => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  useEffect(() => {
    const getDevices = async () => {
      try {
        const {
          data: { data },
        } = await fetchInvestorDevices();
        deviceAction(data);
      } catch (error) {
        setAlertOn(true);
        setAlert({
          alertMessage: 'Device was not deleted, please try again',
          alertMessageType: 'alert-light-danger',
        });
      }
    };
    getDevices();
  }, [deviceAction]);

  const handleDelete = async id => {
    Swal.fire({
      text: 'Are you sure you want to delete this device?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await removeDevices(id);
          const {
            data: { data },
          } = await fetchInvestorDevices();
          deviceAction(data);
        } catch (error) {
          setAlertOn(true);
          setAlert({
            alertMessage: 'Device was not deleted, please try again',
            alertMessageType: 'error',
          });
        }
      }
    });
  };
  return (
    <>
      <Back text="Back" title={'Devices'} />
      <FullWidthWrapper>
        <SettingsPageHeaderSection
          title={'Devices'}
          subtitle={
            'These are the devices currently allowed to access your account'
          }
        />

        {alertOn && <Alert alert={alert} />}
        {devices && devices.length > 0 ? (
          devices.map(device => (
            <div key={device.id}>
              <Device device={device} handleDelete={handleDelete} />
              {devices.length > 1 && <div className="border-top my-3"></div>}
            </div>
          ))
        ) : (
          <div>No Device Found</div>
        )}
      </FullWidthWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  devices: state.devices.devices,
});

export default connect(mapStateToProps, { ...device.actions })(Devices);

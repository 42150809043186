import jwtDecode from 'jwt-decode';

const checkToken = () => {
  const token = localStorage.getItem('ndovuToken');
  if (token && token !== null) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem('ndovuToken');
      return false;
    }
    return true;
  } else {
    return false;
  }
};

const isTokenValidated = () => {
  const isValidated = localStorage.getItem('tokenValidated');
  if (isValidated && isValidated === 'true') {
    return true;
  } else {
    return false;
  }
};

export { checkToken, isTokenValidated };

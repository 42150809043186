import React from 'react';

export const Note = ({
  classes,
  title,
  content,
  titleColor,
  contentColor,
  alignText,
  backgroundColor,
  border,
}) => {
  return (
    <div
      className={`${classes} ${alignText ? alignText : 'text-center'} ${
        backgroundColor ? backgroundColor : 'bg-orange-150'
      } ${
        border ? border : 'border-primary-orange'
      } text-12 text-primary-orange border-radius-8 mt-10 px-5 py-2`}
    >
      <div className={`font-weight-600 text-13 ${titleColor}`}>{title} </div>{' '}
      <div className={`${contentColor}`}> {content}</div>
    </div>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';

const SubscriptionCurrency = ({
  togglePaymentBtns,
  setPaymentCurrency,
  paymentCurrency,
}) => {
  let history = useHistory();

  const prices = history.location.state?.prices;

  const handleRadioClick = e => {
    setPaymentCurrency(e.target.name);
    togglePaymentBtns(e.target.name !== 'KES', e.target.name === 'KES');
  };

  return (
    <div className="text-center">
      <div
        className="px-5 my-5 font-weight-bolder"
        style={{ fontSize: '1.2em' }}
      >
        Pick currency
      </div>
      <div className="d-flex commission justify-content-center">
        <div className="form-group d-flex flex-wrap justify-content-center p-2">
          {prices?.map(price => (
            <label
              className="cursor-pointer d-flex fix-margin-left py-3 px-5 "
              style={{ background: '#EEF2F7', borderRadius: '5px' }}
              key={price.currency}
            >
              <div>
                <div>
                  <input
                    type="radio"
                    onChange={e => handleRadioClick(e)}
                    name={price.currency}
                    checked={paymentCurrency === price.currency}
                    className="mt-1 mr-2"
                  />
                  <span style={{ color: '#0071CE', fontWeight: '500' }}>
                    {price.currency}
                  </span>
                </div>
              </div>
            </label>
          ))}

          <span />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCurrency;

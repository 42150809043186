import React from 'react';

const NumberOfRows = ({ handleChangeLimit, totalRecord, name }) => {
  return (
    <select
      name={name}
      className="btn btn-light-primary font-weight-bold mb-5"
      style={{ paddingTop: '10px', paddingBottom: '10px' }}
      onChange={handleChangeLimit}
    >
      <option value="10">Number of Rows</option>
      <option value="10">10</option>
      {totalRecord > 25 ? <option value="25">25</option> : ''}
      {totalRecord > 50 ? <option value="50">50</option> : ''}
      {totalRecord > 100 ? <option value="100">100</option> : ''}
    </select>
  );
};

export default NumberOfRows;

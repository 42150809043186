import React from 'react';

export const CurrentPlan = () => {
  return (
    <span
      style={{
        color: 'rgb(255, 127, 48)',
        fontFamily: 'Poppins',
        fontWeight: 700,
      }}
    >
      Current Plan
    </span>
  );
};

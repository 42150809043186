export const loginInitialValues = {
  email: '',
  password: '',
};

export const signupInitialValues = userDetails => {
  const { firstName, lastName, email, phoneNumber } = userDetails;

  return {
    firstName: firstName ? firstName : '',
    lastName: lastName ? lastName : '',
    email: email ? email : '',
    password: '',
    changePassword: '',
    acceptTerms: false,
    phoneNumber,
  };
};

import React from 'react';
import { PlanFeature } from './components/PlanFeature';

export const BasicSubscriptionList = () => {
  return (
    <>
      <PlanFeature
        title={'Easy access to global markets'}
        boxBackground={'#FF7F30'}
        boxColor={'rgb(222, 236, 247)'}
      />

      <PlanFeature
        title={'Your very own low cost personalized portfolio'}
        boxBackground={'#FF7F30'}
        boxColor={'rgb(222, 236, 247)'}
      />

      <PlanFeature
        title={'Free automated advice'}
        boxBackground={'#FF7F30'}
        boxColor={'rgb(222, 236, 247)'}
      />

      <PlanFeature
        title={'Free financial education'}
        boxBackground={'#FF7F30'}
        boxColor={'rgb(222, 236, 247)'}
      />
    </>
  );
};

import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import KycProgressBar from '../../KYC/helpers/KycProgressBar';
import UploadDocuments from '../../Management/pages/UploadDocuments';
import '../components/index.scss';
import Information from './Information';
import Instructions from './Instructions';
import InvestorDetails from './InvestorDetails';
import Summary from './Summary';
import UploadIdAndFacePhoto from './UploadIdAndFacePhoto';

const Index = ({ match, profile }) => {
  const { kycLevel, pendingKyc } = profile.investor;

  const [stepperData, setStepperData] = useState([
    { id: 1, text: 'Investor ID', done: false },
    { id: 2, text: 'ID Scan', done: false },
    { id: 3, text: 'Face Validation', done: false },
    { id: 4, text: 'Investor Info', done: false },
  ]);

  const [stepperDataStageTwo, setStepperDataStageTwo] = useState([
    { id: 1, text: 'Residential Details', done: false },
    { id: 2, text: 'Tax Info', done: false },
  ]);

  const [
    stepperDataFromStageOneToThree,
    setStepperDataFromStageOneToThree,
  ] = useState([
    { id: 1, text: 'Investor ID', done: false },
    { id: 2, text: 'ID Scan', done: false },
    { id: 3, text: 'Face Validation', done: false },
    { id: 4, text: 'Investor Info', done: false },
    { id: 5, text: 'Documents', done: false },
  ]);

  const levelOne = kycLevel?.level === 1;
  const levelThree = kycLevel?.level === 3 && pendingKyc === 1;
  const fromLevelTwoToThree = kycLevel?.level > 1 && pendingKyc?.length >= 6;

  const handleStepChange = id => {
    const updateStep = data => {
      return data.map(step => {
        let doneValue;
        if (id?.next) {
          doneValue = true;
        }

        return step.id <= id?.next
          ? { ...step, done: doneValue }
          : step.id <= id?.previous
          ? { ...step, done: false }
          : step;
      });
    };
    levelOne
      ? setStepperData(updateStep(stepperData))
      : fromLevelTwoToThree
      ? setStepperDataFromStageOneToThree(
          updateStep(stepperDataFromStageOneToThree),
        )
      : setStepperDataStageTwo(updateStep(stepperDataStageTwo));
  };

  const pagesWithNoPageIndicator = levelThree
    ? ['/kyc-information', '/summary', '/kyc-information/upload-documents']
    : ['/kyc-information', '/kyc-information/summary'];

  return (
    <>
      {!pagesWithNoPageIndicator.includes(window.location.pathname) ? (
        <KycProgressBar
          stepperData={
            levelOne
              ? stepperData
              : fromLevelTwoToThree
              ? stepperDataFromStageOneToThree
              : stepperDataStageTwo
          }
        />
      ) : null}
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          render={props => <Instructions profile={profile} {...props} />}
        />

        <Route
          exact
          path={`${match.path}/information`}
          render={props => (
            <Information
              profile={profile}
              {...props}
              id={1}
              handleStepChange={handleStepChange}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/investor-documents`}
          render={props => (
            <UploadIdAndFacePhoto
              handleStepChange={handleStepChange}
              id={2}
              profile={profile}
              {...props}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/investor-details`}
          render={props => (
            <InvestorDetails
              handleStepChange={handleStepChange}
              id={fromLevelTwoToThree || levelOne ? 4 : 1}
              profile={profile}
              {...props}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/upload-documents`}
          render={props => (
            <UploadDocuments
              handleStepChange={handleStepChange}
              id={fromLevelTwoToThree ? 5 : 2}
              profile={profile}
              {...props}
            />
          )}
        />

        <Route
          exact
          path={`${match.path}/summary`}
          render={props => (
            <Summary
              profile={profile}
              {...props}
              submittedInvestorSummaryPage={true}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default Index;

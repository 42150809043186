import axios from 'axios';
import dotenv from 'dotenv';
import { saveAs } from 'file-saver';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const DIRECT_INVESTMENT = `${REACT_APP_BASE_URL}/v1/investor/direct-investments`;
const FETCH_TRANSACTIONS = `${REACT_APP_BASE_URL}/v1/investor/transactions`;
const GENERATE_PDF = `${REACT_APP_BASE_URL}/v1/investor/transactions/investment-statement`;

const directInvestment = async (goalAmount, currency, targetDate, planId) => {
  const res = await axios.post(DIRECT_INVESTMENT, {
    amount: goalAmount,
    currency,
    investmentDate: targetDate,
    planId,
  });
  return res;
};

const fetchTransactions = async (investmentType, page, limit) => {
  const res = await axios.get(
    `${FETCH_TRANSACTIONS}/${investmentType}?page=${page}&limit=${limit}`,
  );
  return res;
};

const downloadPdf = async payload => {
  const response = await axios.get(GENERATE_PDF, {
    params: payload,
    responseType: 'arraybuffer', // Setting the response type to 'arraybuffer'
  });

  // Create a Blob from the response data
  const pdfToBlob = new Blob([response.data], { type: 'application/pdf' });

  // Use file-saver to save the Blob as 'statements.pdf' file
  saveAs(pdfToBlob, 'statements.pdf');
};

export { directInvestment, fetchTransactions, downloadPdf };

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { kycStatuses } from '../../../helpers/constants';
import { handleKycRouting } from '../../../helpers/handleKycRouting';
import './KycReminder.scss';

const FixedKycReminder = ({ handleOnclick }) => {
  const { investor } = useSelector(state => state.profile);
  const { kycLevel, kycStatus, pendingKyc, investorType } = investor;
  const [isHovered, setIsHovered] = useState(false);

  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };

  const history = useHistory();

  const kycReminderMessage = {
    left: {
      [kycStatuses.PENDING]: 'User information required',
      [kycStatuses.REJECTED]: 'Your Documents were rejected',
    },
    right: {
      [kycStatuses.PENDING]: 'Tap here to complete',
      [kycStatuses.REJECTED]: 'View Reason',
    },
  };

  const handleClick = () => {
    if (handleOnclick && kycStatus === kycStatuses.REJECTED) {
      handleOnclick();
      return;
    }

    localStorage.setItem('previousPage', history.location.pathname);

    history.push(handleKycRouting(kycChecks, '/manage-account', investorType));
  };

  return kycStatus === kycStatuses.PENDING ||
    kycStatus === kycStatuses.REJECTED ? (
    <div
      className="kyc-reminder right-0 mb-5"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="kyc-reminder-text">
        {kycReminderMessage.left[kycStatus]}
      </span>
      <div className="d-flex align-items-center">
        <span
          onClick={handleClick}
          className="mr-2 kyc-reminder-text cursor-pointer"
        >
          {kycReminderMessage.right[kycStatus]}
        </span>
        <span className="kyc-icon">
          <SVG
            src={`/media/svg/ndovu/${
              isHovered ? 'bell-blue-icon' : 'bell-icon'
            }.svg`}
          />
        </span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default FixedKycReminder;

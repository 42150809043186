import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { ConfirmButton } from './ReusableButtons';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '455px',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down(868)]: {
      width: '90%',
    },
  },
}));

export const CommonSuccessModal = ({
  open,
  setOpen,
  content,
  moreClasses,
  handleButtonClick,
  addCloseButton,
  btnText,
  title,
  buttons,
  showIcon = true,
  iconName,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const body = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${moreClasses} border-radius-8 pt-5 pb-8 px-10`}
    >
      {addCloseButton ? (
        <div
          onClick={setOpen}
          className="bg-blue text-white border-radius-3 font-weight-500 ml-auto cursor-pointer"
          style={{
            paddingBottom: '1px',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          x
        </div>
      ) : null}
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className="w-100 modal-summary-header text-blue font-weight-600 text-center">
          {title ? title : 'Success'}
        </div>

        {showIcon ? (
          <div className="my-10">
            <SVG
              className="mb-1 mr-5"
              src={toAbsoluteUrl(
                `/media/svg/ndovu/${iconName ? iconName : 'thumbsup'}.svg`,
              )}
              style={{ width: '72px', height: '72px' }}
            />
          </div>
        ) : null}
      </div>

      <div className="modal-summary-body text-center">{content}</div>

      {buttons ? (
        buttons
      ) : (
        <ConfirmButton
          buttonText={btnText ? btnText : 'OK'}
          classes={
            'd-flex justify-content-center align-items-center mx-auto mt-10'
          }
          handleOnClick={handleButtonClick}
          buttonWidth={'w-100'}
        />
      )}
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => addCloseButton && setOpen()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

import React from 'react';

import './input.scss';

export default function Input({
  placeholder,
  name,
  type,
  formik,
  className,
  defaultValue,
  handleChange,
  disabled,
  id,
}) {
  return (
    <input
      id={id}
      placeholder={placeholder}
      type={type}
      className={`${className}  border-gray-450`}
      name={name}
      {...formik?.getFieldProps(`${name}`)}
      value={defaultValue || ''}
      onChange={handleChange}
      autoComplete="off"
      disabled={disabled}
    />
  );
}

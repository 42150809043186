import React from 'react';
import CapitalMarketAuthority from '../dashboard-images/CMA.png';
import DataProtectionCommission from '../dashboard-images/dataProtectionCommission.png';
import { Titles } from '../../Common/Titles';

const DashboardCompanyLogos = ({ dashboardCompanyClass }) => {
  return (
    <div className={`p-5 mt-3`}>
      <Titles title={'Regulated by'} classes="text-center" />

      <div className="d-flex justify-content-center">
        <div className={`${dashboardCompanyClass} image-size`}>
          <img
            src={CapitalMarketAuthority}
            alt="Capital Market Authority"
            width={'100%'}
            height={'100%'}
          />
        </div>

        <div className={`${dashboardCompanyClass} image-size`}>
          <img
            src={DataProtectionCommission}
            alt="Data Protection Commission"
            width={'100%'}
            height={'100%'}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardCompanyLogos;

import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { downloadUsersFile } from '../../Profile/_redux/profileCrud';
import DocumentsIcon from '../../../icons/document.svg';

export const DisplayDocuments = ({ file, label, setAlert }) => {
  const handleOpeningFile = async link => {
    downloadUsersFile(link)
      .then(response => {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      })
      .catch(() => {
        setAlert('Failed to download this document');
      });
  };

  const smallDevice = useMediaQuery('(max-width : 768px)');

  return (
    <div>
      {file ? (
        <div className="d-flex justify-content-between align-items-center mb-5">
          <div className="d-flex">
            <img src={DocumentsIcon} alt="Documents Icon" />
            <div
              className={`additional-documents ${
                smallDevice ? 'text-13' : 'text-14'
              } ml-2`}
            >
              {label}
            </div>
          </div>

          <div
            className={`${
              smallDevice ? 'text-13' : 'text-14'
            } ml-2 font-weight-500 text-blue cursor-pointer`}
            onClick={() => handleOpeningFile(file)}
          >
            View
          </div>
        </div>
      ) : null}
    </div>
  );
};

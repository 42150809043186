import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AllPlans from './pages/Plans';
import PlanDetails from './pages/PlanDetails';
import SavingPlans from './pages/SavingAccounts';
import PocketOperations from '../Pocket/pages/PocketOperations';

const Plan = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/`} component={AllPlans} />
        <Route
          exact
          path={`${match.path}/fixed-return`}
          component={SavingPlans}
        />
        <Route exact path={`${match.path}/:id`} component={PlanDetails} />
        <Route
          exact
          path={`${match.path}/fixed-return/:id`}
          component={PlanDetails}
        />
        <Route
          exact
          path={`${match.path}/initialInvestmentAmount/:id`}
          component={PocketOperations}
        />
        <Route
          exact
          path={`${match.path}/topup/:id`}
          component={PocketOperations}
        />
      </Switch>
    </>
  );
};

export default Plan;

import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { handleKycRouting } from '../../../helpers/handleKycRouting';
import Button from '../../Common/Button';
import MainWrapper from '../../Pocket/components/MainWrapper';

const KycReminder = () => {
  const { kycLevel, kycStatus, pendingKyc, investorType } = useSelector(
    state => state.profile.investor,
  );

  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };
  const history = useHistory();

  return (
    <MainWrapper>
      <div>
        <p className="py-10 text-center">
          Please complete your profile by providing us with your details
          <Link to={handleKycRouting(kycChecks, '/manage-account')}>
            {' '}
            here.
          </Link>{' '}
          As per the law, we are required to verify your identity before
          processing any transactions.
        </p>
      </div>

      <div className="text-center">
        <Button
          btnText="Complete KYC"
          className="text-center common-btn btn-size"
          onClick={() =>
            history.push(
              handleKycRouting(kycChecks, '/manage-account', investorType),
            )
          }
        />
        <p className="mt-2 cursor-pointer">
          <Link to={'/home'}>Remind me later</Link>
        </p>
      </div>
    </MainWrapper>
  );
};

export default KycReminder;

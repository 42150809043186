/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ProgressBar from './progress-bar/ProgressBar';
import Selector from './Selector';
import { ageChoices } from '../helpers/choices';
import { progressBarWidth } from '../../../utils/progressBarWidth';
import { routes } from '../../../utils/routes';
import { wizardCopy } from '../../../utils/wizardCopy';

import './../index.scss';

const AgeWizardPage = () => {
  let history = useHistory();
  const [wizardData, setWizardData] = useState(null);

  useEffect(() => {
    const localStorageWizard = JSON.parse(localStorage.getItem('wizard'));
    setWizardData(localStorageWizard);
  }, []);

  const clickChoice = (e, id) => {
    const elementsWithAnim = document.querySelectorAll('.animated-card');
    elementsWithAnim.forEach(each => {
      if (each !== e.target) {
        return each.classList.add('animate__animated', 'animate__fadeOutRight');
      } else {
        return each.classList.add(
          'animate__animated',
          'animate__pulse',
          'bg-info-blue',
        );
      }
    });
    e.target.addEventListener('animationend', () => {
      localStorage.setItem(
        'wizard',
        JSON.stringify({ ...wizardData, age: id }),
      );
      history.push(routes.wizard.purpose);
    });
  };
  return (
    <>
      <div className="container">
        <ProgressBar width={progressBarWidth()} />
        <div className="card-body p-0">
          <Selector
            clickChoice={clickChoice}
            choices={ageChoices}
            id={wizardData?.age}
            item={wizardData?.age}
            prev={routes.wizard.home}
            next={routes.wizard.purpose}
            action={wizardCopy.age.action}
          />
        </div>
      </div>
    </>
  );
};

export default AgeWizardPage;

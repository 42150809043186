export const ageChoices = [
  {
    id: 1,
    choice: '25',
    optionChoice: 'or younger',
  },
  {
    id: 2,
    choice: '26 to 39',
  },
  {
    id: 3,
    choice: '40 to 49',
  },
  {
    id: 4,
    choice: '50',
    optionChoice: 'or older',
  },
];

export const purposeChoices = [
  {
    id: 1,
    choice: 'Retirement',
  },
  {
    id: 2,
    choice: 'Housing or Land',
  },
  {
    id: 3,
    choice: 'Children Education',
  },
  {
    id: 4,
    choice: 'Long term investment growth',
  },
  {
    id: 5,
    choice: 'Nothing specific',
  },
];

export const monthlyIncomeChoices = [
  {
    id: 1,
    choice: '<50k',
  },
  {
    id: 2,
    choice: '51 - 149k',
  },
  {
    id: 3,
    choice: '150 - 249k',
  },
  {
    id: 4,
    choice: '>250k',
  },
];

export const monthlySavingsChoices = [
  {
    id: 1,
    choice: '<10k',
  },
  {
    id: 2,
    choice: '10 - 29k',
  },
  {
    id: 3,
    choice: '30 - 49k',
  },
  {
    id: 4,
    choice: '>50k',
  },
];

export const timeFrameChoices = [
  {
    id: 1,
    choice: '<3 years',
    optionChoice: 'e.g. car, holiday',
  },
  {
    id: 2,
    choice: '3 - 5 years',
    optionChoice: 'e.g. buying a house, wedding',
  },
  {
    id: 3,
    choice: '5+ years',
    optionChoice: 'e.g. university fees, retirement',
  },
];

export const tenPercentInvestmentLossChoices = [
  {
    id: 1,
    choice: 'Invest more',
  },
  {
    id: 2,
    choice: 'Wait',
  },
  {
    id: 3,
    choice: 'Sell some',
  },
  {
    id: 4,
    choice: 'Sell everything',
  },
];

export const familiarInvestmentsChoices = [
  {
    id: 1,
    choice: 'Real Estate',
  },
  {
    id: 2,
    choice: 'Bonds',
  },
  {
    id: 3,
    choice: 'Unit Funds or Exchange Trade Funds (ETFs)',
  },
  {
    id: 4,
    choice: 'Shares',
  },
  {
    id: 5,
    choice: 'Crypto/FX',
  },
];

export const cashSavingsChoices = [
  {
    id: 1,
    choice: 'No cash savings',
  },
  {
    id: 2,
    choice: '1 - 3 months',
  },
  {
    id: 3,
    choice: '3+ months',
  },
];

export const investmentExperienceChoices = [
  {
    id: 1,
    choice: 'I am new to investing',
  },
  {
    id: 2,
    choice: '1 - 4 years',
  },
  {
    id: 3,
    choice: '5+ years',
  },
];

export const investmentAttitudeChoices = [
  {
    id: 1,
    choice: 'Safe and long term growth',
  },
  {
    id: 2,
    choice: `Some risks, if it means 
              potential for higher returns`,
  },
  {
    id: 3,
    choice: 'More risk for better growth opportunities',
  },
];

import React from 'react';
import { PortfolioValueOrPocket } from '../PortfolioValueOrPocket';
import portfolioValueIcon from '../../../../icons/dashboard/portfolioValue.svg';
import pocketIcon from '../../../../icons/dashboard/pocket.svg';

export const PortfolioValueAndPocket = ({
  currency,
  portfolioValue,
  pocketBalance,
  pocketId,
  flag,
}) => {
  return (
    <div className="min-width-289 border-light-blue-540 px-5 pt-5 pb-7 border-radius-20">
      <div className="d-flex align-items-center">
        <div className="card-flag border-radius-5">
          <img
            className="border-radius-5"
            src={flag}
            alt="country flag"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>

        <div className="ml-3 font-weight-600 text-13">{currency}</div>
      </div>

      <PortfolioValueOrPocket
        icon={portfolioValueIcon}
        label={'Portfolio'}
        amount={portfolioValue}
        link={'/portfolio'}
      />

      <PortfolioValueOrPocket
        icon={pocketIcon}
        label={'Pocket'}
        amount={pocketBalance}
        link={`/pocket/${pocketId}`}
        isPocket={true}
      />
    </div>
  );
};

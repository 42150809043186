import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Investments from './pages/Investments';
import ViewInvestment from './pages/ViewInvestment';

const Portfolio = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={Investments} />
        <Route
          exact
          path={`${match.path}/viewInvestment/:planSubscriptionId`}
          component={ViewInvestment}
        />
      </Switch>
    </>
  );
};

export default Portfolio;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GoalCalculator from './pages/GoalCalculator';
import GoalSummary from './pages/GoalSummary';
import NewGoal from './pages/NewGoal';
import SelectGoalList from './pages/SelectGoalList';
import Goals from './pages/Goals';
import ViewGoal from '../Portfolio/pages/ViewGoal';
import PocketOperations from '../Pocket/pages/PocketOperations';

const Goal = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/goals`} component={Goals} />
        <Route
          exact
          path={`${match.path}/select-goal`}
          component={SelectGoalList}
        />
        <Route exact path={`${match.path}/new`} component={NewGoal} />
        <Route
          exact
          path={`${match.path}/calculator`}
          component={GoalCalculator}
        />
        <Route exact path={`${match.path}/summary`} component={GoalSummary} />
        <Route
          exact
          path={`${match.path}/topup/:id`}
          component={PocketOperations}
        />
        <Route exact path={`${match.path}/viewGoal/:id`} component={ViewGoal} />
      </Switch>
    </>
  );
};

export default Goal;

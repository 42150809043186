import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchInvestorDevices } from './devicesAction';

export const actionTypes = {
  DeviceAction: '[DeviceAction] Action',
  FetchDevice: '[FetchDevice] Action',
};

const initialAuthState = {
  devices: null,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.DeviceAction: {
      const { devices } = action.payload;
      return {
        ...state,
        devices,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  deviceAction: devices => ({
    type: actionTypes.DeviceAction,
    payload: { devices },
  }),

  fetchDevicesAction: () => ({ type: actionTypes.FetchDevice }),
};

export function* saga() {
  yield takeLatest(actionTypes.FetchDevice, function* fetchDevicesAction() {
    const {
      data: { data },
    } = yield call(fetchInvestorDevices);
    yield put(actions.deviceAction(data));
  });
}

import React from 'react';
import CongratulationContent from '../../../Common/Button/CongratulationContent';

import HeaderCard from './HeaderCard';
import NextButtonFooter from './NextButtonFooter';

const Success = () => {
  return (
    <div className="card card-custom card-gutter-b">
      <HeaderCard headerText="Notification!" />
      <div className="card-body">
        <div className="px-4 py-4 mt-2 py-md-5 px-md-0">
          <CongratulationContent />
        </div>
      </div>
      <NextButtonFooter url="/portfolio" />
    </div>
  );
};

export default Success;

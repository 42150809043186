import { Link } from 'react-router-dom';
import React from 'react';
import { marketingSiteLinks } from './marketingSiteLinks';

const AppFooter = ({ interanl }) => {
  return (
    <footer
      className="text-center font-weight-bold mt-5"
      style={{ color: '#0071CE' }}
    >
      <span style={{ color: '#0071CE' }}>
        &copy; {new Date().getFullYear()}
      </span>{' '}
      ndovu <span>&#10072;</span>{' '}
      <span>
        <a
          style={{ color: '#0071CE' }}
          href={marketingSiteLinks.privacy}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
      </span>{' '}
      <span style={{ color: '#0071CE' }}>&#10072;</span>{' '}
      <span>
        <a
          style={{ color: '#0071CE' }}
          href={marketingSiteLinks.termsAndConditions}
          target="_blank"
          rel="noopener noreferrer"
        >
          Legal
        </a>
      </span>{' '}
      <span style={{ color: '#0071CE' }}>&#10072;</span>{' '}
      <span>
        <a
          style={{ color: '#0071CE' }}
          href={marketingSiteLinks.contactUs}
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact
        </a>
      </span>{' '}
      {interanl && (
        <div className="mr-5 font-weight-bold d-inline-block ml-30">
          <Link style={{ color: '#0071CE' }} to="/wizard">
            Wizard
          </Link>
        </div>
      )}
    </footer>
  );
};

export default AppFooter;

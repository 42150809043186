import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import { Buttons } from '../Pocket/components/Buttons';
import Alert from './Alert';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '450px',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down(868)]: {
      width: '90%',
    },
  },
}));

export const ConfirmDeleteModal = ({
  open,
  setOpen,
  content,
  moreClasses,
  handleButtonClick,
  addCloseButton,
  loading,
  error,
  title,
  buttonText,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const body = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${moreClasses} border-radius-4 py-10 px-5`}
    >
      <div className="d-flex justify-content-start align-items-center flex-column">
        <div>
          <SVG
            className="mb-1"
            src={toAbsoluteUrl(`/media/svg/ndovu/delete-on-popup.svg`)}
            style={{ width: '72px', height: '72px' }}
          />
        </div>

        <div>
          <div className="text-red-600 font-weight-600 text-20 text-center mt-5">
            {title ? title : 'Delete'}
          </div>
          <div className="modal-summary-body text-16 text-center mt-8">
            {content}
            {error?.alertMessage ? (
              <div className="mt-3">
                <Alert alert={error} />{' '}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-center my-5"
        style={{ gap: '10px' }}
      >
        <Buttons
          btnText={'Cancel'}
          classes={
            'd-flex justify-content-center px-15 bg-white border-light-blue-600 text-blue font-weight-500 text-13 rounded py-2 mx-auto cursor-pointer mt-4 ml-3'
          }
          handleOnClick={() => setOpen(false)}
        />
        <Buttons
          btnText={buttonText ? buttonText : 'Delete'}
          classes={
            'd-flex justify-content-center px-15 bg-red-600 border-0 text-white font-weight-500 text-13 rounded py-2 mx-auto cursor-pointer mt-4 mr-3'
          }
          handleOnClick={() => handleButtonClick()}
          loading={loading}
          disable={loading}
        />
      </div>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => addCloseButton && setOpen()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

import { put, takeLatest } from 'redux-saga/effects';

export const actionTypes = {
  FetchBankList: '[FetchBankList] Action',
};

export const initialState = {
  banksList: null,
};

export const settlementDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FetchBankList: {
      const { payload } = action
      return {...state, payload };
    }

    default: {
      return state;
    }
  }
};

export const actions = {
  fetchBankList: data => ({
    type: actionTypes.FetchBankList,
    payload: { data },
  }),
};

export function* saga() {}
import React from 'react';

import './index.scss';

const LittleCicle = ({ backgroundColor }) => {
  return (
    <span
      style={{ background: `${backgroundColor}` }}
      className={`little-cicle`}
    ></span>
  );
};

export default LittleCicle;

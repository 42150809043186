import React from 'react';
import { NavLink } from 'react-router-dom';

export default function MenuItem({
  getMenuItemActive,
  path,
  handleHover,
  handleMouseOut,
  closeLeftMenuAsideInMobile,
  hoverBackground,
  icon,
  name,
  menuName,
  paddingBottom,
  linkTextStyles,
}) {
  return (
    <li
      className={`menu-item ${hoverBackground} ${getMenuItemActive(
        path,
        false,
      )}`}
      aria-haspopup="true"
      onMouseOver={() => handleHover(name)}
      onMouseOut={() => handleMouseOut(name)}
    >
      <NavLink
        onClick={closeLeftMenuAsideInMobile}
        to={path}
        className={`menu-link ${paddingBottom} ${hoverBackground}`}
      >
        <span id="transaction-icon" className="svg-icon menu-icon">
          <img
            src={icon}
            alt="icon"
            width={25}
            height={25}
            style={{ objectFit: 'contain' }}
          />
        </span>
        <span
          id={name}
          className={`menu-text ${
            linkTextStyles ? 'bottom-menu-text-color' : 'menu-text-color'
          } ${getMenuItemActive(path, false)}`}
        >
          {menuName}
        </span>
      </NavLink>
    </li>
  );
}

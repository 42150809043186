import React from 'react';

export const pocketContents = data => {
  return {
    withdraw: {
      title: 'Pocket Withdrawal',
      inputLabel: 'How much do you want to withdraw?',
      congratulationsModalContent: (
        <div>
          <span>We have received your withdrawal request.</span>{' '}
          <span className="font-weight-bolder">
            {data?.inputOne?.currency} {data?.inputOne?.amount}
          </span>{' '}
          will be deposited into your account in 2 business days
        </div>
      ),
      buttonText1: 'WITHDRAW FROM POCKET',
      buttonText2: 'CANCEL',
      buttonClasses1: 'bg-blue border-0 text-white',
      buttonClasses2: 'bg-light-blue-540 border-light-gray-540 text-blue',
      inputName: 'inputOne',
      action: 'withdraw',
      transactionIcon: 'withdraw',
      actionIcon: 'withdraw',
      permission: 'WALLET_WITHDRAWAL_AND_CONVERSION',
      modalIconName: 'thumbsup',
    },

    topup: {
      title: 'Pocket Top up',
      inputLabel: 'How much do you want to top up?',
      currencyPlaceholder: 'Top up Currency',
      congratulationsModalContent: (
        <div>
          <span>Your top up instruction of</span>{' '}
          <span className="font-weight-bolder">
            {data?.inputOne?.currency} {data?.inputOne?.amount}
          </span>{' '}
          is currently being processed.
        </div>
      ),
      buttonText1: 'Next',
      buttonText2: 'SELECT PAYMENT',
      buttonClasses1: 'bg-blue border-0 text-white',
      buttonClasses2: 'bg-white border-1-blue text-blue',
      inputName: 'inputOne',
      action: 'topup',
      transactionIcon: 'topup',
      actionIcon: 'topup',
      permission: 'POCKET_TOPUP',
      modalIconName: 'sand-glass',
      buttonIcon: 'investment',
    },

    conversion: {
      title: 'Convert',
      inputLabel: 'How much do you want to Convert?',
      currencyPlaceholderFrom: 'From',
      currencyPlaceholderTo: 'To',
      inputName: 'inputOne',
      inputName2: 'inputTwo',
      buttonText1: 'CONVERT NOW',
      buttonClasses1: 'bg-blue border-0 text-white',
      buttonClasses2: 'bg-white border-1-blue text-blue',
      congratulationsModalContent: (
        <div>
          <span>You have successfully converted</span>{' '}
          <span className="font-weight-bolder">
            {data?.inputOne?.currency} {data?.inputOne?.amount}
          </span>{' '}
          to{' '}
          <span className="font-weight-bolder">
            {data?.inputTwo?.currency} {data?.inputTwo?.amount}{' '}
          </span>
          into your {data?.inputTwo?.currency} ndovu pocket.
        </div>
      ),
      paymentModeButton: 'PAY TO CONVERT',
      action: 'conversion',
      transactionIcon: 'conversion',
      actionIcon: 'conversion',
      permission: 'WALLET_WITHDRAWAL_AND_CONVERSION',
      modalIconName: 'thumbsup',
    },

    investment: {
      title: 'Fund Investment',
      fundTopupTitle: 'Fund Top up',
      inputLabel: 'How much do you want to invest today?',
      fundTopupInputLabel: 'How much do you want to top up today?',
      congratulationsModalContent: (
        <div>
          <span>You have successfully deposited</span>{' '}
          <span className="font-weight-bolder">
            {' '}
            {data?.inputOne?.currency} {data?.inputOne?.amount}{' '}
          </span>
          <span>
            into{' '}
            <span className="font-weight-bolder">{`${data?.planName}`}</span>{' '}
            Fund
          </span>
        </div>
      ),
      buttonText1: 'Invest now',
      buttonClasses1: 'bg-blue border-0 text-white',
      buttonClasses2: 'bg-white border-1-blue text-blue',
      inputName: 'inputOne',
      paymentModeButton: 'NEXT',
      action: 'investment',
      transactionIcon: 'investment',
      actionIcon: 'investment',
      permission: 'INVEST_AND_DISINVEST',
      buttonIcon: 'investment',
      modalIconName: 'thumbsup',
    },

    disinvestment: {
      transactionIcon: 'disinvestment',
      permission: 'INVEST_AND_DISINVEST',
      modalIconName: 'thumbsup',
    },

    settlementDetails: {
      title: 'Withdraw Settlement Details',
    },

    refund: {
      transactionIcon: 'refund',
    },

    dividendinterest: {
      transactionIcon: 'dividend-interest',
    },

    goal: {
      title: 'Goal Top up',
      inputLabel: 'How much do you want to top up?',
      actionIcon: 'investment',
      buttonText1: 'NEXT',
      inputName: 'inputOne',
      action: 'goal',
      paymentModeButton: 'NEXT',
      congratulationsModalContent: (
        <div>
          <span>You have successfully deposited</span>{' '}
          <span className="font-weight-bolder">
            {data?.inputOne?.currency} {data?.inputOne?.amount}
          </span>{' '}
          <span>
            into{' '}
            <span className="font-weight-bolder">{`${data?.goalName}`}</span>{' '}
            Goal
          </span>
        </div>
      ),
      modalIconName: 'thumbsup',
    },
  };
};

import React from 'react';
import { useHistory } from 'react-router-dom';

const PromoPlan = ({ plan }) => {
  const history = useHistory();
  return (
    <div className="g-col-6 card card-custom card-stretch promo-investment-card">
      <div
        onClick={() => history.push(`/plans/${plan.id}`)}
        style={{
          width: '100%',
          cursor: 'pointer',
          borderRadius: '5px',
          marginBottom: '10px',
          height: '330px',
        }}
      >
        <img
          src={plan.imageUrl}
          alt="plan img"
          height="100%"
          width="100%"
          style={{ borderRadius: '6px' }}
        ></img>
      </div>
    </div>
  );
};

export default PromoPlan;

import React, { useEffect, useState } from 'react';

const PaymentMethodRadioButton = ({
  label,
  processingDays,
  activePaymentMethod,
  name,
  setActivePaymentMethod,
  fee,
  disable,
  insufficientFunds,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    setPaymentMethod(activePaymentMethod);
  }, [activePaymentMethod]);

  return (
    <div
      className={`mb-3 p-5 rounded ${
        insufficientFunds
          ? 'bg-light-blue-250'
          : disable
          ? 'bg-gray-550'
          : `${
              paymentMethod === name ? 'bg-light-blue-40' : 'bg-white'
            } border-light-blue-545 border-radius-8`
      }`}
    >
      <div className="w-100 d-flex justify-content-between align-items-start">
        <label className="cursor-pointer d-flex">
          <div>
            <div
              className={
                insufficientFunds ? 'custom-radio-error' : 'custom-radio'
              }
            >
              <input
                type="radio"
                onChange={e => setActivePaymentMethod(e.target.name)}
                name={name}
                checked={paymentMethod === name}
                className="mt-1"
                disabled={disable}
              />

              <div className="d-flex align-items-start check-box-label-container">
                <span className="radio-indicator">
                  <span className="radio-indicator-box"></span>
                </span>
                <span
                  className={`text-14 ${
                    disable
                      ? 'text-white'
                      : insufficientFunds && 'text-second-orange'
                  } title-spacing font-weight-600 text-14`}
                >
                  {label}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <div className="sub-title-spacing">
                {fee ? <span className="d-block">{fee}</span> : null}
                <span
                  className={`d-block text-11 ${
                    insufficientFunds
                      ? 'text-second-orange'
                      : disable
                      ? 'text-white font-weight-500'
                      : 'text-dark-450'
                  }`}
                >
                  {insufficientFunds
                    ? `Insufficient Funds: (${processingDays})`
                    : processingDays}
                </span>
              </div>
            </div>
          </div>
        </label>
      </div>
      <span />
    </div>
  );
};

export default PaymentMethodRadioButton;

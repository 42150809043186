import React, { useEffect, useState } from 'react';

import Activity from './Activity';
import fetchInvestorActivities from '../../_redux/activities/activitiesAction';
import Pagination from '@material-ui/lab/Pagination';
import NumberOfRows from '../../../Common/SelectNumberOfRows';
import Back from '../../../Common/BackArrow';
import { FullWidthWrapper } from '../../../Common/FullWidthWrapper';
import { SettingsPageHeaderSection } from '../SettingsPageHeaderSection';

const Activities = () => {
  const [activities, setActivities] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const getActivities = async () => {
      const res = await fetchInvestorActivities(page, limit);
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setActivities(res.data.items);
    };
    getActivities();
  }, []);

  const handleChangePage = async (event, value) => {
    const res = await fetchInvestorActivities(value, limit);
    setPage(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setActivities(res.data.items);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    const res = await fetchInvestorActivities(1, selectedValue);
    setLimit(selectedValue);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setActivities(res.data.items);
    }
  };
  return (
    <>
      <Back text="Back" />
      <FullWidthWrapper>
        <SettingsPageHeaderSection
          title={'Activities'}
          subtitle={'All Activities'}
        />

        {activities && activities.length > 0 ? (
          activities.map(activity => (
            <div key={activity.id}>
              <Activity activity={activity} />
              {activities.length > 1 && <div className="border-top my-3"></div>}
            </div>
          ))
        ) : (
          <div>No Activity found</div>
        )}

        <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
          {totalPages && totalPages > 0 ? (
            <Pagination
              page={page}
              count={totalPages}
              onChange={handleChangePage}
            />
          ) : (
            ''
          )}
          {totalRecord && totalRecord > 10 ? (
            <NumberOfRows
              handleChangeLimit={handleChangeLimit}
              totalRecord={totalRecord}
            />
          ) : (
            ''
          )}
        </div>
      </FullWidthWrapper>
    </>
  );
};

export default Activities;

import React from 'react';
import DocumentsIcon from '../../../icons/document.svg';
import { openUploadedFileInNewWindow } from '../../../helpers/openUploadedFileIntoNewTab';

export const UploadedFile = ({
  loading,
  handleOpeningFile,
  imageUrl,
  fileLabel,
  uploadedFile,
}) => {
  return (
    <div
      className="font-weight-bold custom-input mt-0"
      style={{ color: '#F05B7F' }}
    >
      {loading ? (
        <i
          style={{ color: '#0071CE' }}
          className="fa fa-spinner fa-pulse fa-lg fa-fw"
        />
      ) : null}
      {uploadedFile && !loading ? (
        <div className="d-flex flex-row align-items-center">
          <img src={DocumentsIcon} alt="Documents Icon" />
          <div className="additional-documents text-dark-gray-200 text-13 font-weight-500 ml-3">
            {fileLabel}
            <span
              className="text-blue cursor-pointer pl-3"
              onClick={() => openUploadedFileInNewWindow(uploadedFile)}
            >
              (View)
            </span>
          </div>
        </div>
      ) : imageUrl && !loading ? (
        // TODO: CREATE A COMPONENT
        <div className="d-flex flex-row align-items-center">
          <img src={DocumentsIcon} alt="Documents Icon" />
          <div className="additional-documents text-dark-gray-200 text-13 font-weight-500 ml-3">
            {fileLabel}
            <span
              className="text-blue cursor-pointer pl-3"
              onClick={() => handleOpeningFile(imageUrl)}
            >
              (View)
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

import React from 'react';
import { PayWithBankTransferCardsWrapper } from './PayWithBankTransferCardsWrapper';
import { DetailsItem } from '../../../Common/DetailsItem';

const BankDetails = ({ account }) => {
  return (
    <PayWithBankTransferCardsWrapper>
      <div className="account-container bg-white  border-radius-9">
        <div className="text-12">
          <DetailsItem label={'Account Number'} value={account.accountNumber} />

          <DetailsItem label={'Account name'} value={account.accountName} />

          <DetailsItem label={'Bank name'} value={account.bankName} />

          <DetailsItem label={'Bank branch'} value={account.bankBranch} />

          <DetailsItem label={'Swift Code'} value={account.swiftCode} />

          <DetailsItem
            label={'Address'}
            value={
              account?.address
                ? account.address
                : 'ABC Place, Waiyaki Way, Nairobi'
            }
          />

          <DetailsItem label={'Email'} value={'operations@ndovu.co'} />
        </div>
      </div>
    </PayWithBankTransferCardsWrapper>
  );
};

export default BankDetails;

import React from 'react';
import './index.scss';

const PaymentButtons = ({
  setPayWithMpesa,
  payWithCreditCard,
  loading,
  currency,
  setAlertOn,
  setAlert,
  subscribing,
  mpesaDisabled,
  cardDisabled,
}) => {
  const handlePayWithMpesaClick = () => {
    if (!subscribing && currency !== 'KES') {
      setAlertOn(true);
      setAlert({
        alertMessage: 'MPESA allows KSH only',
        alertMessageType: 'error',
      });
      return;
    }
    setPayWithMpesa(true);
  };

  return (
    <div className="mt-5 text-center">
      {mpesaDisabled ? (
        <div className="d-flex commission justify-content-center">
          <div
            className={`btn-pay py-3 px-9`}
            style={{ background: '#EEF2F7', marginRight: '75px' }}
          >
            Pay with Mpesa{' '}
          </div>
        </div>
      ) : (
        <div className="d-flex commission justify-content-center">
          <div
            onClick={() => handlePayWithMpesaClick()}
            className={`btn-pay py-3 px-9 cursor-pointer`}
            style={{ marginRight: '75px' }}
          >
            Pay with Mpesa{' '}
          </div>
        </div>
      )}

      {cardDisabled ? (
        <div className="my-5 d-flex commission justify-content-center">
          <span
            className={`btn-pay fix-margin-left py-3 px-5 `}
            style={{ fontSize: '14px', background: '#EEF2F7' }}
          >
            Pay with Credit Card{' '}
            {loading && <span className="mx-3 spinner spinner-white" />}
          </span>
          <span className="mt-4 ml-4">2.9% fees</span>
        </div>
      ) : (
        <div className="my-5 d-flex commission justify-content-center">
          <span
            onClick={() => payWithCreditCard()}
            className={`btn-pay fix-margin-left py-3 px-5 cursor-pointer`}
            style={{ fontSize: '14px' }}
          >
            Pay with Credit Card{' '}
            {loading && <span className="mx-3 spinner spinner-white" />}
          </span>
          <span className="mt-4 ml-4">2.9% fees</span>
        </div>
      )}
    </div>
  );
};

export default PaymentButtons;

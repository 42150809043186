import React from 'react';
import { accountTypes, operationType } from '../../../helpers/constants';
import { currencyHelper } from '../../../helpers/currencyHelper';
import PopoverComponent from '../../Common/PopoverComponent';
import TransactionPopup from '../../Common/Popup/transactionPopup';

import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';
import { TransactionStatusBadge } from './TransactionStatusBadge';

export const TransactionsTableData = ({ transaction, investorType }) => {
  return (
    <tr className="text-13">
      <td style={{ minWidth: '120px' }}>
        {transaction?.createdAt ? transaction?.createdAt.split('T')[0] : '---'}
      </td>

      <td style={{ minWidth: '130px' }}>
        {transaction?.planSubscription?.plan?.coolName
          ? transaction?.planSubscription?.plan?.coolName
          : '---'}
      </td>

      <td style={{ minWidth: '150px' }}>
        {transaction?.planSubscription?.plan?.fundManager?.fundname
          ? transaction?.planSubscription?.plan?.fundManager?.fundname
          : '---'}
      </td>

      <td style={{ minWidth: '175px' }}>
        {transaction?.transactionType ? transaction?.transactionType : '---'}
      </td>

      <td style={{ minWidth: transaction?.goal?.name ? '175px' : '' }}>
        {transaction?.goal?.name ? transaction?.goal?.name : '---'}
      </td>

      <td style={{ minWidth: '175px' }}>
        {transaction?.convertedFinalAmount && transaction?.finalCurrency
          ? currencyHelper(
              transaction?.convertedFinalAmount,
              transaction?.finalCurrency,
            )
          : '---'}
      </td>

      <td style={{ minWidth: '150px' }}>
        {transaction?.operationType
          ? firstLetterUpperCase(transaction?.operationType)
          : '---'}
      </td>

      {investorType !== accountTypes.INDIVIDUAL ? (
        <>
          <td style={{ minWidth: '150px' }}>
            {transaction.initiatedBy ? (
              <>
                {firstLetterUpperCase(transaction.initiatedBy?.firstName)}{' '}
                {firstLetterUpperCase(transaction.initiatedBy?.lastName)}
              </>
            ) : (
              '---'
            )}
          </td>

          <td style={{ minWidth: '150px' }}>
            {transaction.initiatedBy ? transaction.initiatedBy?.email : '---'}
          </td>
        </>
      ) : null}

      <td style={{ minWidth: '200px' }}>
        {transaction?.transactionStatus ? (
          <TransactionStatusBadge
            transactionStatus={transaction?.transactionStatus}
          />
        ) : (
          '---'
        )}
      </td>

      {transaction?.operationType === operationType.DEPOSIT && (
        <td>
          <PopoverComponent popoverContent={TransactionPopup(transaction)}>
            <span style={{ cursor: 'pointer' }}>
              <i
                style={{
                  color: '#0071CE',
                }}
                className="ki ki-bold-more-hor"
                aria-hidden="true"
              ></i>
            </span>
          </PopoverComponent>
        </td>
      )}
    </tr>
  );
};

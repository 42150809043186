import React from 'react';
import { ValidationErrors } from '../../Business/components/ValidationErrors';

export const FieldErrorMessage = ({ errorExist, fieldValue, errorMessage }) => {
  return (
    <div className="mt-2">
      {errorExist && (!fieldValue || fieldValue?.length === 0) ? (
        <ValidationErrors errorMessage={errorMessage} />
      ) : null}
    </div>
  );
};

import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { currencyHelper } from '../../../helpers/currencyHelper';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { actions as withdrawalActions } from '../_redux/portfolioReducer';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '680px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down(868)]: {
      width: '90%',
    },
  },
}));

const AmountBasedWithdrawModal = ({
  open,
  setOpenAmountWidthdrawModal,
  title,
  currency,
  minWithdrawableAmount,
  setOpenConfirmModal,
  multiplesWithdrawableAmount,
  setwithdrawalAmount,
  fundValue,
  totalUnits,
  withdrawTheWholeAmount,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [minWithdrawAmountAchieved, setMinWithdrawAmountAchieved] = useState(
    false,
  );

  const dispatch = useDispatch();

  const handleAmountChange = event => {
    const { value } = event.target;
    const withdrawAmount = Number(value);
    setWithdrawAmount(withdrawAmount);
    setwithdrawalAmount(withdrawAmount);

    if (withdrawAmount >= minWithdrawableAmount) {
      setMinWithdrawAmountAchieved(true);
    } else {
      setMinWithdrawAmountAchieved(false);
    }
  };

  const openConfirmationModal = () => {
    const unitPercentage = (withdrawAmount / fundValue) * 100;
    const withdrawalDetails = {
      unitPercentage: withdrawTheWholeAmount ? 100 : unitPercentage?.toFixed(2),
      numberOfUnits: withdrawTheWholeAmount
        ? totalUnits
        : Number(((unitPercentage * totalUnits) / 100).toFixed(2)),
      transactionFee: 0,
      approximatedValue: withdrawTheWholeAmount
        ? fundValue
        : currencyHelper(withdrawAmount, currency),
    };

    dispatch(withdrawalActions.updateWithdrawalDetails(withdrawalDetails));
    setOpenAmountWidthdrawModal(false);
    setOpenConfirmModal(true);
  };

  const displayError = () => {
    const subString = '* minimum withdrawable amount is ';
    return (
      <div className="my-5 withdrw-modal-err">
        {subString}
        {currencyHelper(minWithdrawableAmount, currency)}
      </div>
    );
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="d-flex justify-content-between pt-8 pb-5 px-5">
        <div className="w-100 text-center">
          <div className="text-14 font-weight-600" id="simple-modal-title">
            {title}
          </div>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => setOpenAmountWidthdrawModal(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M13.4 12L19.7 5.7C20.1 5.3 20.1 4.7 19.7 4.3C19.3 3.9 18.7 3.9 18.3 4.3L12 10.6L5.7 4.3C5.3 3.9 4.7 3.9 4.3 4.3C3.9 4.7 3.9 5.3 4.3 5.7L10.6 12L4.3 18.3C4.1 18.5 4 18.7 4 19C4 19.6 4.4 20 5 20C5.3 20 5.5 19.9 5.7 19.7L12 13.4L18.3 19.7C18.5 19.9 18.7 20 19 20C19.3 20 19.5 19.9 19.7 19.7C20.1 19.3 20.1 18.7 19.7 18.3L13.4 12Z"
              fill="#3E3E3E"
            />
          </svg>
        </div>
      </div>

      <div className="divider"></div>

      {!minWithdrawAmountAchieved && withdrawAmount ? displayError() : ''}
      <div className="mb-5 px-5" id="simple-modal-description">
        <div className="mt-10">
          <>
            <div className="d-flex flex-row align-items-center justify-content-center mt-5 pb-5">
              <input
                onChange={handleAmountChange}
                placeholder="Amount"
                className="form-control input-field"
                type="text"
                style={{
                  width: '90%',
                }}
              />
              <div className="ml-2">{currency}</div>
            </div>
          </>

          {multiplesWithdrawableAmount > 1 ? (
            <p className="mt-3 ml-3 mr-3 justify-content-start">
              <b>Note:</b>
              {` The amount withdrawn should be in multiples of ${currencyHelper(
                multiplesWithdrawableAmount,
                currency,
              )}`}
            </p>
          ) : null}
        </div>

        <ButtonsContainer justifyContent="justify-content-between">
          <CancelButton
            buttonText="Cancel"
            handleOnClick={() => {
              setOpenAmountWidthdrawModal(false);
            }}
            twinButton
          />
          <ConfirmButton
            buttonText="Continue"
            handleOnClick={
              minWithdrawAmountAchieved ? openConfirmationModal : null
            }
            twinButton
          />
        </ButtonsContainer>
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpenAmountWidthdrawModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default AmountBasedWithdrawModal;

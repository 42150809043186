import './ProgressBar.scss';

import React from 'react';

const ProgressBar = ({ width, loggedIn, classes }) => {
  return (
    <div className="row mx-md-5 mx-lg-5 mx-0 mt-lg-10 mt-md-10">
      <div className="col-xl-12">
        <div className={`progress ${classes}`} style={{ height: '13px' }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${!loggedIn ? width : '100'}%`,
              background: '#FF7F30',
            }}
            aria-valuenow={width}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;

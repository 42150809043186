const initialGoalActionState = {
  investmentAction: '',
};

export const actionTypes = {
  UpdateInvestmentAction: '[UpdateInvestmentAction] Action',
};

export const reducer = (state = initialGoalActionState, action) => {
  switch (action.type) {
    case actionTypes.UpdateInvestmentAction: {
      const investmentAction = action.payload;
      return { ...state, investmentAction };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  updateInvestmentAction: investmentAction => ({
    type: actionTypes.UpdateInvestmentAction,
    payload: investmentAction,
  }),

  resetInvestmentAction: () => ({
    type: actionTypes.UpdateInvestmentAction,
    payload: '',
  }),
};

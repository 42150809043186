import React, { useMemo } from 'react';
import { useHtmlClassService } from '../../_core/MetronicLayout';

import AppFooter from '../../../../app/utils/appFooter';

export function Footer() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true),
    };
  }, [uiService]);

  return (
    <div
      className={`${layoutProps.footerContainerClasses} d-flex mt-4 bg-white-400 flex-column flex-md-row align-items-center justify-content-between`}
      id="kt_footer">
      <div
        className={`ml-4 d-flex mt-4 bg-white-400 flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <AppFooter internal />
        </div>
      </div>
    </div>
  );
}

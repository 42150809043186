import React from 'react';
import {
  FundType,
  ProfitType,
  sameCurrencyConversion,
} from '../../../helpers/constants';
import { operationType as OperationType } from '../../../helpers/constants';

const TransactionPopup = (
  {
    conversion,
    appliedConversionRate,
    originalCurrency,
    units,
    operationType,
    paymentRecord,
  },
  plan,
) => {
  const paymentChannel = paymentRecord?.paymentLinkMetadata?.paymentChannel;
  const discount = paymentRecord?.paymentLinkMetadata?.discount;

  return (
    <div>
      <table style={{ marginBottom: '0px' }} className="table">
        {conversion &&
          conversion !== sameCurrencyConversion.KES_TO_KES &&
          conversion !== sameCurrencyConversion.USD_TO_USD &&
          conversion !== sameCurrencyConversion.RWF_TO_RWF &&
          conversion !== sameCurrencyConversion.EUR_TO_EUR &&
          conversion !== sameCurrencyConversion.GBP_TO_GBP &&
          conversion !== sameCurrencyConversion.TZS_TO_TZS && (
            <tr>
              <td className="text-12">
                <span class="text-bold-blue">Conversion</span>
              </td>
              <td className="text-12">{conversion?.replace(/_/g, ' ')}</td>
            </tr>
          )}

        {conversion &&
          conversion !== sameCurrencyConversion.KES_TO_KES &&
          conversion !== sameCurrencyConversion.USD_TO_USD &&
          conversion !== sameCurrencyConversion.RWF_TO_RWF &&
          conversion !== sameCurrencyConversion.EUR_TO_EUR &&
          conversion !== sameCurrencyConversion.GBP_TO_GBP &&
          conversion !== sameCurrencyConversion.TZS_TO_TZS && (
            <tr>
              <td className="text-12">
                <span class="text-bold-blue">Conversion Rate</span>
              </td>
              <td className="text-12">{appliedConversionRate?.toFixed(6)}</td>
            </tr>
          )}

        {discount > 0 && (
          <tr>
            <td className="text-12">
              <span class="text-bold-blue">Discount</span>
            </td>
            <td className="text-12">
              {discount?.toFixed(2)} {originalCurrency}
            </td>
          </tr>
        )}

        {units > 0 &&
        plan?.profitType === ProfitType.VARIABLE &&
        plan?.fundType !== FundType.SAVE &&
        !plan?.isMoneyMarketFund ? (
          <tr>
            <td className="td-font-size-11">
              <span class="text-bold-blue">Units</span>
            </td>
            <td className="td-font-size-11">{units.toFixed(4)}</td>
          </tr>
        ) : null}

        {operationType === OperationType.DEPOSIT && (
          <tr>
            <td className="text-12">
              <span class="text-bold-blue">Payment Method</span>
            </td>
            <td className="text-12">{paymentChannel?.replace(/_/g, ' ')}</td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default TransactionPopup;

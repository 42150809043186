export const actionTypes = {
  UpdateSubscriptionPaymentDetails: '[UpdateSubscriptionPaymentDetails] Action',
};

const initialAuthState = {
  subscriptionPaymentDetails: '',
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.UpdateSubscriptionPaymentDetails: {
      const subscriptionPaymentDetails = action.payload;
      return {
        ...state,
        subscriptionPaymentDetails,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  updateSubscriptionPaymentDetails: subscriptionPaymentDetails => ({
    type: actionTypes.UpdateSubscriptionPaymentDetails,
    payload: subscriptionPaymentDetails,
  }),
};

export function* saga() {}

import React from 'react';
import './index.scss';

const CheckBox = ({
  checked,
  label,
  name,
  handleCheckBoxChange,
  customLabel,
  formik,
}) => {
  return (
    <label className="cursor-pointer mt-2 d-flex">
      <div>
        <div className={'custom-radio'}>
          <input
            type="checkbox"
            onChange={handleCheckBoxChange}
            name={name}
            checked={checked}
            {...formik?.getFieldProps(`${name}`)}
          />

          <div className="d-flex align-items-center check-box-label-container">
            <div className="radio-indicator mr-3">
              <span className="radio-indicator-box"></span>
            </div>

            {customLabel ? (
              customLabel
            ) : (
              <div className={`text-13 font-weight-600`}>{label}</div>
            )}
          </div>
        </div>
      </div>
    </label>
  );
};

export default CheckBox;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import './index.scss';
import CloseModal from '../../../Common/CloseModal';

function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: '55%',
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
    maxHeight: '100%',
    overflow: 'auto',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const TextModal = ({ open, setOpen, text, title }) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="d-flex px-8 py-6 justify-content-between align-items-center">
        <div
          className="text-center text-14 font-weight-600"
          id="simple-modal-title"
        >
          {title}
        </div>
        <CloseModal setOpen={setOpen} />
      </div>

      <div className="divider"></div>
      {text}
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default TextModal;

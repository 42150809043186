import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import RiskProfileImage from './RiskProfileImage';
import elephantLogo from '../../../images/elephantLogo.svg';

import { ConfirmButton } from '../../Common/ReusableButtons';
import '../index.scss';

const OfferCodes = () => {
  const profile = useSelector(state => state.profile);
  const investor = profile?.investor;

  const copies = {
    INDIVIDUAL: (
      <div>
        <span className="text-left text-left font-weight-700 text-blue">
          1. Tell us about yourself:
        </span>{' '}
        <span>
          Regulator requires additional info in order to manage your funds.
        </span>
      </div>
    ),

    BUSINESS: (
      <div>
        <span className="text-left text-left font-weight-700 text-blue">
          1. Tell us about your business:
        </span>{' '}
        <span>
          Regulator requires additional info in order to manage your business’s
          funds.
        </span>
      </div>
    ),

    CHAMA: (
      <div>
        <span className="text-left text-left font-weight-700 text-blue">
          1. Tell us about your chama:
        </span>{' '}
        <span>
          Regulator requires additional info in order to manage your chama’s
          funds.
        </span>
      </div>
    ),
  };

  return (
    <>
      <div className="container instructions-container">
        <RiskProfileImage image={elephantLogo} />
        <div className="text-center">
          <p className="instruction-title">Welcome to ndovu</p>
          <p className="text-16">Complete these last steps</p>
        </div>

        <div className="mt-10 left-space text-18">
          {copies[investor?.investorType]}

          <div>
            <span className="text-left font-weight-700 text-blue">
              2. Deposit Funds:
            </span>
            <span> Use mobile money, Bank transfer, or Card.</span>
          </div>
        </div>

        <div className="text-center my-10">
          <Link to="/home" className="btn-mobile">
            <ConfirmButton buttonText="Get started" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default OfferCodes;

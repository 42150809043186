import React from 'react';

export const DateInput = ({
  label,
  name,
  handleDateChange,
  classes,
  min,
  max,
}) => {
  return (
    <div className="d-flex align-items-center mt-3">
      <div className="mr-5">{label}</div>
      <div>
        <input
          type="date"
          className={`${classes} form-control`}
          name={name}
          onChange={handleDateChange}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { getUserProfile } from '../../modules/Profile/_redux/profileCrud';
import { actions as investorProfileActions } from '../../modules/Profile/_redux/profileRedux';

const useUserProfile = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const updateInvestorProfile = useCallback(async () => {
    dispatch(investorProfileActions.profileAction({ loading: true }));
    setError(null);

    try {
      const { data: { data: userProfile } = {} } = await getUserProfile();

      dispatch(
        investorProfileActions.profileAction({
          ...userProfile,
          loading: false,
        }),
      );
    } catch (error) {
      setError({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });

      dispatch(investorProfileActions.profileAction({ loading: false }));
    }
  }, [dispatch]);

  return { updateInvestorProfile, error };
};

export default useUserProfile;

import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import { getRiskProfileLevels } from '../../modules/Profile/_redux/profileCrud';
import { actions as investorProfileActions } from '../../modules/Profile/_redux/profileRedux';

const useRiskLevels = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const updateRiskLevels = useCallback(async () => {
    setError(null);

    try {
      const {
        data: { data },
      } = await getRiskProfileLevels();
      dispatch(investorProfileActions.riskProfileLevels(data));
    } catch (error) {
      setError({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
    }
  }, [dispatch]);

  return { updateRiskLevels, error };
};

export default useRiskLevels;

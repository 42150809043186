export const actionTypes = {
  UpdateTeamMemberInfo: '[UpdateTeamMemberInfo] Action',
  UpdateInviteInfo: '[UpdateInviteInfo] Action',
};

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UpdateTeamMemberInfo: {
      const teamMemberInfo = action.payload;
      return { ...teamMemberInfo };
    }

    default: {
      return state;
    }
  }
};

export const invitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UpdateInviteInfo: {
      const inviteInfo = action.payload;
      return { ...inviteInfo };
    }

    default: {
      return state;
    }
  }
};

export const actions = {
  updateTeamMemberInfo: teamMemberInfo => ({
    type: actionTypes.UpdateTeamMemberInfo,
    payload: teamMemberInfo,
  }),

  updateInviteInfo: inviteInfo => ({
    type: actionTypes.UpdateInviteInfo,
    payload: inviteInfo,
  }),
};

export function* saga() {}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import '../styles.scss';
import { useHistory } from 'react-router-dom';
import { Settings } from '../../../helpers/constants';
import SwitchCheckBox from '../../Common/SwitchCheckBox';
import Alert from '../../Common/Alert';
import changeTwoFactorAuthenticationState from '../../Account/_redux/twoFactorAuthentication/twoFactorAuthenticationActions';
import Back from '../../Common/BackArrow';
import { SettingsPageLink } from '../components/SettingsPageLink';
import { FullWidthWrapper } from '../../Common/FullWidthWrapper';

const AccountSetting = () => {
  const history = useHistory();
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const { user } = useSelector(state => state.profile);
  const twofactorauthenabled = user?.twofactorauthenabled;

  const [switchState, setSwitchState] = useState(twofactorauthenabled);

  const handleTwoFactorChange = async () => {
    try {
      const {
        data: {
          data: { twofactorauthenabled: enabled },
        },
      } = await changeTwoFactorAuthenticationState();

      // Prevent re-rendering when 2fa switch is changed
      enabled
        ? localStorage.setItem('tokenValidated', true)
        : localStorage.removeItem('tokenValidated');
      // profileAction({ ...profile, twofactorauthenabled: enabled });
      setSwitchState(enabled);
    } catch (error) {
      setAlertOn(true);
      setAlert({
        alertMessage: 'Something went wrong, Please try again',
        alertMessageType: 'alert-light-danger',
      });
    }
  };

  return (
    <div className="mt-5">
      <Back title={'Security'} />
      {alertOn && <Alert alert={alert} />}
      <FullWidthWrapper>
        <span className="text-blue text-18 font-weight-500">Settings</span>
        <div className="d-flex flex-row justify-content-between align-content-center mt-5">
          <div className="d-flex flex-column font-weight-500">
            <span className="text-16">Email Authentication</span>
            <span className="text-10 text-primary-orange">
              2 Factor Authentication
            </span>
          </div>
          <SwitchCheckBox
            label="Email Authentication"
            handleTwoFactorChange={handleTwoFactorChange}
            checked={switchState}
            name="emailAuthentication"
          />
        </div>
        {Settings.map(accounts => (
          <SettingsPageLink
            pageName={accounts.text}
            handlePageRedirection={() => history.push(accounts.link)}
          />
        ))}
      </FullWidthWrapper>
    </div>
  );
};

export default AccountSetting;

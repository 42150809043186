export const messages = {
  pendingTransactions: {
    totalInvested:
      'Pending transactions. The total invested amount will be updated once these transactions are processed.',
    currentValue:
      'Pending transactions. The current value amount will be updated once these transactions are processed.',
    title: 'Transactions in progress',
  },

  currentValue:
    'Pending transactions. The current value will be updated once these transactions are processed.',

  fundWithdraw: {
    title: 'Disinvest',
    body:
      'Please note by completing this action you are disinvesting from this fund.  The disinvestment amount displayed is an approximate value. There is no fee charged by ndovu for the disinvestment.',
    unpprovedWithdraw:
      'You have unapproved disinvestment request on this fund. Please wait before making a new investment.',
  },

  goalWithdraw: {
    title: 'Disinvest',
    body:
      'Please note that by completing this action, you are disinvesting the total amount invested in this goal and not a proportion of your investment.',
    unpprovedWithdraw:
      'You have unapproved disinvestment request on this goal. Please wait before making a new investment.',
  },

  annualPerformance:
    'Average Annual Net Asset Value Return (%) - this is how much return the fund generated over the 12 months prior the last update.',
  annualPerformanceSavingProduct:
    'This is the fixed return earned per year. TAX FREE',

  cashDescription: 'Cash holdings like money market fund and fixed deposits',
  equityDescription: 'Equity also known as shares and stock',
  bondDescription:
    'Bonds holdings like government debt and high quality corporate',
  approximativeWithdrawnAmountInTransaction:
    'The disinvestment amount is indicative and may vary once the trade has been processed due to market movements.',

  savingFundAlert:
    '*You can always withdraw your money before the lock in period. However, you will not earn any interest if withdrawn before the lock in period.',

  'Fixed Return funds': 'This investment offers you a return of 7% P.A.',
  'ETFs (Variable funds)':
    "This investment return is not guaranteed, but hystorically was in the range of -5% to +30% a year depending on markets' performance.",
};

export const ProfitTypeCopies = {
  VARIABLE: 'ETFs (Variable funds)',
  FIXED: 'FIXED',
  FIX_SIMPLE: 'FIX_SIMPLE',
  FIX_COMPOUND: 'FIX_COMPOUND',
};

export const actionTypes = {
  UpdateBusinessChama: '[UpdateBusinessChama] Action',
  CleanKycSate: '[CleanKycSate] Action',
};

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UpdateBusinessChama: {
      const { businessChamaInfo } = action.payload;
      return { ...state, businessChamaInfo };
    }

    case actionTypes.CleanKycSate: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  updateBusinessChama: businessChamaInfo => ({
    type: actionTypes.UpdateBusinessChama,
    payload: { businessChamaInfo },
  }),

  cleanKycState: () => ({
    type: actionTypes.CleanKycSate,
  }),
};

export function* saga() {}

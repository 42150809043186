import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const ADD_TO_WATCH_LIST = `${REACT_APP_BASE_URL}/v1/investor/watch-lists`;
const FETCH_WATCH_LIST = `${REACT_APP_BASE_URL}/v1/investor/watch-lists`;
const REMOVE_FROM_WATCH_LIST = `${REACT_APP_BASE_URL}/v1/investor/watch-lists/`;

const addToWatchList = async id => {
  const res = await axios.post(ADD_TO_WATCH_LIST, {
    planId: id,
  });
  return res;
};

const fetchWatchList = async () => {
  let res = await axios.get(FETCH_WATCH_LIST);
  return res;
};

const removeFromWatchList = async id => {
  const res = await axios.delete(`${REMOVE_FROM_WATCH_LIST}${id}`);
  return res;
};

export { addToWatchList, fetchWatchList, removeFromWatchList };

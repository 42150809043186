import React from 'react';

export const PlanDetailsCard = ({ title, children }) => {
  return (
    <div className="border-light-blue-545 border-radius-8 mt-5">
      <div className="text-blue text-16 font-weight-600 text-center py-7">
        {title}
      </div>
      <div className="divider"></div>
      {children}
    </div>
  );
};

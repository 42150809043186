import React from 'react';
import './index.scss';

const RangeSlider = ({
  handleSlideChange,
  sliderValue,
  min,
  max,
  step,
  rangeInputName,
  centerInput = true,
  disabled,
}) => {
  return (
    <div
      className={`${centerInput && 'mx-auto'} slider-input-container mt-5 px-5`}
    >
      <input
        type="range"
        name={rangeInputName}
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        className={`${centerInput && 'mx-auto'} slider slider-input-container`}
        id="myRange"
        style={{
          backgroundSize: ((sliderValue - min) * 100) / (max - min) + '% 100%',
        }}
        onChange={handleSlideChange}
        autoComplete="off"
        disabled={disabled}
      />
    </div>
  );
};

export default RangeSlider;

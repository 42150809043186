import React from 'react';
import RangeSlider from '../../../Common/sliderRanger';

const SliderInput = ({
  input,
  handleChange,
  title,
  silderLabels,
  min,
  max,
  step,
  rangeInputName,
  sliderValue,
  disabled,
}) => {
  return (
    <div className="mx-auto px-lg-10 px-sm-3 text-center pt-3">
      <div className="text-center font-weight-bold mb-2 mx-3 titles">
        {title}
      </div>
      {input}

      <div>
        <RangeSlider
          handleSlideChange={handleChange}
          sliderValue={sliderValue || 1}
          min={min}
          max={max}
          step={step}
          rangeInputName={rangeInputName}
          disabled={disabled}
        />
      </div>
      {silderLabels}
    </div>
  );
};

export default SliderInput;

import React from 'react';
import {
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

const PopoverComponent = ({ children, popoverContent }) => {
  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      {popoverContent}
    </Tooltip>
  );
  return (
    <OverlayTrigger placement="top" overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  );
};

export default PopoverComponent;

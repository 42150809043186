import React from 'react';

const Exit = ({ cancelWizard }) => {
  return (
    <p className="exit" onClick={cancelWizard}>
      <span style={{ textDecoration: 'underline' }}>Exit</span>
    </p>
  );
};

export default Exit;

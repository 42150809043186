import { routes } from './routes';

export const progressBarWidth = loggedIn => {
  switch (window.location.pathname) {
    case routes.wizard.age:
      return 10;
    case routes.wizard.purpose:
      return 20;
    case routes.wizard.monthlyIncome:
      return 30;
    case routes.wizard.monthlySavings:
      return 40;
    case routes.wizard.cashSavings:
      return 50;
    case routes.wizard.timeFrame:
      return 60;
    case routes.wizard.investmentExperience:
      return 70;
    case routes.wizard.familiarInvestments:
      return 80;
    case routes.wizard.investmentAttitude:
      return 90;
    case routes.wizard.tenPercentInvestmentLoss:
      return loggedIn ? 100 : 95;
    case routes.wizard.personalDetails:
      return 100;
    default:
      return 0;
  }
};

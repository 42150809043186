import { accountTypes, kycStatuses } from './constants';

export const handleKycRouting = (kycChecks, accountLink, investorType) => {
  const { kycStatus, kycLevel, pendingKyc } = kycChecks;

  const getAccountLink =
    accountLink && typeof accountLink === 'object'
      ? accountLink[kycStatus]
      : accountLink;

  if (
    kycStatus === kycStatuses.COMPLETED ||
    kycStatus === kycStatuses.IN_EDIT ||
    kycStatus === kycStatuses.SUBMITTED ||
    kycStatus === kycStatuses.REJECTED
  ) {
    return investorType === accountTypes.INDIVIDUAL
      ? '/manage-account/information'
      : '/investor-information/summary';
  } else {
    if (investorType !== accountTypes.INDIVIDUAL) {
      return '/investor-information/create-account';
    } else {
      if ((kycLevel > 1 && pendingKyc?.length >= 6) || kycLevel === 1) {
        return '/kyc-information';
      } else if (kycLevel === 2 || kycLevel === 3) {
        return '/manage-account/investor-info/instructions';
      } else {
        return getAccountLink;
      }
    }
  }
};

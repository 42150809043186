import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const UPDATE_PROFILE_URL = `${REACT_APP_BASE_URL}/v1/investor/account/update`;
const UPDATE_PASSWORD_URL = `${REACT_APP_BASE_URL}/v1/investor/investors/update-password`;
const USER_PROFILE_URL = `${REACT_APP_BASE_URL}/v1/investor/investors/profile`;
const UPDATE_INVESTOR_PROFILE_URL = `${REACT_APP_BASE_URL}/v1/investor/investors/update-investor-profile`;
const UPDATE_USER_PREFERED_CURRENCY = `${REACT_APP_BASE_URL}/v1/investor/investors/update-user-prefered-currency`;
const GET_RISK_PROFILE_LEVELS = `${REACT_APP_BASE_URL}/v1/investor/risk-profile-levels`;
const REMOVE_DOWNLOAD_APP_BANNER = `${REACT_APP_BASE_URL}/v1/investor/investors/disable-app-download-bar`;

const editProfile = async newProfileValues => {
  const res = await axios.post(UPDATE_PROFILE_URL, {
    ...newProfileValues,
  });
  return res;
};

const updateInvestorProfile = async newProfileValues => {
  const res = await axios.put(UPDATE_INVESTOR_PROFILE_URL, {
    ...newProfileValues,
  });
  return res;
};

const getUserProfile = async () => {
  const res = await axios.get(USER_PROFILE_URL);
  return res;
};

const editPassword = async passwordValues => {
  const res = await axios.put(UPDATE_PASSWORD_URL, {
    ...passwordValues,
  });
  return res;
};

const downloadUsersFile = async imageURL => {
  const res = await axios.get(imageURL, { responseType: 'blob' });
  return res;
};

const changePreferedCurrency = async currencyId => {
  const res = await axios.put(UPDATE_USER_PREFERED_CURRENCY, {
    currencyId,
  });
  return res;
};

const getRiskProfileLevels = async () => {
  const response = await axios.get(GET_RISK_PROFILE_LEVELS);

  return response;
};

const removeDownloadAppBanner = async () => {
  const response = await axios.put(REMOVE_DOWNLOAD_APP_BANNER);

  return response;
};

export {
  editProfile,
  editPassword,
  getUserProfile,
  updateInvestorProfile,
  downloadUsersFile,
  changePreferedCurrency,
  getRiskProfileLevels,
  removeDownloadAppBanner,
};

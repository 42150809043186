import axios from 'axios';
import dotenv from 'dotenv';
import { Currency, paymentMethod } from '../../../helpers/constants';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_ALL_PLANS = `${REACT_APP_BASE_URL}/v1/investor/plans-new`;
const FETCH_HOT_FUNDS = `${REACT_APP_BASE_URL}/v1/investor/plans/hot-funds`;
const FETCH_A_SINGLE_PLAN_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/plans/`;
const FETCH_GRAPH_DATA = `${REACT_APP_BASE_URL}/v1/investor/plans/`;
const CREATE_INVESTMENT = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions`;
const MPESA_FUND_INVESTMENT = `${REACT_APP_BASE_URL}/v1/investor/mobile-money/fund-investment`;
const FUND_TOPUP_URL = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions/`;
const WITHDRAW_URL = `${REACT_APP_BASE_URL}/v2/investor/plan-subscriptions/`;
const WITHDRAW_IN_AMOUNTS_URL = `${REACT_APP_BASE_URL}/v1/investor/plan-subscriptions/`;

const fetchPlans = async fundType => {
  const res = await axios.get(`${FETCH_ALL_PLANS}?fundType=${fundType}`);
  return res;
};

const fetchAllPlans = async () => {
  const res = await axios.get(`${FETCH_ALL_PLANS}`);
  return res;
};

const fetchHotFunds = async () => axios.get(FETCH_HOT_FUNDS);

const fetchSinglePlanDetails = async planId => {
  const res = await axios.get(`${FETCH_A_SINGLE_PLAN_DETAILS}${planId}`);
  return res;
};

const fetchGraphData = async (planId, numOfMonths) => {
  const res = await axios.get(
    `${FETCH_GRAPH_DATA}${planId}/graph?range=${numOfMonths}`,
  );
  return res;
};

/**
 * Handle paymentSubscription/fund investment or Top up using MPESA
 * @param {number} id Identification of the fund or plan-subscription
 * @param {string} phoneNumber user's phone number to charge
 * @param {string} amount Amount to pay with no promo code applied
 * @param { enum } type type of investment <INITIAL_INVESTMENT | TOP_UP>
 * @param {string} promoCode promotion code if any. It should be null.
 */
const fundInvestmentWithMPESA = async (
  id,
  phoneNumber,
  amount,
  type,
  promoCode,
) => {
  const termsAndConditionsAccepted = !!JSON.parse(
    localStorage.getItem('termsAndConditions'),
  );

  return axios.post(MPESA_FUND_INVESTMENT, {
    id: parseInt(id),
    phoneNumber,
    amount,
    promoCode,
    type,
    currency: Currency.KES,
    paymentOption: paymentMethod.MPESA,
    termsAndConditionsAccepted,
  });
};

const directInvestment = async (
  planId,
  txReference,
  channel,
  transactionId,
) => {
  const termsAndConditionsAccepted = !!JSON.parse(
    localStorage.getItem('termsAndConditions'),
  );

  const res = await axios.post(CREATE_INVESTMENT, {
    planId,
    txReference,
    channel,
    transactionId,
    termsAndConditionsAccepted,
  });
  return res;
};

const fundTopUp = async (
  planSubscribtionId,
  txReference,
  channel,
  transactionId,
) => {
  const res = await axios.post(
    `${FUND_TOPUP_URL}${planSubscribtionId}/top-up`,
    {
      txReference,
      channel,
      transactionId,
    },
  );
  return res;
};

const withdrawWithPercentage = async (planId, withdrawPercentage) => {
  return axios.post(`${WITHDRAW_URL}${planId}/percentage-withdraw`, {
    percentage: withdrawPercentage,
  });
};

const withdrawWithAmount = async (planId, withdrawAmount) => {
  return axios.post(
    `${WITHDRAW_IN_AMOUNTS_URL}${planId}/flat-amount-withdraw`,
    { amount: withdrawAmount },
  );
};

export {
  directInvestment,
  fetchAllPlans,
  fetchGraphData,
  fetchHotFunds,
  fetchPlans,
  fetchSinglePlanDetails,
  fundInvestmentWithMPESA,
  fundTopUp,
  withdrawWithAmount,
  withdrawWithPercentage,
};

import React from 'react'
import KycBackArrow from '../../Business/components/KycBackArrow'

const Subscription = () => {
  return (
    <div className="mt-5">
      <KycBackArrow text={'Investor Information'} />
      <div className="card card-custom gutter-b px-md-10 px-lg-10">
        <div className="card-body business-overview">
          Sub
        </div>
      </div>

    </div>
  )
}

export default Subscription

import React from 'react';
import './closeModal.scss';

const CloseModal = ({ setOpen }) => {
  return (
    <span className="close-icon" onClick={() => setOpen(false)}>
      <span className="close-icon-x">X</span>
    </span>
  );
};

export default CloseModal;

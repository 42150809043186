import { pocketActionsEnum } from '../../../helpers/constants';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';

export const getInitialInputValue = ({
  inputOneAmount,
  inputTwoAmount,
  currency,
  operationType,
  currentPocketBalance,
}) => {
  let inputOneInitialAmount;
  let inputTwoInitialAmount;

  const defaultAmount = currency?.defaultAmount;
  const minimumWithdrawableAmount = currency?.minimumWithdrawableAmount;

  if (operationType === pocketActionsEnum.WITHDRAW) {
    if (
      inputOneAmount &&
      convertAmountToNumber(inputOneAmount) > currentPocketBalance
    ) {
      inputOneInitialAmount = currentPocketBalance;
    } else {
      inputOneInitialAmount =
        currentPocketBalance && currentPocketBalance < minimumWithdrawableAmount
          ? currentPocketBalance
          : inputOneAmount
          ? inputOneAmount
          : minimumWithdrawableAmount;
    }
  } else {
    if (inputOneAmount) {
      inputOneInitialAmount = inputOneAmount;
    } else {
      inputOneInitialAmount = defaultAmount;
    }

    if (inputTwoAmount) {
      inputTwoInitialAmount = inputTwoAmount;
    } else {
      inputTwoInitialAmount = defaultAmount;
    }
  }

  return {
    inputOneInitialAmount,
    inputTwoInitialAmount,
  };
};

import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const SETTLEMENT_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/settlement-details`;

const BANK_LIST = `${REACT_APP_BASE_URL}/v1/investor/payment-channels/banks`;

// for both bank details and mobile money (diffs is payload)
const createSettlementDetails = async data => {
  const res = await axios.post(SETTLEMENT_DETAILS, data);
  return res;
};

const updateSettlementDetails = async (data, id) => {
  const res = await axios.put(`${SETTLEMENT_DETAILS}/${id}`, data);
  return res;
};

const fetchSingleDetail = async id => {
  const res = await axios.get(`${SETTLEMENT_DETAILS}/${id}`);
  return res;
};

const fetchSettlementDetails = async () => {
  const res = await axios.get(SETTLEMENT_DETAILS);
  return res;
};

const fetchBankList = async () => {
  const res = await axios.get(BANK_LIST);
  return res;
};

const deleteSettlementDetails = async id => {
  const res = await axios.delete(`${SETTLEMENT_DETAILS}/${id}`);
  return res;
};

export {
  createSettlementDetails,
  updateSettlementDetails,
  fetchSettlementDetails,
  fetchSingleDetail,
  fetchBankList,
  deleteSettlementDetails,
};

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import useMediaQuery from '@mui/material/useMediaQuery';

import { pieChatTypes } from './constants';

const CustomPieChart = ({
  values,
  labels,
  data,
  currency,
  colorsArray,
  displayAmount,
  type,
}) => {
  const getColorByKey = key => {
    const colorObj = colorsArray.find(obj => obj.hasOwnProperty(key));
    return colorObj ? colorObj[key] : null;
  };
  const colors = labels.map(label => getColorByKey(label));

  const breakPoints = {
    breakPoint_1122: { size: '180px', breakPoint: 1122 },
    breakPoint_1067: { size: '150px', breakPoint: 1067 },
    breakPoint_767: { size: '180px', breakPoint: 767 },
    breakPoint_480: { size: '145px', breakPoint: 480 },
  };

  const dimension_1122 = useMediaQuery(
    `(max-width: ${breakPoints.breakPoint_1122.breakPoint}px)`,
  );
  const dimension_1067 = useMediaQuery(
    `(max-width: '${breakPoints.breakPoint_1067.breakPoint}px)`,
  );
  const dimension_767 = useMediaQuery(
    `(max-width: ${breakPoints.breakPoint_767.breakPoint}px)`,
  );
  const dimension_480 = useMediaQuery(
    `(max-width: ${breakPoints.breakPoint_480.breakPoint}px)`,
  );

  const getChartWidthAndHeight = () => {
    if (dimension_1122) return breakPoints['breakPoint_1122'].size;

    if (dimension_1067) return breakPoints['breakPoint_1067'].size;

    if (dimension_767) return breakPoints['breakPoint_767'].size;

    if (dimension_480) return breakPoints['breakPoint_480'].size;

    return '207px';
  };

  const config = {
    series: data || [],
    options: {
      chart: {
        width: 110,
        type: 'donut',
        height: 82,
      },
      labels: labels,
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors,
      },
      legend: {
        show: false,
      },
      colors,
      plotOptions: {
        pie: {
          donut: {
            size: '55%',
          },
        },
      },
      stroke: {
        colors: ['#FFFFFF'], // Set the color of the border line
        width: 0.5, // Set the width of the border line
      },
      responsive: [
        {
          breakpoint: breakPoints['breakPoint_1122'].breakPoint,
          options: {
            chart: {
              width: breakPoints['breakPoint_1122'].size,
            },
            legend: {
              show: false,
            },
          },
        },
        {
          breakpoint: breakPoints['breakPoint_1067'].breakPoint,
          options: {
            chart: {
              width: breakPoints['breakPoint_1067'].size,
            },
            legend: {
              show: false,
            },
          },
        },
        {
          breakpoint: breakPoints['breakPoint_767'].breakPoint,
          options: {
            chart: {
              width: breakPoints['breakPoint_767'].size,
            },
            legend: {
              show: false,
            },
          },
        },
        {
          breakpoint: breakPoints['breakPoint_480'].breakPoint,
          options: {
            chart: {
              width: breakPoints['breakPoint_480'].size,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  // Check if all data values are 0
  const allValuesZero = data.every(value => value === 0);

  const listItems = labels.map((label, index) => {
    return (
      <li key={index}>
        <div>
          <div
            className="dot"
            style={{ backgroundColor: getColorByKey(label) }}
          ></div>
          <span className="asset-name ml-1">{label}</span>
          <span style={{ paddingLeft: '3px' }}>
            (
            {Number(data[index]).toLocaleString('en-US', {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
            % )
          </span>
          {displayAmount ? (
            <>
              <br />
              <span className="asset-name-amount text-blue font-weight-500">
                {Number(values[index] || 0).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
                <span>
                  {type === pieChatTypes.CURRENCIES ? label : currency[index]}
                </span>
              </span>
            </>
          ) : null}
        </div>
      </li>
    );
  });

  return (
    <div className="d-flex align-items-center">
      <div
        className={
          allValuesZero ? 'pie-chart-wrapper-none' : 'pie-chart-wrapper'
        }
      >
        <ReactApexChart
          options={config.options}
          series={data}
          type="donut"
          width={getChartWidthAndHeight()}
          height={getChartWidthAndHeight()}
        />
      </div>
      <ul className="pie-chart-info">{listItems}</ul>
    </div>
  );
};

export default CustomPieChart;

import React from 'react';
import { ConfirmButton } from '../../../Common/ReusableButtons';

const NextButton = ({
  className,
  initialAmount,
  currency,
  goalAmount,
  handleSubmit,
}) => {
  const minimumAmount = currency?.minimumInvestableAmount;
  const buttonEnabled =
    Number(initialAmount) >= Number(minimumAmount) &&
    Number(initialAmount) <= Number(currency?.maximumInvestableAmount) &&
    Number(goalAmount) >= Number(minimumAmount);

  return (
    <div className={`text-center mt-5 mb-10 mb-lg-20 ${className}`}>
      <ConfirmButton
        buttonText="Next"
        disable={!buttonEnabled}
        handleOnClick={handleSubmit}
      />
    </div>
  );
};

export default NextButton;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';

import { actions as kycActions } from '../../KYC/_redux/kycRedux';
import { Note } from '../../Common/Note';
import Back from '../../Common/BackArrow';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { KycPageContentWrapper } from '../../Common/KycPageContentWrapper/KycPageContentWrapper';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ButtonsContainer } from '../../Common/ButtonsContainer';
import './index.scss';
import { eventTracking } from '../../../eventTracking/httpRequest';
import { customerIOEventType } from '../../../helpers/constants';

const Instructions = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const previousPage = localStorage.getItem('previousPage');

  const handleStartVerification = async () => {
    setLoading(true);
    try {
      // Track user starts KYC process
      await eventTracking(customerIOEventType.START_KYC);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    dispatch(kycActions?.cleanKycState());
    history.push('/kyc-information/information');
  };

  return (
    <>
      <Back title={'Getting Started'} previousPageLink={previousPage} />
      <KycPageContentWrapper>
        <div className="button-md-w-360 button-w-360 mx-auto">
          <div className="d-flex justify-content-start align-items-center">
            <SVG src={toAbsoluteUrl('/media/svg/ndovu/kyc-user-avatar.svg')} />
            <span className="kyc-page-title font-weight-500 ml-2">
              Investor information
            </span>
          </div>
          <div
            style={{ maxWidth: '308px' }}
            className="mt-8  mx-auto text-dark-550 text-center"
          >
            <span className="text-center">
              We need some information to help us confirm your identity. This
              may take you up to 5 minutes
            </span>
          </div>

          <ButtonsContainer>
            <ConfirmButton
              buttonText="START VERIFICATION"
              handleOnClick={handleStartVerification}
              buttonWidth={'button-md-w-360 button-w-360'}
              loading={loading}
            />
          </ButtonsContainer>

          <Note
            classes={
              'button-md-w-360 button-w-360 mx-auto bg-orange-150 border-primary-orange p-3 border-radius-7 text-11'
            }
            title={'Note:'}
            content={'Please, have your ID Document or Passport at hand.'}
            titleColor="text-primary-orange"
            contentColor="text-red-200"
            alignText="text-left"
          />
        </div>
      </KycPageContentWrapper>
    </>
  );
};

export default Instructions;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';

import { actions as teamMemberActions } from '../_redux/teamMemberReducer';
import {
  fetchAllInvites,
  fetchAllTeamMembers,
} from '../../Business/_redux/busChamaCrud';
import { teamMemberRole } from '../../../helpers/constants';
import Alert from '../../Common/Alert';
import Back from '../../Common/BackArrow';
import { TeamMemberOrInvite } from '../components/TeamMemberOrInvite';
import { ConfirmButton } from '../../Common/ReusableButtons';
import { FullWidthWrapper } from '../../Common/FullWidthWrapper';

const TeamMembers = () => {
  const { user } = useSelector(state => state.profile);
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [mounted, setMounted] = useState(true); // Initialize with true
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const dispatch = useDispatch();

  const fetchTeamMembers = async () => {
    try {
      setLoading(true);
      const res = await fetchAllTeamMembers();

      if (mounted) {
        setTeamMembers(res?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });

      if (mounted) {
        return error.response.data.message;
      }
    }
  };

  const fetchInvites = async () => {
    try {
      setLoading(true);
      const res = await fetchAllInvites();

      if (mounted) {
        setInvites(res?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      if (mounted) {
        return error.response.data.message;
      }
    }
  };

  useEffect(() => {
    fetchTeamMembers();
    fetchInvites();

    dispatch(teamMemberActions.updateTeamMemberInfo({}));
    dispatch(teamMemberActions.updateInviteInfo({}));

    // Cleanup function to set mounted to false when the component is unmounted
    return () => {
      setMounted(false);
    };
  }, []);

  const handleItemClick = member => {
    member && dispatch(teamMemberActions.updateTeamMemberInfo(member));

    history.push(`/manage-account/member-details/${member?.user?.id}`);
  };

  return (
    <div className="mt-5">
      <Back title={'Team Members'} previousPageLink={'/manage-account'} />

      <div className="mb-5">
        {alert?.alertMessage ? <Alert alert={alert} /> : null}
      </div>

      <FullWidthWrapper>
        {loading ? (
          <div className="text-center" style={{ marginTop: '100px' }}>
            <img
              style={{ width: '70px' }}
              src={toAbsoluteUrl('/media/logos/loading.gif')}
              alt={'loading gif'}
            />
          </div>
        ) : (
          <>
            <div className="d-flex flex-row justify-content-between mb-5">
              <div className="custom-card-title">Account Users</div>

              {user?.businessChamaRole?.name !== teamMemberRole.ADMIN ? null : (
                <ConfirmButton
                  buttonText={'Invite Member'}
                  buttonType={'submit'}
                  handleOnClick={() =>
                    history.push('/manage-account/invite-member')
                  }
                  buttonWidth={'width-140'}
                />
              )}
            </div>

            <div className="d-flex flex-row flex-wrap justify-content-between">
              {teamMembers?.map(member => (
                <TeamMemberOrInvite
                  data={member}
                  handleOnclick={handleItemClick}
                  key={member?.id}
                />
              ))}
            </div>
          </>
        )}
      </FullWidthWrapper>

      <FullWidthWrapper>
        {loading ? (
          <div className="text-center" style={{ marginTop: '100px' }}>
            <img
              style={{ width: '70px' }}
              src={toAbsoluteUrl('/media/logos/loading.gif')}
              alt={'loading gif'}
            />
          </div>
        ) : (
          <>
            <div className="custom-card-title">Team Invitations</div>

            <div className="d-flex flex-row flex-wrap justify-content-between mt-5">
              {invites?.map(invite => (
                <TeamMemberOrInvite data={invite} key={invite?.id} />
              ))}
            </div>
          </>
        )}
      </FullWidthWrapper>
    </div>
  );
};

export default TeamMembers;

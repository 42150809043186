export const getPayingNowAmount = (investing, discount) => {
  let payingNow;
  let result;
  if (investing && discount) {
    result = Number(investing) - Number(discount);
  }

  if (result < 0) {
    payingNow = 0;
  } else {
    payingNow = result;
  }

  return payingNow;
};

import React from 'react';

export const SettingsPageHeaderSection = ({ title, subtitle }) => {
  return (
    <div className="d-flex flex-column mb-5">
      <span className="text-dark-blue-600 text-20 font-weight-500">
        {title}
      </span>
      <small className="font-size-sm">{subtitle}</small>
    </div>
  );
};

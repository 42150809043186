import React from 'react';
import { Link } from 'react-router-dom';
import InvestmentWhiteIcon from '../../../../icons/investBtnIcon.svg';

const NewInvestmentOption = ({
  ElephantIcon,
  title,
  text,
  isPromoPlansAvalaible,
  link,
}) => {
  return (
    <div
      className="g-col-6 card card-custom card-stretch gutter-b new-investment-card"
      style={{
        height: isPromoPlansAvalaible ? '330px' : '200px',
      }}
    >
      <div className="card-body d-flex justify-content-between align-items-center pr-3">
        <div className="d-flex justify-content-between upper-container">
          <div className="order-one">
            <div className="font-weight-bolder heading-text px-2">{title}</div>
            <div className="new-investment-text px-2">{text}</div>
          </div>
        </div>
        <div className="d-flex justify-content-between flex-column align-items-center lower-container">
          <div>
            <img
              src={ElephantIcon}
              alt={'Elephant'}
              style={{
                height: '80px',
                width: '80px',
              }}
            />
          </div>
          <Link
            to={link}
            className="btn select-investment-btn px-5 d-flex align-items-center"
          >
            <img src={InvestmentWhiteIcon} alt={'Invest and save icon'} />
            <span className="ml-3 select-btn-text">Select</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewInvestmentOption;

import React, { useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { requestResendEmailVerification } from '../_redux/authCrud';

import ExternalPageTitle from '../../Common/ExternalPageTitle/ExternalPageTitle';
import Loader from '../../Common/Loader';
import CustomInput from '../../Common/CustomInput';
import '../styles/registration.scss';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';
import { ButtonsContainer } from '../../Common/ButtonsContainer';

const initialValues = {
  email: '',
};

const ResendEmailVerification = props => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const ResendEmailVerificationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 Characters')
      .max(50, 'Maximum 50 Characters')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ResendEmailVerificationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        const response = await requestResendEmailVerification(
          values.email.toLowerCase(),
        );
        if (response.status === 200) {
          setLoading(false);
          setSuccess(true);
          setResponseMessage(response.data.message);
        }
      } catch (error) {
        setSubmitting(false);
        setLoading(false);
        setResponseMessage(error.response.data.message);
        setSuccess(false);
      }
    },
  });

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {responseMessage ? (
        <div
          className={`mb-lg-20 mb-10  text-center`}
          style={{ color: success ? '#67CCCA' : '#F05B7F' }}
        >
          <div className="font-weight-bold">
            <h6>{responseMessage}</h6>
          </div>
        </div>
      ) : null}

      <ExternalPageTitle
        title="Verification"
        subTitle="Enter your email to get a new verification link"
      />

      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 ">
            <div
              className="text-center font-weight-bold"
              style={{ color: '#F05B7F' }}
            >
              {formik.status}
            </div>
          </div>
        ) : (
          ''
        )}
        <CustomInput
          type="email"
          placeholder="Email"
          name="email"
          formik={formik}
          inputWidth={'auth-page-input-container'}
        />
        <ButtonsContainer>
          {' '}
          <div className="auth-buttons-size">
            <Link to="/auth/login" className="w-100">
              <CancelButton buttonText="Cancel" buttonWidth={'w-100'} />
            </Link>
          </div>
          <ConfirmButton
            buttonType={'submit'}
            buttonText="Submit"
            loading={loading}
            disable={success}
            buttonWidth={'auth-buttons-size'}
          />
        </ButtonsContainer>{' '}
      </form>
    </div>
  );
};

export default injectIntl(connect(null, null)(ResendEmailVerification));

import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { addCommas } from '../../../helpers/handleInput';
import { moneyFormat } from '../../../helpers/moneyFormat';
import { pocketContents } from '../copies';

const { investment, goal, conversion, withdraw } = pocketContents();

const validateMinimumAmount = (
  minimum,
  operation,
  investorSelectedCurrency,
  minInvestableAmounts,
  amount,
) => {
  let minimumAmount;

  if (operation === withdraw?.action) {
    minimumAmount = investorSelectedCurrency?.[minimum];
  } else {
    minimumAmount =
      minInvestableAmounts &&
      minInvestableAmounts[investorSelectedCurrency?.currency]
        ? minInvestableAmounts[investorSelectedCurrency?.currency]
        : investorSelectedCurrency?.[minimum];
  }

  if (minimumAmount > convertAmountToNumber(amount)) {
    const errorMessage = `The minimum amount is ${
      investorSelectedCurrency?.currency
    } ${moneyFormat(minimumAmount)}`;

    return errorMessage;
  }

  return undefined;
};

export const minimumAndMaximumAmountValidation = (
  validationData,
  minInvestableAmounts,
) => {
  const {
    amount,
    currency: investorSelectedCurrency,
    operation,
    pocketBalance,
  } = validationData;

  if (operation === investment?.action || operation === goal?.action) {
    const minimumAmountError = validateMinimumAmount(
      'minimumInvestableAmount',
      operation,
      investorSelectedCurrency,
      minInvestableAmounts,
      amount,
    );
    if (minimumAmountError) {
      return minimumAmountError;
    }

    if (
      investorSelectedCurrency?.maximumInvestableAmount <
      convertAmountToNumber(amount)
    ) {
      const errorMessage = `The maximum amount is ${
        investorSelectedCurrency?.currency
      } ${moneyFormat(investorSelectedCurrency?.maximumInvestableAmount)}`;

      return errorMessage;
    }
  } else if (operation === withdraw?.action) {
    const minimumAmountError = validateMinimumAmount(
      'minimumWithdrawableAmount',
      operation,
      investorSelectedCurrency,
      minInvestableAmounts,
      amount,
    );

    if (minimumAmountError) return minimumAmountError;

    if (convertAmountToNumber(amount) > pocketBalance)
      return 'Withdrawn amount is greater than the current balance';
  } else if (operation === conversion?.action) {
    if (
      investorSelectedCurrency?.minimumPocketConvertibleAmount &&
      investorSelectedCurrency?.minimumPocketConvertibleAmount >
        convertAmountToNumber(amount)
    ) {
      const errorMessage = `This action can not be completed as the amount is below the accepted conversion limit of ${investorSelectedCurrency?.currency} ${investorSelectedCurrency?.minimumPocketConvertibleAmount}`;

      return errorMessage;
    }
  }

  return undefined;
};

export const validatePlanInvestment = (validationData, planDetails) => {
  const {
    amount,
    currency: investorSelectedCurrency,
    operation,
  } = validationData;

  const investmentAmount = convertAmountToNumber(amount);

  const {
    allowOnlyDefaultCurrency,
    minAmount,
    multiplesAmount,
    currency: planCurrency,
    minInvestableAmounts,
  } = planDetails;

  if (allowOnlyDefaultCurrency && operation === investment?.action) {
    if (investorSelectedCurrency?.currency !== planCurrency) {
      return `The only currency allowed for this investment is ${planCurrency}`;
    }

    if (minAmount && investmentAmount < minAmount) {
      return `The minimum value for this investment is ${planCurrency} ${addCommas(
        minAmount,
      )}`;
    }

    if (multiplesAmount && investmentAmount % multiplesAmount !== 0) {
      return `The amount investable can only be in multiple of ${planCurrency} ${addCommas(
        minAmount,
      )}. For example you can invest ${planCurrency} ${addCommas(
        minAmount,
      )} or ${planCurrency} ${addCommas(
        minAmount * 2,
      )}, but not ${planCurrency} ${addCommas(minAmount * 1.2)}.`;
    }

    if (
      investorSelectedCurrency?.minimumInvestableAmount >=
      convertAmountToNumber(amount)
    ) {
      const errorMessage = `The minimum amount is ${
        investorSelectedCurrency?.currency
      } ${moneyFormat(investorSelectedCurrency?.minimumInvestableAmount)}`;

      return errorMessage;
    }

    const minimumAmountError = validateMinimumAmount(
      'minimumInvestableAmount',
      operation,
      investorSelectedCurrency,
      minInvestableAmounts,
      amount,
    );
    if (minimumAmountError) {
      return minimumAmountError;
    }
  } else {
    return minimumAndMaximumAmountValidation(
      validationData,
      minInvestableAmounts,
    );
  }
};

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';

export const Button = ({ action, link, type, btnText }) => {
  return (
    <Fragment>
      <Link to={link}>
        <button
          type="button"
          className={`${
            type === 'next' ? 'next-btn mb-2' : 'prev-btn mb-2'
          } btn  font-weight-bolder px-20 py-5`}
          style={{
            color: type === 'next' ? '#fff' : '#0071CE',
            background: type === 'next' ? '#0071CE' : 'rgba(0, 113, 206, 0.25)',
          }}
          onClick={action}
        >
          {capitalizeTheFirstLetterOfEachWord(btnText)}
        </button>
      </Link>
    </Fragment>
  );
};

export const BackAndNextButton = ({ link, type }) => {
  return (
    <Fragment>
      <Link to={link}>
        <button
          type="button"
          className={`btn nextBtn font-weight-bolder text-capitalize px-15 py-4`}
          style={{
            color: type === 'next' ? '#fff' : '#0071CE',
            background: type === 'next' ? '#0071CE' : 'rgba(0, 113, 206, 0.25)',
          }}
        >
          {type === 'next' ? 'Next' : 'Back'}
        </button>
      </Link>
    </Fragment>
  );
};

export const SubmitButton = ({
  disabled,
  BtnText,
  classes,
  loading,
  btnType,
}) => {
  return (
    <button
      type={btnType ? btnType : 'submit'}
      className={`btn font-weight-bolder text-white bg-blue ${classes} px-9 py-3`}
      disabled={disabled}
    >
      <span className="w-100">
        <span className="text-center">
          {BtnText ? capitalizeTheFirstLetterOfEachWord(BtnText) : 'Submit'}
        </span>

        {loading && <span className="ml-3 spinner spinner-white" />}
      </span>
    </button>
  );
};

export const CancelButton = ({
  disabled,
  BtnText,
  classes,
  onClick,
  loading,
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${classes} bg-light-blue-400 cancel-btn-padding text-blue font-weight-bolder`}
      disabled={disabled}
    >
      {BtnText ? capitalizeTheFirstLetterOfEachWord(BtnText) : 'Cancel'}
      {loading && <span className="ml-3 spinner spinner-white" />}
    </button>
  );
};

export const KycBackButton = ({ btnText, onClick }) => {
  return (
    <button
      type="button"
      className={`btn nextBtn font-weight-bolder text-capitalize px-15 py-4 kyc-back-button`}
      onClick={onClick}
    >
      {btnText ? capitalizeTheFirstLetterOfEachWord(btnText) : 'Back'}
    </button>
  );
};

export const SimpleButton = ({
  classes,
  handleOnClick,
  btnText,
  btnType,
  whiteRightArrow,
}) => {
  return (
    <button
      type={btnType ? btnType : 'button'}
      className={classes}
      onClick={handleOnClick}
    >
      <div className="d-flex align-items-center">
        {capitalizeTheFirstLetterOfEachWord(btnText)}
        {whiteRightArrow ? (
          <img className="ml-1" src={whiteRightArrow} alt="Right arrow" />
        ) : null}
      </div>
    </button>
  );
};

import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

const UnableViewPassword = ({ handleClickEvent, viewPassword, classes }) => {
  return (
    <div onClick={handleClickEvent} className={`${classes}`}>
      <SVG
        src={toAbsoluteUrl(
          `/media/svg/ndovu/${
            viewPassword ? 'view-password' : 'hide-password'
          }.svg`,
        )}
      />
    </div>
  );
};

export default UnableViewPassword;

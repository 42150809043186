export const actionTypes = {
  Age: '[Age] Action',
  Purpose: '[Purpose] Action',
  MonthlyIncome: '[MonthlyIncome] Action',
  MonthlySavings: '[MonthlySavings] Action',
  TimeFrame: '[TimeFrame] Action',
  TenPercentInvestmentLoss: '[TenPercentInvestmentLoss] Action',
  FamiliarInvestments: '[FamiliarInvestments] Action',
  CashSavings: '[CashSavings] Action',
  InvestmentExperience: '[InvestmentExperience] Action',
  InvestmentAttitude: '[InvestmentAttitude] Action',
  NameEmail: '[NameEmail] Action',
  ResetWizard: '[ResetWizard] Action',
};

const initialAuthState = {
  age: null,
  purpose: null,
  monthlyIncome: null,
  monthlySavings: null,
  timeFrame: null,
  tenPercentInvestmentLoss: null,
  familiarInvestments: [],
  cashSavings: null,
  investmentExperience: null,
  investmentAttitude: null,
  firstName: null,
  lastName: null,
  email: null,
  password: null,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Age: {
      const { age } = action.payload;
      return { ...state, age };
    }
    case actionTypes.Purpose: {
      const { purpose } = action.payload;
      return { ...state, purpose };
    }
    case actionTypes.MonthlyIncome: {
      const { monthlyIncome } = action.payload;
      return { ...state, monthlyIncome };
    }
    case actionTypes.MonthlySavings: {
      const { monthlySavings } = action.payload;
      return { ...state, monthlySavings };
    }
    case actionTypes.TimeFrame: {
      const { timeFrame } = action.payload;
      return { ...state, timeFrame };
    }
    case actionTypes.TenPercentInvestmentLoss: {
      const { tenPercentInvestmentLoss } = action.payload;
      return { ...state, tenPercentInvestmentLoss };
    }
    case actionTypes.FamiliarInvestments: {
      const { familiarInvestments } = action.payload;
      return { ...state, familiarInvestments };
    }
    case actionTypes.CashSavings: {
      const { cashSavings } = action.payload;
      return { ...state, cashSavings };
    }
    case actionTypes.InvestmentExperience: {
      const { investmentExperience } = action.payload;
      return { ...state, investmentExperience };
    }
    case actionTypes.InvestmentAttitude: {
      const { investmentAttitude } = action.payload;
      return { ...state, investmentAttitude };
    }
    case actionTypes.NameEmail: {
      const { firstName, lastName, email, password } = action.payload;
      return { ...state, firstName, lastName, email, password };
    }
    case actionTypes.ResetWizard: {
      return { ...initialAuthState };
    }

    default:
      return state;
  }
};

export const actions = {
  age: age => ({ type: actionTypes.Age, payload: { age } }),
  purpose: purpose => ({
    type: actionTypes.Purpose,
    payload: { purpose },
  }),
  monthlyIncome: monthlyIncome => ({
    type: actionTypes.MonthlyIncome,
    payload: { monthlyIncome },
  }),
  monthlySavings: monthlySavings => ({
    type: actionTypes.MonthlySavings,
    payload: { monthlySavings },
  }),
  timeFrame: timeFrame => ({
    type: actionTypes.TimeFrame,
    payload: { timeFrame },
  }),
  tenPercentInvestmentLoss: tenPercentInvestmentLoss => ({
    type: actionTypes.TenPercentInvestmentLoss,
    payload: { tenPercentInvestmentLoss },
  }),
  familiarInvestments: familiarInvestments => {
    return {
      type: actionTypes.FamiliarInvestments,
      payload: { familiarInvestments },
    };
  },
  cashSavings: cashSavings => {
    return {
      type: actionTypes.CashSavings,
      payload: { cashSavings },
    };
  },
  investmentExperience: investmentExperience => {
    return {
      type: actionTypes.InvestmentExperience,
      payload: { investmentExperience },
    };
  },
  investmentAttitude: investmentAttitude => {
    return {
      type: actionTypes.InvestmentAttitude,
      payload: { investmentAttitude },
    };
  },
  nameEmail: (firstName, lastName, email, password) => ({
    type: actionTypes.NameEmail,
    payload: { firstName, lastName, email, password },
  }),
  ResetWizard: () => ({ type: actionTypes.ResetWizard }),
};

export function* saga() {}

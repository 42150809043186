import React, { useState, useEffect } from 'react';
import CustomPieChart from '../../../helpers/CustomPieChart';
import { PredefinedColors } from '../../../helpers/constants';

const PortfolioDetailsCard = ({ type, data, displayAmount }) => {
  const [mounted, setMounted] = useState(false);
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    setMounted(true);

    const fetchData = async () => {
      const pieData = Object.entries(data || {})
        .filter(data => typeof data[1] === 'object')
        .map(([name, value]) => {
          return {
            name,
            data: value?.value || [],
            amount: value,
            currency: value?.currency,
          };
        });

      if (mounted) {
        setPieData(pieData);
      }
    };

    fetchData();

    return () => {
      setMounted(false);
      // Cancel any ongoing asynchronous tasks or subscriptions here
    };
  }, [data, mounted]);

  const pieColorData = Object.entries(data || {})
    .filter(data => typeof data[1] === 'object')
    .reduce((acc, [name], index) => {
      return {
        ...acc,
        [name]: PredefinedColors[index % PredefinedColors.length],
      };
    }, {});

  const colors = [pieColorData];

  return (
    <>
      {pieData.length > 0 && (
        <div className="col-xs-12 col-sm-6 col-md-6 portfolio-content border-radius-8 border-light-blue-600 py-4 px-5">
          <div className="d-flex flex-row justify-content-between">
            <div className="text-13 font-weight-500">{type}</div>
          </div>
          <CustomPieChart
            values={pieData.map(item => item?.data)}
            labels={pieData.map(item => item?.name)}
            data={pieData.map(item => item?.amount?.percentage)}
            currency={pieData.map(item => item?.currency)}
            colorsArray={colors}
            displayAmount={displayAmount}
            type={type}
          />
        </div>
      )}
    </>
  );
};

export default PortfolioDetailsCard;

import Swal from 'sweetalert2';

export const handleAlert = message => {
  Swal.fire({
    icon: 'success',
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    text: message,
    showConfirmButton: false,
    timer: 1800,
  });
};

export const currencyHelper = (amount, currency, mathSign = null) => {
  const twoDecimalDigit = amount?.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const money = `${twoDecimalDigit} ${currency?.toUpperCase()}`;

  return mathSign ? `${mathSign} ${money}` : money;
};

import React from 'react';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export const InvestorAccountType = ({ selectPrimaryAccount, account }) => {
  return (
    <div
      onClick={() => selectPrimaryAccount(account?.value)}
      className="card animated-card card-custom bg-light-blue-200 border-radius-3 border-light-blue wizard-custom-selector gutter-b"
    >
      <div
        className="card-body d-flex flex-row justify-content-between align-content-center"
        style={{ padding: '10px 17px' }}
      >
        <div>
          <span className="text-17 font-weight-500 text-dark-blue-600">
            {account?.label}
          </span>
        </div>

        <div>
          <SVG src={toAbsoluteUrl('/media/svg/ndovu/arrow.svg')}></SVG>
        </div>
      </div>
    </div>
  );
};

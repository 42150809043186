import React from 'react';
import { capitalizeTheFirstLetterOfEachWord } from '../../../helpers/investmentHelpers';

export const Buttons = ({
  btnText,
  classes,
  handleOnClick,
  btnType,
  disable,
  loading,
  buttonWidth,
}) => {
  return (
    <button
      onClick={handleOnClick}
      className={`${classes} ${
        buttonWidth ? buttonWidth : 'button-width'
      } font-weight-500`}
      type={btnType ? btnType : 'button'}
      disabled={disable}
    >
      <span className="font-weight-500">
        {typeof btnText === 'string'
          ? capitalizeTheFirstLetterOfEachWord(btnText)
          : btnText}{' '}
        {loading && <span className="ml-3 spinner spinner-white" />}
      </span>
    </button>
  );
};

export const openUploadedFileInNewWindow = uploadedFile => {
  const isPdfDataUrl = dataUrl => {
    // Extract the MIME type from the Data URL
    const mimeType = dataUrl?.split(';')[0]?.split(':')[1];

    // Check if the MIME type indicates a PDF
    return mimeType === 'application/pdf';
  };

  const htmlForFile = isPdfDataUrl(uploadedFile)
    ? '<html style="height: 100%; background-color: rgb(14, 14, 14);"><body style="margin: auto;height: 100%;width: 100%;display: flex;justify-content: center;align-items: center;" ><embed src="' +
      uploadedFile +
      '" type="application/pdf" width="100%" height="100%"></body></html>'
    : '<html style="height: 100%; background-color: rgb(14, 14, 14);"><body style="margin: 0px;height: 100%;width: 100%;display: flex;justify-content: center;align-items: center;" ><img src="' +
      uploadedFile +
      '" style="width: 100%; height: 100%; object-fit: contain"/></body></html>';

  if (uploadedFile) {
    const newTab = window.open();
    newTab.document.write(htmlForFile);
  }
};

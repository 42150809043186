import React from 'react';
import './input.scss';

export default function Input({ placeholder, name, type, formik, className }) {
  return (
    <div>
      <input
        placeholder={placeholder}
        type={type}
        className={`${className} border-gray-450 border-radius-8`}
        name={name}
        {...formik?.getFieldProps(`${name}`)}
        autoComplete="off"
      />
    </div>
  );
}

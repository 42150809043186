import { countries } from 'countries-list';

export const countryCodes = Object.keys(countries);

const getCountries = () => {
  const countriesArray = [];
  for (const i in countries) {
    countriesArray.push({ ...countries[i], countryCode: i });
  }
  const selectOptions = countriesArray.map(country => {
    return {
      countryCallingCode: country.phone,
      name: country.name,
      flag: `flag-icon-${country.countryCode.toLowerCase()}`,
      countryCode: country.countryCode,
    };
  });

  const sortCountries = selectOptions.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return sortCountries;
};

export default getCountries;

import React, { useState, useEffect } from 'react';
import PaymentMethodRadioButton from '../RadioButton/paymentMethod';
import { Note } from '../Note';
import { paymentMethod } from '../../../helpers/constants';
import ReusableModal from '../ReusableModal';
import HorizontalLine from '../../../helpers/horizontalLine';
import { pocketContents } from '../../Pocket/copies';
import { useSelector } from 'react-redux';
import { addCommas } from '../../../helpers/handleInput';
import {
  calculateNdovuFee,
  getNdovuFee,
} from '../../../helpers/calculateNdovuFee';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { CancelButton, ConfirmButton } from '../ReusableButtons';
import { ButtonsContainer } from '../ButtonsContainer';
import { DetailsItem } from '../DetailsItem';

export const PaymentMethodModalContent = ({
  pocketDetails,
  setActivePaymentMethod,
  activePaymentMethod,
  handlePaymentFromModal,
  closeModal,
  loading,
  btnText,
  displayErrorMessage,
  insufficientFunds,
  paymentDetails,
  modalTitle,
  planDetails,
  goalDetails,
  ndovuPercentage,
  isPlan,
}) => {
  const [open, setOpen] = useState(false);
  const { type } = useSelector(state => state.pocketFlow);
  const [ndovuFee, setNdovuFee] = useState();

  useEffect(() => {
    // It should be the amount minus discount amount but we do not have discount on pocket
    const amount = isPlan
      ? paymentDetails?.inputOne?.amount
      : goalDetails?.amount;

    const getNdovuFee = calculateNdovuFee(
      goalDetails?.staticGoalId,
      amount,
      planDetails,
      amount,
      ndovuPercentage,
    );
    setNdovuFee(getNdovuFee);
  }, []);

  const modalBody = (
    <div className="payment-method-container mx-auto">
      <div className="text-center">
        <h3 className="mb-8">{`Confirm ${modalTitle}  Details`}</h3>
        <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />
        {displayErrorMessage}
      </div>
      {/* if there is name, then it is a fund, otherwise, it is a goal */}
      <DetailsItem
        label={isPlan ? 'Fund Name' : 'Goal Name'}
        value={isPlan ? planDetails?.coolName : goalDetails?.goalName}
        valueAdditionalClasses={'text-blue font-weight-500'}
        changeValueString={false}
      />

      <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />

      <DetailsItem
        label={`Deposit Amount`}
        value={`${pocketDetails?.currency?.toUpperCase()} ${
          isPlan ? paymentDetails?.inputOne?.amount : goalDetails?.amount
        }`}
        valueAdditionalClasses={'text-blue font-weight-500'}
        changeValueString={false}
      />

      <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />

      <DetailsItem
        label={`Transactions Fee`}
        value={`${pocketDetails?.currency?.toUpperCase()} 0`}
        valueAdditionalClasses={'text-blue font-weight-500'}
        changeValueString={false}
      />

      <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />

      <DetailsItem
        label={`ndovu One-off Fee (${getNdovuFee(
          goalDetails?.staticGoalId,
          planDetails,
          ndovuPercentage,
        )}%)`}
        value={`${pocketDetails?.currency?.toUpperCase()} ${addCommas(
          ndovuFee,
        )}`}
        valueAdditionalClasses={'text-blue font-weight-500'}
        changeValueString={false}
      />

      <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />

      <DetailsItem
        label={`Total Invested Amount`}
        value={`${pocketDetails?.currency?.toUpperCase()} ${addCommas(
          convertAmountToNumber(
            isPlan ? paymentDetails?.inputOne?.amount : goalDetails?.amount,
          ) - convertAmountToNumber(ndovuFee),
        )}`}
        valueAdditionalClasses={'text-blue font-weight-500'}
        changeValueString={false}
      />
      <HorizontalLine color="#D0DFE6" width={'100%'} height={0.1} />

      <Note
        title={'Note:'}
        content={'Your Money will be deducted from your ndovu pocket'}
        titleColor="text-primary-orange"
        contentColor="text-primary-orange"
        alignText="text-left"
      />

      <ButtonsContainer justifyContent="justify-content-between">
        <CancelButton
          buttonText="Cancel"
          handleOnClick={() => setOpen(false)}
          twinButton={true}
        />

        <ConfirmButton
          buttonText={'Next'}
          loading={loading}
          handleOnClick={() =>
            handlePaymentFromModal(
              pocketContents()?.[type?.path.toLowerCase()]?.action,
            )
          }
          disable={loading}
          twinButton={true}
        />
      </ButtonsContainer>
    </div>
  );

  const onHandleClick = () => {
    if (activePaymentMethod === paymentMethod.POCKET) {
      setOpen(true);
    } else {
      handlePaymentFromModal();
    }
  };

  return (
    <div className="payment-method-container mx-auto">
      <div>
        <div className="text-center">
          {' '}
          <h3 className="mb-3">Select Payment Method </h3>
          <div className="mt-5">{displayErrorMessage}</div>
        </div>
        <PaymentMethodRadioButton
          processingDays={
            pocketDetails?.balance || Number(pocketDetails?.balance) === 0
              ? `Pocket Balance: ${pocketDetails?.currency} ${addCommas(
                  pocketDetails?.balance,
                )}`
              : null
          }
          label={'Pay with Pocket'}
          name={paymentMethod.POCKET}
          setActivePaymentMethod={setActivePaymentMethod}
          activePaymentMethod={activePaymentMethod}
          disable={!pocketDetails?.balance || insufficientFunds}
          insufficientFunds={insufficientFunds}
          pocketDetails={pocketDetails}
        />

        <PaymentMethodRadioButton
          processingDays="Mobile Money, Bank Transfers & Cards"
          label={'Other Payment Modes'}
          name={paymentMethod.OTHERS}
          setActivePaymentMethod={setActivePaymentMethod}
          activePaymentMethod={activePaymentMethod}
        />

        <ButtonsContainer justifyContent="justify-content-between">
          <CancelButton
            buttonText="Cancel"
            handleOnClick={() => closeModal()}
            twinButton={true}
          />

          <ConfirmButton
            buttonText={
              activePaymentMethod === paymentMethod.OTHERS ? 'NEXT' : btnText
            }
            loading={loading}
            handleOnClick={onHandleClick}
            disable={!activePaymentMethod}
            twinButton={true}
          />
        </ButtonsContainer>
      </div>
      {open && <ReusableModal open={open} setOpen={setOpen} text={modalBody} />}
    </div>
  );
};

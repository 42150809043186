export const getGender = gender => {
  if (gender?.toUpperCase() === 'M' || gender?.toUpperCase() === 'MALE') {
    return { label: 'Male', value: 'MALE' };
  } else if (
    gender?.toUpperCase() === 'F' ||
    gender?.toUpperCase() === 'FEMALE'
  ) {
    return { label: 'Female', value: 'FEMALE' };
  } else {
    return { label: null, value: null };
  }
};

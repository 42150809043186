import React from 'react';
import { OneItem } from './OneItem';

export const PaymentDetailsItem = ({
  fromCurrency,
  toCurrency,
  amount,
  rate,
}) => {
  return (
    <>
      <OneItem
        sign={'equal'}
        amount={amount}
        currency={fromCurrency}
        text={"amount we'll convert"}
      />
      {rate ? (
        <OneItem
          sign={'multiplication'}
          amount={rate}
          currency={toCurrency}
          text={'rate'}
        />
      ) : null}
    </>
  );
};

export const addCommas = (amount, roundAmount = true) => {
  const amountWithThoundSeparator = amount
    ? amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : 0;
  let parts =
    amountWithThoundSeparator && amountWithThoundSeparator?.split('.');
  if (parts.length === 2) {
    parts[1] = roundAmount
      ? parts[1]?.replace(/,/g, '')?.substring(0, 2)
      : parts[1]?.replace(/,/g, '');
    return parts?.join('.');
  }
  return amountWithThoundSeparator;
};

export const removeNonNumeric = amount =>
  amount?.toString()?.replace(/[^0-9.]/g, '');

export const hasOnlyOneDot = amount => {
  const dots = amount
    ?.toString()
    ?.split('')
    ?.filter(char => char === '.');
  return dots?.length > 1 ? false : true;
};

import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const CHANGE_TWO_FACTOR_AUTHENTICATION_STATE = `${REACT_APP_BASE_URL}/v1/investor/auth/change-2fa-state`;

const changeTwoFactorAuthenticationState = async () => {
  const res = await axios.put(`${CHANGE_TWO_FACTOR_AUTHENTICATION_STATE}`);
  return res;
};

export default changeTwoFactorAuthenticationState;

export const actionTypes = {
  UpdatePocketFlow: '[UpdatePocketFlow] Action',
  ResetPocketFlow: '[ResetPocketFlow] Action',
  UpdatePockets: '[UpdatePockets] Action',
  UpdatePocket: '[UpdatePocket] Action',
  GetPocketTransactions: '[GetPocketTransactions] Action',
  UpdatePocketTopupDetails: '[UpdatePocketTopupDetails] Action',
};

export const initialState = {
  type: null,
  inputOne: null,
  paymentMethod: null,
  inputTwo: null,
  pocketId: null,
  planId: null,
  planSubscriptionId: null,
  investmentType: null,
};

const transactionsInitialState = {
  transactions: [],
  totalRecord: 0,
  totalPages: 0,
  page: 1,
  limit: 50,
};

const pocketFlowState = initialState;

export const reducer = (state = pocketFlowState, action) => {
  switch (action.type) {
    case actionTypes.UpdatePocketFlow: {
      return {
        ...state,
        ...action.payload.pocketFlow,
      };
    }

    case actionTypes.ResetPocketFlow: {
      return { ...pocketFlowState };
    }

    default: {
      return state;
    }
  }
};

export const pocketsReducer = (state = null, action) => {
  switch (action.type) {
    case actionTypes.UpdatePockets: {
      return action.payload.pockets;
    }

    default: {
      return state;
    }
  }
};

export const pocketTransactionsReducer = (
  state = transactionsInitialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.GetPocketTransactions: {
      return action.payload.pocketTransactions;
    }

    default: {
      return state;
    }
  }
};

export const updatePocketReducer = (state = null, action) => {
  switch (action.type) {
    case actionTypes.UpdatePocket: {
      return action.payload.pocketDetails;
    }

    default: {
      return state;
    }
  }
};

export const updatePocketTopupDetails = (state = null, action) => {
  switch (action.type) {
    case actionTypes.UpdatePocketTopupDetails: {
      return action.payload.pocketTopupDetails;
    }

    default: {
      return state;
    }
  }
};

export const actions = {
  updatePocketFlow: pocketFlow => ({
    type: actionTypes.UpdatePocketFlow,
    payload: { pocketFlow },
  }),

  updatePockets: pockets => ({
    type: actionTypes.UpdatePockets,
    payload: { pockets },
  }),

  getPocketTransactions: pocketTransactions => ({
    type: actionTypes.GetPocketTransactions,
    payload: { pocketTransactions },
  }),

  updatePocket: pocketDetails => ({
    type: actionTypes.UpdatePocket,
    payload: { pocketDetails },
  }),

  updatePocketTopupDetails: pocketTopupDetails => ({
    type: actionTypes.UpdatePocketTopupDetails,
    payload: { pocketTopupDetails },
  }),

  clearPocketFlow: () => ({
    type: actionTypes.ResetPocketFlow,
  }),
};

export function* saga() {}

import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_PLANS = `${REACT_APP_BASE_URL}/v1/investor/plans-new/`;

const getOnPromoPlans = async () => {
  try {
    const res = await axios.get(FETCH_PLANS);

    if (res.status === 200 && res.data?.data) {
      const planObj = res.data.data;
      const planArr = Object.keys(planObj).map(category => planObj[category]);
      const allPlans = planArr.flat();
      const promoPlans = allPlans.filter(plan => plan.isCurrentOffer);
      return promoPlans;
    }
  } catch {}
};

export default getOnPromoPlans;

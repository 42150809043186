import React from 'react';
import { useSelector } from 'react-redux';

const RiskProfileInformation = () => {
  const riskProfileLevels = useSelector(state => state.riskProfileLevels);

  const riskProfileInfo = {
    adventurous: {
      title: 'Adventurous Portfolio:',
      description:
        'This portfolio aims to achieve higher returns over the long term but incurs more risk, so it is likely to have more short-term ups and down when compared to lower-risk portfolios.',
    },

    balanced: {
      title: 'Balanced Portfolio:',
      description:
        'This portfolio aims to achieve returns that grow steadily over the long term, so it’s likely to have less drastic short-term ups and downs in exchange for receiving returns that outpace inflation.',
    },

    conservative: {
      title: 'Conservative Portfolio:',
      description:
        'This portfolio aims to protect the value of your money over the long term, so it’s likely to experience steady growth that generates returns that match or slightly outpace inflation.',
    },
  };

  return (
    <div className="my-5 px-5">
      {riskProfileLevels?.map(riskLevel => (
        <div key={riskLevel.id}>
          <label>
            <strong>
              {riskProfileInfo[riskLevel.alias.toLowerCase()]?.title}
            </strong>
          </label>
          <p className="my-1 pb-2" id="simple-modal-description">
            {riskProfileInfo[riskLevel.alias.toLowerCase()]?.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default RiskProfileInformation;

import { pocketActionsEnum } from '../../../helpers/constants';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';

export const pocketBalanceIsLessThanMinimumWithdrawableAmount = (
  pocketBalance,
  minimumWithdrawbleAmount,
  operationType,
) => {
  return (
    operationType &&
    operationType === pocketActionsEnum.WITHDRAW &&
    Number(pocketBalance) < convertAmountToNumber(minimumWithdrawbleAmount)
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../Common/Button';

const NoAccess = () => {
  let history = useHistory();

  return (
    <div className="card card-custom gutter-b p-md-10 p-lg-10 mt-5">
      <div
        className="card-body business-overview"
        style={{
          backgroundColor: '#FFFFFF',
          borderRadius: '7.111px',
          border: '0.267px solid #D9EAF8',
        }}
      >
        <div className="d-flex justify-content-center align-items-center p-5">
          <span
            className="text-center"
            style={{ color: '#0071ce', fontWeight: 500 }}
          >
            You dont have Permission to perform this action or view this page
          </span>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-10">
          <Button
            btnText="Exit to Manage Account"
            className="text-center common-btn btn-size"
            onClick={() => history.push('/manage-account')}
          />
        </div>
      </div>
    </div>
  );
};

export default NoAccess;

import React from 'react';

const ExternalPageTitle = ({ title, subTitle }) => {
  return (
    <div className="text-center mb-5 mb-lg-10">
      <h1 className="text-30 font-weight-600 text-blue">{title}</h1>
      <p className="text-14">{subTitle}</p>
    </div>
  );
};

export default ExternalPageTitle;

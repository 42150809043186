export const formatCurrency = currencies => {
  return (
    currencies?.map(currency => ({
      label: currency.name,
      value: currency.currency,
    })) || []
  );
};

export const kenyaBanks = [
  {
    cbkCode: '001',
    value: 'BANK01',
    label: 'KCB',
    sortCode: '1140401000',
  },
  {
    cbkCode: '002',
    value: 'BANK02',
    label: 'Standard chartered',
    sortCode: '1140402000',
  },
  {
    cbkCode: '003',
    value: 'BANK03',
    label: 'ABSA',
    sortCode: '1140403000',
  },
  {
    cbkCode: '007',
    value: 'BANK07',
    label: 'NCBA',
    sortCode: '1140407000',
  },
  {
    cbkCode: '010',
    value: 'BANK10',
    label: 'Prime Bank',
    sortCode: '1140410000',
  },
  {
    cbkCode: '011',
    value: 'BANK11',
    label: 'Coop Bank',
    sortCode: '1140411000',
  },
  {
    cbkCode: '012',
    value: 'BANK12',
    label: 'National Bank',
    sortCode: '1140412000',
  },
  {
    cbkCode: '016',
    value: 'BANK16',
    label: 'Citi Bank',
    sortCode: '1140416000',
  },
  {
    cbkCode: '018',
    value: 'BANK18',
    label: 'Middle east Bank',
    sortCode: '1140418000',
  },
  {
    cbkCode: '019',
    value: 'BANK19',
    label: 'BOA',
    sortCode: '1140419000',
  },
  {
    cbkCode: '025',
    value: 'BANK25',
    label: 'Credit Bank',
    sortCode: '1140425000',
  },
  {
    cbkCode: '031',
    value: 'BANK31',
    label: 'Stanbic Bank',
    sortCode: '1140431000',
  },
  {
    cbkCode: '035',
    value: 'BANK35',
    label: 'ABC Bank',
    sortCode: '1140435000',
  },
  {
    cbkCode: '050',
    value: 'BANK50',
    label: 'Paramount',
    sortCode: '1140450000',
  },
  {
    cbkCode: '051',
    value: 'BANK51',
    label: 'Kingdom Bank',
    sortCode: '1140451000',
  },
  {
    cbkCode: '053',
    value: 'BANK53',
    label: 'GT Bank',
    sortCode: '1140453000',
  },
  {
    cbkCode: '054',
    value: 'BANK54',
    label: 'Victoria Commercial Bank',
    sortCode: '1140454000',
  },
  {
    cbkCode: '055',
    value: 'BANK55',
    label: 'Guardian Bank',
    sortCode: '1140455000',
  },
  {
    cbkCode: '057',
    value: 'BANK57',
    label: 'I&M Bank',
    sortCode: '1140457000',
  },
  {
    cbkCode: '061',
    value: 'BANK61',
    label: 'Housing Finance (HFC)',
    sortCode: '1140461000',
  },
  {
    cbkCode: '063',
    value: 'BANK63',
    label: 'DTB',
    sortCode: '1140463000',
  },
  {
    cbkCode: '065',
    value: 'BANK65',
    label: 'Mayfair',
    sortCode: '1140465000',
  },
  {
    cbkCode: '066',
    value: 'BANK66',
    label: 'Sidian',
    sortCode: '1140466000',
  },
  {
    cbkCode: '068',
    value: 'BANK68',
    label: 'Equity Bank',
    sortCode: '1140468000',
  },
  {
    cbkCode: '070',
    value: 'BANK70',
    label: 'Family Bank',
    sortCode: '1140470000',
  },
  {
    cbkCode: '072',
    value: 'BANK72',
    label: 'Gulf African Bank',
    sortCode: '1140472000',
  },
  {
    cbkCode: '074',
    value: 'BANK74',
    label: 'First Community Bank',
    sortCode: '1140474000',
  },
  {
    cbkCode: '075',
    value: 'BANK75',
    label: 'Dubai Islamic Bank',
    sortCode: '1140475000',
  },
  {
    cbkCode: '078',
    value: 'BANK78',
    label: 'KWFT',
    sortCode: '1140478000',
  },
];

export const findBank = (kenyaBanks, bankName) => {
  let bank = kenyaBanks.find(bank => bank.value === bankName);
  return {
    value: bankName,
    label: bank?.label,
  };
};

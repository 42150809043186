import React from 'react';
import MultiProgress from 'react-multi-progress';
import { PredefinedColors, pieChatTypes } from './constants';

const ProgressBar = ({ valueArr, currency, hidden, type }) => {
  const valuesWithColor = valueArr.map((obj, index) => ({
    ...obj,
    color: PredefinedColors[index % PredefinedColors.length],
  }));

  return (
    <div className="line-chart">
      <div className="w-100 border-radius-7 mb-2">
        <MultiProgress
          elements={valuesWithColor}
          backgroundColor="#EFEFEF"
          height={10}
        />
      </div>
      {hidden ? (
        <></>
      ) : (
        <ul className="line-chart-info">
          {valuesWithColor.map((value, index) => (
            <li key={index}>
              <div>
                <span
                  style={{ color: value.color, width: '9px', height: '9px' }}
                >
                  &#x25CF;
                </span>
                <span
                  style={{ marginLeft: '5px', textTransform: 'capitalize' }}
                >
                  {type === pieChatTypes.PRODUCT_TYPE
                    ? value.title.toLowerCase()
                    : value.title}
                </span>
                <span style={{ marginLeft: '2px', color: '#0071CE' }}>
                  (
                  {Number(value.value).toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}
                  %)
                </span>
              </div>
              <div className="ml-3">
                <span>
                  {Number(value.amount).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </span>
                <span className="ml-1">{currency}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProgressBar;

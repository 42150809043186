import React from 'react';
import PopoverComponent from '../../Common/PopoverComponent';

export const GoalOrPlanDetailsOneItem = ({
  label,
  value,
  hasInformationIcon,
  hasPendingTransactions,
  popoverComponentContent,
}) => {
  return (
    <div className="d-flex justify-content-between text-13 my-2">
      <span className="text-primary-black">{label}</span>
      <span className="text-blue font-weight-600">
        <span>{value}</span>

        {hasInformationIcon && hasPendingTransactions && (
          <PopoverComponent popoverContent={popoverComponentContent}>
            <span className="cursor-pointer" style={{ marginLeft: '5px' }}>
              <i
                style={{ color: '#FF7F30', fontSize: '13px' }}
                className="fa fa-exclamation-circle"
                aria-hidden="true"
              ></i>
            </span>
          </PopoverComponent>
        )}
      </span>
    </div>
  );
};

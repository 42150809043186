import React from 'react';
import SelectPhoneNumber from './SelectPhoneNumber';
import { getInputClasses } from '../../helpers/getInputClasses';

export const PhoneNumberInput = ({
  setOpen,
  selectedCountry,
  formik,
  validPhoneNumber,
}) => {
  return (
    <div className="fv-plugins-icon-container w-100 mt-7">
      <div className="d-flex flex-content-between w-100">
        <div className="w-50 mr-3">
          <SelectPhoneNumber
            setOpen={setOpen}
            selectedCountry={selectedCountry}
          />

          {formik.touched.phoneNumber &&
          !selectedCountry?.countryCallingCode ? (
            <div style={{ color: '#F05B7F' }}>Required field</div>
          ) : null}
        </div>

        <div className="w-75">
          <input
            name="phoneNumber"
            className={`form-control border-gray-450 border-radius-8 height-45 input-field ${getInputClasses(
              'phoneNumber',
              formik,
            )}`}
            type="tel"
            {...formik.getFieldProps('phoneNumber')}
            placeholder="Phone Number"
            autoComplete="off"
          />

          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div style={{ color: '#F05B7F' }}>{formik.errors.phoneNumber}</div>
          ) : null}
          {validPhoneNumber === false ? (
            <div
              style={{
                color: '#F05B7F',
                fontSize: '13px',
                lineHeight: '13px',
                marginTop: '5px',
              }}
            >
              Enter a valid phone number e.g. 7********
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const wizardCopy = {
  age: {
    action: `How old are you?`,
  },
  purpose: {
    action: `What are you investing for?`,
    subAction: `Start with one goal that is important to you right now.`,
  },
  monthlyIncome: {
    action: `What is your monthly income?`,
    subAction: `(An estimate in KES).`,
  },
  monthlySavings: {
    action: `How much do you save every month?`,
    subAction: `(An estimate in KES).`,
  },
  cashSavings: {
    action: `How long will your cash savings last in case of a shock?`,
  },
  timeFrame: { action: `How long are you looking to be invested for?` },
  investmentExperience: {
    action: `For how long have you been an investor? (e.g. buying shares, land)`,
  },
  investmentAttitude: {
    action: `How would you describe your attitude towards investing?`,
  },
  tenPercentInvestmentLoss: {
    action: `What would you do if your investment portfolio lost 10% of it's value in a month?`,
  },
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  BusinessManagement,
  ChamaManagement,
  PersonalManagement,
  accountTypes,
} from '../../../helpers/constants';
import { handleKycRouting } from '../../../helpers/handleKycRouting';
import Back from '../../Common/BackArrow';
import { SettingsPageLink } from '../components/SettingsPageLink';

const ManageAccount = () => {
  const history = useHistory();

  const { investor } = useSelector(state => state.profile);
  const { kycLevel, kycStatus, pendingKyc, investorType } = investor;

  const kycChecks = { kycLevel: kycLevel?.level, kycStatus, pendingKyc };

  const previousPage = localStorage.getItem('kycEntryPointUrl');

  return (
    <div className="mt-5">
      <Back
        title={'Manage Account'}
        text={'Back'}
        previousPageLink={previousPage}
      />

      <div className="border-light-blue-600 border-radius-8 p-md-10 p-5">
        <div className="business-overview d-flex flex-column pl-md-0">
          {/* TODO: ADD PERMISSIONS CHECK */}

          {(investor?.investorType === accountTypes.INDIVIDUAL
            ? PersonalManagement
            : investor?.investorType === accountTypes.BUSINESS
            ? BusinessManagement
            : ChamaManagement
          ).map(account => (
            <SettingsPageLink
              pageName={account.text}
              handlePageRedirection={() => {
                localStorage.setItem('previousPage', history.location.pathname);
                account?.kyc
                  ? history.push(
                      handleKycRouting(kycChecks, account.link, investorType),
                    )
                  : history.push(account.link);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageAccount;

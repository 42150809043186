import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_CURRENCIES = `${REACT_APP_BASE_URL}/v1/investor/currencies`;

const fetchCurrencies = async () => {
  const res = await axios.get(`${FETCH_CURRENCIES}`);
  return res;
};

export { fetchCurrencies };

import React from 'react';
import { getInputClasses } from '../../helpers/getInputClasses';
import { ValidationErrors } from '../Business/components/ValidationErrors';

const BusinessInput = ({
  title,
  formik,
  type,
  placeholder,
  name,
  disabled,
  required,
  inputWidth,
  classes,
}) => {
  return (
    <>
      <div
        className={`${
          inputWidth ? inputWidth : 'width-345'
        } ${classes} fv-plugins-icon-container  center-input mt-7`}
      >
        {title ? (
          <label className="d-flex text-12 mb-1">
            <span className="text-dark-gray-200">{title}</span>
            <span
              style={{
                display: 'inline',
                top: '15px',
                color: '#F05B7F',
                marginLeft: '5px',
              }}
            >
              *
            </span>
          </label>
        ) : null}

        <input
          type={type}
          className={`form-control height-45 border-gray-450 pr-3 ${getInputClasses(
            `${name}`,
            formik,
          )}`}
          placeholder={placeholder}
          name={name}
          disabled={disabled ? true : false}
          min={type === 'number' ? 0 : null}
          {...formik.getFieldProps(`${name}`)}
          autoComplete="off"
        />

        {required && required}

        {formik.touched[name] && formik.errors[name] ? (
          <ValidationErrors errorMessage={formik.errors[name]} />
        ) : null}
      </div>
    </>
  );
};

export default BusinessInput;

import StateManager from 'react-select';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  TokenValid: '[TokenValid] Action',
  UpdateAuthPageLogo: '[UpdateAuthPageLogo] Action',
  ResetAuthPageLogo: '[ResetAuthPageLogo] Action',
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  tokenValid: false,
  riskProfile: null,
  authPageLogo: null,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.Login: {
      const { authToken } = action.payload;
      localStorage.setItem('ndovuToken', authToken.accessToken);
      return {
        ...StateManager,
        authToken,
      };
    }

    case actionTypes.Register: {
      const { authToken } = action.payload;

      return { ...state, authToken };
    }

    case actionTypes.Logout: {
      return initialAuthState;
    }

    case actionTypes.TokenValid: {
      const { tokenValid } = action.payload;

      return { ...state, tokenValid };
    }

    case actionTypes.UpdateAuthPageLogo: {
      const { authPageLogo } = action.payload;
      return {
        ...state,
        authPageLogo,
      };
    }

    case actionTypes.ResetAuthPageLogo: {
      return {
        ...state,
        authPageLogo: null,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  login: authToken => ({ type: actionTypes.Login, payload: { authToken } }),

  accessTokenValidated: tokenValid => {
    localStorage.setItem('tokenValidated', tokenValid);
    return {
      type: actionTypes.TokenValid,
      payload: { tokenValid },
    };
  },

  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),

  updateAuthPageLogo: authPageLogo => ({
    type: actionTypes.UpdateAuthPageLogo,
    payload: { authPageLogo },
  }),

  resetAuthPageLogo: () => ({
    type: actionTypes.ResetAuthPageLogo,
  }),

  logout: () => ({ type: actionTypes.Logout }),
};

export function* saga() {}

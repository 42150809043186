import React from 'react';

const Alert = ({ alert, alertRef, alignText }) => {
  return (
    <div
      className={`mx-2 mb-2 fade show text-13`}
      role="alert"
      style={{
        color: alert?.alertMessageType === 'success' ? '#67CCCA' : '#F05B7F',
        margin: '0px -10px',
      }}
      ref={alertRef}
    >
      <div
        className={`${alignText ? alignText : 'text-center'} font-weight-500`}
      >
        {alert.alertMessage && Array.isArray(alert.alertMessage)
          ? alert.alertMessage.map((error, index) => <p key={index}>{error}</p>)
          : alert.alertMessage}
      </div>
    </div>
  );
};

export default Alert;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import SavingPlanCard from '../components/SavingPlanCard';
import SavingAccountMesssage from '../components/SavingAccoutMessage';
import { FundType } from '../../../helpers/constants';
import { messages } from '../../../utils/messages';
import { fetchPlans } from '../_redux/plansActions';
import { firstLetterUpperCase } from '../../../helpers/investmentHelpers';
import Back from '../../Common/BackArrow';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

const SavingPlans = () => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState(false);

  const fetchAllPlans = async () => {
    try {
      setLoading(true);
      const res = await fetchPlans(FundType.SAVE);
      const allPlans = Object.values(res.data.data).reduce((a, b) => {
        a = a.concat(b);
        return a;
      }, []);
      setPlans(allPlans.reverse());
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllPlans();
  }, []);

  const getThePlans = () => {
    return (
      <>
        <h3 className="text-center my-8">Fixed Return Accounts</h3>
        <div className="p-3 saving-plans-container px-5">
          {plans.map(plan => (
            <div className="mr-5" key={plan?.id}>
              <SavingPlanCard
                classes={
                  plan?.name === 'ndovu Sure Supreme'
                    ? 'saving-plan-card-border'
                    : null
                }
                data={plan}
                key={plan?.id}
                planTypeName={firstLetterUpperCase(plan?.planType?.name)}
                url={`/plans/fixed-return/${plan?.id}`}
              />
            </div>
          ))}
        </div>

        <SavingAccountMesssage
          message={messages.savingFundAlert}
          classes="mx-7 mb-7"
        />
      </>
    );
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : plans && plans.length ? (
        <>
          {/* <Back text={'Back'} onClick={() => history.push('/new-investment')} /> */}
          <div className="card gutter-b internal-container">
            <div className={classes.root}>{getThePlans()}</div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SavingPlans;

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { checkIsActive } from '../../../../_helpers';

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

import { menuList } from './MenuList';
import MenuItem from './MenuItem';
import { useSelector } from 'react-redux';
import { renderMenuItemsBool } from './renderMenuItemsBool';
import { trackMenuItemClickEvent } from '../../../../_helpers/trackMenuItemClickevent';

export function AsideMenuList({ layoutProps }) {
  const [hoverBackground, setHoverBackground] = useState('');
  const [menu, setMenu] = useState(menuList);
  const location = useLocation();
  const { originPartner } = useSelector(state => state?.profile);
  const menuIdTags = originPartner?.tagsToDisplay?.menuIdTags;

  const changeIconOnHover = (name, action) => {
    let hover = false;
    const newArray = [];
    menu.forEach(element => {
      if (element.name === name) {
        if (action === 'mouseOver') {
          hover = true;
          element.hover = hover;
        }
        if (action === 'mouseOut') {
          hover = false;
          element.hover = hover;
        }
      }
      newArray.push(element);
    });
    setMenu(newArray);
  };

  useEffect(() => {
    const newArray = [];
    menu.forEach(element => {
      if (location.pathname.startsWith(element.path)) {
        element.active = true;
      } else {
        element.active = false;
      }
      newArray.push(element);
    });
    setMenu(newArray);
  }, [location.pathname]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'active-menu-item'}`
      : '';
  };

  const handleHover = id => {
    const linkText = document.getElementById(id);
    linkText.classList.add('hover-color');
    setHoverBackground('hover-background');
    changeIconOnHover(id, 'mouseOver');
  };

  const handleMouseOut = id => {
    const linkText = document.getElementById(id);
    linkText.classList.remove('hover-color');
    setHoverBackground('');
    changeIconOnHover(id, 'mouseOut');
  };

  const closeLeftMenuAsideInMobile = async menuName => {
    let asideItemElement = document.getElementById('kt_aside');
    let asideItemToggleBtn = document.getElementById('kt_aside_mobile_toggle');
    asideItemElement.classList.remove('aside-on');
    asideItemToggleBtn.click();
    await trackMenuItemClickEvent(menuName);
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {menu.map(item => {
          const displayMenuItem = renderMenuItemsBool(
            item,
            originPartner,
            menuIdTags,
          );

          return displayMenuItem ? (
            <MenuItem
              key={item.name}
              getMenuItemActive={getMenuItemActive}
              path={item.path}
              handleHover={handleHover}
              handleMouseOut={handleMouseOut}
              closeLeftMenuAsideInMobile={() =>
                closeLeftMenuAsideInMobile(item.name)
              }
              hoverBackground={hoverBackground}
              icon={
                item.active || item.hover ? item.icon.white : item.icon.active
              }
              name={item.id}
              menuName={item.title}
              paddingBottom="padding-bottom"
            />
          ) : null;
        })}
      </ul>
    </>
  );
}

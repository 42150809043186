import React from 'react';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers/';

import './KycProgressBar.scss';

const KycProgressBar = ({ stepperData }) => {
  return (
    <div className="d-flex justify-content-center align-items-center kyc-progress-bar my-5 px-10">
      {stepperData.map(step => (
        <div key={step.id}>
          <div>
            <span className="mr-1 svg-icon svg-icon-md">
              <SVG
                src={toAbsoluteUrl(
                  `/media/svg/ndovu/${step.done ? 'yellow' : 'blue'}-check.svg`,
                )}
                className="kyc-icon"
              />
            </span>
            <span className="kyc-progress-bar-text text-14 font-weight-500">
              {step.text}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default KycProgressBar;

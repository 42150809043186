/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';

import * as auth from '../../Auth/_redux/authRedux';
import * as profile from '../../Profile/_redux/profileRedux';

import ProgressBar from './progress-bar/ProgressBar';
import { connect } from 'react-redux';
import { progressBarWidth } from '../../../utils/progressBarWidth';
import { routes } from '../../../utils/routes';
import { tenPercentInvestmentLossChoices } from '../helpers/choices';
import { updateLoggedInUserInvestorProfile } from '../_redux/wizardCrud';

import './../index.scss';
import Exit from './Exit';
import { CancelButton, ConfirmButton } from '../../Common/ReusableButtons';

const TenPercentInvestmentLossWizardPage = props => {
  const token = localStorage.getItem('ndovuToken');
  let history = useHistory();

  const [tenPercentInvestmentLoss, setTenPercentInvestmentLoss] = useState(
    null,
  );
  const [wizardData, setWizardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [referralCompleted, setReferralCompleted] = useState();
  const isRetakingWizard = localStorage.getItem('retakingTheWizard');

  useEffect(() => {
    const localStorageWizard = JSON.parse(localStorage.getItem('wizard'));
    setWizardData(localStorageWizard);
    setReferralCompleted(localStorage.getItem('referralCompleted'));
    setTenPercentInvestmentLoss(localStorageWizard?.tenPercentInvestmentLoss);
  }, [wizardData?.tenPercentInvestmentLoss]);

  const clickChoice = (e, id) => {
    const elementsWithAnim = document.querySelectorAll('.animated-card');
    elementsWithAnim.forEach(each => {
      if (each !== e.target) {
        return each.classList.add('animate__animated');
      } else {
        return each.classList.add(
          'animate__animated',
          'animate__pulse',
          'bg-info-blue',
        );
      }
    });
    e.target.addEventListener('animationend', () => {
      localStorage.setItem(
        'wizard',
        JSON.stringify({ ...wizardData, tenPercentInvestmentLoss: id }),
      );
      setTenPercentInvestmentLoss(id);
      token === null && history.push('/wizard/personalDetails');
    });
  };

  const cancelWizard = () => {
    localStorage.removeItem('wizard');
    localStorage.removeItem('retakingTheWizard');
    history.push('/dashboard');
  };

  const submitUserValues = async e => {
    const wizardPayload = JSON.parse(localStorage.getItem('wizard'));

    e.preventDefault();

    try {
      setLoading(true);

      const response = await updateLoggedInUserInvestorProfile(wizardPayload);

      if (response.status === 200 || response.status === 201) {
        localStorage.removeItem('wizard');
        props.fetchProfileAction();

        setLoading(false);
        setAlertOn(true);
        setAlert({
          alertMessage: 'Your investor profile has been successfully created.',
          alertMessageType: 'alert-light-success',
        });

        const user = { accessToken: token, user: response?.data?.data };

        props.login(user);
        props.profileAction(response?.data?.data);
        history.push('/investor-type');
      }
    } catch (error) {
      setLoading(false);
      setAlertOn(true);
      setAlert({
        alertMessage: error?.response?.data?.message
          ? error.response.data.message
          : 'unable to create investor profile. Try again',
        alertMessageType: 'alert-light-danger',
      });
    }
  };
  return (
    <div>
      <div className="container">
        <ProgressBar width={progressBarWidth(token)} />
        <div className="card-body p-0">
          <div
            className="wizard wizard-1 animate__animated animate__fadeInUp"
            id="kt_wizard"
            data-wizard-state="step-first"
            data-wizard-clickable="false"
          >
            {/* <!--begin::Alert--> */}
            {alertOn ? (
              <div
                className={`alert alert-custom ${alert.alertMessageType} mt-10 fade show`}
                role="alert"
              >
                {Array.isArray(alert.alertMessage) ? (
                  <div className="alert-text font-weight-bold">
                    <ul>
                      {alert.alertMessage.map(message => (
                        <li key={message}>{message}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="alert-text font-weight-bold">
                    {alert.alertMessage}
                  </div>
                )}
              </div>
            ) : null}
            {/* {/* <!--end::Alert--> */}
            {/* <!--begin::Wizard Body--> */}
            <div className="row justify-content-center my-10 px-1 my-lg-15 px-lg-10">
              <div className="col-xl-12">
                {/* <!--begin::Wizard Form--> */}
                <form className="form" id="kt_form">
                  <div
                    className="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h2 className="mb-5 font-weight-bold text-dark">
                      What would you do if your investment portfolio value
                      declined by 10% ?
                    </h2>
                    {isRetakingWizard ? (
                      <Exit cancelWizard={cancelWizard} />
                    ) : null}
                  </div>
                  <div className="row">
                    {tenPercentInvestmentLossChoices.map(eachChoice => {
                      return (
                        <div className="col-xl-12" key={eachChoice.id}>
                          {eachChoice.id === tenPercentInvestmentLoss ? (
                            <div
                              onClick={e => clickChoice(e, eachChoice.id)}
                              className="card card-custom wizard-custom-selector animated-card gutter-b"
                              style={{
                                background: '#0071CE',
                                color: '#ffff',
                              }}
                            >
                              {/* <!--begin::Body--> */}
                              <div
                                className="card-body d-flex flex-row"
                                style={{ padding: '1.5rem 2.25rem' }}
                              >
                                <div className="font-weight-light font-size-h4">
                                  {eachChoice.choice}
                                </div>

                                {/* <!--end::Stats--> */}
                              </div>
                              {/* <!--end::Body--> */}
                            </div>
                          ) : (
                            <div
                              onClick={e => clickChoice(e, eachChoice.id)}
                              className="card animated-card card-custom wizard-custom-selector gutter-b"
                              style={{
                                background: 'rgba(0, 113, 206, 0.05)',
                                border: '0.5px solid rgba(0, 113, 206, 0.5)',
                                borderRadius: '5px',
                              }}
                            >
                              {/* <!--begin::Body--> */}
                              <div
                                className="card-body d-flex flex-row"
                                style={{ padding: '1.5rem 2.25rem' }}
                              >
                                {/* <!--begin::Stats--> */}
                                <div className="font-weight-light font-size-h4">
                                  {eachChoice.choice}
                                </div>
                              </div>
                              {/* <!--end::Body--> */}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <>
                    <div className="d-flex d-lg-flex d-md-flex justify-content-between btn-wrapper  pt-10">
                      <Link to={routes.wizard.investmentAttitude}>
                        <CancelButton
                          buttonText="Prev"
                          buttonWidth={'width-140'}
                        />
                      </Link>

                      {token !== null ? (
                        <ConfirmButton
                          buttonText={'Submit'}
                          buttonWidth={'width-140'}
                          handleOnClick={e => submitUserValues(e)}
                          loading={loading}
                        />
                      ) : (
                        <Link to={'/wizard/personalDetails'}>
                          <ConfirmButton
                            buttonText={'Next'}
                            buttonWidth={'width-140'}
                          />
                        </Link>
                      )}
                    </div>
                  </>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => state;

export default connect(mapStateToProps, {
  ...auth.actions,
  ...profile.actions,
})(TenPercentInvestmentLossWizardPage);

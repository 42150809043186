import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { reduxBatch } from '@manaflair/redux-batch';
import { rootReducer, rootSaga } from './rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
];

/**
 * @see https://www.npmjs.com/package/redux-persist
 */

//use the whitelist whenever a new reducer is added that we need to persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'plan',
    'investment',
    'goal',
    'subscription',
    'pocketFlow',
    'pockets',
    'pocketDetails',
    'kyc',
    'businessChama',
    'createdGoalsList',
    'teamMember',
    'inviteDetails',
    'withdrawalDetails',
    'pocketTopupDetails',
    'profile',
    'currencies',
    'conversions',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  // devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch],
});

const persistedStore = persistStore(store);

sagaMiddleware.run(rootSaga);

export { persistedStore };
export default store;

import React from 'react';

import HeaderCard from './HeaderCard';
import NextButtonFooter from './NextButtonFooter';

const AfterSubscription = () => {
  return (
    <div className="card card-custom card-gutter-b">
      <HeaderCard headerText="Congratulations!" />
      <div className="card-body">
        <div className="px-4 py-4 mt-2 py-md-8 px-md-0 text-center">
          <p className="font-weight-bold">
            Your payment has gone through. You have received an email with the
            details of your Payment.
          </p>
        </div>
      </div>
      <NextButtonFooter url="/subscription" />
    </div>
  );
};

export default AfterSubscription;

import React from 'react';

const CompanyBrand = ({ companyName, brandLogo }) => {
  return (
    <div className="mt-3 mr-4 d-flex flex-column">
      {brandLogo ? (
        <>
          <div className="company-brand-image">
            <img
              style={{ width: '100%', height: '100%' }}
              src={brandLogo}
              alt="Company logo"
            />
          </div>
          <p
            style={{
              fontSize: '14px',
              fontWeight: '500',
              marginTop: '10px',
              marginLeft: '10px',
              width: '160px',
            }}
          >
            {companyName}
          </p>
        </>
      ) : (
        <>
          <div className="company-brand-image">
            <div
              style={{
                backgroundColor: '#DEECF7',
                height: '100%',
                width: '100%',
                border: 'none',
              }}
              className="company-brand"
            ></div>
          </div>
          <p style={{ fontSize: '12px', fontWeight: '500', marginTop: '10px' }}>
            {companyName}
          </p>
        </>
      )}
    </div>
  );
};

export default CompanyBrand;

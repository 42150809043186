import React from 'react';
import { Sign } from './Sign';

export const OneItem = ({ sign, amount, currency, text }) => {
  return (
    <div className="d-flex align-items-center">
      <Sign sign={sign} />
      <div className="font-weight-bolder mx-2">
        {amount} {currency}
      </div>
      <div>{text}</div>
    </div>
  );
};

import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_SUBSCRIPTIONS = `${REACT_APP_BASE_URL}/v1/investor/subscriptions`;
const DOWNGRADE_SUBSCRIPTION = `${REACT_APP_BASE_URL}/v1/investor/subscriptions/downgrade`;
const SUBSCRIBE_TO_BLACK_URL = `${REACT_APP_BASE_URL}/v1/investor/subscriptions/pay`;

const fetchSubscriptions = async () => {
  const res = await axios.get(`${FETCH_SUBSCRIPTIONS}`);
  return res;
};

const downgradeSubscription = async () => {
  const res = await axios.put(DOWNGRADE_SUBSCRIPTION);
  return res;
};

const subscribeToBlack = async (
  subscriptionId,
  txReference,
  channel,
  flwTransactionId,
) => {
  const res = await axios.post(SUBSCRIBE_TO_BLACK_URL, {
    subscriptionId,
    txReference,
    channel,
    flwTransactionId,
  });
  return res;
};

export { fetchSubscriptions, downgradeSubscription, subscribeToBlack };

import React from 'react';

import { ConfirmButton } from '../modules/Common/ReusableButtons';

export const ErrorPage = () => {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30">
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15 text-center"
          style={{ fontSize: '150px' }}
        >
          OOPS!
        </h1>
        <p className="font-size-h3 font-weight-light text-center">
          Something went wrong here
        </p>

        <ConfirmButton
          buttonText={'Back To Dashboard'}
          handleOnClick={() => window.location.replace('/')}
          classes={'mx-auto'}
        />
      </div>
    </div>
  );
};
